import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import * as moment from 'moment-timezone';

import Datetime from "react-datetime";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import LeadsIcon from "@material-ui/icons/Assignment";
import SmsIcon from "@material-ui/icons/Sms";

import CheckIcon from "@material-ui/icons/CheckCircle";

import Button from "components/CustomButtons/Button.jsx";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// react router components
import { Link } from 'react-router-dom'

import "assets/main.css";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Leads_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      lead: "",
      interest: "",
      tours: [],
      tourWaves: [],
      displayTourWaves: [],
      selectTourTime: "",
      selectTourDate: "",
      gifts: [],
      selectGift: "",
      selectGiftId: "",
      selectGiftName: "",
      giftsAdded: [],
      salesReps: [],
      selectSalesRep: "",
      marketingReps: [],
      selectMarketingRep: "",
      selectMarketingSource : "",
      calendarStartDate: moment().format('YYYY-MM-DD'),
      openBookTourDialog: false,
      leadCommunications: [],
      communicationType: "",
      communicationNote: "",
      selectAmPm: "",
      tourTimeOther: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  componentDidMount(){
  }

  getData(leadId){

    tikiAPI.getLead(leadId,
      function(leadResponse) {
        this.setState({lead: leadResponse});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

    tikiAPI.getCommunicationForLead(leadId,
      function(response) {
        this.setState({leadCommunications: response});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

    tikiAPI.getInterestsForLead(leadId,
      function(interestsResponse) {
        this.setState({interest: interestsResponse});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

    tikiAPI.getToursForLead(leadId,
      function(toursResponse) {
        this.setState({tours: toursResponse});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

    tikiAPI.getSalesReps(
      function(salesRepsResponse) {
        this.setState({salesReps: salesRepsResponse});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

    tikiAPI.getMarketingReps(
      function(marketingRepsResponse) {
        this.setState({ marketingReps: marketingRepsResponse });
      }.bind(this),
      function(error) {
        console.log(error);
      });

    tikiAPI.getAvailableGifts(
      function(giftsResponse) {
        this.setState({gifts: giftsResponse});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );
  
  }

  postTour(salesRepId, marketingRepId) {

    var giftNames = "";

    for (var i = 0; i < this.state.giftsAdded.length; i++) {
      giftNames += this.state.giftsAdded[i].giftName + ", ";
    }
    giftNames = giftNames.substring(0, giftNames.length - 2);
    
    var tour = {
      "leadId": this.state.lead.leadId,
      "leadFirstName": this.state.lead.firstName,
      "leadLastName": this.state.lead.lastName,
      "assignedEmployeeId": this.state.selectSalesRep,
      "marketingEmployeeId": this.state.selectMarketingRep,
      "marketingSource": this.state.selectMarketingSource,
      "tourStatus": "Scheduled",
      "giftNames": giftNames,
      "createdBy": this.state.user.userId,
      "tourDate": this.state.selectTourDate,
      "scheduledStartTime": this.state.selectTourDate + " " + this.state.selectTourTime
    };

    tikiAPI.createNewTour(tour,
      function(tourResponse) {
        this.postGiftTransactions(tourResponse.tourId);
        this.getData(this.props.match.params.id);
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

  }
    
  bookNewTour = (e) => {
    document.getElementById("newTourDiv").classList.remove("hide");
    document.getElementById("bookTourButton").classList.add("hide");
    //this.setState({ openBookTourDialog: true });
  }

  handleClose = () => {
    this.setState({ openBookTourDialog: false });
  };

  postGiftTransactions (tourId) {
    /* TODO: Remove temporarily until ledger is refactored

    for (var i = 0; i < this.state.giftsAdded.length; i++) {
      console.log(this.state.giftsAdded[i]);
      var giftTransaction = {
        "createdBy": 0,
        "tourId": tourId,
        "leadId": this.state.lead.leadId,
        "giftTypeId": this.state.giftsAdded[i].giftTypeId,
        "transactionType": "TourGift",
        "debitOrCredit": "Debit",
        "giftQty": 1
      }

      tikiAPI.createGiftLedgerTransaction(giftTransaction,
        function(giftTransactionResponse) {
          // Nothing to do here?
        },
        function(error) {
          console.log(error);
        }
      );

    }

    //Reset form values
    this.setState({
      selectGift: "",
      selectGiftId: "",
      selectGiftName: "",
      selectTourWave: "",
      selectSalesRep: "",
      giftsAdded: [],  
    });
*/
  }

  handleAddGift = () => {
    var gifts = this.state.giftsAdded;
    var gift = {"giftName": this.state.selectGift};
    gifts.push(gift);
    console.log(gifts);
    this.setState({"giftsAdded": gifts, "selectGift": ""});
  }

  handleBookTour = () => {
    this.postTour();
    document.getElementById("newTourDiv").classList.add("hide");
    document.getElementById("bookTourButton").classList.remove("hide");
  };

  editLead() {
    window.location = '/admin/leads-edit/' + this.state.lead.leadId;
  }

  createLeadCommunication() {
    var newComm = {};
    newComm.CreatedAt = new Date();
    newComm.CreatedBy = this.state.user.firstName + " " + this.state.user.lastName;
    newComm.Type = this.state.communicationType;
    newComm.Note = this.state.communicationNote;

    if (this.state.leadCommunications) {
      this.state.leadCommunications.unshift(newComm);
    } else {
      this.state.leadCommunications = new Array();
      this.state.leadCommunications.unshift(newComm);
    }

    this.setState({ lead: {
      ...this.state.lead,
      communications: JSON.stringify(this.state.leadCommunications)
    }}, () => {

      tikiAPI.updateLead(this.state.lead,
        function() {
          this.setState({ communicationNote: "", communicationType: "" })
          var cssClass = document.getElementById('communicationType').className;
          cssClass = cssClass + ' placeholderColor';
          document.getElementById('communicationType').className = cssClass;      
        }.bind(this),
          function(err) {
            console.log(err);
          }
        );
      }

    );

  }

  handleInput = event => {
    this.setState({ [event.target.id]: event.target.value });

    this.removePlaceholder(event);
  }

  removePlaceholder = event => {
    var cssClass = event.target.className;
    cssClass = cssClass.replace(' placeholderColor', '');
    event.target.className = cssClass;
  }

  selectTourDate = date => {
    var selectedDate = date.format("MM/DD/YYYY");
    this.setState({ selectTourDate: selectedDate });
  }

  render() {  

    const { classes, fullScreen } = this.props;

    return (
      <Can permissions={this.state.user.permissions} perform="Leads_Detail" yes={() => (

      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <LeadsIcon />
              </CardIcon>
              <h4 className={classes.cardTitle}>Lead Detail</h4>
            </CardHeader>
              <CardBody>  

              <Can permissions={this.state.user.permissions} perform="Leads_Edit" yes={() => (
                <GridContainer>
                  <GridItem xs={12}><div align="center"><Button color="success" onClick={(e) => this.editLead()}>Edit</Button></div></GridItem>
                </GridContainer>
              )} />

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Lead Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Name</div>
                      <div className="displayContent">{this.state.lead.firstName} {this.state.lead.middleName} {this.state.lead.lastName}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Lead #</div>
                      <div className="displayContent">{this.state.lead.leadId}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                <Can permissions={this.state.user.permissions} perform="Leads_Sensitive" yes={() => (
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Address</div>
                      <div className="displayContent">{this.state.lead.address1}<br />{this.state.lead.city}, {this.state.lead.state} {this.state.lead.zipcode} {this.state.lead.country}</div>
                    </div>
                    </GridItem>
                )} />
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Mobile Phone</div>
                      <Can permissions={this.state.user.permissions} perform="Other_Beta" yes={() => (
                        <div className="displayContent">{this.state.lead.smsOptIn == 'true' && <CheckIcon style={{ color: "#4caf50", fontSize: 12, position: "relative", top: "2px", marginRight: "4px" }} />}

                        <Can permissions={this.state.user.permissions} perform="Leads_Sensitive" yes={() => (
                          <span>{this.state.lead.mobilePhone}</span>
                        )} />
                        
                        </div>

                      )}
                      no={() => (

                        <Can permissions={this.state.user.permissions} perform="Leads_Sensitive" yes={() => (
                          <div className="displayContent">{this.state.lead.mobilePhone}</div>
                        )} />

                      )} />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Home Phone</div>
                      <div className="displayContent">{this.state.lead.homePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Work Phone</div>
                      <div className="displayContent">{this.state.lead.workPhone}</div>
                    </div>
                  </GridItem>
                <Can permissions={this.state.user.permissions} perform="Leads_Sensitive" yes={() => (
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Email</div>
                      <div className="displayContent">{this.state.lead.email}</div>
                    </div>
                  </GridItem>
                )} />
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Spouse Name</div>
                      <div className="displayContent">{this.state.lead.spouseFirstName} {this.state.lead.spouseLastName}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Spouse Mobile Phone</div>
                      <div className="displayContent">{this.state.lead.spouseMobilePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Spouse Home Phone</div>
                      <div className="displayContent">{this.state.lead.spouseHomePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Spouse Work Phone</div>
                      <div className="displayContent">{this.state.lead.spouseWorkPhone}</div>
                    </div>
                  </GridItem>
                <Can permissions={this.state.user.permissions} perform="Leads_Sensitive" yes={() => (
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Spouse Email</div>
                      <div className="displayContent">{this.state.lead.spouseEmail}</div>
                    </div>
                  </GridItem>
                )} />
                </GridContainer>
                
                <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Tours</div></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={11}>
                      <div>
                        <table>
                          <thead>                    
                          <tr>
                            <th>Tour Date</th>
                            <th>Tour Status</th>
                            <th>Tour Result</th>
                            <th>Marketing Source</th>
                            <th>Gift(s)</th>
                            <th>Sales Rep</th>
                            <th>Marketing Rep</th>
                            <th>Notes</th>
                          </tr>
                          </thead>

                          <tbody>
                          { (this.state.tours.length > 0) ? (
                              this.state.tours.map((tour, index) => (
                                <tr key={index}>
                                  <td><Link to={`/admin/tours-detail/${tour.tourId}`} className="ui basic button green">{moment(tour.scheduledStartTime).format('MM/DD/YYYY hh:mm a')}</Link></td>
                                  <td>{tour.tourStatus}</td>
                                  <td>{tour.tourResult}</td>
                                  <td>{tour.marketingSource}</td>
                                  <td width="20%">{tour.giftNames}</td>
                                  <td>{tour.assignedEmployeeFirstName} {tour.assignedEmployeeLastName}</td>
                                  <td>{tour.marketingEmployeeFirstName} {tour.marketingEmployeeLastName}</td>
                                  <td width="20%">{tour.notes}</td>
                                </tr>
                              ))                            
                            ) : (
                              <tr><td colSpan="7">No tours available.</td></tr>
                            )
                          }

                        <Can permissions={this.state.user.permissions} perform="Tours_New" yes={() => (

                        <tr><td><div id="bookTourButton"><Button color="info" onClick={(e) => this.bookNewTour(e)}>Book New Tour</Button></div></td></tr>

                        ) } />

                        </tbody>
                      </table>
                      </div>
                    </GridItem>
                  </GridContainer>

                  <div id="newTourDiv" className="hide">
                    <GridContainer>
                      <GridItem xs={12}><div className="softDivider">New Tour</div></GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={2}>
                        <div className="DateTime-adjust">
                          <Datetime dateFormat="MM/DD/YYYY" inputProps={{ placeholder: 'Choose Tour Date'}} timeFormat={false} closeOnSelect={true} onChange={this.selectTourDate} />
                        </div>
                      </GridItem>

                      <GridItem xs={2}>
                        <select id="selectTourTime" value={this.state.selectTourTime || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                          <option value="">Select Tour Time</option>
                          <option value="">Select Tour Time</option>
                          <option value="09:00 AM">09:00 AM</option>
                          <option value="09:15 AM">09:15 AM</option>
                          <option value="09:30 AM">09:30 AM</option>
                          <option value="09:45 AM">09:45 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="10:15 AM">10:15 AM</option>
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="10:45 AM">10:45 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="11:15 AM">11:15 AM</option>
                          <option value="11:30 AM">11:30 AM</option>
                          <option value="11:45 AM">11:45 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="12:15 PM">12:15 PM</option>
                          <option value="12:30 PM">12:30 PM</option>
                          <option value="12:45 PM">12:45 PM</option>
                          <option value="01:00 PM">01:00 PM</option>
                          <option value="01:15 PM">01:15 PM</option>
                          <option value="01:30 PM">01:30 PM</option>
                          <option value="01:45 PM">01:45 PM</option>
                          <option value="02:00 PM">02:00 PM</option>
                          <option value="02:15 PM">02:15 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="02:45 PM">02:45 PM</option>
                          <option value="03:00 PM">03:00 PM</option>
                        </select>
                      </GridItem>

                      <GridItem xs={2}>
                        <select id="selectSalesRep" value={this.state.selectSalesRep || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                            <option>Select Sales Rep</option>

                            { (this.state.salesReps.length > 0) ? (
                                  this.state.salesReps.map((salesRep, index) => (
                                    <option key={index} value={salesRep.employeeId}>
                                      {salesRep.firstName} {salesRep.lastName}
                                    </option>
                                  ))                            
                                ) : (
                                  <option>No available sales reps.</option>
                                )
                              }

                        </select>
                      </GridItem>

                      <GridItem xs={2}>
                        <select id="selectMarketingRep" value={this.state.selectMarketingRep || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                            <option>Select Marketing Rep</option>

                            { (this.state.marketingReps.length > 0) ? (
                                  this.state.marketingReps.map((marketingRep, index) => (
                                    <option key={index} value={marketingRep.employeeId}>
                                      {marketingRep.firstName} {marketingRep.lastName}
                                    </option>
                                  ))                            
                                ) : (
                                  <option>No available marketing reps.</option>
                                )
                              }

                        </select>
                      </GridItem>

                      <GridItem xs={4}>
                        <select id="selectMarketingSource" value={this.state.selectMarketingSource ||''} onChange={(event) => this.handleInput(event)} className="formSelect">
                          <option value="">Marketing Source</option>
                          <option value="RMC Check-In">RMC Check-In</option>
                          <option value="Spinnaker Wine and Cheese">Spinnaker Wine and Cheese</option>
                          <option value="Spinnaker Breakfast">Spinnaker Breakfast</option>
                          <option value="Spinnaker Check-In">Spinnaker Check-In</option>
                          <option value="Village Check-In">Village Check-In</option>
                          <option value="Village Wine and Cheese">Village Wine and Cheese</option>
                          <option value="Village Breakfast">Village Breakfast</option>
                          <option value="Southwind II Wine and Cheese">Southwind II Wine and Cheese</option>
                          <option value="Southwind II Check-In">Southwind II Check-In</option>
                          <option value="Sea Crest Check-In">Sea Crest Check-In</option>
                          <option value="Sea Crest Wine and Cheese">Sea Crest Wine and Cheese</option>
                          <option value="Sea Crest Breakfast">Sea Crest Breakfast</option>
                          <option value="Seawatch Check-In">Seawatch Check-In</option>
                          <option value="Seawatch Wine and Cheese">Seawatch Wine and Cheese</option>
                          <option value="Seawatch Breakfast">Seawatch Breakfast</option>
                          <option value="The Players Club Check-In">The Players Club Check-In</option>
                          <option value="The Players Club Wine and Cheese">The Players Club Wine and Cheese</option>
                          <option value="The Players Club Breakfast">The Players Club Breakfast</option>
                          <option value="Sands Village Check-In">Sands Village Check-In</option>
                          <option value="Ocean Cove Check-In">Ocean Cove Check-In</option>
                          <option value="Ocean Cove Wine and Cheese">Ocean Cove Wine and Cheese</option>
                          <option value="Springwood Check-In">Springwood Check-In</option>
                          <option value="Marriott's Grande Ocean Wine and Cheese">Marriott's Grande Ocean Wine and Cheese</option>
                          <option value="Ocean View Wine and Cheese">Ocean View Wine and Cheese</option>
                          <option value="Sands Village Wine and Cheese">Sands Village Wine and Cheese</option>
                          <option value="Flyer">Flyer</option>
                          <option value="Flynn">Flynn</option>
                          <option value="Invitation">Invitation</option>
                          <option value="Mimosas">Mimosas</option>
                          <option value="Mailout">Mailout</option>
                          <option value="Mini">Mini</option>
                          <option value="Telemarketing">Telemarketing</option>
                          <option value="OPC">OPC</option>
                          <option value="Walk-In">Walk-In</option>
                          <option value="Call In">Call In</option>
                          <option value="Brochure">Brochure</option>
                          <option value="Be Back">Be Back</option>
                          <option value="Referral">Referral</option>
                          <option value="Pool">Pool</option>
                          <option value="RMC Staff">RMC Staff</option>
                          <option value="GMG">GMG</option>
                          <option value="Digital Science">Digital Science</option>
                          <option value="Black Jet">Black Jet</option>
                          <option value="Allen Marketing Group (AMG)">Allen Marketing Group (AMG)</option>
                          <option value="Other"></option>
                        </select>
                      </GridItem>


                      <GridItem xs={1}>
                        <div className="hide"><input id="tourTimeOther" value={this.state.tourTimeOther || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="00:00"></input></div>
                      </GridItem>

                      <GridItem xs={1}>
                        <div className="hide">
                          <select id="selectAmPm" value={this.state.selectAmPm || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                            <option value="">AM/PM</option>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </div>
                      </GridItem>

                    </GridContainer>



                    <GridContainer>
                      <GridItem>
                        <select id="selectGift" value={this.state.selectGift || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                              <option>Select Gift</option>

                              { (this.state.gifts.length > 0) ? (
                                    this.state.gifts.map((gift, index) => (
                                      <option key={index} value={gift.giftName}>
                                        {gift.giftName}
                                      </option>
                                    ))                            
                                  ) : (
                                    <option>No available gifts.</option>
                                  )
                                }
                        </select>

                      <Button style={{width: 100}} onClick={this.handleAddGift} color="success" autoFocus variant="contained">Add Gift</Button>

                    <div>
                      <ul>
                      { this.state.giftsAdded.map((gift, index) => (
                            <li key={index}>{gift.giftName}</li>
                          )
                        )
                      }
                      </ul>
                    </div>

                      </GridItem> 
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={2}><Button color="info" onClick={(e) => this.handleBookTour(e)}>Save</Button></GridItem>
                    </GridContainer>
                  </div>
                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Marketing Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Guest Vacation Location</div>
                      <div className="displayContent">{this.state.lead.guestVacationLocation}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Marketing Source</div>
                      <div className="displayContent">{this.state.lead.marketingSource}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Marketing Campaign</div>
                      <div className="displayContent">{this.state.lead.marketingCampaign}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Demographic Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Age</div>
                      <div className="displayContent">{this.state.lead.age}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Credit Score</div>
                      <div className="displayContent">{ this.state.lead.creditScore >= 600 ? "Approved for Credit" : "Traditional Purchase" }</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Marital Status</div>
                      <div className="displayContent">{this.state.lead.maritalStatus}</div>
                    </div>
                  </GridItem>
                <Can permissions={this.state.user.permissions} perform="Leads_Sensitive" yes={() => (
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Income</div>
                      <div className="displayContent">{tikiAPI.numberToDisplay(this.state.lead.incomeTier)}</div>
                    </div>
                  </GridItem>
                )} />
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Occupation</div>
                      <div className="displayContent">{this.state.lead.occupation}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                  <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Interests</div></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={11}>
                      <table>
                        <thead>
                          <tr>
                            <th>Resort</th>
                            <th>Unit #</th>
                            <th>Bedrooms</th>
                            <th>Price</th>
                            <th>Week #</th>
                            <th>Fixed/Float</th>
                            <th>Use Year Type</th>
                            <th>Season</th>
                            <th>RCI Points</th>
                            <th width="40%">Notes</th>
                          </tr>
                          </thead>

                          <tbody>
                          { (this.state.interest) ? (
                              <tr>
                                <td>{this.state.interest.resort}</td>
                                <td>{this.state.interest.unitNumber}</td>
                                <td>{this.state.interest.bedrooms}</td>
                                <td>{tikiAPI.numberToDisplay(this.state.interest.price)}</td>
                                <td>{this.state.interest.weekNumber}</td>
                                <td>{this.state.interest.fixedFloat}</td>
                                <td>{this.state.interest.useYearType}</td>
                                <td>{this.state.interest.season}</td>
                                <td>{this.state.interest.rciPoints}</td>
                                <td>{this.state.interest.notes}</td>
                              </tr>
                            ) : (
                              <tr><td>No interests are available.</td></tr>
                            )
                          }
                          </tbody>
                        </table>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Lead Communications</div></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={8} md={3}>
                      <div>
                        <div className="inputLabel">Communication Type</div>
                        <select id="communicationType" value={this.state.communicationType || ''} onChange={(event) => this.handleInput(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                          <option value="">Communication Type</option>
                          <option value="In Person">In Person</option>
                          <option value="Phone Call">Phone Call</option>
                          <option value="Email">Email</option>
                          <option value="Letter">Letter</option>
                          <option value="Other">Other</option>
                        </select> 
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={5}>
                      <div>
                        <div className="inputLabel">Note</div>
                        <textarea rows="3" id="communicationNote" value={this.state.communicationNote || ''} onChange={(event) => this.handleInput(event)} className="formInput" placeholder="Add note..."></textarea>
                      </div>
                    </GridItem>
                    <GridItem xs={12} md={2}>
                        <div className="verticalAlignButton"><Button size="sm" color="info" onClick={(e) => this.createLeadCommunication()}>Save</Button></div>
                    </GridItem>
                </GridContainer>

                  <GridContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>From/Phone</th>
                        <th>To/Phone</th>
                        <th width="60%">Message</th>
                      </tr>
                    </thead>

                    <tbody>

                      {this.state.leadCommunications && this.state.leadCommunications.map((comm, index) => (
                        <tr key={index}>
                          <td width="10%">{moment(comm.createdAt).format('MM/DD/YYYY hh:mm A')}</td>
                          <td width="10%">{comm.communicationType}</td>
                          <td width="15%">{comm.fromName}<br/>{comm.fromPhoneNumber}</td>
                          <td width="15%">{comm.toName}<br/>{comm.toPhoneNumber}</td>
                          <td>{comm.message}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>       
        </GridContainer>

      </div>

      )} />
      
      );
    }
}

export default withStyles(styles)(Leads_Detail);