import React from "react";
import { TikiAPI } from "../../global";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ToursIcon from "@material-ui/icons/DateRange";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();


const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      tours: [],
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
            this.setState({ user: userData });
            this.getAllTours();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  componentDidMount() {
    this.getAllTours();
    console.log(this.state.tours);
  }

  getAllTours() {

    tikiAPI.getAllTours(
      function(data) {
          console.log(data);
          data.sort(tikiAPI.sortByTourDate);
          console.log(data);
          this.setState({ tours: data });
      }.bind(this),
      function(error) { console.log(error) }
    );
  }
 
  render() {
    const { classes } = this.props;
    return (

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ToursIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>All Tours</h4>
              </CardHeader>
              <CardBody>
              <GridContainer>
                <table>
                    <tr>
                        <th>Tour ID</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Sales Rep</th>
                        <th>Lead Name</th>
                        <th>Status</th>
                        <th>Result</th>
                        <th>Gifts</th>
                    </tr>

                    {
                    (this.state.tours.length > 0) ? (
                        this.state.tours.map((tour, index) => (
                            <tr key={index}>
                                <td>{ tour.tourId }</td>
                                <td>{ moment(tour.tourDate).format("MM/DD/YYYY") }</td>
                                <td>{ moment(tour.scheduledStartTime).format("hh:mma") }</td>
                                <td>{ tour.assignedEmployeeFirstName } { tour.assignedEmployeeLastName }</td>
                                <td>{ tour.leadFirstName } { tour.leadLastName }</td>
                                <td>{ tour.tourStatus }</td>
                                <td>{ tour.tourResult }</td>
                                <td>{ tour.giftNames }</td>
                            </tr>
                        ))                            
                    ) : (
                        <option>No tours found.</option>
                    )
                    }

                </table>
              </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </div>

    );
  }
}

export default withStyles(styles)(Reports);