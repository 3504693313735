import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import ContractsIcon from "@material-ui/icons/InsertDriveFile";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

// react router components
import { Link } from 'react-router-dom'

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Epic_Contracts_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      epicContract: {},
      salesReps: [],
      updateContractStatus: "",
      disableButton: false
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getEpicContract(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getEpicContract(epicContractId) {
    tikiAPI.getEpicContract( epicContractId,
      function(response) {
        console.log(response);
        console.log(tikiAPI.generateTilaForContract(response));
        this.setState( { epicContract: response, updateContractStatus: response.contractStatus });
      }.bind(this),
      function(err) {
        console.log(err);
      })
  }

  handleInput = event => {
    this.setState({ [event.target.id]: event.target.value });
  }

  changeContractStatus() {
    var element = document.getElementById("changeContractStatusButton");
    element.classList.add("hide");

    var element = document.getElementById("changeContractStatusSelect");
    element.classList.remove("hide");
  }

  saveContractStatus() {
    console.log(this.state.updateContractStatus );
    this.setState({ epicContract: {
      ...this.state.epicContract,
      contractStatus: this.state.updateContractStatus }
    }, function() {
      tikiAPI.updateEpicContract(this.state.epicContract,
        function(epicContractResponse) {
          console.log("Contract Updated");
        },
        function(error) {
          console.log(error);
        });

        var element = document.getElementById("changeContractStatusSelect");
        element.classList.add("hide");
    
        var element = document.getElementById("changeContractStatusButton");
        element.classList.remove("hide");
    
    })
  }

  editContract() {
    window.location = '/admin/epic-contracts-edit/' + this.state.epicContract.epicContractId;
  }

  generateContract() {
    if (!this.state.disableSave) {
      document.getElementById("generateContractButton").innerHTML = "Please wait...";
      
      this.setState( { 
        disableButton: true
      });

      tikiAPI.printEpicContract(this.state.epicContract.epicContractId,
        function(epicContractResponse) {
          this.setState( {epicContract: epicContractResponse} , function() {

            document.getElementById("generateContractButton").innerHTML = "Done";

          })
        }.bind(this),
        function(error) {
          console.log(error);
        }
      );  
    }

  }

  uploadSignedDocument() {
    document.getElementById('uploadSignedDocumentInput').click();
    document.getElementById("uploadSignedDocumentInput").onchange = function(e) {
      let documentName = document.getElementById("uploadSignedDocumentInput").value.substring(document.getElementById("uploadSignedDocumentInput").value.lastIndexOf("\\") + 1);
      tikiAPI.uploadSignedContract(this.state.epicContract.epicContractId, document.getElementById("uploadSignedDocumentInput").files[0], documentName, 
      function(result) {
        console.log(result)
        this.setState({ epicContract: result });
      }.bind(this),
      function(error) {
        console.log(error);
      }
      );
    }.bind(this);
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Epic_Contracts_Detail" yes={() => (

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"><ContractsIcon /></CardIcon>
              <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Epic Contract</h4>
            </CardHeader>
              <CardBody>

              <GridContainer>
                  <GridItem xs={12}>
                    <div className="bottomForm" id="changeContractStatusButton"><Button color="success" onClick={(e) => this.editContract()}>Edit</Button></div>
                    <div className="bottomForm hide" id="changeContractStatusSelect">
                      <select id="updateContractStatus" value={this.state.updateContractStatus || ''} onChange={(event) => this.handleInput(event)} className="inlineSelect">
                      <option value="Draft">Draft</option>
                        <option value="Documents Generated">Documents Generated</option>
                        <option value="Signed">Signed</option>
                        <option value="Preparing To Close">Preparing To Close</option>
                        <option value="Closed">Closed</option>
                        <option value="Accounting Complete">Accounting Complete</option>
                        <option value="Rescinded">Rescinded</option>
                        <option value="Canceled">Canceled</option>
                        <option value="Expired">Expired</option>
                      </select>
                      <Button color="success" onClick={(e) => this.saveContractStatus()}>Save</Button></div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Contract</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Member ID</div>
                      <div className="displayContent">
                        <Can permissions={this.state.user.permissions} perform="Epic_Members_Detail"
                          yes={() => ( <Link to={`/admin/epic-members-detail/${this.state.epicContract.memberId}`} className="ui basic button green">{this.state.epicContract.memberId}</Link> ) }
                          no={() => ( <div>{this.state.epicContract.memberId}</div> ) }
                          />
                        </div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Contract ID</div>
                      <div className="displayContent">{this.state.epicContract.epicContractId}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">TPP Deal #</div>
                      <div className="displayContent">{this.state.epicContract.tppDealNumber}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Contract Date</div>
                      <div className="displayContent">{this.state.epicContract.contractDate && moment(this.state.epicContract.contractDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Contract Good Date</div>
                      <div className="displayContent">{this.state.epicContract.contractGoodDate && moment(this.state.epicContract.contractGoodDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Paid In Full Date</div>
                      <div className="displayContent">{this.state.epicContract.paidInFullDate && moment(this.state.epicContract.paidInFullDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Contract Status</div>
                      <div className="displayContent">{this.state.epicContract.contractStatus}</div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Payment Status</div>
                      <div className="displayContent">{this.state.epicContract.paymentStatus}</div>
                    </div>
                  </GridItem>
                </GridContainer>              

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Buyer</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Buyer Name</div>
                      <div className="displayContent">{this.state.epicContract.buyerFirstName} {this.state.epicContract.buyerMiddleName} {this.state.epicContract.buyerLastName}</div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Co-Buyer Name</div>
                      <div className="displayContent">{this.state.epicContract.coBuyerFirstName} {this.state.epicContract.coBuyerMiddleName} {this.state.epicContract.coBuyerLastName}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Address</div>
                      <div className="displayContent">{this.state.epicContract.buyerAddress}</div>
                      <div className="displayContent">{this.state.epicContract.buyerCity}, {this.state.epicContract.buyerState} {this.state.epicContract.buyerZipcode} {this.state.epicContract.buyerCountry}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Mobile Phone</div>
                      <div className="displayContent">{this.state.epicContract.buyerMobilePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Home Phone</div>
                      <div className="displayContent">{this.state.epicContract.buyerHomePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Work Phone</div>
                      <div className="displayContent">{this.state.epicContract.buyerWorkPhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Email</div>
                      <div className="displayContent">{this.state.epicContract.buyerEmail}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Epic Vacations Membership</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Tier</div>
                      <div className="displayContent">{this.state.epicContract.membershipTier}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Term</div>
                      <div className="displayContent">{this.state.epicContract.membershipTerm}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                   <div>
                      <div className="displayLabel">Membership Start</div>
                      <div className="displayContent">{moment(this.state.epicContract.membershipStartDate).format('MM/DD/YYYY')}</div>
                    </div>
                   </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Expires</div>
                      <div className="displayContent">{moment(this.state.epicContract.membershipExpDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Contract Financials</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Transaction Type</td>
                          <td align="right">{this.state.epicContract.transactionType}</td>
                        </tr>
                        <tr>
                          <td>Purchase Price</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.purchasePrice)}</td>
                        </tr>
                        <tr>
                          <td>Escrow Services</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.escrowServices)}</td>
                        </tr>
                        <tr>
                          <td>Membership Processing</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.membershipProcessing)}</td>
                        </tr>
                        <tr>
                          <td>First Year Dues</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.firstYearDues)}</td>
                        </tr>
                        <tr>
                          <td>Trustee Fee</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.trusteeFees)}</td>
                        </tr>
                        <tr>
                          <td>Third Party Providers</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.thirdPartyProviders)}</td>
                        </tr>
                        <tr>
                          <td>Loan Processing Fee</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.loanProcessingFee)}</td>
                        </tr>
                        <tr>
                          <td>Credit Report Fee</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.creditReportFee)}</td>
                        </tr>
                        <tr>
                          <td>Total Purchase Price</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.totalPurchasePrice)}</td>
                        </tr>
                        <tr>
                          <td>Down Payment</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.minimumDownPayment + this.state.epicContract.additionalDownPayment)}</td>
                        </tr>
                        <tr>
                          <td>Total Closing Costs</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.closingCostAmount)}</td>
                        </tr>
                        <tr>
                          <td>CC Processing Fee</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.creditCardProcessingFee)}</td>
                        </tr>
                        <tr>
                          <td>Due at Closing</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.dueAtClosing)}</td>
                        </tr>
                        <tr>
                          <td>Financed Amount</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.financedAmount)}</td>
                        </tr>
                        <tr>
                          <td>Interest Rate</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.interestRate)}</td>
                        </tr>
                        <tr>
                          <td>Actual APR</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.aprRate)}</td>
                        </tr>
                        <tr>
                          <td>Loan Term (months)</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.loanTerm)}</td>
                        </tr>
                        <tr>
                          <td>Monthly Payment</td>
                          <td align="right">{tikiAPI.numberToDisplay(this.state.epicContract.regularPaymentAmount)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Documents</div></GridItem>
                </GridContainer>

                {this.state.epicContract.contractDocumentLink && !this.state.epicContract.signedContractDocumentLink &&
                  <Button id="generateContractButton" size="sm" color="success" onClick={(e) => this.generateContract()}>Recreate Contract</Button>
                }

                {!this.state.epicContract.contractDocumentLink &&  
                    <Button id="generateContractButton" size="sm" color="success" onClick={(e) => this.generateContract()}>Create Contract</Button>
                }

                
                <GridContainer>

                  {this.state.epicContract.contractDocumentLink &&
                    <GridItem xs={2}>
                      <div>
                        <div className="displayLabel">Unsigned Documents</div>
                        <div className="displayContent"><a href={this.state.epicContract.contractDocumentLink} target="_blank" rel="noopener noreferrer">{this.state.epicContract.contractFileName}</a></div>
                      </div>
                    </GridItem>
                  }

                  {this.state.epicContract.contractDocumentLink &&
                    <GridItem xs={2}>
                      <div>
                        <div className="displayLabel">Date Modified</div>
                        <div className="displayContent">{moment(this.state.epicContract.contractDocumentDate).format("MM/DD/YYYY h:mma")}</div>
                      </div>
                    </GridItem>
                  }

                  {this.state.epicContract.signedContractDocumentLink &&
                    <GridItem xs={2}>
                      <div>
                        <div className="displayLabel">Signed Documents</div>
                        <div className="displayContent"><a href={this.state.epicContract.signedContractDocumentLink} target="_blank" rel="noopener noreferrer">{this.state.epicContract.signedContractFileName}</a></div>
                      </div>
                    </GridItem>
                  }

                  {!this.state.epicContract.signedContractDocumentLink && this.state.epicContract.contractDocumentLink &&
                    <GridItem xs={2}>
                      <div>
                        <div className="displayLabel">Signed Documents</div>
                        <div className="displayContent"><Button id="uploadSignedDocumentButton" size="sm" color="info" onClick={(e) => this.uploadSignedDocument()}>Upload Signed Contract</Button></div>
                        <input type="file" id="uploadSignedDocumentInput" size="chars" style={{ display: "block", visibility: "hidden", width: 0, height: 0 }} />
                      </div>
                    </GridItem>
                  }

                  {this.state.epicContract.signedContractDocumentLink &&
                    <GridItem xs={2}>
                      <div>
                        <div className="displayLabel">Date Modified</div>
                        <div className="displayContent">{moment(this.state.epicContract.signedContractDocumentDate).format("MM/DD/YYYY h:mma")}</div>
                      </div>
                    </GridItem>
                  }

                  </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Sales and Marketing Reps</div></GridItem>
                </GridContainer>
                
                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                      <div>
                        <div className="displayLabel">Sales Agent</div>
                        <div className="displayContent">{this.state.epicContract.salesAgent}</div>
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={8} md={3}>
                      <div>
                        <div className="displayLabel">TO Agent</div>
                        <div className="displayContent">{this.state.epicContract.toName}</div>
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={8} md={3}>
                      <div>
                        <div className="displayLabel">Marketing Rep</div>
                        <div className="displayContent"></div>
                      </div>
                    </GridItem>
                  </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

      </GridContainer>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Epic_Contracts_Detail);