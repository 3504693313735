import React from "react";
import Cookies from 'universal-cookie';
import { ENV } from "../../global";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CardMedia from '@material-ui/core/CardMedia';

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";


import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const cookies = new Cookies();

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      loginFailed: false
    };
  }

  signIn(emailParam, passwordParam) {
    fetch(ENV.BASE_API_URL + "/api/Users/AuthEmail?email=" + emailParam + "&password=" + passwordParam)
    .then((response) => {
      if (response.status === 204) {
        // Login Failed; No username/password match
        this.setState({"loginFailed": true, "password": ""});
      }
      return response.json();
    })
    .then((user) => {
        // Login Successful
        cookies.set('isAuthenticated', true, { path: '/' });
        cookies.set('authToken', user.authToken, { path: '/' });
        document.location.href = user.homeRoute;
    })
    .catch((error) => {
      //TODO: No username/password match doesn't return JSON; just 204 with no content.  Should return JSON with error message to be processed on client.
      console.error('There has been a problem with your fetch operation:', error);
    });
  }

  forgotPassword() {
    window.location = "../auth/forgot-password";
  }

  handleInput = event => {
    this.setState({[event.target.id]: event.target.value});
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    cookies.remove('isAuthenticated', { path: '/' });
    cookies.remove('authToken', { path: '/' });
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="warning"
                >
                  <h4 className={classes.cardTitle}>Login</h4>
                </CardHeader>
                <CardBody>
                  <CardMedia image="/assets/img/ResortSourceLogo.png" title="Resort Source Logo" />
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    value={this.state.email}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      onChange: (event) => this.handleInput(event),
                      type: "text",
                      value: this.state.email
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    value={this.state.password}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: (event) => this.handleInput(event),
                      type: "password",
                      value: this.state.password
                    }}
                  />

                  { this.state.loginFailed && <div style={{"textAlign": "center", "color": "red"}}>Username/password are incorrect.  Please try again.</div> }

                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="rose" simple size="lg" block onClick={ e => {
                      this.signIn(this.state.email, this.state.password);
                    }}>
                    Sign In
                  </Button>
                  <Button color="info" simple size="lg" block onClick={ e => {
                      this.forgotPassword();
                    }}>
                    Forgot Password
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
