import React from "react";
import { TikiAPI } from "../../global";
import Cookies from 'universal-cookie';
import Can from "../../components/Can";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReportsIcon from "@material-ui/icons/BarChart";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();


const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      tours: [],
      reportRows: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  componentDidMount() {
  }

  getAllTours() {

    tikiAPI.getAllTours(
      function(data) {
        this.setState({ tours: data });
      }.bind(this),
      function(error) { console.log(error) }
    );
  }
 
  render() {
    const { classes } = this.props;
    return (

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ReportsIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Reports</h4>
              </CardHeader>
              <CardBody>
              <GridContainer>
  
                <Can permissions={this.state.user.permissions} perform="Reports_SalesDashboard" yes={() => (
                <GridItem xs={12} md={3}>
                  <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Sales Reports</div></GridItem>
                    <GridItem xs={12}>
                      <ul>
                      <li><a href="/admin/reports-sales-rep-detail">Sales Rep Detail Report</a></li>
                      <li><a href="/admin/reports-sales-volume-yoy">Sales Monthly Volume YoY</a></li>
                      <li><a href="/admin/reports-sales-vpg-yoy">Sales Monthly VPG YoY</a></li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                )} />

                <Can permissions={this.state.user.permissions} perform="Reports_MarketingDashboard" yes={() => (
                <GridItem xs={12} md={3}>
                  <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Marketing Reports</div></GridItem>
                    <GridItem xs={12}>
                      <ul>
                        <li><a href="/admin/reports-marketing-source-detail">Marketing Source Detail Report</a></li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                )} />


                <Can permissions={this.state.user.permissions} perform="Reports_ContractsDashboard" yes={() => (
                <GridItem xs={12} md={3}>
                  <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Contracts Reports</div></GridItem>
                    <GridItem xs={12}>
                      <ul>
                        <li><a href="/admin/reports-contracts-dashboard">Contracts Dashboard</a></li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                )} />

                <Can permissions={this.state.user.permissions} perform="Reports_EpicDashboard" yes={() => (
                <GridItem xs={12} md={3}>
                  <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Epic Reports</div></GridItem>
                    <GridItem xs={12}>
                      <ul>
                      <li><a href="/admin/reports-epic-dashboard">Epic Dashboard</a></li>
                      <li><a href="/admin/reports-epic-membership-points">Epic Membership Points</a></li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                )} />
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </div>

    );
  }
}

export default withStyles(styles)(Reports);