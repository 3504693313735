import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import LeadsIcon from "@material-ui/icons/Assignment";

import Button from "components/CustomButtons/Button.jsx";

// react router components

import "assets/main.css";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Leads_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      lead: "",
      smsOptIn: false,
      leadCommunications: [],
      communicationType: "",
      communicationNote: "",
      tour: ""
    };

    if (!cookies.get('authToken')) {
      //window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.id);
        }.bind(this),
        function(err) {
          //window.location = '/auth/login-page';
        }
      );
    }

  }

  componentDidMount(){
  }

  getData(tourId){
    tikiAPI.getTour(tourId,
      function(response) {
        this.setState({ tour: response });

        tikiAPI.getLead(response.leadId,
            function(leadResponse) {
              if (leadResponse.smsOptIn == "true") {
                this.setState( { smsOptIn: true} );
              }
              this.setState({ lead: leadResponse, leadCommunications: JSON.parse(leadResponse.communications) });              
            }.bind(this),
            function(error) {
              console.log(error);
            }
          );  
    
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );  
  }

  editTour() {
    window.location = '/admin/tours-edit/' + this.state.tour.tourId;
  }

  internalContract = () => {
    window.location = '/admin/contracts-new-for-tour/' + this.state.tour.tourId + '/inventory/0';
  }

  hsiRciContract = () => {
    window.location = '/admin/contracts-new-hsi-rci-for-tour/' + this.state.tour.tourId + '/inventory/0';
  }

  epicContract = () => {
    window.location = '/admin/epic-contracts-new-for-tour/' + this.state.tour.tourId;
  }

  resaleContract = () => {
    window.location = '/admin/inventory-search-for-tour/' + this.state.tour.tourId;
  }

  removePlaceholder = event => {
    var cssClass = event.target.className;
    cssClass = cssClass.replace(' placeholderColor', '');
    event.target.className = cssClass;
  }

  handleInput = event => {
    if (event.target.value == "Text Message") {
      document.getElementById("textMessageTemplate").classList.remove("hide");
      document.getElementById("textMessagePreviewSection").classList.remove("hide");
      document.getElementById("communicationNoteSection").classList.add("hide");
      document.getElementById("saveCommButton").innerHTML = "Send Text Message";      
    }

    if (event.target.id == "textMessageTemplate") {
      switch (event.target.value) {
        case "text1":
          var message = "Hi " + this.state.tour.leadFirstName + "! Your Epic Vacations tour is scheduled for " + moment(this.state.tour.scheduledStartTime).format('MM/DD/YYYY') + " at " + moment(this.state.tour.scheduledStartTime).format('hh:mma') + ".  We look forward to seeing you then.";
          this.setState( { communicationNote: message });
          break;
        case "text2":
          var message = "Hi " + this.state.tour.leadFirstName + "! It looks like you are running a little late for your " + moment(this.state.tour.scheduledStartTime).format('hh:mma') + " Epic Vacations tour.  Will you be able to make it here in the next 10 minutes?";
          this.setState( { communicationNote: message });
          break;
        case "text3":
          var message = "Hi " + this.state.tour.leadFirstName + "! It looks like you weren't able to make your Epic Vacations tour today at " + moment(this.state.tour.scheduledStartTime).format('hh:mma') + ".  Can we reschedule you for the same time tomorrow?";
          this.setState( { communicationNote: message });
          break;  
        default:
          console.log("don't know")
          break;
      }
    }

    

    this.setState({ [event.target.id]: event.target.value });

    this.removePlaceholder(event);
  }

  createLeadCommunication() {
    if (this.state.communicationType == "Text Message") {
      tikiAPI.sendMessage(this.state.lead.mobilePhone, this.state.communicationNote,
        function(response) {
          console.log(response);
        }.bind(this),
          function(err) {
            console.log(err);
          }
        );
    }

    var newComm = {};
    newComm.CreatedAt = new Date();
    newComm.CreatedBy = this.state.user.firstName + " " + this.state.user.lastName;
    newComm.Type = this.state.communicationType;
    newComm.Note = this.state.communicationNote;

    if (this.state.leadCommunications) {
      this.state.leadCommunications.unshift(newComm);
    } else {
      this.state.leadCommunications = new Array();
      this.state.leadCommunications.unshift(newComm);
    }

    this.setState({ lead: {
      ...this.state.lead,
      communications: JSON.stringify(this.state.leadCommunications)
    }}, () => {

      tikiAPI.updateLead(this.state.lead,
        function() {
          this.setState({ communicationNote: "", communicationType: "" })
          document.getElementById("textMessageTemplate").classList.add("hide");
          document.getElementById("textMessagePreviewSection").classList.add("hide");
          document.getElementById("communicationNoteSection").classList.remove("hide");
    
          document.getElementById("saveCommButton").innerHTML = "Save";      
          var cssClass = document.getElementById('communicationType').className;
          cssClass = cssClass + ' placeholderColor';
          document.getElementById('communicationType').className = cssClass;      
        }.bind(this),
          function(err) {
            console.log(err);
          }
        );
      }

    );

  }

  render() {  

    const { classes } = this.props;

    return (
      <Can permissions={this.state.user.permissions} perform="Tours_Detail" yes={() => (

        <div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <LeadsIcon />
                        </CardIcon>
                        
                        <h4 className={classes.cardTitle}>Tour Detail</h4>
                    </CardHeader>
                    
                    <CardBody>

                        <GridContainer>
                            <GridItem xs={12}><div align="center"><Button color="success" onClick={(e) => this.editTour()}>Edit</Button></div></GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12}><div className="softDivider">Tour Information</div></GridItem>
                        </GridContainer>
                        
                        <GridContainer>
                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Lead Name</div>
                                <div className="displayContent"><a href={`/admin/leads-detail/${this.state.lead.leadId}`}>{this.state.tour.leadFirstName} {this.state.tour.leadLastName}</a></div>
                            </div>
                            </GridItem>

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Spouse Name</div>
                                <div className="displayContent">{this.state.tour.spouseFirstName} {this.state.tour.spouseLastName}</div>
                            </div>
                            </GridItem>

                            <GridItem xs={12} sm={8} md={3}>
                            <div>
                                <div className="displayLabel">Date/Time</div>
                                <div className="displayContent">{moment(this.state.tour.scheduledStartTime).format('MM/DD/YYYY hh:mm a')}</div>
                            </div>
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Assigned Sales Rep</div>
                                <div className="displayContent">{this.state.tour.assignedEmployeeFirstName} {this.state.tour.assignedEmployeeLastName}</div>
                            </div>
                            </GridItem> 

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Marketing Source</div>
                                <div className="displayContent">{this.state.tour.marketingSource}</div>
                            </div>
                            </GridItem> 
 
                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Status</div>
                                <div className="displayContent">{this.state.tour.tourStatus}</div>
                            </div>
                            </GridItem> 

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Result</div>
                                <div className="displayContent">{this.state.tour.tourResult}</div>
                            </div>
                            </GridItem> 

                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={6}>
                            <div>
                                <div className="displayLabel">Gifts</div>
                                <div className="displayContent">{this.state.tour.giftNames}</div>
                            </div>
                          </GridItem> 

                          <GridItem xs={6}>
                            <div>
                                <div className="displayLabel">Notes</div>
                                <div className="displayContent">{this.state.tour.notes}</div>
                            </div>
                          </GridItem> 
                        </GridContainer>

                        <GridContainer>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12}><div className="softDivider">Purchase Options</div></GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12}>
                            <Button color="info" onClick={(e) => this.epicContract()}>Epic</Button>&nbsp;
                            <Button color="info" onClick={(e) => this.resaleContract()}>Resale</Button>&nbsp;
                            <Button color="info" onClick={(e) => this.hsiRciContract()}>HSI / RCI</Button>&nbsp;
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Lead Communications</div></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={8} md={3}>
                      <div>
                        <div className="inputLabel">Communication Type</div>
                        <select id="communicationType" value={this.state.communicationType || ''} onChange={(event) => this.handleInput(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                          <option value="">Communication Type</option>
                            <Can permissions={this.state.user.permissions} perform="Other_Beta" yes={() => (
                              this.state.smsOptIn && <option value="Text Message">Text Message</option>
                          )} />
                          <option value="In Person">In Person</option>
                          <option value="Phone Call">Phone Call</option>
                          <option value="Email">Email</option>
                          <option value="Letter">Letter</option>
                          <option value="Other">Other</option>
                        </select> 
                      </div>

                      <div>
                        <select id="textMessageTemplate" value={this.state.textMessageTemplate || ''} onChange={(event) => this.handleInput(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor hide">
                          <option value="">Select Text Msg Template</option>
                          <option value="text1">Tour Reminder</option>
                          <option value="text2">Late for Tour</option>
                          <option value="text3">Reschedule</option>
                        </select> 
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={5}>
                      <div id="communicationNoteSection">
                        <div className="inputLabel">Note</div>
                        <textarea rows="3" id="communicationNote" value={this.state.communicationNote || ''} onChange={(event) => this.handleInput(event)} className="formInput" placeholder="Add note..."></textarea>
                      </div>
                      <div id="textMessagePreviewSection" className="hide">
                        <div className="inputLabel">Text Message Preview</div>
                        <div className="textMessagePreview" id="textMessagePreview">{this.state.communicationNote}</div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} md={2}>
                        <div className="verticalAlignButton"><Button id="saveCommButton" size="sm" color="info" onClick={(e) => this.createLeadCommunication()}>Save</Button></div>
                    </GridItem>
                </GridContainer>

                  <GridContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Created By</th>
                        <th>Type</th>
                        <th width="60%">Note</th>
                      </tr>
                    </thead>

                    <tbody>

                      {this.state.leadCommunications && this.state.leadCommunications.map((comm, index) => (
                        <tr key={index}>
                          <td>{moment(comm.CreatedAt).format('MM/DD/YYYY hh:mm A')}</td>
                          <td>{comm.CreatedBy}</td>
                          <td>{comm.Type}</td>
                          <td>{comm.Note}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </GridContainer>

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        </div>

      )} />
      
      );
    }
}

export default withStyles(styles)(Leads_Detail);