import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import SettingsIcon from "@material-ui/icons/Lock";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Users_Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      userEdit: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
            this.setState({ user: userData });
            this.getUser(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

getUser(userId) {
    tikiAPI.getUser( userId,
      function(data) {
          var permissionsArray = data.permissions.split(",");
          for (var i=0; i<permissionsArray.length; i++) {
              document.getElementById(permissionsArray[i]).checked = true;
          }
          this.setState( { userEdit: data });
      }.bind(this),
      function(err) {
        console.log(err);
      })
    }

updateUser() {
  console.log(this.state.userEdit);
    tikiAPI.updateUser(this.state.userEdit,
        function(response) {
        window.location = '/admin/settings/';
    }.bind(this),
    function(err) {
        console.log(err);
        }
    );
}

buildPermissionsString() {
    var newPermissions = [];
    for(var i=0; i < document.getElementById("permissionsForm").elements.length; i++){
        var e = document.getElementById("permissionsForm").elements[i];
        if (e.checked) {
            newPermissions.push(e.id);
        }
    }
    this.setState({ userEdit: {
        ...this.state.userEdit,
        permissions: newPermissions.join()
    } }, function() {
        this.updateUser();
    });
}

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Users_Edit" yes={() => (

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"><SettingsIcon /></CardIcon>
              <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Edit User Permissions</h4>
            </CardHeader>
              <CardBody>
                  <GridContainer>
                      <GridItem>
                          <h3>{this.state.userEdit.firstName} {this.state.userEdit.lastName} : Permissions</h3>
                        <div>
                            <form id="permissionsForm">
                            <table>
                              <tbody>
                                <tr>
                                    <th>Module</th>
                                    <th width="80">Main</th>
                                    <th width="80">Detail</th>
                                    <th width="80">New</th>
                                    <th width="80">Edit</th>
                                    <th width="80">Sensitive</th>
                                    <th width="80">Admin</th>
                                    <th>Other</th>
                                </tr>

                                <tr>
                                    <td>Accounting</td>
                                    <td><input id="Accounting_Main" type="checkbox"></input></td>
                                    <td><input id="Accounting_Detail" type="checkbox"></input></td>
                                    <td><input id="Accounting_New" type="checkbox"></input></td>
                                    <td><input id="Accounting_Edit" type="checkbox"></input></td>
                                    <td><input id="Accounting_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Accounting_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Commission</td>
                                    <td><input id="Commission_Main" type="checkbox"></input></td>
                                    <td><input id="Commission_Detail" type="checkbox"></input></td>
                                    <td><input id="Commission_New" type="checkbox"></input></td>
                                    <td><input id="Commission_Edit" type="checkbox"></input></td>
                                    <td><input id="Commission_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Commission_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Contracts</td>
                                    <td><input id="Contracts_Main" type="checkbox"></input></td>
                                    <td><input id="Contracts_Detail" type="checkbox"></input></td>
                                    <td><input id="Contracts_New" type="checkbox"></input></td>
                                    <td><input id="Contracts_Edit" type="checkbox"></input></td>
                                    <td><input id="Contracts_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Contracts_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Employees</td>
                                    <td><input id="Employees_Main" type="checkbox"></input></td>
                                    <td><input id="Employees_Detail" type="checkbox"></input></td>
                                    <td><input id="Employees_New" type="checkbox"></input></td>
                                    <td><input id="Employees_Edit" type="checkbox"></input></td>
                                    <td><input id="Employees_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Employees_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Epic Contracts</td>
                                    <td><input id="Epic_Contracts_Main" type="checkbox"></input></td>
                                    <td><input id="Epic_Contracts_Detail" type="checkbox"></input></td>
                                    <td><input id="Epic_Contracts_New" type="checkbox"></input></td>
                                    <td><input id="Epic_Contracts_Edit" type="checkbox"></input></td>
                                    <td><input id="Epic_Contracts_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Epic_Contracts_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Epic Members</td>
                                    <td><input id="Epic_Members_Main" type="checkbox"></input></td>
                                    <td><input id="Epic_Members_Detail" type="checkbox"></input></td>
                                    <td><input id="Epic_Members_New" type="checkbox"></input></td>
                                    <td><input id="Epic_Members_Edit" type="checkbox"></input></td>
                                    <td><input id="Epic_Members_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Epic_Members_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Gifts</td>
                                    <td><input id="Gifts_Main" type="checkbox"></input></td>
                                    <td><input id="Gifts_Detail" type="checkbox"></input></td>
                                    <td><input id="Gifts_New" type="checkbox"></input></td>
                                    <td><input id="Gifts_Edit" type="checkbox"></input></td>
                                    <td><input id="Gifts_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Gifts_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Inventory</td>
                                    <td><input id="Inventory_Main" type="checkbox"></input></td>
                                    <td><input id="Inventory_Detail" type="checkbox"></input></td>
                                    <td><input id="Inventory_New" type="checkbox"></input></td>
                                    <td><input id="Inventory_Edit" type="checkbox"></input></td>
                                    <td><input id="Inventory_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Inventory_Admin" type="checkbox"></input></td>
                                    <td><input id="Inventory_List" type="checkbox"></input> View Inventory</td>
                                </tr>

                                <tr>
                                    <td>Leads</td>
                                    <td><input id="Leads_Main" type="checkbox"></input></td>
                                    <td><input id="Leads_Detail" type="checkbox"></input></td>
                                    <td><input id="Leads_New" type="checkbox"></input></td>
                                    <td><input id="Leads_Edit" type="checkbox"></input></td>
                                    <td><input id="Leads_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Leads_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Listings</td>
                                    <td><input id="Listings_Main" type="checkbox"></input></td>
                                    <td><input id="Listings_Detail" type="checkbox"></input></td>
                                    <td><input id="Listings_New" type="checkbox"></input></td>
                                    <td><input id="Listings_Edit" type="checkbox"></input></td>
                                    <td><input id="Listings_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Listings_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Points Ledger</td>
                                    <td><input id="PointsLedger_Main" type="checkbox"></input></td>
                                    <td><input id="PointsLedger_Detail" type="checkbox"></input></td>
                                    <td><input id="PointsLedger_New" type="checkbox"></input></td>
                                    <td><input id="PointsLedger_Edit" type="checkbox"></input></td>
                                    <td><input id="PointsLedger_Sensitive" type="checkbox"></input></td>
                                    <td><input id="PointsLedger_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Tours</td>
                                    <td><input id="Tours_Main" type="checkbox"></input></td>
                                    <td><input id="Tours_Detail" type="checkbox"></input></td>
                                    <td><input id="Tours_New" type="checkbox"></input></td>
                                    <td><input id="Tours_Edit" type="checkbox"></input></td>
                                    <td><input id="Tours_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Tours_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Search Results</td>
                                    <td><input id="SearchResults" type="checkbox"></input></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                
                                <tr>
                                    <td>Settings</td>
                                    <td><input id="Settings_Main" type="checkbox"></input></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Users</td>
                                    <td><input id="Users_Main" type="checkbox"></input></td>
                                    <td><input id="Users_Detail" type="checkbox"></input></td>
                                    <td><input id="Users_New" type="checkbox"></input></td>
                                    <td><input id="Users_Edit" type="checkbox"></input></td>
                                    <td><input id="Users_Sensitive" type="checkbox"></input></td>
                                    <td><input id="Users_Admin" type="checkbox"></input></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Reports</td>
                                    <td><input id="Reports_Main" type="checkbox"></input></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <input id="Reports_SalesDashboard" type="checkbox"></input> Sales Dashboard<br />
                                        <input id="Reports_MarketingDashboard" type="checkbox"></input> Marketing Dashboard<br />
                                        <input id="Reports_ContractsDashboard" type="checkbox"></input> Contracts Dashboard<br />
                                        <input id="Reports_EpicDashboard" type="checkbox"></input> Epic Dashboard<br />
                                    </td>
                                </tr>

                                <tr>
                                    <td>Other</td>
                                    <td colSpan="7">
                                        <input id="Other_AllowRemoteLogin" type="checkbox"></input> Allow Remote Login<br />
                                        <input id="Other_Beta" type="checkbox"></input> Enable Beta Features<br />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </form> 
                        </div>
                      </GridItem>
                  </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <div align="center"><Button color="success" onClick={(e) => this.buildPermissionsString()}>Save</Button></div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

      </GridContainer>

      ) } /> // End Can RBAC element
      
      );
  }
}

export default withStyles(styles)(Users_Edit);