// TODO: IS THIS STILL BEING USED?  DOESN'T SEEM TO DO ANYTING?

import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import ContractsIcon from "@material-ui/icons/InsertDriveFile";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Epic_Contracts_New extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      epicAdvantageContract: {},
      epicMember: {},
      epicMemberAdvantageContracts: [],
      newPurchase: { "pricePerPoint": 0.02, "transactionFee": 29.00 }
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData, epicContract: { "createdBy": userData.userId } });
          this.getEpicMember(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getEpicMember(epicMemberId) {
    tikiAPI.getEpicMember( epicMemberId,
      function(epicMemberResponse) {
        console.log(epicMemberResponse);
        this.setState( { epicMember: epicMemberResponse });
      }.bind(this),
      function(err) {
        console.log(err);
      })

      tikiAPI.getAllEpicAdvantageContractsForMember( epicMemberId,
        function(epicMemberAdvantageContractsResponse) {
          console.log(epicMemberAdvantageContractsResponse);
          this.setState( { epicMemberAdvantageContracts: epicMemberAdvantageContractsResponse });
        }.bind(this),
        function(err) {
          console.log(err);
        })      

    }

  createEpicAdvantageContract() {

    tikiAPI.createEpicContract(this.state.epicContract,
      function(epicContractResponse) {
        console.log(epicContractResponse);
        window.location = '/admin/epic-contracts-detail/' + epicContractResponse.epicContractId;
      },
      function(err) {
        console.log(err);
      }
    );

  }

  handleInput = event => {
    this.setState({ newPurchase: {
      ...this.state.newPurchase,
      [event.target.id]: event.target.value }
    }, function() {

        if (this.state.newPurchase !== "") {
            var total = this.state.newPurchase.pointsPurchased * this.state.newPurchase.pricePerPoint + this.state.newPurchase.transactionFee;
            this.setState({ newPurchase: {
                ...this.state.newPurchase,
                totalPurchasePrice: total
              } });
        }
    });

    this.removePlaceholder(event);

  }

  removePlaceholder = event => {
    var cssClass = event.target.className;
    cssClass = cssClass.replace(' placeholderColor', '');
    event.target.className = cssClass;
  }

  componentDidMount () {
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Leads_New" yes={() => (

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"><ContractsIcon /></CardIcon>
              <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Purchase Epic Advantage Points</h4>
            </CardHeader>
              <CardBody>

              <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Member Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Name</div>
                      <div className="displayContent">{this.state.epicMember.firstName} {this.state.epicMember.middleName} {this.state.epicMember.lastName}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Member #</div>
                      <div className="displayContent">{this.state.epicMember.memberId}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Address</div>
                      <div className="displayContent">{this.state.epicMember.address}<br />{this.state.epicMember.city}, {this.state.epicMember.state} {this.state.epicMember.zipcode} {this.state.epicMember.country}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Mobile Phone</div>
                      <div className="displayContent">{this.state.epicMember.mobilePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Home Phone</div>
                      <div className="displayContent">{this.state.epicMember.homePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Work Phone</div>
                      <div className="displayContent">{this.state.epicMember.workPhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Email</div>
                      <div className="displayContent">{this.state.epicMember.email}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Epic Vacations Membership</div></GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Status</div>
                      <div className="displayContent">{this.state.epicMember.memberStatus}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Tier</div>
                      <div className="displayContent">{this.state.epicMember.membershipTier}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Term</div>
                      <div className="displayContent">{this.state.epicMember.membershipTerm}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                   <div>
                      <div className="displayLabel">Membership Start</div>
                      <div className="displayContent">{moment(this.state.epicMember.membershipStartDate).format('MM/DD/YYYY')}</div>
                    </div>
                   </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Expires</div>
                      <div className="displayContent">{moment(this.state.epicMember.membershipExpDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Contract #</div>
                      <div className="displayContent">{this.state.epicMember.epicContractId}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Epic Advantage Points</div></GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                            <div className="displayLabel">Advantage Points Available</div>
                            <div className="displayContent">{tikiAPI.numberToDisplay(this.state.epicMember.currentYearEpicAdvPointsAvail)}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={3}>
                        <div>
                            <div className="displayLabel">Annual Maximum Advantage Points</div>
                            <div className="displayContent">{tikiAPI.numberToDisplay(this.state.epicMember.currentYearMaxEpicAdvPoints)}</div>
                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                  <table className="tableNewDesign">
                    <thead>
                      <tr>
                        <th>Purchase Date</th>
                        <th>Points</th>
                        <th>$/Point</th>
                        <th>Fee</th>
                        <th>Total Purchase</th>
                        <th>Payment Reference #</th>
                      </tr>
                    </thead>

                    <tbody>

                      {this.state.epicMemberAdvantageContracts.map((epicAdvantageContract, index) => (
                        <tr key={index}>
                          <td>{moment(epicAdvantageContract.createdAt).format('MM/DD/YYYY')}</td>
                          <td>{tikiAPI.numberToDisplay(epicAdvantageContract.pointsPurchased)}</td>
                          <td>{tikiAPI.numberToDisplay(epicAdvantageContract.pricePerPoint)}</td>
                          <td>{tikiAPI.numberToDisplay(epicAdvantageContract.transactionFee)}</td>
                          <td>{tikiAPI.numberToDisplay(epicAdvantageContract.totalPurchasePrice)}</td>
                          <td>{epicAdvantageContract.paymentReferenceId}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">New Purchase</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Points</div>
                      <select id="pointsPurchased" value={this.state.newPurchase.pointsPurchased || ''} onChange={(event) => this.handleInput(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                        <option value="">Select Points</option>
                        <option value="5000">5,000</option>
                        <option value="10000">10,000</option>
                        <option value="15000">15,000</option>
                        <option value="20000">20,000</option>
                      </select> 
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">$ Per Point</div>
                      <input id="pricePerPoint" readOnly value={tikiAPI.numberToDisplay(this.state.newPurchase.pricePerPoint) || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="$ Per Point"></input>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Transaction Fee</div>
                      <input id="transactionFee" value={tikiAPI.numberToDisplay(this.state.newPurchase.transactionFee) || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Transaction Fee"></input>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Total Purchase Price</div>
                      <input id="totalPurchasePrice" value={tikiAPI.numberToDisplay(this.state.newPurchase.totalPurchasePrice) || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Total Purchase Price"></input>
                    </div>
                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Credit Card #</div>
                      <input id="" onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Credit Card #"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={1}>
                    <div>
                      <div className="inputLabel">Exp</div>
                      <input id="" onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="MM / YY"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={1}>
                    <div>
                      <div className="inputLabel">CCV</div>
                      <input id="" onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="CCV"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={1}>
                    <div>
                      <div className="inputLabel">Zipcode</div>
                      <input id="" onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Zipcode"></input>
                    </div>
                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} md={2}>
                    <form id="payment-form">
                      <div id="card-element"></div>
                      <Button color="success" id="card-button">Purchase</Button>
                      <p id="payment-result"></p>
                    </form>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

      </GridContainer>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Epic_Contracts_New);