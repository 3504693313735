import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import ContractsIcon from "@material-ui/icons/InsertDriveFile";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Epic_Contracts_New extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      epicContract: {},
      epicContractFormValue: {},
      salesReps: [],
      products: [],
      productSelected: "",
      tour: "",
      disableSave: false
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function (userData) {
          this.setState({ user: userData });
          this.getSalesReps();
          this.setState({
            epicContract: {
              createdBy: userData.userId,
              contractStatus: "Draft",
              buyerCountry: "US"
            }
          }, function () {
            this.setState({ epicContractFormValue: JSON.parse(JSON.stringify(this.state.epicContract)) });
          })
          this.getProducts();
          this.getTour(this.props.match.params.tourId);
        }.bind(this),
        function (err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getProducts() {
    tikiAPI.getAllProducts(
      function (data) {
        this.setState({ products: data });
      }.bind(this),
      function (error) {
        console.log(error);
      }
    )
  }

  getTour(tourId) {
    tikiAPI.getTour(tourId,
      function (tourResponse) {
        tikiAPI.getLead(tourResponse.leadId,
          function (leadResponse) {
            this.setState({
              epicContract: {
                ...this.state.epicContract,
                buyerFirstName: leadResponse.firstName,
                buyerLastName: leadResponse.lastName,
                coBuyerFirstName: leadResponse.spouseFirstName,
                coBuyerLastName: leadResponse.spouseLastName,
                buyerEmail: leadResponse.email,
                buyerAddress: leadResponse.address1,
                buyerCity: leadResponse.city,
                buyerState: leadResponse.state,
                buyerZipcode: leadResponse.zipcode,
                buyerCountry: leadResponse.country,
                buyerHomePhone: leadResponse.homePhone,
                buyerWorkPhone: leadResponse.workPhone,
                buyerMobilePhone: leadResponse.mobilePhone,
                tourId: tourId,
                leadId: leadResponse.leadId
              }
            });
          }.bind(this),
          function (error) {
            console.log(error);
          })

        this.setState({ tour: tourResponse });

        this.setState({
          epicContract: {
            ...this.state.epicContract,
            buyerFirstName: tourResponse.lead
          }
        });
      }.bind(this),
      function (error) {
        console.log(error);
      })
  }

  getSalesReps() {
    tikiAPI.getSalesReps(
      function (salesRepResponse) {
        this.setState({ salesReps: salesRepResponse });
      }.bind(this),
      function (error) {
        console.log(error);
      })
  }

  createEpicContract() {
    if (this.state.epicContract.aprRate > 18) {
      alert("The APR cannot exceed 18%.");
      return;
    }
    if (!this.state.disableSave) {
      document.getElementById("saveButton").innerHTML = "Please wait...";

      this.setState({
        disableSave: true
      });

      tikiAPI.createEpicContract(this.state.epicContract,
        function (epicContractResponse) {
          window.location = '/admin/epic-contracts-detail/' + epicContractResponse.epicContractId;
        },
        function (error) {
          console.log(error);
        }
      );
    }

  }

  handleInputMembershipTier = event => {
    this.setState({ productSelected: event.target.value });

    var product = this.state.products[event.target.value - 1];

    var closingCostValues = {};

    console.log(product.productId);

    if (product.productId == 1 || product.productId == 2) {
      closingCostValues = {
        escrowServices: 0,
        membershipProcessing: 0,
        firstYearDues: 0,
        trusteeFees: 0,
        thirdPartyProviders: 0,
        closingCostAmount: 0
      }
    } else {
      closingCostValues = {
        escrowServices: 175,
        membershipProcessing: 220,
        firstYearDues: 599,
        trusteeFees: 20,
        thirdPartyProviders: 69,
        creditReportFee: 0.00,
        loanProcessingFee: 0
      }
    }

    var contractDate = moment().format("MM/DD/YYYY");

    if (moment().month() >= 8 && moment().month() <= 10) {
      closingCostValues.firstYearDues += 119;
    }

    var membershipStartDate = moment().add(10, 'days').format("MM/DD/YYYY");
    var expYear = moment().add(product.termMonths, 'month').format("YYYY");
    var membershipExpDate = moment("12/31/" + expYear, "MM/DD/YYYY").format("MM/DD/YYYY");

    this.setState({
      epicContract: {
        ...this.state.epicContract,
        escrowServices: closingCostValues.escrowServices,
        membershipProcessing: closingCostValues.membershipProcessing,
        firstYearDues: closingCostValues.firstYearDues,
        trusteeFees: closingCostValues.trusteeFees,
        thirdPartyProviders: closingCostValues.thirdPartyProviders,
        loanProcessingFee: closingCostValues.loanProcessingFee,
        closingCostAmount: closingCostValues.closingCostAmount,
        membershipTerm: product.termMonths,
        membershipTier: product.memberTier,
        interestRate: product.listInterestRate,
        loanTerm: 60,
        annualFixedPoints: 15000,
        firstYearEpicAdvantagePointsCost: product.firstYearEpicAdvantagePointsCost,
        annualMaxEpicAdvantagePoints: product.annualMaxEpicAdvantagePoints,
        contractDate: contractDate,
        membershipStartDate: membershipStartDate,
        membershipExpDate: membershipExpDate
      }
    }, function () {

      var closingCostAmount = this.getClosingCostAmount();

      console.log(product.listPrice - closingCostAmount);

      this.setState({
        epicContract: {
          ...this.state.epicContract,
          closingCostAmount: closingCostAmount,
          purchasePrice: product.listPrice - closingCostAmount,
          totalPurchasePrice: product.listPrice
        }
      }, function () {
        document.getElementById("purchasePrice").value = tikiAPI.numberToDisplay(this.state.epicContract.purchasePrice);
        this.recalc();
      });

    });




    document.getElementById('contractFinancials').style.display = "block";

  }

  handleContractDate = event => {
    var contractDateMoment = moment(event.target.value);
    console.log(contractDateMoment);
    var contractDate = contractDateMoment.format("MM/DD/YYYY");
    console.log(contractDate);
    var membershipStartDate = contractDateMoment.add(10, 'days').format("MM/DD/YYYY");
    console.log(membershipStartDate);
    var expYear = contractDateMoment.add(this.state.epicContract.membershipTerm, 'month').format("YYYY");
    console.log(expYear);
    var membershipExpDate = moment("12/31/" + expYear, "MM/DD/YYYY").format("MM/DD/YYYY");
    console.log(membershipExpDate);

    this.setState({
      epicContract: {
        ...this.state.epicContract,
        contractDate: contractDate,
        membershipStartDate: membershipStartDate,
        membershipExpDate: membershipExpDate
      }
    });

    this.removePlaceholder(event);

  }

  handleInput = event => {
    this.setState({
      epicContract: {
        ...this.state.epicContract,
        [event.target.id]: event.target.value
      }
    });

    this.removePlaceholder(event);

  }

  updateNumber = event => {
    console.log("updated number @@@@@@@@@@@@@@@@@@@@@@@ with " + event.target.value);

    this.setState({
      epicContract: {
        ...this.state.epicContract,
        [event.target.id]: tikiAPI.numberToDisplay(event.target.value)
      }
    }, function () {
      this.recalc();
    }.bind(this));
  }

  handleTransactionType = event => {
    if ((this.state.epicContract.membershipTier == "Embarc" || this.state.epicContract.membershipTier == "Rendezvous") && (event.target.value == "Finance Transaction" || event.target.value == "Cash / Check Finance Balance")) {
      alert("Embarc and Rendezvous contracts must be paid in full with credit card or cash.")
      this.setState( { transactionType: "" } )
      return;
    }

    if (event.target.value == "Credit Card (Full Payment)") {
      console.log("full pay");
      this.setState({
        epicContract: {
          ...this.state.epicContract,
          [event.target.id]: event.target.value,
          minimumDownPayment: this.state.epicContract.purchasePrice,
          additionalDownPayment: 0,
          creditReportFee: 0.00
        }
      }, function () { this.recalc() });
    } else {
      console.log("finance pay");
      //TODO: does this path really only represent finance pay? or just not full cash payment?
      this.setState({
        epicContract: {
          ...this.state.epicContract,
          [event.target.id]: event.target.value,
        }
      }, function () { this.recalc() });
    }


    this.removePlaceholder(event);

  }

  removePlaceholder = event => {
    var cssClass = event.target.className;
    cssClass = cssClass.replace(' placeholderColor', '');
    event.target.className = cssClass;
  }

  getCurrentPurchasePriceOrMinimum(product, totalPurchasePrice) {
    if (totalPurchasePrice < product.minimumPrice) {
      alert("The minimum total purchase price including closing costs for this product is $" + tikiAPI.numberToDisplay(product.minimumPrice));
      var purchasePrice = product.minimumPrice - this.getClosingCostAmount();
    } else {
      return tikiAPI.displayToNumber(this.state.epicContract.purchasePrice);
    }

    return purchasePrice;
  }

  getClosingCostAmount() {
    var closingCostAmount = 0;
    closingCostAmount += tikiAPI.displayToNumber(this.state.epicContract.escrowServices || 0);
    closingCostAmount += tikiAPI.displayToNumber(this.state.epicContract.membershipProcessing || 0);
    closingCostAmount += tikiAPI.displayToNumber(this.state.epicContract.firstYearDues || 0);
    closingCostAmount += tikiAPI.displayToNumber(this.state.epicContract.trusteeFees || 0);
    closingCostAmount += tikiAPI.displayToNumber(this.state.epicContract.thirdPartyProviders || 0);
    closingCostAmount += tikiAPI.displayToNumber(this.state.epicContract.loanProcessingFee || 0);
    closingCostAmount += tikiAPI.displayToNumber(this.state.epicContract.creditReportFee || 0);

    return tikiAPI.displayToNumber(closingCostAmount);
  }

  recalc() {
    var product = this.state.products[this.state.productSelected - 1]

    var totalPurchasePrice = tikiAPI.displayToNumber(this.state.epicContract.purchasePrice) + this.getClosingCostAmount() ;

    console.log(totalPurchasePrice)

    var purchasePrice = this.getCurrentPurchasePriceOrMinimum(product, totalPurchasePrice);

    console.log(purchasePrice)

    var minimumDownPayment;
    var additionalDownPayment;
    var creditReportFee;

    if (this.state.epicContract.transactionType == "Credit Card (Full Payment)" || this.state.epicContract.transactionType == "Cash/Check Full Payment") {
      minimumDownPayment = purchasePrice;
      additionalDownPayment = 0;
      creditReportFee = 0;
    } else {
      minimumDownPayment = purchasePrice * product.minimumDownPayment;
      console.log(minimumDownPayment)

      var additionalDownPayment = tikiAPI.displayToNumber(this.state.epicContract.additionalDownPayment || '');
      if (isNaN(additionalDownPayment)) {
        additionalDownPayment = 0;
      }

      var creditReportFee = tikiAPI.displayToNumber(this.state.epicContract.creditReportFee || 0);
      if (isNaN(creditReportFee)) {
        creditReportFee = 0;
      }

    }

    if (this.state.epicContract.transactionType == "Finance Transaction" || this.state.epicContract.transactionType == "Cash / Check Finance Balance") {
      var loanProcessingFee = 200;
      var creditReportFee = 25;
    } else {
      var loanProcessingFee = 0;
      var creditReportFee = 0;
      var aprRate = null;
    }

    this.setState({
      epicContract: {
        ...this.state.epicContract,
        loanProcessingFee: loanProcessingFee,
        creditReportFee: creditReportFee,
        aprRate: aprRate
      }
    }, function () {
      var closingCostAmount = this.getClosingCostAmount();

      if (this.state.epicContract.transactionType == "Credit Card (Full Payment)" || this.state.epicContract.transactionType == "Finance Transaction") {
        var creditCardProcessingFee = Math.round(((minimumDownPayment + additionalDownPayment + closingCostAmount) * .03) * 100) / 100;
      } else {
        var creditCardProcessingFee = 0;
      }


      var subtotalDueAtClosing = minimumDownPayment + additionalDownPayment + closingCostAmount;

      var dueAtClosing = subtotalDueAtClosing + creditCardProcessingFee;

      var financedAmount = purchasePrice - minimumDownPayment - additionalDownPayment;

      console.log(tikiAPI.displayToNumber(purchasePrice));

      var totalPurchasePrice = tikiAPI.displayToNumber(purchasePrice) + closingCostAmount;
      console.log(totalPurchasePrice)
      var subtotalDueAtClosing =

        this.setState({
          epicContract: {
            ...this.state.epicContract,
            purchasePrice: purchasePrice,
            minimumDownPayment: minimumDownPayment,
            additionalDownPayment: additionalDownPayment,
            subtotalDueAtClosing: subtotalDueAtClosing,
            dueAtClosing: dueAtClosing,
            financedAmount: financedAmount,
            totalPurchasePrice: totalPurchasePrice,
            creditCardProcessingFee: creditCardProcessingFee,
            loanProcessingFee: loanProcessingFee,
            closingCostAmount: closingCostAmount
          }
        }, function () {

          document.getElementById("purchasePrice").value = tikiAPI.numberToDisplay(this.state.epicContract.purchasePrice);
          document.getElementById("additionalDownPayment").value = tikiAPI.numberToDisplay(this.state.epicContract.additionalDownPayment);

          this.setState({ epicContractFormValue: JSON.parse(JSON.stringify(this.state.epicContract)) });

          if (this.state.epicContract.transactionType == "Finance Transaction" || this.state.epicContract.transactionType == "Cash / Check Finance Balance") {
            this.setState({ epicContract: tikiAPI.generateTilaForContract(this.state.epicContract) }, function () {
              console.log(this.state.epicContract);
            })
          }
        });
    }.bind(this));

  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Epic_Contracts_New" yes={() => (

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info"><ContractsIcon /></CardIcon>
                <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>New Epic Contract</h4>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Buyer</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Buyer First Name</div>
                      <input id="buyerFirstName" value={this.state.epicContract.buyerFirstName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="First Name"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Buyer Middle Name</div>
                      <input id="buyerMiddleName" value={this.state.epicContract.buyerMiddleName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Middle Name"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Buyer Last Name</div>
                      <input id="buyerLastName" value={this.state.epicContract.buyerLastName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Last Name"></input>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Co-Buyer First Name</div>
                      <input id="coBuyerFirstName" value={this.state.epicContract.coBuyerFirstName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="First Name"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Co-Buyer Middle Name</div>
                      <input id="coBuyerMiddleName" value={this.state.epicContract.coBuyerMiddleName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Middle Name"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Co-Buyer Last Name</div>
                      <input id="coBuyerLastName" value={this.state.epicContract.coBuyerLastName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Last Name"></input>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <div>
                      <div className="inputLabel">Buyer Email</div>
                      <input id="buyerEmail" value={this.state.epicContract.buyerEmail || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Email"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={3}>
                    <div>
                      <div className="inputLabel">Buyer Mobile Phone</div>
                      <input id="buyerMobilePhone" value={this.state.epicContract.buyerMobilePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Mobile Phone"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={3}>
                    <div>
                      <div className="inputLabel">Buyer Home Phone</div>
                      <input id="buyerHomePhone" value={this.state.epicContract.buyerHomePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Home Phone"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={3}>
                    <div>
                      <div className="inputLabel">Buyer Worker Phone</div>
                      <input id="buyerWorkPhone" value={this.state.epicContract.buyerWorkPhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Work Phone"></input>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={3}>
                    <div>
                      <div className="inputLabel">Address</div>
                      <input id="buyerAddress" value={this.state.epicContract.buyerAddress || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Address"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={2}>
                    <div>
                      <div className="inputLabel">City</div>
                      <input id="buyerCity" value={this.state.epicContract.buyerCity || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="City"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={2}>
                    <div>
                      <div className="inputLabel">State</div>

                      <select id="buyerState" value={this.state.epicContract.buyerState || ''} onChange={(event) => this.handleInput(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect">
                        <option className="formSelectDefault" value="">State</option>
                        <option value="AL" >Alabama</option>
                        <option value="AK" >Alaska</option>
                        <option value="AR" >Arkansas</option>
                        <option value="AZ" >Azizona</option>
                        <option value="CA" >California</option>
                        <option value="CO" >Colorado</option>
                        <option value="CT" >Connecticut</option>
                        <option value="DE" >Delaware</option>
                        <option value="FL" >Florida</option>
                        <option value="GA" >Georgia</option>
                        <option value="HI" >Hawaii</option>
                        <option value="ID" >Idaho</option>
                        <option value="IL" >Illinois</option>
                        <option value="IN" >Indiana</option>
                        <option value="IA" >Iowa</option>
                        <option value="KS" >Kansas</option>
                        <option value="KY" >Kentucky</option>
                        <option value="LA" >Louisiana</option>
                        <option value="ME" >Maine</option>
                        <option value="MD" >Maryland</option>
                        <option value="MA" >Massachusetts</option>
                        <option value="MI" >Michigan</option>
                        <option value="MN" >Minnesota</option>
                        <option value="MS" >Mississippi</option>
                        <option value="MO" >Missouri</option>
                        <option value="MT" >Montana</option>
                        <option value="NE" >Nebraska</option>
                        <option value="NV" >Nevada</option>
                        <option value="NH" >New Hampshire</option>
                        <option value="NJ" >New Jersey</option>
                        <option value="NM" >New Mexico</option>
                        <option value="NY" >New York</option>
                        <option value="NC" >North Carolina</option>
                        <option value="ND" >North Dakota</option>
                        <option value="OH" >Ohio</option>
                        <option value="OK" >Oklahoma</option>
                        <option value="OR" >Oregon</option>
                        <option value="PA" >Pennsylvania</option>
                        <option value="RI" >Rhode Island</option>
                        <option value="SC" >South Carolina</option>
                        <option value="SD" >South Dakota</option>
                        <option value="TN" >Tennessee</option>
                        <option value="TX" >Texas</option>
                        <option value="UT" >Utah</option>
                        <option value="VT" >Vermont</option>
                        <option value="VA" >Virginia</option>
                        <option value="WA" >Washington</option>
                        <option value="DC" >Washington D.C.</option>
                        <option value="WV" >West Virginia</option>
                        <option value="WI" >Wisconsin</option>
                        <option value="WY" >Wyoming</option>
                        <option value="AB" >Alberta</option>
                        <option value="BC" >British Columbia</option>
                        <option value="MB" >Manitoba</option>
                        <option value="NB" >New Brunswick</option>
                        <option value="NL" >Newfoundland</option>
                        <option value="NT" >Northwest Territories</option>
                        <option value="NS" >Nova Scotia</option>
                        <option value="NU" >Nunavut</option>
                        <option value="ON" >Ontario</option>
                        <option value="PE" >Prince Edward Island</option>
                        <option value="QC" >Quebec</option>
                        <option value="SK" >Saskatchewan</option>
                        <option value="YT" >Yukon</option>
                      </select>
                    </div>
                  </GridItem>

                  <GridItem xs={2}>
                    <div>
                      <div className="inputLabel">Zip Code</div>
                      <input id="buyerZipcode" value={this.state.epicContract.buyerZipcode || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Zipcode"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={3}>
                    <div>
                      <div className="inputLabel">Country</div>
                      <select id="buyerCountry" value={this.state.epicContract.buyerCountry || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="US">United States</option>
                        <option value="CA" >Canada</option>
                      </select>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Epic Vacations Membership</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Membership Tier</div>
                      <select id="productSelected" value={this.state.productSelected || ''} onChange={(event) => this.handleInputMembershipTier(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                        <option value="">Membership Tier</option>
                        {(this.state.products.length > 0) ? (
                          this.state.products
                            .map((product, index) => (product.status === 'Active' && <option key={index} value={product.productId}>{product.productName}</option>))
                        ) : (
                          <option>No available products.</option>
                        )
                        }
                      </select>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Term (months)</div>
                      <input id="membershipTerm" readOnly value={this.state.epicContract.membershipTerm || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Mermbership Term"></input>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Contract Date</div>
                      <input id="contractDate" value={this.state.epicContract.contractDate || ''} onChange={(event) => this.handleInput(event)} onBlur={(event) => this.handleContractDate(event)} className="formInput" type="text" placeholder="Contract Date"></input>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Membership Start</div>
                      <input id="membershipStartDate" readOnly value={this.state.epicContract.membershipStartDate || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Membership Start"></input>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Membership Expires</div>
                      <input id="membershipExpDate" readOnly value={this.state.epicContract.membershipExpDate || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Membership Expires"></input>
                    </div>
                  </GridItem>

                </GridContainer>

                <div id="contractFinancials" style={{ display: "none" }}>

                  <GridContainer>
                    <GridItem xs={5}><div className="softDivider">Closing Costs</div></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Transaction Type</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <select id="transactionType" value={this.state.epicContract.transactionType || ''} onChange={(event) => this.handleTransactionType(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                        <option value="">Select Transaction Type</option>
                        <option value="Credit Card (Full Payment)">Credit Card (Full Payment)</option>
                        <option value="Finance Transaction">Finance Transaction</option>
                        <option value="Cash/Check Full Payment">Cash/Check Full Payment</option>
                        <option value="Cash / Check Finance Balance">Cash / Check Finance Balance</option>
                      </select>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Settlement/Closing</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="escrowServices" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.escrowServices || '')} className="formInput number" type="text" placeholder="Escrow Services"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Membership Processing</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="membershipProcessing" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.membershipProcessing || '')} className="formInput number" type="text" placeholder="Membership Processing"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Activation</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="firstYearDues" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.firstYearDues || '')} className="formInput number" type="text" placeholder="Activation"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Trustee Fee</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="trusteeFees" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.trusteeFees || '')} className="formInput number" type="text" placeholder="Trustee Fee"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Third Party Providers</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="thirdPartyProviders" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.thirdPartyProviders || '')} className="formInput number" type="text" placeholder="Third Party Providers"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Loan Processing Fee</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="loanProcessingFee" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.loanProcessingFee || 0)} className="formInput number" type="text" placeholder="Loan Processing Fee"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Credit Report Fee</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="creditReportFee" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.creditReportFee || 0)} className="formInput number" type="text" placeholder="Credit Report Fee"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Total Closing Costs</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="closingCostAmount" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.closingCostAmount) || ''} className="formInput number" type="text" placeholder="Closing Costs"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={5}><div className="softDivider">Purchase Price</div></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Purchase Price</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="purchasePrice" onBlur={(event) => this.updateNumber(event)} className="formInput number" type="text" placeholder="Purchase Price"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Total Purchase Price</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="totalPurchasePrice" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.totalPurchasePrice || '')} className="formInput number" type="text" placeholder="Total Purchase Price"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={5}><div className="softDivider">Payment Details</div></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Minimum Down Payment</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="minimumDownPayment" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.minimumDownPayment || '')} className="formInput number" type="text" placeholder="Minimum Down Payment"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Additional Down Payment</div>
                    </GridItem>
                    <GridItem xs={2}>
                      <input id="additionalDownPayment" onBlur={(event) => this.updateNumber(event)} className="formInput number" type="text" placeholder="Additional Down"></input>
                    </GridItem>
                  </GridContainer>


                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Down Payment + Closing Costs</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="creditCardProcessingFee" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.subtotalDueAtClosing || '')} className="formInput number" type="text" placeholder="Sub-total Due at Closing"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">CC Processing Fee</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="creditCardProcessingFee" readOnly value={this.state.epicContract.creditCardProcessingFee || ''} className="formInput number" type="text" placeholder="CC Processing Fee"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Due at Closing</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="dueAtClosing" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.dueAtClosing || '')} className="formInput number" type="text" placeholder="Due at Closing"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={5}><div className="softDivider">Financing Details</div></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Financed Amount</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="financedAmount" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.financedAmount || '')} className="formInput number" type="text" placeholder="Financed Amount"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Interest Rate</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="interestRate" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.interestRate || '')} onChange={(event) => this.handleInput(event)} className="formInput number" type="text" placeholder="Interest Rate"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Actual APR</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="aprRate" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.aprRate || '')} onChange={(event) => this.handleInput(event)} className="formInput number" type="text" placeholder="Actual APR"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Loan Term (mo.)</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="loanTerm" value={this.state.epicContract.loanTerm || ''} onChange={(event) => this.handleInput(event)} className="formInput number" type="text" placeholder="Loan Term"></input>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={2}>
                      <div className="inputLabelTable">Monthly Payment</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <input id="regularPaymentAmount" readOnly value={tikiAPI.numberToDisplay(this.state.epicContract.regularPaymentAmount || '')} onChange={(event) => this.handleInput(event)} className="formInput number" type="text" placeholder="Monthly Payment"></input>
                    </GridItem>
                  </GridContainer>

                </div>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Sales and Marketing Reps</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Sales Agent</div>
                      <select id="salesAgent" value={this.state.epicContract.salesAgent || ''} onChange={(event) => this.handleInput(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                        <option value="">Sales Agent</option>

                        {(this.state.salesReps.length > 0) ? (
                          this.state.salesReps.map((salesRep, index) => (
                            <option key={index} value={salesRep.fullName}>{salesRep.fullName}</option>
                          ))
                        ) : (
                          <option>No available sales reps.</option>
                        )
                        }
                      </select>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">TO Agent</div>
                      <select id="toName" value={this.state.epicContract.toName || ''} onChange={(event) => this.handleInput(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                        <option value="">TO Agent</option>

                        {(this.state.salesReps.length > 0) ? (
                          this.state.salesReps.map((salesRep, index) => (
                            (salesRep.isToRep === "Yes") && <option key={index} value={salesRep.fullName}>{salesRep.fullName}</option>
                          ))
                        ) : (
                          <option>No available sales reps.</option>
                        )
                        }

                      </select>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <div className="bottomForm"><Button id="saveButton" color="success" onClick={(e) => this.createEpicContract()}>Save</Button></div>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>

      )} />

    );
  }
}

export default withStyles(styles)(Epic_Contracts_New);