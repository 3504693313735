import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import EpicMemberIcon from "@material-ui/icons/AccountCircle";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

// react router components
import { Link } from 'react-router-dom'

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Epic_Contracts_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      epicMember: {},
      epicMemberAdvantageContracts: [],
      memberCommunications: [],
      communicationType: "",
      communicationNote: "",
      newPurchase: "",
      pointsIncrements: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function (userData) {
          this.setState({ user: userData });
          this.getEpicMember(this.props.match.params.id);
        }.bind(this),
        function (err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getEpicMember(epicMemberId) {
    tikiAPI.getEpicMember(epicMemberId,
      function (epicMemberResponse) {
        console.log(epicMemberResponse);
        this.setState({ epicMember: epicMemberResponse, memberCommunications: JSON.parse(epicMemberResponse.communications) });
        var createNewPurchase = {
          epicMemberId: epicMemberResponse.memberId,
          createdBy: this.state.user.userId,
          useYear: moment().format('YYYY'),
          paymentStatus: 'Draft',
          pricePerPoint: 0.02,
          transactionFee: 29.00
        };
        console.log(createNewPurchase);
        this.setState({ newPurchase: createNewPurchase });

        if (epicMemberResponse.currentYearEpicAdvPointsAvail < 5000) {
          this.setState({ pointsIncrements: ['No Points Available'] })
        } else {
          var pointsIncrementsAvailable = epicMemberResponse.currentYearEpicAdvPointsAvail / 5000;
          var pointsOptions = [];
          for (var i = 1; i <= pointsIncrementsAvailable; i++) {
            pointsOptions.push(i * 5000);
          }
          this.setState({ pointsIncrements: pointsOptions });
        }
      }.bind(this),
      function (err) {
        console.log(err);
      })

    tikiAPI.getAllEpicAdvantageContractsForMember(epicMemberId,
      function (epicMemberAdvantageContractsResponse) {
        console.log(epicMemberAdvantageContractsResponse);
        this.setState({ epicMemberAdvantageContracts: epicMemberAdvantageContractsResponse });
      }.bind(this),
      function (err) {
        console.log(err);
      })

  }

  createMemberCommunication() {
    console.log(typeof (this.state.memberCommunications));
    console.log(this.state.memberCommunications);
    var newComm = {};
    newComm.CreatedAt = new Date();
    newComm.CreatedBy = this.state.user.firstName + " " + this.state.user.lastName;
    newComm.Type = this.state.communicationType;
    newComm.Note = this.state.communicationNote;
    console.log(newComm);
    if (this.state.memberCommunications) {
      this.state.memberCommunications.unshift(newComm);
    } else {
      this.state.memberCommunications = new Array();
      this.state.memberCommunications.unshift(newComm);
    }

    this.setState({
      epicMember: {
        ...this.state.epicMember,
        communications: JSON.stringify(this.state.memberCommunications)
      }
    }, () => {

      console.log(this.state.epicMember);

      tikiAPI.updateEpicMember(this.state.epicMember,
        function () {
          this.setState({ communicationNote: "", communicationType: "" })
          var cssClass = document.getElementById('communicationType').className;
          cssClass = cssClass + ' placeholderColor';
          document.getElementById('communicationType').className = cssClass;
        }.bind(this),
        function (err) {
          console.log(err);
        }
      );
    }

    );

  }

  createEpicAdvantageContract() {

    console.log(this.state.newPurchase);

    tikiAPI.createEpicAdvantageContract(this.state.newPurchase,
      function (epicAdvantageContractResponse) {
        console.log(epicAdvantageContractResponse);
        window.location = '/epic-advantage-secure-checkout.html?id=' + epicAdvantageContractResponse.epicAdvantageContractId;
      },
      function (err) {
        console.log(err);
      }
    );

  }

  handleInput = event => {
    this.setState({ [event.target.id]: event.target.value });

    this.removePlaceholder(event);
  }

  handleInputNewContract = event => {
    console.log("newContract");
    console.log(this.state.newPurchase);
    this.setState({
      newPurchase: {
        ...this.state.newPurchase,
        [event.target.id]: event.target.value
      }
    }, function () {

      if (this.state.newPurchase != "") {
        var total = this.state.newPurchase.pointsPurchased * this.state.newPurchase.pricePerPoint + this.state.newPurchase.transactionFee;
        this.setState({
          newPurchase: {
            ...this.state.newPurchase,
            totalPurchasePrice: total
          }
        });
      }
    });

    this.removePlaceholder(event);

  }

  removePlaceholder = event => {
    var cssClass = event.target.className;
    cssClass = cssClass.replace(' placeholderColor', '');
    event.target.className = cssClass;
  }

  editEpicMember() {
    window.location = '/admin/epic-members-edit/' + this.state.epicMember.memberId;
  }

  resetMemberPassword() {
    tikiAPI.resetMemberPassword(this.state.epicMember,
      function (data) {
        console.log(data);
        document.getElementById('passwordReset').innerHTML = data.password;
        console.log(data.password);
      }.bind(this),
      function (err) {
        console.log(err);
      }
    );
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Epic_Members_Detail" yes={() => (

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info"><EpicMemberIcon /></CardIcon>
                <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Epic Member</h4>
              </CardHeader>
              <CardBody>

                <Can permissions={this.state.user.permissions} perform="Epic_Members_Edit" yes={() => (
                  <GridContainer>
                    <GridItem xs={12}><div align="center"><Button color="success" onClick={(e) => this.editEpicMember()}>Edit</Button></div></GridItem>
                  </GridContainer>
                )} />

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Member Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Name</div>
                      <div className="displayContent">{this.state.epicMember.firstName} {this.state.epicMember.middleName} {this.state.epicMember.lastName}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Member #</div>
                      <div className="displayContent">{this.state.epicMember.memberId}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">RCI #</div>
                      <div className="displayContent">{this.state.epicMember.rciMemberNumber}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">SoR #</div>
                      <div className="displayContent">{this.state.epicMember.sorLink}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Website Username</div>
                      <div className="displayContent">{this.state.epicMember.username}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Website Password</div>
                      {this.state.epicMember.passwordIsTemp == "Yes" && <div className="displayContent">{this.state.epicMember.password}<br /><button onClick={(e) => this.resetMemberPassword()}>Reset Password</button></div>}
                      {this.state.epicMember.passwordIsTemp == "No" && <div id="passwordReset" className="displayContent"><button onClick={(e) => this.resetMemberPassword()}>Reset Password</button></div>}
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Address</div>
                      <div className="displayContent">{this.state.epicMember.address}<br />{this.state.epicMember.city}, {this.state.epicMember.state} {this.state.epicMember.zipcode}<br />{this.state.epicMember.country}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Mobile Phone</div>
                      <div className="displayContent">{this.state.epicMember.mobilePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Home Phone</div>
                      <div className="displayContent">{this.state.epicMember.homePhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Work Phone</div>
                      <div className="displayContent">{this.state.epicMember.workPhone}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Email</div>
                      <div className="displayContent">{this.state.epicMember.email}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Epic Vacations Membership</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Status</div>
                      <div className="displayContent">{this.state.epicMember.memberStatus}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Tier</div>
                      <div className="displayContent">{this.state.epicMember.membershipTier}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Term</div>
                      <div className="displayContent">{this.state.epicMember.membershipTerm}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Start</div>
                      <div className="displayContent">{moment(this.state.epicMember.membershipStartDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Expires</div>
                      <div className="displayContent">{moment(this.state.epicMember.membershipExpDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Contract #</div>
                      <div className="displayContent">
                        <Can permissions={this.state.user.permissions} perform="Epic_Contracts_Detail"
                          yes={() => (<Link to={`/admin/epic-contracts-detail/${this.state.epicMember.epicContractId}`} className="ui basic button green">{this.state.epicMember.epicContractId}</Link>)}
                          no={() => (<div>{this.state.epicMember.epicContractId}</div>)}
                        />
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Epic Advantage Points</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Points</div>
                      <select id="pointsPurchased" value={this.state.newPurchase.pointsPurchased || ''} onChange={(event) => this.handleInputNewContract(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                        <option value="">Select Points</option>
                        {this.state.pointsIncrements && this.state.pointsIncrements.map((value, index) => (
                          <option key={index} value={value}>{tikiAPI.numberToDisplay(value)}</option>
                        ))}
                      </select>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">$ Per Point</div>
                      <input id="pricePerPoint" readOnly value={tikiAPI.numberToDisplay(this.state.newPurchase.pricePerPoint) || ''} onChange={(event) => this.handleInputNewContract(event)} className="formInput" type="text" placeholder="$ Per Point"></input>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Transaction Fee</div>
                      <input id="transactionFee" value={tikiAPI.numberToDisplay(this.state.newPurchase.transactionFee) || ''} onChange={(event) => this.handleInputNewContract(event)} className="formInput" type="text" placeholder="Transaction Fee"></input>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="inputLabel">Total Purchase Price</div>
                      <input id="totalPurchasePrice" value={tikiAPI.numberToDisplay(this.state.newPurchase.totalPurchasePrice) || ''} onChange={(event) => this.handleInputNewContract(event)} className="formInput" type="text" placeholder="Total Purchase Price"></input>
                    </div>
                  </GridItem>

                  <GridItem xs={2}>
                    <div className="verticalAlignButton"><Button onClick={(event) => this.createEpicAdvantageContract(event)} className="verticalAlignButton" size="sm" color="info">Checkout</Button></div>
                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Advantage Points Available</div>
                      <div className="displayContent">{tikiAPI.numberToDisplay(this.state.epicMember.currentYearEpicAdvPointsAvail)}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Annual Maximum Advantage Points</div>
                      <div className="displayContent">{tikiAPI.numberToDisplay(this.state.epicMember.currentYearMaxEpicAdvPoints)}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Purchase Date</th>
                        <th>Points</th>
                        <th>$/Point</th>
                        <th>Fee</th>
                        <th>Total Purchase</th>
                        <th>Status</th>
                        <th>Payment Reference #</th>
                      </tr>
                    </thead>

                    <tbody>

                      {this.state.epicMemberAdvantageContracts && this.state.epicMemberAdvantageContracts.map((epicAdvantageContract, index) => (
                        <tr key={index}>
                          <td>{moment(epicAdvantageContract.createdAt).format('MM/DD/YYYY')}</td>
                          <td>{tikiAPI.numberToDisplay(epicAdvantageContract.pointsPurchased)}</td>
                          <td>{tikiAPI.numberToDisplay(epicAdvantageContract.pricePerPoint)}</td>
                          <td>{tikiAPI.numberToDisplay(epicAdvantageContract.transactionFee)}</td>
                          <td>{tikiAPI.numberToDisplay(epicAdvantageContract.totalPurchasePrice)}</td>
                          <td>{epicAdvantageContract.paymentStatus}</td>
                          <td>{epicAdvantageContract.paymentReferenceId}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Member Communications</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="inputLabel">Communication Type</div>
                      <select id="communicationType" value={this.state.communicationType || ''} onChange={(event) => this.handleInput(event)} onClick={(event) => this.removePlaceholder(event)} className="formSelect placeholderColor">
                        <option value="">Communication Type</option>
                        <option value="In Person">In Person</option>
                        <option value="Phone Call">Phone Call</option>
                        <option value="Email">Email</option>
                        <option value="Letter">Letter</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={5}>
                    <div>
                      <div className="inputLabel">Note</div>
                      <textarea rows="3" id="communicationNote" value={this.state.communicationNote || ''} onChange={(event) => this.handleInput(event)} className="formInput" placeholder="Add note..."></textarea>
                    </div>
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    <div className="verticalAlignButton"><Button size="sm" color="info" onClick={(e) => this.createMemberCommunication()}>Save</Button></div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Created By</th>
                        <th>Type</th>
                        <th width="60%">Note</th>
                      </tr>
                    </thead>

                    <tbody>

                      {this.state.memberCommunications && this.state.memberCommunications.map((comm, index) => (
                        <tr key={index}>
                          <td>{moment(comm.CreatedAt).format('MM/DD/YYYY hh:mm A')}</td>
                          <td>{comm.CreatedBy}</td>
                          <td>{comm.Type}</td>
                          <td>{comm.Note}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>

      )} />

    );
  }
}

export default withStyles(styles)(Epic_Contracts_Detail);