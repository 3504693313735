import React from "react";
import { TikiAPI } from "../../global";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

import Chart from "react-google-charts";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReportsIcon from "@material-ui/icons/BarChart";

import "assets/main.css";

// react router components
import { Link } from 'react-router-dom';

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      members: [],
      membersByTier: [],
      membersByStatus: [],
      reportTopics: [],
      displayReport: [],
      reportByTitle: "",
      currentBalance: "",
      displayListView: false,
      displayMembers: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData();
          this.setState( { reportByTitle: "Marketing Source" });
        }.bind(this),
          function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getData() {
    tikiAPI.getPointsLedgerCurrentBalance(
        function(data) {
          this.setState({ currentBalance: data });
        }.bind(this),
        function(error) { console.log(error) }
    );

    tikiAPI.getAllEpicMembers(
        function(data) {
            this.setState({ members: data }, function() {

                // Members By Tier
                var membershipTiers = [];
                var membershipTiersCount = [];

                var membershipStatuses = [];
                var membershipStatusesCount = [];

                this.state.members.forEach((member) => {
                    var topicIndex = membershipTiers.indexOf(member.membershipTier);

                    if (topicIndex == -1) {
                        membershipTiers.push(member.membershipTier);
                        topicIndex = membershipTiers.indexOf(member.membershipTier);
                    }
                    
                    if (membershipTiersCount[topicIndex] === undefined) {
                        membershipTiersCount[topicIndex] = 0;
                    }
                    membershipTiersCount[topicIndex]++;

                    var topicIndexII = membershipStatuses.indexOf(member.memberStatus);

                    if (topicIndexII == -1) {
                        membershipStatuses.push(member.memberStatus);
                        topicIndexII = membershipStatuses.indexOf(member.memberStatus);
                    }
                    
                    if (membershipStatusesCount[topicIndexII] === undefined) {
                        membershipStatusesCount[topicIndexII] = 0;
                    }
                    membershipStatusesCount[topicIndexII]++;
                });

                var membersByTier = [];
                membersByTier.push( ['Tier', 'Member Count'] );

                for (var i=0; i<membershipTiers.length; i++) {
                    membersByTier.push( [membershipTiers[i], membershipTiersCount[i]] );
                }

                this.setState( { membersByTier: membersByTier } );

                var membersByStatus = [];
                membersByStatus.push( ['Status', 'Member Count'] );

                for (var i=0; i<membershipStatuses.length; i++) {
                    membersByStatus.push( [membershipStatuses[i], membershipStatusesCount[i]] );
                }

                console.log(membersByStatus);

                this.setState( { membersByStatus: membersByStatus } );

            });
        }.bind(this),
        function(error) { console.log(error) }
    );
  }

  getAllTours() {

    tikiAPI.getAllTours(
      function(data) {
        data.sort(tikiAPI.sortByMarketingSource);
        this.setState({ tours: data }, function() {            
          this.calcReport();
        });
      }.bind(this),
      function(error) { console.log(error) }
    );
  }

  filterMembersByTier(filter) {
    var displayMembers = this.state.members.filter(m => m.membershipTier === filter);
    this.setState({ displayMembers: displayMembers });
  };

  filterMembersByStatus(filter) {
    var displayMembers = this.state.members.filter(m => m.memberStatus === filter);
    this.setState({ displayMembers: displayMembers });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ReportsIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>RCI Monthly Cede Requirement</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                    <GridItem xs={12} md={6}>
                        <table className="tableNewDesign">
                            <thead>
                            <tr>
                                <th>Contract Status</th>
                                <th>Member Count</th>
                                <th width="5%" align="right">Points</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Canceled</td>
                                    <td>12</td>
                                    <td width="5%" align="right">0</td>
                                    <td>&nbsp;</td>
                                </tr>

                                <tr>
                                    <td>Rescinded</td>
                                    <td>4</td>
                                    <td width="5%" align="right">0</td>
                                    <td>&nbsp;</td>
                                </tr>

                                <tr>
                                    <td>Draft</td>
                                    <td>8</td>
                                    <td width="5%" align="right">120,000</td>
                                    <td>&nbsp;</td>
                                </tr>

                                <tr>
                                    <td>Signed</td>
                                    <td>3</td>
                                    <td width="5%" align="right">45,000</td>
                                    <td>&nbsp;</td>
                                </tr>

                                <tr>
                                    <td>Preparing to Close</td>
                                    <td>6</td>
                                    <td width="5%" align="right">90,000</td>
                                    <td>&nbsp;</td>
                                </tr>

                                <tr>
                                    <td>Closed (w/o RCI #)</td>
                                    <td>4</td>
                                    <td width="5%" align="right">60,000</td>
                                    <td>&nbsp;</td>
                                </tr>

                                <tr className="tableTotalRow">
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td  width="5%" align="right">225,000</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                            </table>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12}>
                        <table className="tableNewDesign">
                            <thead>
                            <tr>
                                <th>Contract Status</th>
                                <th>Aug 2020</th>
                                <th>Sep 2020</th>
                                <th>Oct 2020</th>
                                <th>&nbsp;</th>
                                <th>Jun 2021</th>
                                <th>Jul 2021</th>
                                <th>Aug 2021</th>
                            </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Draft</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>&nbsp;</td>
                                    <td>0</td>
                                    <td>2</td>
                                    <td>8</td>
                                </tr>

                                <tr>
                                    <td>Signed</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>&nbsp;</td>
                                    <td>0</td>
                                    <td>4</td>
                                    <td>3</td>
                                </tr>

                                <tr>
                                    <td>Preparing to Close</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>&nbsp;</td>
                                    <td>0</td>
                                    <td>5</td>
                                    <td>6</td>
                                </tr>

                                <tr>
                                    <td>Closed</td>
                                    <td>18</td>
                                    <td>16</td>
                                    <td>24</td>
                                    <td>&nbsp;</td>
                                    <td>15</td>
                                    <td>17</td>
                                    <td>4</td>
                                </tr>

                                <tr className="tableTotalRow">
                                    <td>&nbsp;</td>
                                    <td>18</td>
                                    <td>12</td>
                                    <td>12</td>
                                    <td>&nbsp;</td>
                                    <td>12</td>
                                    <td>12</td>
                                    <td>21</td>
                                </tr>
                            </tbody>
                            </table>
                    </GridItem>

                </GridContainer>


              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>


      </div>
    );
  }
}

export default withStyles(styles)(Panels);