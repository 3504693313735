import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import { TikiAPI } from "../../global";
import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import CommissionIcon from "@material-ui/icons/LocalAtm";

const styles = {
  cardTitle,
  cardSubtitle
};

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      monthlyPayDates: [],
      weeklyPayDates: [],
      employees: [],
      salesExecWeeklyPayPeriod: "",
      salesExecMonthlyPayPeriod: "",
      salesManagers: [],
      managerOverridesCommissionsPayPeriod: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllEmployees();
          this.getWeeklyPayDates();
          this.getMonthlyPayDates();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    } 
  }

  getAllEmployees() {
    tikiAPI.getAllEmployees(
      function(data) { 
        this.setState({ employees: data })
    }.bind(this),
      function(error) {
        console.log(error);
      }
    );
  }

  getEmployeeByName(employeeName) {
    var employees = this.state.employees.filter(employee => employee.fullName == employeeName);
    return employees[0];
  }

  getWeeklyPayDates() {
    var date = moment();
    if (date.day() > 2) {
      var firstEligbleFriday = date.add(5 - date.day(), 'day'); 
    } else {
      var firstEligbleFriday = date.subtract(2 + date.day(), 'day'); 
    }

    var payDates = [];
    payDates.push(firstEligbleFriday.format("YYYY-MM-DD"));
    payDates.push("-------------------");
    var commissionDate = firstEligbleFriday.add(8, 'week');

    for (var i = 0; i < 60; i++) {
      commissionDate = firstEligbleFriday.subtract(1, 'week');
      payDates.push(commissionDate.format("YYYY-MM-DD"));
    }

    this.setState( { weeklyPayDates: payDates } );
    console.log(payDates);
  }

  getMonthlyPayDates() {
    var date = moment();

    if (date.format("D") > 20) {
      date = date.add(1, "month");
    }

    var payDates = [];
    var commissionDate = date.add(4, 'month');

    for (var i = 0; i < 18; i++) {
      commissionDate = date.subtract(1, 'month');
      payDates.push(this.getFirstFridayOfMonth(commissionDate).format("YYYY-MM-DD"));
    }

    this.setState( { monthlyPayDates: payDates } );
  }

  getFirstFridayOfMonth(date) {
    let startOfMonth = date.startOf('month');
    if (startOfMonth.day() == 6) {
      var firstFridayOfMonth = date.add(6, 'day'); 
    } else {
      var firstFridayOfMonth = date.add(5 - startOfMonth.day(), 'day'); 
    }
    return firstFridayOfMonth;
  }

  handleInput = event => {
    this.setState( { [event.target.id]: event.target.value } );
  }

  runSalesExecWeekly() {
    if (this.state.salesExecWeeklyPayPeriod) {
      document.getElementById('commissionMenu').classList.add("hide");
      document.getElementById('commissionReload').classList.remove("hide");

      var htmlBuilder = "";
      htmlBuilder = "<h3>Sales Executive - Weekly Commission for " + moment(this.state.salesExecWeeklyPayPeriod).format("MMMM DD, YYYY")  + "</h3>";

      tikiAPI.getContractsForPayPeriod("week", this.state.salesExecWeeklyPayPeriod,
        function(allContractsFromPayPeriod) {
          console.log(allContractsFromPayPeriod);
          var eligbleContractsFromPayPeriod = allContractsFromPayPeriod.filter(contract => contract.contractStatus != "Canceled");
          eligbleContractsFromPayPeriod = eligbleContractsFromPayPeriod.filter(contract => contract.contractStatus != "Rescinded");

          if (document.getElementById('salesExecWeeklyEmployee').value != "") {
            eligbleContractsFromPayPeriod = eligbleContractsFromPayPeriod.filter(contract => contract.salesAgent == document.getElementById('salesExecWeeklyEmployee').value);
          }

          if (eligbleContractsFromPayPeriod.length == 0) {
            htmlBuilder += "<div class='commissionSubTitle'>No commissions found for this report.</div>";
            htmlBuilder += "<div class='commissionSubTitle'>&nbsp;</div>";
          }

          var contractsGroupedByAgent = this.groupBy(eligbleContractsFromPayPeriod, "salesAgent");

          for (const salesAgent in contractsGroupedByAgent) {
            if (!this.getEmployeeByName(salesAgent).isSalesRep) {
              continue;
            }

            htmlBuilder += "<div class='commissionSubTitle'>" + salesAgent + "</div>";
            htmlBuilder += "<table><tr><th>TPP #</th><th>Buyer Name</th></th><th>Contract Date</th><th>Net Commissionable</th><th>Commission %</th><th>Commission $</th><th>Less Reserve</th><th>Net $</th></tr>";

            var totalCommissionForAgent = 0;

            for (const contract in contractsGroupedByAgent[salesAgent]) {
              if (this.getEmployeeByName(salesAgent).isSalesManager == "Yes" && contractsGroupedByAgent[salesAgent][contract].isToUsed == "NO") {
                continue;
              }

              if (salesAgent == contractsGroupedByAgent[salesAgent][contract].toName) {
                continue;
              }

              var commissionPercent = this.calcSalesExecCommission(contractsGroupedByAgent[salesAgent][contract], this.state.salesExecWeeklyPayPeriod);

              totalCommissionForAgent += commissionPercent * contractsGroupedByAgent[salesAgent][contract].purchasePrice;

              htmlBuilder += "<tr>";
              htmlBuilder += "<td>" + contractsGroupedByAgent[salesAgent][contract].tppDealNumber + "</td>";
              htmlBuilder += "<td>" + contractsGroupedByAgent[salesAgent][contract].buyerFirstName + " " + contractsGroupedByAgent[salesAgent][contract].buyerLastName + "</td>";
              htmlBuilder += "<td>" + moment(contractsGroupedByAgent[salesAgent][contract].contractDate).format("MMM DD, YYYY") + "</td>";
              htmlBuilder += "<td>" + tikiAPI.numberToDisplay(contractsGroupedByAgent[salesAgent][contract].purchasePrice) + "</td>";
              htmlBuilder += "<td>" + (commissionPercent * 100).toFixed(0) + "%</td>";
              htmlBuilder += "<td>" + tikiAPI.numberToDisplay(commissionPercent * contractsGroupedByAgent[salesAgent][contract].purchasePrice) + "</td>";
              htmlBuilder += "<td>" + tikiAPI.numberToDisplay(commissionPercent * contractsGroupedByAgent[salesAgent][contract].purchasePrice * .15) + "</td>";
              htmlBuilder += "<td>" + tikiAPI.numberToDisplay(commissionPercent * contractsGroupedByAgent[salesAgent][contract].purchasePrice * .85) + "</td>";
              htmlBuilder += "</tr>";
            }

            htmlBuilder += "<tr>";
            htmlBuilder += "<td colspan='5' align='right'>Total Commission:";
            htmlBuilder += "<td>" + tikiAPI.numberToDisplay(totalCommissionForAgent) + "</td>";
            htmlBuilder += "<td>" + tikiAPI.numberToDisplay(totalCommissionForAgent * .15) + "</td>";
            htmlBuilder += "<td>" + tikiAPI.numberToDisplay(totalCommissionForAgent * .85) + "</td>";
            htmlBuilder += "</tr>";    

            htmlBuilder += "</table>";
          }

          document.getElementById('commissionReport').innerHTML = htmlBuilder; 
        }.bind(this),
        function(error) {
          console.log(error);
        }
      );
  
    } else {
      alert("Please select a pay period.");
    }
  }

  calcSalesExecCommission(contract, payDate) {
    console.log(contract);
    payDate = new Date(payDate);
    var paidInFullCutOff = payDate.setDate(payDate.getDate() -2);

    if (contract.paidInFullDate) { var paidInFullDate = new Date(contract.paidInFullDate) }

    if (paidInFullDate <= paidInFullCutOff && (contract.paymentStatus == "Paid in Full at Signing" || contract.notes == "Credit Card (Full Payment)" || contract.notes == "Cash/Check Full Payment"))  {
      return 0.09;
    }

    if ((contract.depositAmount / contract.purchasePrice) >= 0.5) {
      return 0.08;
    }

    if ((contract.depositAmount / contract.purchasePrice) >= 0.25) {
      return 0.07;
    }

    if (contract.tppDealNumber == "RS21-01149") {
      return 0.07;
    }        
    
    return 0;
  }

  calcSalesManagerCommission(contract, salesAgent) {    
    console.log(contract);

    if (((contract.depositAmount / contract.purchasePrice) < 0.25) && contract.tppDealNumber != "RS21-01149") {
      return 0.00;
    }

    var baseCommission = 0.01;

    if (this.getEmployeeByName(salesAgent).isSeniorSalesManager == "Yes") {
      baseCommission = 0.02;
    }

    if (contract.salesAgent == salesAgent) {
      return 0.13;
    }

    if (contract.toName == salesAgent) {
      baseCommission += 0.025;
    }
    return baseCommission;
  }

  runSalesExecMonthly() {
    if (this.state.salesExecMonthlyPayPeriod) {
      document.getElementById('commissionMenu').classList.add("hide");
      document.getElementById('commissionReload').classList.remove("hide");
      var htmlBuilder = "";
      htmlBuilder = "<h3>Sales Executive - Monthly Volume Bonus for " + this.state.salesExecMonthlyPayPeriod + "</h3>";

      tikiAPI.getContractsForPayPeriod("month", this.state.salesExecMonthlyPayPeriod,
      function(allContractsFromPayPeriod) {
        console.log(allContractsFromPayPeriod);

        // Contracts must be paid in full by the 20th of the month prior to the pay date
        console.log(this.state.salesExecMonthlyPayPeriod);
        var payDate = new Date(this.state.salesExecMonthlyPayPeriod);
        payDate.setHours( payDate.getHours() + 12 );
        console.log(payDate);
        var paidInFullCutOff = new Date(payDate);
        paidInFullCutOff.setMonth(payDate.getMonth() - 1);
        paidInFullCutOff.setDate(20);
        
        var eligbleContractsFromPayPeriod = allContractsFromPayPeriod.filter(contract => contract.contractStatus != "Canceled");
        eligbleContractsFromPayPeriod = eligbleContractsFromPayPeriod.filter(contract => contract.contractStatus != "Rescinded");
        eligbleContractsFromPayPeriod = eligbleContractsFromPayPeriod.filter(contract => new Date(contract.paidInFullDate) <= paidInFullCutOff);

        if (document.getElementById('salesExecMonthlyEmployee').value != "") {
          eligbleContractsFromPayPeriod = eligbleContractsFromPayPeriod.filter(contract => contract.salesAgent == document.getElementById('salesExecMonthlyEmployee').value);
        }

        if (eligbleContractsFromPayPeriod.length == 0) {
          htmlBuilder += "<div class='commissionSubTitle'>No commissions found for this report.</div>";
          htmlBuilder += "<div class='commissionSubTitle'>&nbsp;</div>";
        }

        var contractsGroupedByAgent = this.groupBy(eligbleContractsFromPayPeriod, "salesAgent");

        for (const salesAgent in contractsGroupedByAgent) {
          console.log(salesAgent);

          if (this.getEmployeeByName(salesAgent).isSalesRep != "Yes") {
            continue;
          }

          var monthlyVolume = 0;
          var bonusPercent = 0;
          var bonusAmount = 0;

           var subhtmlBuilder = "<table width='50%'><tr><th>&nbsp;</th><th>Contract #</th><th>Buyer Name</th><th>Contract Date</th><th>Net Commissionable</th></tr>";

          for (const contract in contractsGroupedByAgent[salesAgent]) {
            subhtmlBuilder += "<tr>";
            subhtmlBuilder += "<td width='50'>&nbsp;</td>";
            subhtmlBuilder += "<td>" + contractsGroupedByAgent[salesAgent][contract].tppDealNumber + "</td>";
            subhtmlBuilder += "<td>" + contractsGroupedByAgent[salesAgent][contract].buyerFirstName + " " + contractsGroupedByAgent[salesAgent][contract].buyerLastName + "</td>";
            subhtmlBuilder += "<td>" + moment(contractsGroupedByAgent[salesAgent][contract].contractDate).format("MMM DD, YYYY") + "</td>";
            subhtmlBuilder += "<td>" + tikiAPI.numberToDisplay(contractsGroupedByAgent[salesAgent][contract].purchasePrice) + "</td>";
            subhtmlBuilder += "</tr>";
            console.log("contractId: " + contractsGroupedByAgent[salesAgent][contract].contractId);
            monthlyVolume += contractsGroupedByAgent[salesAgent][contract].purchasePrice;
          }

          subhtmlBuilder += "</table>";

         bonusPercent = this.calcMonthlyVolumeBonus(monthlyVolume);

          bonusAmount = monthlyVolume * bonusPercent;

          console.log("Monthly Bonus for " + salesAgent);
          console.log("Monthly Volume: " + monthlyVolume);
          console.log("Bonus %: " + (bonusPercent * 100) + "%");
          console.log("Bonus $: " + bonusAmount);
          htmlBuilder += "<table>";
          htmlBuilder += "<tr><th>Sales Rep</th><th>Volume</th><th>Bonus %</th><th>Bonus $</th></tr>"
          htmlBuilder += "<tr>";
          htmlBuilder += "<td>" + salesAgent + "</td>";
          htmlBuilder += "<td>" + tikiAPI.numberToDisplay(monthlyVolume) + "</td>";
          htmlBuilder += "<td>" + (bonusPercent * 100) + "%" + "</td>";
          htmlBuilder += "<td>" + tikiAPI.numberToDisplay(bonusAmount) + "</td>";
          htmlBuilder += "</tr>";
          htmlBuilder += "<tr><td colSpan=4>";
          htmlBuilder += "</td></tr>";
          htmlBuilder += "</table>";
          htmlBuilder += subhtmlBuilder;
        }

        document.getElementById('commissionReport').innerHTML = htmlBuilder; 
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );
    } else {
      alert("Please select a pay period.");
    }
  }

  calcMonthlyVolumeBonus(monthlyVolume) {
    var bonusPercent = 0;

    if (monthlyVolume >= 35000 && monthlyVolume <= 45000) {
      bonusPercent = 0.01;
    }

    if (monthlyVolume >= 45001 && monthlyVolume <= 55000) {
      bonusPercent = 0.02;
    }

    if (monthlyVolume >= 55001 && monthlyVolume <= 65000) {
      bonusPercent = 0.03;
    }

    if (monthlyVolume >= 65001 && monthlyVolume <= 75000) {
      bonusPercent = 0.04;
    }

    if (monthlyVolume >= 75001 && monthlyVolume <= 85000) {
      bonusPercent = 0.05;
    }

    if (monthlyVolume >= 85001 && monthlyVolume <= 95000) {
      bonusPercent = 0.06;
    }

    if (monthlyVolume >= 95001 && monthlyVolume <= 125000) {
      bonusPercent = 0.07;
    }

    if (monthlyVolume >= 125000) {
      bonusPercent = 0.10;
    }

    return bonusPercent;
  }

  groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  runManagerOverride() {
    if (this.state.managerOverridesCommissionsPayPeriod) {
      document.getElementById('commissionMenu').classList.add("hide");
      document.getElementById('commissionReload').classList.remove("hide");

      var htmlBuilder = "";
      htmlBuilder = "<h3>Sales Manager - Weekly Commission and Overrides for " + this.state.managerOverridesCommissionsPayPeriod + "</h3>";

      tikiAPI.getContractsForPayPeriod("week", this.state.managerOverridesCommissionsPayPeriod,
        function(allContractsFromPayPeriod) {
          console.log(allContractsFromPayPeriod);
          var eligbleContractsFromPayPeriod = allContractsFromPayPeriod.filter(contract => contract.contractStatus != "Canceled");
          eligbleContractsFromPayPeriod = eligbleContractsFromPayPeriod.filter(contract => contract.contractStatus != "Rescinded");
  
          for (const salesAgentIndex in this.state.employees) {
            var salesAgent = this.state.employees[salesAgentIndex].fullName;

            if (this.getEmployeeByName(salesAgent).isSeniorSalesManager != "Yes" && this.getEmployeeByName(salesAgent).isSalesManager != "Yes") {
              continue;
            }

            if (document.getElementById('managerOverridesCommissionsEmployee').value != "" && document.getElementById('managerOverridesCommissionsEmployee').value != salesAgent) {
              continue;
            }
  
            htmlBuilder += "<div class='commissionSubTitle'>" + salesAgent + "</div>";
            htmlBuilder += "<table><tr><th>TPP #</th><th>Buyer Name</th><th>Contract Date</th><th>Net Commissionable</th><th>Commission %</th><th>Commission $</th><th>Less Reserve</th><th>Net $</th></tr>";

            var totalCommissionForAgent = 0;

            for (const contractIndex in eligbleContractsFromPayPeriod) {
              var contract = eligbleContractsFromPayPeriod[contractIndex];
              var commissionPercent = this.calcSalesManagerCommission(contract, salesAgent);

              totalCommissionForAgent += commissionPercent * contract.purchasePrice;

              htmlBuilder += "<tr>";
              htmlBuilder += "<td>" + contract.tppDealNumber + "</td>";
              htmlBuilder += "<td>" + contract.buyerFirstName + " " + contract.buyerLastName + "</td>";
              htmlBuilder += "<td>" + moment(contract.contractDate).format("MMM DD, YYYY") + "</td>";
              htmlBuilder += "<td>" + tikiAPI.numberToDisplay(contract.purchasePrice) + "</td>";
              htmlBuilder += "<td>" + (commissionPercent * 100).toFixed(1) + "%</td>";
              htmlBuilder += "<td>" + tikiAPI.numberToDisplay(commissionPercent * contract.purchasePrice) + "</td>";
              htmlBuilder += "<td>" + tikiAPI.numberToDisplay(commissionPercent * contract.purchasePrice * .15) + "</td>";
              htmlBuilder += "<td>" + tikiAPI.numberToDisplay(commissionPercent * contract.purchasePrice * .85) + "</td>";
              htmlBuilder += "</tr>";    
            }

            htmlBuilder += "<tr>";
            htmlBuilder += "<td colspan='5' align='right'>Total Commission:";
            htmlBuilder += "<td>" + tikiAPI.numberToDisplay(totalCommissionForAgent) + "</td>";
            htmlBuilder += "<td>" + tikiAPI.numberToDisplay(totalCommissionForAgent * .15) + "</td>";
            htmlBuilder += "<td>" + tikiAPI.numberToDisplay(totalCommissionForAgent * .85) + "</td>";
            htmlBuilder += "</tr>";    

            htmlBuilder += "</table>";
          }

          document.getElementById('commissionReport').innerHTML = htmlBuilder; 
        }.bind(this),
        function(error) {
          console.log(error);
        }
      );
  
    } else {
      alert("Please select a pay period.");
    }
  }

  runManagerDailyClosing() {
    alert("This report is not available.");
  }

  runManagerWeeklyVPG() {
    alert("This report is not available.");
  }

  reloadPage() {
    document.location.reload();
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Commission_Main" yes={() => (

      <div>

        <GridContainer>

        <GridItem xs={1}>&nbsp;</GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <CommissionIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Commission</h4>
              </CardHeader>
              <CardBody>
                <div id="commissionReload" className="hide">
                  <GridItem xs={12}>
                    <div className="bottomForm"><Button id="saveButton" color="info" onClick={(e) => this.reloadPage()}>Back</Button></div>
                  </GridItem>
                </div>
                <div id="commissionMenu">
                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Sales Executive Commissions</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <div className="displayLabelLarge">Sales Executive Weekly</div>
                    <select id="salesExecWeeklyPayPeriod" value={this.state.salesExecWeeklyPayPeriod || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">Select Pay Period</option>
                      { this.state.weeklyPayDates.map((payDate, index) => (
                          <option key={index} value={payDate}>{payDate}</option>                               
                        ))                            
                      } 
                    </select>
                    <select id="salesExecWeeklyEmployee" value={this.state.salesExecWeeklyEmployee || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">All Sales Reps</option>
                      { this.state.employees.filter(employee => employee.isSalesRep === "Yes").map((employee, index) => (

                          <option key={index} value={employee.fullName}>{employee.fullName}</option>                               
                        ))                            
                      } 
                    </select>
                    <div className="bottomForm"><Button color="success" onClick={(e) => this.runSalesExecWeekly()}>Run Report</Button></div>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <div className="displayLabelLarge">Sales Executive Monthly</div>
                    <select id="salesExecMonthlyPayPeriod" value={this.state.salesExecMonthlyPayPeriod || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">Select Pay Period</option>
                      { this.state.monthlyPayDates.map((payDate, index) => (
                          <option key={index} value={payDate}>{payDate}</option>                               
                        ))                            
                      } 
                    </select>
                    <select id="salesExecMonthlyEmployee" value={this.state.salesExecMonthlyEmployee || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">All Sales Reps</option>
                      { this.state.employees.filter(employee => employee.isSalesRep === "Yes").map((employee, index) => (

                          <option key={index} value={employee.fullName}>{employee.fullName}</option>                               
                        ))                            
                      } 
                    </select>
                    <div className="bottomForm"><Button color="success" onClick={(e) => this.runSalesExecMonthly()}>Run Report</Button></div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Sales Manager Commissions</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <div className="displayLabelLarge">Manager Overrides &amp; Commissions</div>
                    <select id="managerOverridesCommissionsPayPeriod" value={this.state.managerOverridesCommissionsPayPeriod || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">Select Pay Period</option>
                      { this.state.weeklyPayDates.map((payDate, index) => (
                          <option key={index} value={payDate}>{payDate}</option>                               
                        ))                            
                      } 
                    </select>
                    <select id="managerOverridesCommissionsEmployee" value={this.state.managerOverridesCommissionsEmployee || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">All Sales Managers</option>
                      { this.state.employees.filter(employee => employee.isSalesManager === "Yes").map((employee, index) => (

                          <option key={index} value={employee.fullName}>{employee.fullName}</option>                               
                        ))                            
                      } 
                    </select>
                    <div className="bottomForm"><Button color="success" onClick={(e) => this.runManagerOverride()}>Run Report</Button></div>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <div className="displayLabelLarge">Manager Daily Closing %</div>
                    <select id="managerDailyClosing" value={this.state.managerDailyClosing || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">Select Pay Period</option>
                      { this.state.weeklyPayDates.map((payDate, index) => (
                          <option key={index} value={payDate}>{payDate}</option>                               
                        ))                            
                      } 
                    </select>
                    <select id="managerDailyClosingEmployee" value={this.state.managerDailyClosingEmployee || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">All Sales Managers</option>
                      { this.state.employees.filter(employee => employee.isSalesManager === "Yes").map((employee, index) => (

                          <option key={index} value={employee.fullName}>{employee.fullName}</option>                               
                        ))                            
                      } 
                    </select>
                    <div className="bottomForm"><Button color="success" onClick={(e) => this.runManagerDailyClosing()}>Run Report</Button></div>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <div className="displayLabelLarge">Manager Weekly VPG</div>
                    <select id="managerWeeklyVPG" value={this.state.managerWeeklyVPG || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">Select Pay Period</option>
                      { this.state.weeklyPayDates.map((payDate, index) => (
                          <option key={index} value={payDate}>{payDate}</option>                               
                        ))                            
                      } 
                    </select>
                    <select id="managerWeeklyVPGEmployee" value={this.state.managerWeeklyVPGEmployee || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">All Sales Managers</option>
                      { this.state.employees.filter(employee => employee.isSalesManager === "Yes").map((employee, index) => (

                          <option key={index} value={employee.fullName}>{employee.fullName}</option>                               
                        ))                            
                      } 
                    </select>
                    <div className="bottomForm"><Button color="success" onClick={(e) => this.runManagerWeeklyVPG()}>Run Report</Button></div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Marketing Rep Commissions</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div>Coming soon...</div></GridItem>
                </GridContainer>
                </div>

                <div id="commissionReport"></div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      ) } />
);
  }
}

export default withStyles(styles)(Panels);