import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import AccountingIcon from "@material-ui/icons/AttachMoney";

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

class Accounting_Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      contracts: [],
      selectResort: "",
      inputUnitNumber: "",
      resortSelect: "",
      locationSelect: "",
      bedroomSelect: "",
      unitSelect: "",
      priceSelect: "",
      seasonSelect: "",
      typeSelect: "",
      timeshareTypeSelect: "",
      checked: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllContracts();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }
  }

  componentDidMount() {
  }

  numberToDisplay(x) {
    if (x != null) {
      x = this.displayToNumber(x);
      x = x.toFixed(2);
      x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return x;
    }
  }

  getAllContracts() {

    tikiAPI.getAllContracts(
      function(contractsResponse) {
        console.log(contractsResponse);
        this.setState({ contracts: contractsResponse });
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Commission_Main" yes={() => (

      <div>

        {this.state.alert}

        <GridContainer>

        <GridItem xs={1}>&nbsp;</GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <AccountingIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Accounting</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={11}>
                  <table>
                    <thead>
                    <tr>
                      <th>Contract ID</th>                    
                      <th>Status</th>                    
                      <th className="alignRight">Purchase Date</th>                    
                      <th className="alignRight">Purchase Price</th>
                      <th>Sales Rep</th>
                      <th className="alignRight">Income GL Code</th>                    
                      <th className="alignRight">Income Amount</th>                    
                      <th className="alignRight">COG Inv</th>                    
                      <th className="alignRight">COG Inv Amount</th>
                      <th>TO Rep</th>
                      <th className="alignRight">COG Inv OR</th>                    
                      <th className="alignRight">COG Inv OR Amount</th>
                    </tr>
                    </thead>

                    <tbody>

                    {this.state.contracts.map((contract, index) => (
                      <tr key={index}>
                        <td>{contract.contractId}</td>
                        <td>{contract.contractStatus}</td>
                        <td align="right">{moment(contract.createdAt).format('MM/DD/YYYY')}</td>
                        <td align="right">{tikiAPI.numberToDisplay(contract.purchasePrice)}</td>
                        <td>{contract.salesAgent}</td>
                        <td align="right">{contract.incomeGlCode}</td>
                        <td align="right">{tikiAPI.numberToDisplay(contract.incomeAmount)}</td>
                        <td align="right">{contract.cogGlCode}</td>
                        <td align="right">{tikiAPI.numberToDisplay(contract.cogAmount)}</td>
                        <td>{contract.toName}</td>
                        <td align="right">{contract.toGlCode}</td>
                        <td align="right">{tikiAPI.numberToDisplay(contract.toCommission)}</td>
                      </tr>
                    ))}

                    </tbody>
                  </table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      ) } />
);
  }
}

export default withStyles(styles)(Accounting_Main);