import React from "react";
import { TikiAPI } from "../../global";
import Cookies from 'universal-cookie';

import Chart from "react-google-charts";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReportsIcon from "@material-ui/icons/BarChart";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      tours: [],
      reportTopics: [],
      displayReport: [],
      reportByTitle: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllTours();
          this.setState( { reportByTitle: "Marketing Source" });
        }.bind(this),
          function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getAllTours() {

    tikiAPI.getAllTours(
      function(data) {
        data.sort(tikiAPI.sortByMarketingSource);
        this.setState({ tours: data }, function() {            
          this.calcReport();
        });
      }.bind(this),
      function(error) { console.log(error) }
    );
  }

  calcReport () {

    var reportRows = [];

    var reportType = "marketingSource";

    this.state.tours.forEach((tour) => {
      if (tour[reportType] === null || tour[reportType] == "") { tour[reportType] = "(None)" };

      var topicIndex = this.state.reportTopics.indexOf(tour[reportType].toString());

      if (topicIndex == -1) {
        this.state.reportTopics.push(tour[reportType].toString());
      }

      topicIndex = this.state.reportTopics.indexOf(tour[reportType].toString());

      var salesRepName = "";

      if (tour.assignedEmployeeFirstName) { salesRepName += tour.assignedEmployeeFirstName };

      salesRepName += " ";

      if (tour.assignedEmployeeLastName) { salesRepName += tour.assignedEmployeeLastName };

      if (reportRows[topicIndex] === undefined) {
        reportRows[topicIndex] = {
          reportType: tour[reportType],
          tourCount: 0,
          salesSum: 0,
          salesCount: 0,
          noShow: 0,
          goodTours: 0,
          noQual: 0,
          qual: 0,
          rescindCount: 0,
          rescindSalesSum: 0
        };
      }
      
      reportRows[topicIndex].tourCount++;

      if (tour.contractStatus ==  "Rescinded") {
        reportRows[topicIndex].salesCount++;
        reportRows[topicIndex].salesSum += tour.salesVolume;
        reportRows[topicIndex].rescindCount++;
        reportRows[topicIndex].rescindSalesSum += tour.salesVolume;
      }

      if (tour.tourStatus === "No Show" || tour.tourStatus === "Canceled") {
        reportRows[topicIndex].noShow++;
        return;
      } else {
        reportRows[topicIndex].goodTours++;
      }

      if (tour.tourStatus === "Not Qualified") {
        reportRows[topicIndex].noQual++;
        return;
      } else {
        reportRows[topicIndex].qual++;
      }

      if (tour.tourResult == "Purchased" && tour.contractStatus == "Closed") {
        reportRows[topicIndex].salesCount++;
        reportRows[topicIndex].salesSum += tour.salesVolume;
      }
    });

    reportRows.sort(function(a,b) {return (a.reportType > b.reportType) ? 1 : ((b.reportType > a.reportType) ? -1 : 0);} );

    this.setState( { displayReport: reportRows } );

  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ReportsIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Marketing Dashboard</h4>
              </CardHeader>
              <CardBody>

              <GridContainer>
                  <GridItem xs={11}>
                    <div className="chartTitle">Monthly Tours</div>
                    <div style={{ display: 'flex', maxWidth: 1200 }}>
                        <Chart
                            width={1000}
                            height={300}
                            chartType="Bar"
                            data={[
                            ['', '2021 Tours', '2020 Tours'],
                            ['Jan', tikiAPI.filterToursByMonthAndYear("01", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("01", "2020", this.state.tours).length],
                            ['Feb', tikiAPI.filterToursByMonthAndYear("02", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("02", "2020", this.state.tours).length],
                            ['Mar', tikiAPI.filterToursByMonthAndYear("03", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("03", "2020", this.state.tours).length],
                            ['Apr', tikiAPI.filterToursByMonthAndYear("04", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("04", "2020", this.state.tours).length],
                            ['May', tikiAPI.filterToursByMonthAndYear("05", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("05", "2020", this.state.tours).length],
                            ['Jun', tikiAPI.filterToursByMonthAndYear("06", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("06", "2020", this.state.tours).length],
                            ['Jul', tikiAPI.filterToursByMonthAndYear("07", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("07", "2020", this.state.tours).length],
                            ['Aug', tikiAPI.filterToursByMonthAndYear("08", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("08", "2020", this.state.tours).length],
                            ['Sep', tikiAPI.filterToursByMonthAndYear("09", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("09", "2020", this.state.tours).length],
                            ['Oct', tikiAPI.filterToursByMonthAndYear("10", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("10", "2020", this.state.tours).length],
                            ['Nov', tikiAPI.filterToursByMonthAndYear("11", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("11", "2020", this.state.tours).length],
                            ['Dec', tikiAPI.filterToursByMonthAndYear("12", "2021", this.state.tours).length, tikiAPI.filterToursByMonthAndYear("12", "2020", this.state.tours).length],
                            ]}
                        />
                    </div>
                </GridItem>
            </GridContainer>

                <GridContainer>
                  <GridItem xs={11}>
                  <div className="chartTitle">Detailed Sales Report by Marketing Source</div>
                    <table>
                      <thead>
                      <tr>
                        <th>{this.state.reportByTitle}</th>                    
                        <th className="alignRight">Booked</th>                    
                        <th className="alignRight">NS/Cncld</th>                    
                        <th className="alignRight">Tours</th>                    
                        <th className="alignRight">NQ</th>                    
                        <th className="alignRight">Qual</th>                    
                        <th className="alignRight">Sales</th>                    
                        <th className="alignRight">Sales Vol.</th>                    
                        <th className="alignRight">Close %</th>                    
                        <th className="alignRight">Rescind</th>                    
                        <th className="alignRight">Resc Vol.</th>                    
                        <th className="alignRight">Net Sales</th>                    
                        <th className="alignRight">Net Vol.</th>                    
                        <th className="alignRight">Net Closing</th>                    
                        <th className="alignRight">VPG</th>                    
                      </tr>
                      </thead>
                      
                      <tbody>

                      {
                      this.state.displayReport.map((reportRow, index) => (
                        <tr key={index}>
                          <td>{reportRow.reportType}</td>
                          <td align="right">{reportRow.tourCount}</td>
                          <td align="right">{reportRow.noShow}</td>
                          <td align="right">{reportRow.goodTours}</td>
                          <td align="right">{reportRow.noQual}</td>
                          <td align="right">{reportRow.qual}</td>
                          <td align="right">{reportRow.salesCount}</td>
                          <td align="right">{tikiAPI.numberToDisplay(reportRow.salesSum)}</td>
                          <td align="right">{((reportRow.salesCount / reportRow.goodTours)*100).toFixed(2)}%</td>
                          <td align="right">{reportRow.rescindCount}</td>
                          <td align="right">{tikiAPI.numberToDisplay(reportRow.rescindSalesSum)}</td>
                          <td align="right">{reportRow.salesCount - reportRow.rescindCount}</td>
                          <td align="right">{tikiAPI.numberToDisplay(reportRow.salesSum - reportRow.rescindSalesSum)}</td>
                          <td align="right">{( ( (reportRow.salesCount - reportRow.rescindCount) / reportRow.goodTours ) *100 ).toFixed(2)}%</td>
                          <td align="right">{ tikiAPI.numberToDisplay((reportRow.salesSum - reportRow.rescindSalesSum) / reportRow.tourCount)}</td>
                        </tr>
                      ))}

                    </tbody>
                    </table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Panels);