import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      transactions: [],
      currentBalance: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount() {
  }

  getData() {

    tikiAPI.getPointsLedgerTransactions(
      function(data) {
        data.reverse();
        this.setState({ transactions: data });
      }.bind(this),
      function(error) { console.log(error) }
    );

    tikiAPI.getPointsLedgerCurrentBalance(
      function(data) {
        this.setState({ currentBalance: data });
      }.bind(this),
      function(error) { console.log(error) }
    )
  
  }

  render() {
    
    const { classes } = this.props;

    return (
    
    <Can permissions={this.state.user.permissions} perform="PointsLedger_Main" yes={() => (
    
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info"><Assignment /></CardIcon>
            
            <Can permissions={this.state.user.permissions} perform="PointsLedger_Main" yes={() => (
            
            <h4 className={classes.cardIconTitle}>Current Points Balance: {tikiAPI.numberToDisplay(this.state.currentBalance)}</h4>
            
            )} />
            
            </CardHeader>
              <CardBody>
                <div>
                  <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Debit/Credit</th>
                      <th>Points</th>
                      <th>Contract ID</th>
                      <th>Member ID</th>
                      <th>Resort</th>
                      <th>Unit</th>
                      <th>Week</th>
                      <th>Year</th>
                    </tr>
                    </thead>
                    <tbody>  
                    {this.state.transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>{transaction.pointLedgerId}</td>
                        <td>{moment(transaction.createdAt).format('MM/DD/YYYY')}</td>
                        <td>{transaction.status}</td>
                        <td>{transaction.transactionType}</td>
                        <td>{transaction.debitOrCredit}</td>
                        <td>{tikiAPI.numberToDisplay(transaction.pointsQty)}</td>
                        <td>{transaction.contractId}</td>
                        <td>{transaction.memberId}</td>
                        <td>{transaction.resort}</td>
                        <td>{transaction.unitNumber}</td>
                        <td>{transaction.useWeek}</td>
                        <td>{transaction.useYear}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
      </GridContainer>
      
      )} />
      
      );
    }
  }
  
export default withStyles(extendedTablesStyle)(Transactions);