import React from "react";
import { TikiAPI } from "../../global";
import Cookies from 'universal-cookie';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReportsIcon from "@material-ui/icons/BarChart";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      tours: [],
      reportTopics: [],
      displayReport: [],
      reportByTitle: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllTours();
          this.setState( { reportByTitle: "Marketing Source" });
        }.bind(this),
          function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getAllTours() {

    tikiAPI.getAllTours(
      function(data) {
        data.sort(tikiAPI.sortByMarketingSource);
        this.setState({ tours: data }, function() {
          this.calcReport();
        });
      }.bind(this),
      function(error) { console.log(error) }
    );
  }

  calcReport () {

    var reportRows = [];

    var reportType = "marketingSource";

    this.state.tours.forEach((tour) => {
      if (tour[reportType] === null) { tour[reportType] = "(none)" };

      var topicIndex = this.state.reportTopics.indexOf(tour[reportType].toString());

      if (topicIndex == -1) {
        this.state.reportTopics.push(tour[reportType].toString());
      }

      topicIndex = this.state.reportTopics.indexOf(tour[reportType].toString());

      if (reportRows[topicIndex] === undefined) {
        reportRows[topicIndex] = {
          reportType: tour[reportType],
          tourCount: 0,
          salesSum: 0,
          salesCount: 0,
          noShow: 0,
          goodTours: 0,
          noQual: 0,
          qual: 0,
          rescindCount: 0,
          rescindSalesSum: 0
        };
      }
      
      reportRows[topicIndex].tourCount++;

      if (tour.contractStatus ==  "Rescinded") {
        reportRows[topicIndex].salesCount++;
        reportRows[topicIndex].salesSum += tour.salesVolume;
        reportRows[topicIndex].rescindCount++;
        reportRows[topicIndex].rescindSalesSum += tour.salesVolume;
      }

      if (tour.tourStatus === "No Show") {
        reportRows[topicIndex].noShow++;
      } else {
        reportRows[topicIndex].goodTours++;
      }

      if (tour.qualified === "Qualified") {
        reportRows[topicIndex].qual++;
      } else if (tour.qualified === "Not Qualified") {
        reportRows[topicIndex].noQual++;
      }

      if (tour.tourResult == "Purchased" && tour.contractStatus == "Closed") {
        reportRows[topicIndex].salesCount++;
        reportRows[topicIndex].salesSum += tour.salesVolume;
      }
    });

    reportRows.sort(function(a,b) {return (a.reportType > b.reportType) ? 1 : ((b.reportType > a.reportType) ? -1 : 0);} );

    this.setState( { displayReport: reportRows } );

  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ReportsIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Sales Report</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={11}>
                    <table>
                      <thead>
                      <tr>
                        <th>{this.state.reportByTitle}</th>                    
                        <th className="alignRight">Booked</th>                    
                        <th className="alignRight">NS</th>                    
                        <th className="alignRight">Tours</th>                    
                        <th className="alignRight">NQ</th>                    
                        <th className="alignRight">Qual</th>                    
                        <th className="alignRight">Sales</th>                    
                        <th className="alignRight">Sales Vol.</th>                    
                        <th className="alignRight">Close %</th>                    
                        <th className="alignRight">Rescind</th>                    
                        <th className="alignRight">Resc Vol.</th>                    
                        <th className="alignRight">Net Sales</th>                    
                        <th className="alignRight">Net Vol.</th>                    
                        <th className="alignRight">Net Closing %</th>                    
                        <th className="alignRight">VPG</th>                    
                      </tr>
                      </thead>
                      
                      <tbody>

                      {
                      this.state.displayReport.map((reportRow, index) => (
                        <tr key={index}>
                          <td>{reportRow.reportType}</td>
                          <td align="right">{reportRow.tourCount}</td>
                          <td align="right">{reportRow.noShow}</td>
                          <td align="right">{reportRow.goodTours}</td>
                          <td align="right">{reportRow.noQual}</td>
                          <td align="right">{reportRow.qual}</td>
                          <td align="right">{reportRow.salesCount}</td>
                          <td align="right">{tikiAPI.numberToDisplay(reportRow.salesSum)}</td>
                          <td align="right">{((reportRow.salesCount / reportRow.goodTours)*100).toFixed(2)}%</td>
                          <td align="right">{reportRow.rescindCount}</td>
                          <td align="right">{tikiAPI.numberToDisplay(reportRow.rescindSalesSum)}</td>
                          <td align="right">{reportRow.salesCount - reportRow.rescindCount}</td>
                          <td align="right">{tikiAPI.numberToDisplay(reportRow.salesSum - reportRow.rescindSalesSum)}</td>
                          <td align="right">{( ( (reportRow.salesCount - reportRow.rescindCount) / reportRow.goodTours ) *100 ).toFixed(2)}%</td>
                          <td align="right">{ tikiAPI.numberToDisplay((reportRow.salesSum - reportRow.rescindSalesSum) / reportRow.tourCount)}</td>
                        </tr>
                      ))}

                    </tbody>
                    </table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Panels);