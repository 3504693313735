import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import EmployeesIcon from '@material-ui/icons/AssignmentInd';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Employees_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      employee: {}
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
            this.setState({ user: userData });
          this.getEmployee(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

getEmployee(employeeId) {
    tikiAPI.getEmployee( employeeId,
      function(response) {
        this.setState( { employee: response });
      }.bind(this),
      function(err) {
        console.log(err);
      })
    }

editEmployee() {
    window.location = '/admin/employees-edit/' + this.state.employee.employeeId;
}

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Employees_Detail" yes={() => (

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"><EmployeesIcon /></CardIcon>
              <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Employee</h4>
            </CardHeader>
              <CardBody>

                <Can permissions={this.state.user.permissions} perform="Employees_Edit" yes={() => (
                  <GridContainer>
                    <GridItem xs={12}><div align="center"><Button color="success" onClick={(e) => this.editEmployee()}>Edit</Button></div></GridItem>
                  </GridContainer>
                )} />

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Employee Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">Status</div>
                        <div className="displayContent">{this.state.employee.employeeStatus}</div>
                        </div>
                    </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Name</div>
                      <div className="displayContent">{this.state.employee.fullName}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Senior Sales Manager</div>
                      <div className="displayContent">{this.state.employee.isSeniorSalesManager}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Sales Manager</div>
                      <div className="displayContent">{this.state.employee.isSalesManager}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Sales Rep</div>
                      <div className="displayContent">{this.state.employee.isSalesRep}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">TO Rep</div>
                      <div className="displayContent">{this.state.employee.isToRep}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Marketing Manager</div>
                      <div className="displayContent">{this.state.employee.isMarketingManager}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Marketing Rep</div>
                      <div className="displayContent">{this.state.employee.isMarketingRep}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Accounting Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeIncomeInv</div>
                        <div className="displayContent">{this.state.employee.glCodeIncomeInv}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeIncomeRci</div>
                        <div className="displayContent">{this.state.employee.glCodeIncomeRci}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeIncomeHsi</div>
                        <div className="displayContent">{this.state.employee.glCodeIncomeHsi}</div>
                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeCogInv</div>
                        <div className="displayContent">{this.state.employee.glCodeCogInv}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeCogRci</div>
                        <div className="displayContent">{this.state.employee.glCodeCogRci}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeCogHsi</div>
                        <div className="displayContent">{this.state.employee.glCodeCogHsi}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeCogInvOr</div>
                        <div className="displayContent">{this.state.employee.glCodeCogInvOr}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeCogRciOr</div>
                        <div className="displayContent">{this.state.employee.glCodeCogRciOr}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeCogHsiOr</div>
                        <div className="displayContent">{this.state.employee.glCodeCogHsiOr}</div>
                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                        <div className="displayLabel">GlCodeLiabilityReserve</div>
                        <div className="displayContent">{this.state.employee.glCodeLiabilityReserve}</div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={3}>
                        <div>
                        <div className="displayLabel">GlCodeLiabilitySalesComm</div>
                        <div className="displayContent">{this.state.employee.glCodeLiabilitySalesComm}</div>
                        </div>
                    </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

      </GridContainer>

      ) } /> // End Can RBAC element
      
      );
  }
}

export default withStyles(styles)(Employees_Detail);