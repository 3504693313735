import React from "react";
import { TikiAPI } from "../../global";
import Cookies from 'universal-cookie';
import * as moment from 'moment';
import Can from "../../components/Can";

import Chart from "react-google-charts";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReportsIcon from "@material-ui/icons/BarChart";

import "assets/main.css";

// react router components
import { Link } from 'react-router-dom';

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      contracts: [],
      contractsByStatus: [],
      displayListView: false,
      displayContracts: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData();
        }.bind(this),
          function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getData() {
    tikiAPI.getAllContracts(
        function(data) {
            this.setState({ contracts: data }, function() {
                var contractsStatuses = [];
                var contractsStatusesCount = [];

                this.state.contracts.forEach((contract) => {
                    var topicIndex = contractsStatuses.indexOf(contract.contractStatus);

                    if (topicIndex == -1) {
                        contractsStatuses.push(contract.contractStatus);
                        topicIndex = contractsStatuses.indexOf(contract.contractStatus);
                    }
                    
                    if (contractsStatusesCount[topicIndex] === undefined) {
                        contractsStatusesCount[topicIndex] = 0;
                    }
                    contractsStatusesCount[topicIndex]++;
                });

                var contractsByStatus = [];
                contractsByStatus.push( ['Status', 'Count'] );

                for (var i=0; i<contractsStatuses.length; i++) {
                    contractsByStatus.push( [contractsStatuses[i], contractsStatusesCount[i]] );
                }

                this.setState( { contractsByStatus: contractsByStatus } );
            });
        }.bind(this),
        function(error) { console.log(error) }
    );
  }

  filterContractsByStatus(filter) {
    var displayContracts = this.state.contracts.filter(c => c.contractStatus === filter);
    this.setState({ displayContracts: displayContracts });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ReportsIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Contracts Dashboard</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                    <GridItem xs={11}>
                        <div style={{ display: 'flex', maxWidth: 1200 }}>
                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            data={ this.state.contractsByStatus }
                            options={{
                                title: 'Contracts by Status',
                            }}
                            chartEvents={[
                                {
                                  eventName: 'select',
                                  callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart()
                                    const selection = chart.getSelection()
                                    if (selection.length === 1) {
                                      const [selectedItem] = selection
                                      const dataTable = chartWrapper.getDataTable()
                                      const { row } = selectedItem
                                      this.filterContractsByStatus(dataTable.getValue(row,0));
                                    }
                                  },
                                },
                              ]}                            
                        />

                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                <table cellPadding="5" cellSpacing="0" width="100%">
                  <thead>
                    <tr>
                      <th>Contract ID</th>               
                      <th>TPP #</th>               
                      <th>Status</th>               
                      <th>Contract Date</th>                    
                      <th>Buyer</th>
                      <th>Seller</th>
                      <th>Resort</th>                    
                      <th>Unit #</th>                    
                      <th>Use Week</th>
                    </tr>
                  </thead>

                  <tbody>
                  {this.state.displayContracts.map((contract, index) => (
                    <tr key={index}>
                      <td>
                      <Can permissions={this.state.user.permissions} perform="Contracts_Detail"
                        yes={() => ( <Link to={`contracts-detail/${contract.contractId}`} className="ui basic button green">{contract.contractId}</Link> ) }
                        no={() => ( <div>{ contract.contractId }</div> ) }
                        />
                      </td>
                      <td><a href={contract.tppLink} target="_blank" rel="noopener noreferrer">{contract.tppDealNumber}{!contract.tppDealNumber && contract.tppLink && <span>Complete Jiffydocs Contract</span>}</a></td>
                      <td>{contract.contractStatus}</td>
                      <td>{moment(contract.contractDate).format('MM/DD/YYYY')}</td>
                      <td>{contract.buyerFirstName} {contract.buyerLastName}</td>
                      <td>{contract.sellerCompanyName} {contract.sellerFirstName} {contract.sellerLastName}</td>
                      <td>{contract.resort}</td>
                      <td>{contract.unitNumber}</td>
                      <td>{contract.useWeek}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>


      </div>
    );
  }
}

export default withStyles(styles)(Panels);