import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import InventoryIcon from "@material-ui/icons/VpnKey";
import Button from "components/CustomButtons/Button.jsx";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      inventory: "",
      tourId: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData, tourId: this.props.match.params.tourId });
          this.getData(this.props.match.params.inventoryId);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount(){
  }

  getData(inventoryId){

    tikiAPI.getInventory(inventoryId,
      function(data) {
        this.setState({inventory: data});
      }.bind(this),
      function(error) { console.log(error) }
    );
  }    

  purchaseInventory(id) {
    window.location = '/admin/contracts-new-for-tour/' + this.state.tourId + '/inventory/' + this.state.inventory.inventoryId;
  }

  render() {  

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Inventory_Detail" yes={() => (

      <div>
        <GridContainer>

        <Can permissions={this.state.user.permissions} perform="Contracts_New" yes={() => (

        <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
          <Button color="info" onClick={(e) => this.purchaseInventory()}>Purchase</Button>
        </GridItem>

        ) } />

<GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <InventoryIcon />
              </CardIcon>
              <h4 className={classes.cardTitle}>Inventory Detail</h4>
            </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <table cellPadding="5" cellSpacing="0" width="100%">

                    <thead>
                        <tr bgcolor="#f0f0f0">
                          <td colSpan="5">Unit Summary</td>
                        </tr>
                      </thead>
                      <tbody>  
                      <tr>
                          <td width="20%" align="right"><b>ID:</b></td>
                          <td width="20%">{this.state.inventory.inventoryId}</td>
                          <td width="20%" align="right"><b>Status:</b></td>
                          <td width="20%">{this.state.inventory.listingStatus}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="20%" align="right"><b>Resort:</b></td>
                          <td width="20%">{this.state.inventory.resortName}</td>
                          <td width="20%" align="right"><b>Week:</b></td>
                          <td width="20%">{this.state.inventory.useWeek}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>

                        <tr>
                          <td width="20%" align="right"><b>Unit:</b></td>
                          <td width="20%">{this.state.inventory.unitNumber}</td>
                          <td width="20%" align="right"><b>Next Use Year:</b></td>
                          <td width="20%">{this.state.inventory.nextUseYear}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="20%" align="right"><b>Use Year Type:</b></td>
                          <td width="20%">{this.state.inventory.useYearType}</td>
                          <td width="20%" align="right">&nbsp;</td>
                          <td width="20%">&nbsp;</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <table cellPadding="5" cellSpacing="0" width="100%">
                    <thead>
                        <tr bgcolor="#f0f0f0">
                          <td colSpan="5">Unit Details</td>
                        </tr>
                      </thead>
                      <tbody>  
                      <tr>
                          <td width="20%" align="right"><b>Unit Type:</b></td>
                          <td width="20%">{this.state.inventory.unitType}</td>
                          <td width="20%" align="right"><b>Bed:</b></td>
                          <td width="20%">{this.state.inventory.bedrooms}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="20%" align="right"><b>Bath:</b></td>
                          <td width="20%">{this.state.inventory.bathrooms}</td>
                          <td width="20%" align="right"><b>Style:</b></td>
                          <td width="20%">{this.state.inventory.unitStyle}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="20%" align="right"><b>View:</b></td>
                          <td width="20%">{this.state.inventory.unitView}</td>
                          <td width="20%" align="right"><b>Golf:</b></td>
                          <td width="20%">{this.state.inventory.golfPackage}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <table cellPadding="5" cellSpacing="0" width="100%">
                    <thead>
                        <tr bgcolor="#f0f0f0">
                          <td colSpan="5">Financial</td>
                        </tr>
                      </thead>
                      <tbody>  

                          <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive"
                            yes={() => (
                              <tr>
                                <td width="20%" align="right"><b>Retail Price:</b></td>
                                <td width="20%">{tikiAPI.numberToDisplay(this.state.inventory.retailPrice)}</td>
                                <td width="20%" align="right"><b>Listing Price:</b></td>
                                <td width="20%">{tikiAPI.numberToDisplay(this.state.inventory.listingPrice)}</td>
                                <td width="20%">&nbsp;</td>
                              </tr>
                            )}
                            no={() => (
                              <tr>
                                <td width="20%" align="right"><b>Retail Price:</b></td>
                                <td width="20%">{tikiAPI.numberToDisplay(this.state.inventory.retailPrice)}</td>
                                <td width="20%">&nbsp;</td>
                                <td width="20%">&nbsp;</td>
                                <td width="20%">&nbsp;</td>
                              </tr>
                            )}
                            />
                        <tr>
                          <td width="20%" align="right"><b>Maintenance Fee:</b></td>
                          <td width="20%">{tikiAPI.numberToDisplay(this.state.inventory.maintFee)}</td>
                          <td width="20%" align="right">&nbsp;</td>
                          <td width="20%">&nbsp;</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <table cellPadding="5" cellSpacing="0" width="100%">
                    <thead>
                        <tr bgcolor="#f0f0f0">
                          <td colSpan="5">Points</td>
                        </tr>
                      </thead>
                      <tbody>  
                      <tr>
                          <td width="20%" align="right"><b>Points:</b></td>
                          <td width="20%">{this.state.inventory.pointValue}</td>
                          <td width="20%" align="right"><b>Listed w/ Points:</b></td>
                          <td width="20%">{this.state.inventory.listedWithPoints}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="20%" align="right"><b>Points Season:</b></td>
                          <td width="20%">{this.state.inventory.pointsSeason}</td>
                          <td width="20%" align="right">&nbsp;</td>
                          <td width="20%">&nbsp;</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                  <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
                    <table cellPadding="5" cellSpacing="0" width="100%">
                    <thead>
                      <tr bgcolor="#f0f0f0">
                        <td colSpan="5">Listing</td>
                      </tr>
                    </thead>
                    <tbody>  
                    <tr>
                        <td width="20%" align="right"><b>Owner:</b></td>
                        <td width="20%">{this.state.inventory.ownerName}</td>
                        <td width="20%" align="right"><b>Mkt Fee Date Paid:</b></td>
                        <td width="20%">{this.state.inventory.mktFeeDatePd}</td>
                        <td width="20%">&nbsp;</td>
                      </tr>
                      <tr>
                        <td width="20%" align="right"><b>Mkt Fee:</b></td>
                        <td width="20%">{this.state.inventory.mktFee}</td>
                        <td width="20%" align="right"><b>Amount Paid:</b></td>
                        <td width="20%">{this.state.inventory.amountPaid}</td>
                        <td width="20%">&nbsp;</td>
                      </tr>
                      <tr>
                        <td width="20%" align="right"><b>Variance:</b></td>
                        <td width="20%">{this.state.inventory.variance}</td>
                        <td width="20%" align="right"><b>Notes:</b></td>
                        <td width="20%">{this.state.inventory.notes}</td>
                        <td width="20%">&nbsp;</td>
                      </tr>
                    </tbody>
                    </table>
                  )} />

                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                  <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
                    <table cellPadding="5" cellSpacing="0" width="100%">
                    <thead>
                        <tr bgcolor="#f0f0f0">
                          <td colSpan="5">Notes</td>
                        </tr>
                      </thead>
                      <tbody>  
                      <tr>
                          <td width="20%" align="right"><b>Entry Date:</b></td>
                          <td width="20%">{this.state.inventory.entryDate}</td>
                          <td width="20%" align="right"><b>Verified By:</b></td>
                          <td width="20%">{this.state.inventory.verifiedBy}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="20%" align="right"><b>Date Verified:</b></td>
                          <td width="20%">{this.state.inventory.dateVerified}</td>
                          <td width="20%" align="right"><b>Exp Date:</b></td>
                          <td width="20%">{this.state.inventory.expDate}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="20%" align="right"><b>Notes:</b></td>
                          <td colSpan="3">{this.state.inventory.notes}</td>
                          <td width="20%">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  )} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Panels);