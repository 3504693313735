import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ListingsIcon from "@material-ui/icons/ListAlt";

import Button from "components/CustomButtons/Button.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      inventory: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  getData(inventoryId){

    tikiAPI.getInventory(inventoryId,
      function(data) {
        this.setState({inventory: data}, function() {
          console.log(this.state.inventory);
        });
      }.bind(this),
      function(error) { console.log(error) }
      );
  
    }

  generateListingAgreement(){

    document.getElementById("generateContractButton").innerHTML = "Please wait...";

    tikiAPI.generateListingAgreement(this.state.inventory.inventoryId,
      function(data) {
        this.setState({inventory: data}, function() {
          console.log(this.state.inventory);
          document.getElementById("generateContractButton").innerHTML = "Done!";
        });
      }.bind(this),
      function(error) {
        console.log(error);
        document.getElementById("generateContractButton").innerHTML = "Error - Please Contact Support";
      }
      );  
    }

    sendListingAgreementToDocusign(){

    document.getElementById("sendListingAgreementToDocusignContainer").innerHTML = "Please wait...";

    tikiAPI.sendListingAgreementToDocusign(this.state.inventory.inventoryId,
      function(data) {
        this.setState({inventory: data}, function() {
          console.log(this.state.inventory);
          document.getElementById("sendListingAgreementToDocusignContainer").innerHTML = data.docuSignId;
        });
      }.bind(this),
      function(error) {
        console.log(error);
        document.getElementById("sendListingAgreementToDocusignContainer").innerHTML = "Error - Please Contact Support";
      }
      );  
    }
  
  editListing(id) {
    window.location = '/admin/listings-edit/' + id;
  }

  render() {  

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Inventory_Detail" yes={() => (

      <div>
        <GridContainer>

        <Can permissions={this.state.user.permissions} perform="Inventory_Edit" yes={() => (

        <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
          <Button color="info" onClick={(e) => this.editListing(this.state.inventory.inventoryId)}>Edit</Button>
        </GridItem>

        ) } />

          <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <ListingsIcon />
              </CardIcon>
              <h4 className={classes.cardTitle}>Listing Detail</h4>
            </CardHeader>
            <CardBody>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Listing Summary</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Inventory ID</div>
                    <div className="displayContent">{this.state.inventory.inventoryId}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Seller Name</div>
                    <div className="displayContent">
                      {this.state.inventory.sellerFirstName} {this.state.inventory.sellerMiddleName} {this.state.inventory.sellerLastName}
                      {this.state.inventory.sellerCompanyName}
                      {this.state.inventory.sellerTrustName}
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Status</div>
                    <div className="displayContent">{this.state.inventory.listingStatus}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Start Date</div>
                    <div className="displayContent">{this.state.inventory.listingStartDate && moment(this.state.inventory.listingStartDate).format("MM/DD/YYYY")}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing End Date</div>
                    <div className="displayContent">{this.state.inventory.listingEndDate && moment(this.state.inventory.listingEndDate).format("MM/DD/YYYY")}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Unit Details</div></GridItem>
              </GridContainer>

              <GridContainer>
              <GridItem xs={12}>
                  <div>
                    <div className="displayLabel">Resort</div>
                    <div className="displayContent">{this.state.inventory.resortName}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit #</div>
                    <div className="displayContent">{this.state.inventory.unitNumber}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Week</div>
                    <div className="displayContent">{this.state.inventory.useWeek}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Fixed / Float</div>
                    <div className="displayContent">{this.state.inventory.fixedFloat}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Use Year Type</div>
                    <div className="displayContent">{this.state.inventory.useYearType}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Next Use Year</div>
                    <div className="displayContent">{this.state.inventory.nextUseYear}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit Type</div>
                    <div className="displayContent">{this.state.inventory.unitType}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Bedrooms</div>
                    <div className="displayContent">{this.state.inventory.bedrooms}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Bathrooms</div>
                    <div className="displayContent">{this.state.inventory.bathrooms}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit Sleeps</div>
                    <div className="displayContent">{this.state.inventory.unitSleeps}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Golf Package</div>
                    <div className="displayContent">{this.state.inventory.golfPackage}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Points</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Point Value</div>
                    <div className="displayContent">{this.state.inventory.pointsAmount}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listed w/ Points</div>
                    <div className="displayContent">{this.state.inventory.listedWithPoints}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">RCI Number</div>
                    <div className="displayContent">{this.state.inventory.rciNumber}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Points Season</div>
                    <div className="displayContent">{this.state.inventory.pointsSeason}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Financial</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Retail Price</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.retailPrice)}</div>
                  </div>
                </GridItem>

                <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Price</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.listingPrice)}</div>
                  </div>
                </GridItem>
                )} />

                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Maintenance Fee</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.maintFee)}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Financing Eligible</div>
                    <div className="displayContent">{this.state.inventory.financingEligible}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Marketing Fee</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Marketing Fee</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.marketingFee)}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={6}>
                  <div>
                    <div className="displayLabel">Marketing Fee Notes</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.variance)}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Seller Information</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Entity Type</div>
                    <div className="displayContent">{this.state.inventory.sellerEntityType}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">HOA Owned</div>
                    <div className="displayContent">{this.state.inventory.isHoaOwned}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Seller Name</div>
                    <div className="displayContent">
                      {this.state.inventory.sellerFirstName} {this.state.inventory.sellerMiddleName} {this.state.inventory.sellerLastName}
                      {this.state.inventory.sellerCompanyName}
                      {this.state.inventory.sellerTrustName}
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Email</div>
                    <div className="displayContent">{this.state.inventory.sellerEmail}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Address</div>
                    <div className="displayContent">
                      {this.state.inventory.sellerAddress}<br />
                      {this.state.inventory.sellerCity}, {this.state.inventory.sellerState} {this.state.inventory.sellerZipcode} {this.state.inventory.sellerCountry}<br />
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Home Phone</div>
                    <div className="displayContent">{this.state.inventory.sellerHomePhone}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Work Phone</div>
                    <div className="displayContent">{this.state.inventory.sellerWorkPhone}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Mobile Phone</div>
                    <div className="displayContent">{this.state.inventory.sellerMobilePhone}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Co-Seller Name</div>
                    <div className="displayContent">{this.state.inventory.coSellerFirstName} {this.state.inventory.coSellerLastName}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Co-Seller Home Phone</div>
                    <div className="displayContent">{this.state.inventory.coSellerHomePhone}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Co-Seller Work Phone</div>
                    <div className="displayContent">{this.state.inventory.coSellerWorkPhone}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Co-Seller Mobile Phone</div>
                    <div className="displayContent">{this.state.inventory.coSellerMobilePhone}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Co-Seller Email</div>
                    <div className="displayContent">{this.state.inventory.coSellerEmail}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Internal Notes</div></GridItem>
              </GridContainer>
              )} />

              <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
              <GridContainer>
                <GridItem xs={12} sm={8}>
                  <div>
                    <div className="displayLabel">Notes</div>
                    <div className="displayContent">{this.state.inventory.notes}</div>
                  </div>
                </GridItem>
              </GridContainer>
              )} />

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Listing Agreements</div></GridItem>

                <GridItem xs={12}>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <table>
                    <tr>
                      <th>Generate Contract</th>
                      <th>File</th>
                      <th>Last Updated</th>
                      <th>DocuSign</th>
                    </tr>

                    <tr>
                      {this.state.inventory.listingDocumentLink &&
                        <td><Button id="generateContractButton" size="sm" color="success" onClick={(e) => this.generateListingAgreement()}>Recreate Listing Agreement</Button></td>
                      }
                      {this.state.inventory.listingDocumentLink &&
                        <td><a href={this.state.inventory.listingDocumentLink}>{this.state.inventory.listingFileName}</a></td>
                      }
                      {this.state.inventory.listingDocumentLink &&
                        <td>{moment(this.state.inventory.listingDocumentDate).format("MM/DD/YYYY hh:mm a")}</td>
                      }

                      {!this.state.inventory.listingDocumentLink &&
                      <span>
                        <td><Button id="generateContractButton" size="sm" color="success" onClick={(e) => this.generateListingAgreement()}>Create Listing Agreement</Button></td>
                        <td>&nbsp;</td>
                      </span>
                        
                      }
                      {this.state.inventory.docuSignId && this.state.inventory.listingDocumentLink &&
                      <td>
                        <div id="sendListingAgreementToDocusignContainer">
                          {this.state.inventory.docuSignId}
                        </div>
                        <div>
                          <Button id="sendListingAgreementToDocusignButton" size="sm" color="success" onClick={(e) => this.sendListingAgreementToDocusign()}>Resend DocuSign</Button>
                        </div>
                      </td>
                      }
                      {!this.state.inventory.docuSignId && this.state.inventory.listingDocumentLink &&
                      <td>
                        <div id="sendListingAgreementToDocusignContainer">
                          <Button id="sendListingAgreementToDocusignButton" size="sm" color="success" onClick={(e) => this.sendListingAgreementToDocusign()}>Request DocuSign</Button>
                        </div>
                      </td>
                      }

                    </tr>
                  </table>
                </GridItem>
              </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Panels);