import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ContractIcon from "@material-ui/icons/InsertDriveFile";
import Button from "components/CustomButtons/Button.jsx";

import { Redirect } from "react-router-dom";

import "assets/main.css";

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: { color: "#3C4858", textDecoration: "none", textAlign: "center" },
  cardCategory: { margin: "0", color: "#999999" },
  selectCSS: { padding: "50px" }
};

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      contract: "",
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function (userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.id);
        }.bind(this),
        function (err) {
          window.location = '/auth/login-page';
        }
      );
    }
  }

  componentDidMount() {
  }

  getData(contractId) {
    tikiAPI.getContract(contractId,
      function (data) {
        this.setState({ contract: data });
      }.bind(this),
      function (error) {
        console.log(error);
      }
    );
  }

  editContract() {
    window.location = '/admin/contracts-edit/' + this.state.contract.contractId;
  }

  generateContract() {
    if (!this.state.disableSave) {
      document.getElementById("generateContractButton").innerHTML = "Please wait...";

      this.setState({
        disableButton: true
      });

      tikiAPI.printContract(this.state.contract.contractId,
        function (contractResponse) {
          this.setState({ contract: contractResponse }, function () {

            document.getElementById("generateContractButton").innerHTML = "Done";

          })
        }.bind(this),
        function (error) {
          console.log(error);
        }
      );
    }

  }

  generateSellerContract() {
    if (!this.state.disableSave) {
      document.getElementById("sendSellerContractToDocuSignButton").innerHTML = "Please wait...";

      this.setState({
        disableButton: true
      });

      tikiAPI.printSellerContract(this.state.contract.contractId,
        function (contractResponse) {
          this.setState({ contract: contractResponse }, function () {

            document.getElementById("sendSellerContractToDocuSignButton").innerHTML = "Done";

          })
        }.bind(this),
        function (error) {
          console.log(error);
        }
      );
    }

  }

  sendSellerContractToDocusign() {

    document.getElementById("generateSellerContractButton").innerHTML = "Please wait...";

    tikiAPI.sendSellerContractToDocusign(this.state.contract.contractId,
      function (data) {
        this.setState({ contract: data });
      }.bind(this),
      function (error) {
        console.log(error);
        document.getElementById("generateSellerContractButton").innerHTML = "Error - Please Contact Support";
      }
    );
  }

  render() {
    if (!cookies.get('isAuthenticated')) {
      return <Redirect to='../auth/login-page' />
    }

    const { classes } = this.props;
    return (
      <Can permissions={this.state.user.permissions} perform="Contracts_Detail" yes={() => (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ContractIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Contract</h4>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                  <Button color="success" onClick={(e) => this.editContract()}>Edit</Button>
                </GridItem>

                <GridContainer><GridItem xs={12}><div className="softDivider">Contract</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Contract ID</div>
                    <div className="displayContent">{this.state.contract.contractId}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">TPP Deal #</div>
                    <div className="displayContent"><a href={this.state.contract.tppLink} target="_blank" rel="noopener noreferrer">{this.state.contract.tppDealNumber}{!this.state.contract.tppDealNumber && this.state.contract.tppLink && <span>Complete Jiffydocs Contract</span>}</a></div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Contract Date</div>
                    <div className="displayContent">{this.state.contract.contractDate && moment(this.state.contract.contractDate).format('MM/DD/YYYY')}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Contract Good Date</div>
                    <div className="displayContent">{this.state.contract.contractGoodDate && moment(this.state.contract.contractGoodDate).format('MM/DD/YYYY')}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Paid In Full Date</div>
                    <div className="displayContent">{this.state.contract.paidInFullDate && moment(this.state.contract.paidInFullDate).format('MM/DD/YYYY')}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Contract Status</div>
                    <div className="displayContent">{this.state.contract.contractStatus}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Payment Status</div>
                    <div className="displayContent">{this.state.contract.paymentStatus}</div>
                  </GridItem>

                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Resort Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Resort Name</div>
                    <div className="displayContent">{this.state.contract.resort}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Building Number</div>
                    <div className="displayContent">{this.state.contract.buildingNumber}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Unit Number</div>
                    <div className="displayContent">{this.state.contract.unitNumber}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Unit Sleeps</div>
                    <div className="displayContent">{this.state.contract.unitSleeps}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Bedrooms</div>
                    <div className="displayContent">{this.state.contract.bedrooms}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Bathrooms</div>
                    <div className="displayContent">{this.state.contract.bathrooms}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Maintenance Fee</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.contract.maintFee)}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Deeded Week</div>
                    <div className="displayContent">{this.state.contract.useWeek}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Fixed or Float?</div>
                    <div className="displayContent">{this.state.contract.fixedFloat}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Use Type</div>
                    <div className="displayContent">{this.state.contract.useYearType}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Ownership Type</div>
                    <div className="displayContent">{this.state.contract.ownershipType}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4}>
                    <div className="displayLabel">Managing Entity</div>
                    <div className="displayContent">{this.state.contract.managingEntity}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Buyer Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <div className="displayLabel">Entity Type</div>
                    <div className="displayContent">{this.state.contract.buyerEntityType}</div>
                  </GridItem>
                </GridContainer>

                {this.state.contract.buyerEntityType == "INDIVIDUAL" &&
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">First Name</div>
                        <div className="displayContent">{this.state.contract.buyerFirstName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Middle Name</div>
                        <div className="displayContent">{this.state.contract.buyerMiddleName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Last Name</div>
                        <div className="displayContent">{this.state.contract.buyerLastName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Home Phone</div>
                        <div className="displayContent">{this.state.contract.buyerHomePhone}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Mobile Phone</div>
                        <div className="displayContent">{this.state.contract.buyerMobilePhone}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Email</div>
                        <div className="displayContent">{this.state.contract.buyerEmail}</div>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Buyer First Name</div>
                        <div className="displayContent">{this.state.contract.coBuyerFirstName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Buyer Middle Name</div>
                        <div className="displayContent">{this.state.contract.coBuyerMiddleName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Buyer Last Name</div>
                        <div className="displayContent">{this.state.contract.coBuyerLastName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Buyer Home Phone</div>
                        <div className="displayContent">{this.state.contract.coBuyerHomePhone}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Buyer Mobile Phone</div>
                        <div className="displayContent">{this.state.contract.coBuyerMobilePhone}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Buyer Email</div>
                        <div className="displayContent">{this.state.contract.coBuyerEmail}</div>
                      </GridItem>
                    </GridContainer>
                  </div>
                }

                {this.state.contract.buyerEntityType == "COMPANY" &&
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Company Name</div>
                        <div className="displayContent">{this.state.contract.buyerCompanyName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">State of Inc.</div>
                        <div className="displayContent">{this.state.contract.buyerStateInc}</div>
                      </GridItem>
                    </GridContainer>
                  </div>
                }

                {this.state.contract.buyerEntityType == "TRUST" &&
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Trust Name</div>
                        <div className="displayContent">{this.state.contract.buyerTrustName}</div>
                      </GridItem>
                    </GridContainer>
                  </div>
                }

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="displayLabel">Address</div>
                    <div className="displayContent">{this.state.contract.buyerAddress}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="displayLabel">City</div>
                    <div className="displayContent">{this.state.contract.buyerCity}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">State/Province</div>
                    <div className="displayContent">{this.state.contract.buyerState}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Postal Code</div>
                    <div className="displayContent">{this.state.contract.buyerZipcode}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Country</div>
                    <div className="displayContent">{this.state.contract.buyerCountry}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Seller Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Entity Type</div>
                    <div className="displayContent">{this.state.contract.sellerEntityType}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Is this an HOA sale?</div>
                    <div className="displayContent">{this.state.contract.isHoaInventory}</div>
                  </GridItem>
                </GridContainer>

                {this.state.contract.sellerEntityType == "INDIVIDUAL" &&
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">First Name</div>
                        <div className="displayContent">{this.state.contract.sellerFirstName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Middle Name</div>
                        <div className="displayContent">{this.state.contract.sellerMiddleName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Last Name</div>
                        <div className="displayContent">{this.state.contract.sellerLastName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={3}>
                        <div className="displayLabel">Email</div>
                        <div className="displayContent">{this.state.contract.sellerEmail}</div>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Home Phone</div>
                        <div className="displayContent">{this.state.contract.sellerHomePhone}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Mobile Phone</div>
                        <div className="displayContent">{this.state.contract.sellerMobilePhone}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Work Phone</div>
                        <div className="displayContent">{this.state.contract.sellerWorkPhone}</div>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Seller First Name</div>
                        <div className="displayContent">{this.state.contract.coSellerFirstName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Seller Middle Name</div>
                        <div className="displayContent">{this.state.contract.coSellerMiddleName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Seller Last Name</div>
                        <div className="displayContent">{this.state.contract.coSellerLastName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={3}>
                        <div className="displayLabel">Co-Seller Email</div>
                        <div className="displayContent">{this.state.contract.coSellerEmail}</div>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Seller Home Phone</div>
                        <div className="displayContent">{this.state.contract.coSellerHomePhone}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Seller Mobile Phone</div>
                        <div className="displayContent">{this.state.contract.coSellerMobilePhone}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Co-Seller Work Phone</div>
                        <div className="displayContent">{this.state.contract.coSellerWorkPhone}</div>
                      </GridItem>
                    </GridContainer>
                  </div>
                }


                {this.state.contract.sellerEntityType == "COMPANY" &&
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Company Name</div>
                        <div className="displayContent">{this.state.contract.sellerCompanyName}</div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">State of Inc.</div>
                        <div className="displayContent">{this.state.contract.sellerStateInc}</div>
                      </GridItem>
                    </GridContainer>
                  </div>
                }

                {this.state.contract.sellerEntityType == "TRUST" &&
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                        <div className="displayLabel">Trust Name</div>
                        <div className="displayContent">{this.state.contract.sellerTrustName}</div>
                      </GridItem>
                    </GridContainer>
                  </div>
                }

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="displayLabel">Address</div>
                    <div className="displayContent">{this.state.contract.sellerAddress}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="displayLabel">City</div>
                    <div className="displayContent">{this.state.contract.sellerCity}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">State/Province</div>
                    <div className="displayContent">{this.state.contract.sellerState}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Postal Code</div>
                    <div className="displayContent">{this.state.contract.sellerZipcode}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Country</div>
                    <div className="displayContent">{this.state.contract.sellerCountry}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Sales Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Sales Agent</div>
                    <div className="displayContent">{this.state.contract.salesAgent}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Is a TO being used?</div>
                    <div className="displayContent">{this.state.contract.isToUsed}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Who is the TO?</div>
                    <div className="displayContent">{this.state.contract.toName}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Closing Agent</div>
                    <div className="displayContent">{this.state.contract.closingAgent}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Contracts Processor</div>
                    <div className="displayContent">{this.state.contract.contractsProcessor}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Escrow Agent</div>
                    <div className="displayContent">{this.state.contract.escrowAgent}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Contract Dates</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Contract Date</div>
                    <div className="displayContent">{this.state.contract.contractDate}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Funds in escrow by</div>
                    <div className="displayContent">{this.state.contract.fundsEscrowByDate}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Seller sign by:</div>
                    <div className="displayContent">{this.state.contract.sellerSignBy}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Closing Date</div>
                    <div className="displayContent">{this.state.contract.closingDate}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Financial Terms</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Purchase Price</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.purchasePrice)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Closing Fee</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.closingFee)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Credit Report Fee</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.creditReportFee)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Pre-Paid Maint, Fee</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.prePaidMaintFee)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Contract Sales Price</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.contractSalesPrice)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Paid in full at signing?</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{this.state.contract.fullAmountCollected}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Deposit / Signing Type</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{this.state.contract.depositMadeBy}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Deposit / Signing Amount</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.depositAmount)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Subtotal of charges</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.subtotalCharges)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Credit Card Fee</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.creditCardProcessingFee)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Due on contract signing</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.cashDueAtSigning)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Balance Due at Closing</div>
                  </GridItem>

                  <GridItem xs={1}>
                    <div className="valueTable">{tikiAPI.numberToDisplay(this.state.contract.balanceDue)}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Additional Options</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="displayLabel">Is there a resort enhancement?</div>
                    <div className="displayContent">{this.state.contract.includesResortEnhancement}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="displayLabel">Internal timeshare trade-in?</div>
                    <div className="displayContent">{this.state.contract.includesInternalTradein}</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Buyer Documents</div></GridItem>
                </GridContainer>

                <GridContainer>

                  {this.state.contract.contractDocumentLink &&
                    <GridItem xs={3}>
                      <div>
                        <div className="displayLabel">Document Name</div>
                        <div className="displayContent"><a href={this.state.contract.contractDocumentLink} target="_blank" rel="noopener noreferrer">{this.state.contract.contractFileName}</a></div>
                        <div><Button id="generateContractButton" size="sm" color="success" onClick={(e) => this.generateContract()}>Recreate Contract</Button></div>
                      </div>
                    </GridItem>
                  }

                  {this.state.contract.contractDocumentLink &&
                    <GridItem xs={3}>
                      <div>
                        <div className="displayLabel">Date Modified</div>
                        <div className="displayContent">{moment(this.state.contract.contractDocumentDate).format("MM/DD/YYYY h:mma")}</div>
                      </div>
                    </GridItem>
                  }
                </GridContainer>

                {!this.state.contract.contractDocumentLink &&
                  <GridContainer>
                    <GridItem xs={12}>
                      <Button id="generateContractButton" size="sm" color="success" onClick={(e) => this.generateContract()}>Create Contract</Button>
                    </GridItem>
                  </GridContainer>
                }

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Seller Documents</div></GridItem>
                </GridContainer>

                <GridContainer>
                  {this.state.contract.sellerContractDocumentLink &&
                    <GridItem xs={2}>
                      <div>
                        <div className="displayLabel">Document Name</div>
                        <div className="displayContent"><a href={this.state.contract.sellerContractDocumentLink} target="_blank" rel="noopener noreferrer">{this.state.contract.sellerContractFileName}</a></div>
                        <div><Button id="generateSellerContractButton" size="sm" color="success" onClick={(e) => this.generateSellerContract()}>Recreate Contract</Button></div>
                      </div>
                    </GridItem>
                  }

                  {this.state.contract.sellerContractDocumentLink &&
                    <GridItem xs={3}>
                      <div>
                        <div className="displayLabel">Date Modified</div>
                        <div className="displayContent">{moment(this.state.contract.sellerContractDocumentDate).format("MM/DD/YYYY h:mma")}</div>
                      </div>
                    </GridItem>
                  }

                  {this.state.contract.sellerDocuSignId &&
                    <GridItem xs={4}>
                      <div>
                        <div className="displayLabel">DocuSign ID</div>
                        <div className="displayContent">{this.state.contract.sellerDocuSignId}</div>
                        <div><Button id="sendSellerContractToDocuSignButton" size="sm" color="success" onClick={(e) => this.sendSellerContractToDocusign()}>Resend DocuSign</Button></div>
                      </div>
                    </GridItem>
                  }

                  {this.state.contract.sellerDocuSignSentDate &&
                    <GridItem xs={3}>
                      <div>
                        <div className="displayLabel">DocuSign Sent</div>
                        <div className="displayContent">{moment(this.state.contract.sellerDocuSignSentDate).format("MM/DD/YYYY h:mma")}</div>
                      </div>
                    </GridItem>
                  }

                  {!this.state.contract.sellerDocuSignId &&
                    <GridItem xs={3}>
                      <Button id="sendSellerContractToDocuSignButton" size="sm" color="success" onClick={(e) => this.sendSellerContractToDocusign()}>Send DocuSign</Button>
                    </GridItem>
                  }

                  {!this.state.contract.sellerContractDocumentLink &&
                    <GridItem xs={12}>
                      <Button id="generateSellerContractButton" size="sm" color="success" onClick={(e) => this.generateSellerContract()}>Create Contract</Button>
                    </GridItem>
                  }
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )} />
    );
  }
}

export default withStyles(styles)(Panels);