import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ContractIcon from "@material-ui/icons/InsertDriveFile";

// react router components
import { Link } from 'react-router-dom'

import "assets/main.css";

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      contracts: [],
      selectResort: "",
      inputUnitNumber: "",
      resortSelect: "",
      locationSelect: "",
      bedroomSelect: "",
      unitSelect: "",
      priceSelect: "",
      seasonSelect: "",
      typeSelect: "",
      timeshareTypeSelect: "",
      checked: [],
      countDraft: 0,
      countDocumentsGenerated: 0,
      countSigned: 0,
      countPreparingToClose: 0,
      countClosed: 0,
      countAccountingComplete: 0,
      countRescinded: 0,
      countCanceled: 0,
      countExpired: 0,
      filterStatus: "In Progress",
      filteredContracts: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllContracts();
        }.bind(this),
        function(error) {
          console.log(error);
          window.location = '/auth/login-page';
        }
      );
    }
 
  }

  componentDidMount() {
  }

  filterContracts(filter) {
    var contracts = this.state.contracts.filter(contract => contract.contractStatus === filter);
    this.setState({ filteredContracts: contracts });
  };


 
 
  getAllContracts() {

    tikiAPI.getAllContracts(
      function(data) {

        var xcountDraft = 0;
        var xcountDocumentsGenerated = 0;
        var xcountSigned = 0;
        var xcountPreparingToClose = 0;
        var xcountClosed = 0;
        var xcountAccountingComplete = 0;
        var xcountRescinded = 0;
        var xcountCanceled = 0;
        var xcountExpired = 0;

        this.setState({ contracts: data, filteredContracts: data }, function() {
          data.reverse();
          for (var contract in data) {
            console.log(data[contract].contractStatus);
            switch (data[contract].contractStatus) {
              case "Draft":
                xcountDraft++;
                break;
              case "Documents Generated":
                xcountDocumentsGenerated++;
                break;
              case "Signed":
                xcountSigned++;
                break;
              case "Preparing To Close":
                xcountPreparingToClose++;
                break;      
              case "Closed":
                xcountClosed++;
                break;      
              case "Accounting Complete":
                xcountAccountingComplete++;
                break;      
              case "Rescinded":
                xcountRescinded++;
                break;      
              case "Canceled":
                xcountCanceled++;
                break;      
              case "Expired":
                xcountExpired++;
                break;      
              default:
                break;
              }

            this.setState( {
              countDraft: xcountDraft,
              countDocumentsGenerated: xcountDocumentsGenerated,
              countSigned: xcountSigned,
              countPreparingToClose: xcountPreparingToClose,
              countClosed: xcountClosed,
              countAccountingComplete: xcountAccountingComplete,
              countRescinded: xcountRescinded,
              countCanceled: xcountCanceled,
              countExpired: xcountExpired
            })
          }
        });

      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Contracts_Main" yes={() => (

      <div>

        {this.state.alert}

        <GridContainer>

        <GridItem xs={1}>&nbsp;</GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ContractIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Contracts</h4>
              </CardHeader>
              <CardBody>

                <table cellPadding="5" cellSpacing="0">
                  <thead>
                    <tr>
                      <th width="11">Draft</th>
                      <th width="11">Documents Generated</th>
                      <th width="11">Signed</th>
                      <th width="11">Preparing to Close</th>
                      <th width="11">Closed</th>
                      <th width="11">Accounting Complete</th>
                      <th width="11">Rescinded</th>
                      <th width="11">Canceled</th>
                      <th width="11">Expired</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Draft')}>{this.state.countDraft}</div></td>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Documents Generated')}>{this.state.countDocumentsGenerated}</div></td>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Signed')}>{this.state.countSigned}</div></td>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Preparing To Close')}>{this.state.countPreparingToClose}</div></td>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Closed')}>{this.state.countClosed}</div></td>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Accounting Complete')}>{this.state.countAccountingComplete}</div></td>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Rescinded')}>{this.state.countRescinded}</div></td>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Canceled')}>{this.state.countCanceled}</div></td>
                    <td><div style={{cursor: 'pointer', color: '#017b8a', fontWeight: 'bold'}} onClick={() => this.filterContracts('Expired')}>{this.state.countExpired}</div></td>
                    </tr>
                  </tbody>
                </table>

                <table cellPadding="5" cellSpacing="0" width="100%">
                  <thead>
                    <tr>
                      <th>Contract ID</th>               
                      <th>TPP #</th>               
                      <th>Status</th>               
                      <th>Contract Date</th>                    
                      <th>Buyer</th>
                      <th>Seller</th>
                      <th>Resort</th>                    
                      <th>Unit #</th>                    
                      <th>Use Week</th>
                    </tr>
                  </thead>

                  <tbody>
                  {this.state.filteredContracts.map((contract, index) => (
                    <tr key={index}>
                      <td>
                      <Can permissions={this.state.user.permissions} perform="Contracts_Detail"
                        yes={() => ( <Link to={`contracts-detail/${contract.contractId}`} className="ui basic button green">{contract.contractId}</Link> ) }
                        no={() => ( <div>{ contract.contractId }</div> ) }
                        />
                      </td>
                      <td><a href={contract.tppLink} target="_blank" rel="noopener noreferrer">{contract.tppDealNumber}{!contract.tppDealNumber && contract.tppLink && <span>Complete Jiffydocs Contract</span>}</a></td>
                      <td>{contract.contractStatus}</td>
                      <td>{moment(contract.contractDate).format('MM/DD/YYYY')}</td>
                      <td>{contract.buyerFirstName} {contract.buyerLastName}</td>
                      <td>{contract.sellerCompanyName} {contract.sellerFirstName} {contract.sellerLastName}</td>
                      <td>{contract.resort}</td>
                      <td>{contract.unitNumber}</td>
                      <td>{contract.useWeek}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      ) } />
);
  }
}

export default withStyles(styles)(Panels);