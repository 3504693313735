import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import { TikiAPI } from "../../global";
import * as moment from 'moment';
import rules from "../../rbac-rules";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import Button from "components/CustomButtons/Button.jsx";

import ListingsIcon from "@material-ui/icons/ListAlt";

// react router components
import { Link } from 'react-router-dom'

import "assets/main.css";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      inventory: [],
      displayInventory: [],
      searchInventory: [],
      selectResort: "",
      inputUnitNumber: "",
      resortSelect: [],
      locationSelect: "",
      bedroomSelect: "",
      unitSelect: "",
      priceSelect: "",
      seasonSelect: "",
      typeSelect: "",
      timeshareTypeSelect: "",
      checked: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllInventory();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount() {
  }

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.searchInventory();
    }
  }

  searchInventory = event => {
          document.getElementById("search_message").innerHTML = "";
          
          var searchInventory = this.state.inventory;

          const permissions = rules[this.state.user.role];
          const staticPermissions = permissions.static;

          if (document.getElementById('search_sellerName').value != "") {
            searchInventory = searchInventory.filter(v => v.sellerFirstName == document.getElementById('search_sellerName').value || v.sellerLastName == document.getElementById('search_sellerName').value );
          }

          if (document.getElementById('search_resortName').value != "") {
            searchInventory = searchInventory.filter(v => v.resortName == document.getElementById('search_resortName').value);
          }

          if (document.getElementById('search_listingEndDate').value != "") {
            if (document.getElementById('search_listingEndDate').value == "Recently_Expired") {
              searchInventory = searchInventory.filter(v => v.listingStatus == "Expired" && moment(v.listingEndDate) > moment().subtract(30, 'days'));
            } else if (document.getElementById('search_listingEndDate').value == "Expiring_Soon") {
              searchInventory = searchInventory.filter(v => v.listingStatus == "Available" && moment(v.listingEndDate) < moment().add(30, 'days'));
            } else if (document.getElementById('search_listingEndDate').value == "Recently_Listed") {
              searchInventory = searchInventory.filter(v => moment(v.listingStartDate) > moment().subtract(30, 'days'));
            }
          } else {
            if (document.getElementById('search_listingStatus').value != "") {
              searchInventory = searchInventory.filter(v => v.listingStatus == document.getElementById('search_listingStatus').value);
            }  
          }

          if (document.getElementById('search_listingStatus').value != "") {
            searchInventory = searchInventory.filter(v => v.listingStatus == document.getElementById('search_listingStatus').value);
          }  

          if (document.getElementById('search_unitNumber').value != "") {
            searchInventory = searchInventory.filter(v => v.unitNumber == document.getElementById('search_unitNumber').value);
          }
    
          if (document.getElementById('search_useWeek').value != "") {
            console.log(document.getElementById('search_useWeek').value);
            var weeks = document.getElementById('search_useWeek').value.split(",");
            console.log(weeks);

            var subsearchInventory = [];
            var isFirstLoad = true;

            for (var week in weeks) {
              var matchingWeekInventory = searchInventory.filter(v => v.useWeek == weeks[week].trim());

              if (isFirstLoad) {
                subsearchInventory = matchingWeekInventory;
                isFirstLoad = false;
              } else {
                subsearchInventory = subsearchInventory.concat(matchingWeekInventory);
              }
            }
 
              searchInventory = subsearchInventory;
              
          }
            
          if (document.getElementById('search_retailPrice').value != "") {
            searchInventory = searchInventory.filter(v => v.retailPrice < document.getElementById('search_retailPrice').value);
          }
    
          this.setState({ searchInventory: searchInventory }, function () {
            setTimeout(function () {
              if (Object.keys(searchInventory).length < 1) {
                document.getElementById("search_message").innerHTML = "<b>No inventory matches your search.  Please try again.</b>";
              }  
            }, 100);
          });
          this.setState({ displayInventory: searchInventory });
  }

  handleResortSelect = (event) => {
    this.searchInventory();
  }

  handleListingStatusSelect = (event) => {
    this.searchInventory();
  }

  handleListingEndDateSelect = (event) => {
    this.searchInventory();
  }

  getAllInventory() {

    tikiAPI.getAllInventory(
      function(data) {
        console.log(data);
        data = data.filter(v => v.isHoaOwned == "No");
        console.log(data);
        this.setState({ inventory: data });
        const resorts = [...new Set(this.state.inventory.map(x => x.resortName))]
        this.setState({ resortSelect: resorts});
      }.bind(this),
      function(error) { console.log(error) }
    );
    
  }

  render() {

    const { classes } = this.props;

    return (

    <Can permissions={this.state.user.permissions} perform="Inventory_Main" yes={() => (

    <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

            <Can permissions={this.state.user.permissions} perform="Inventory_New" yes={() => (
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <div align="center"><Link to='listings-new/'><Button color="success">New Listing</Button></Link></div>
                </GridItem>
              </GridContainer>
            )} />

            <Can permissions={this.state.user.permissions} perform="Inventory_Main" yes={() => (

            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ListingsIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Listings</h4>
              </CardHeader>
              <CardBody>

                <table cellPadding="5" cellSpacing="0" width="100%">
                  <thead>
                    <tr>
                      <td>
                        <input 
                        id="search_inventoryId" 
                        onBlur={(event) => this.searchInventory(event)} 
                        onKeyPress={(event) => this.handleKeyPress(event)} 
                        className="searchInput" 
                        type="text" 
                        placeholder="ID" />
                      </td>

                      <td><input id="search_sellerName" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Seller Name" /></td>                    
                      <td>
                        <select id="search_listingStatus" className="searchInputSelect" onChange={(event) => this.handleListingStatusSelect(event)}>
                          <option value="">All</option>
                          <option value="Draft">Draft</option>
                          <option value="Available">Available</option>
                          <option value="Canceled">Canceled</option>
                          <option value="Expired">Expired</option>
                        </select>
                      </td>
                      <td>
                        <select id="search_resortName" className="searchInputSelect" onChange={(event) => this.handleResortSelect(event)}>
                          <option value="">Select Resort</option>
                          {this.state.resortSelect.map((resort, index) => (
                            <option key={index} value={resort}>{resort}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select id="search_listingEndDate" className="searchInputSelect" onChange={(event) => this.handleListingEndDateSelect(event)}>
                          <option value="All">All</option>
                          <option value="Recently_Listed">Recently Listed</option>
                          <option value="Recently_Expired">Recently Expired</option>
                          <option value="Expiring_Soon">Expiring Soon</option>
                        </select>
                      </td>
                      <td><input id="search_unitNumber" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Unit #" /></td>                    
                      <td><input id="search_useWeek" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Week #" /></td>                    
                      <td><input id="search_retailPrice" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Retail Price" /></td>                    
                    </tr>

                    <tr>
                      <th>ID</th>                    
                      <th>Seller</th>                    
                      <th>Listing Status</th>                    
                      <th>Resort</th>                    
                      <th>Listing Exp.</th>                    
                      <th>Unit #</th>
                      <th>Week #</th>                    
                      <th align="right">Retail Price</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr><td colSpan="9"><div id="search_message"></div></td></tr>
                    {this.state.displayInventory.map((inv, index) => (
                    <tr key={index}>
                        <td><Link to={`listings-detail/${inv.inventoryId}`} className="ui basic button green">{inv.inventoryId}</Link></td>
                        <td>{inv.sellerFirstName} {inv.sellerLastName}</td>
                        <td>{inv.listingStatus}</td>                    
                        <td>{inv.resortName}</td>
                        <td>{moment(inv.listingEndDate).format("MM/DD/YYYY")}</td>                                      
                        <td>{inv.unitNumber}</td>
                        <td>{inv.useWeek}</td>
                        <td align="right">{tikiAPI.numberToDisplay(inv.retailPrice)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </CardBody>
            </Card>
            
            )} />

          </GridItem>
        </GridContainer>
      </div>

    ) } />
    );
  }
}

export default withStyles(styles)(Panels);