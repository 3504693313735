// MODULES //
// ACCOUNTING
import Accounting_Main from "modules/Accounting/Accounting_Main.jsx";

// ADMIN
import Settings_Main from "modules/Settings/Settings_Main.jsx";
import Users_Edit from "modules/Users/Users_Edit.jsx";

// AUTH
import LoginPage from "modules/Auth/LoginPage.jsx";
import ForgotPassword from "modules/Auth/ForgotPassword.jsx";
import ChangePassword from "modules/Auth/ChangePassword.jsx";

// COMMISSIONS
import Commission_Main from "modules/Commissions/Commission_Main.jsx";

// CONTRACTS
import Contracts_Main from "modules/Contracts/Contracts_Main.jsx";
import Contracts_Detail from "modules/Contracts/Contracts_Detail.jsx";
import Contracts_New from "modules/Contracts/Contracts_New.jsx";
import Contracts_New_For_Tour_Inventory from "modules/Contracts/Contracts_New.jsx";
import Contracts_New_Hsi_Rci_For_Tour_Inventory from "modules/Contracts/Contracts_New_Hsi_Rci.jsx";
import Contracts_Edit from "modules/Contracts/Contracts_Edit.jsx";

// EMPLOYEES
import Employees_Main from "modules/Employees/Employees_Main.jsx";
import Employees_Detail from "modules/Employees/Employees_Detail.jsx";
import Employees_Edit from "modules/Employees/Employees_Edit.jsx";
import Employees_New from "modules/Employees/Employees_New.jsx";

// EPIC CONTRACTS
import Epic_Contracts_Main from "modules/EpicContracts/Epic_Contracts_Main.jsx";
import Epic_Contracts_Detail from "modules/EpicContracts/Epic_Contracts_Detail.jsx";
import Epic_Contracts_New from "modules/EpicContracts/Epic_Contracts_New.jsx";
import Epic_Contracts_New_For_Tour from "modules/EpicContracts/Epic_Contracts_New.jsx";
import Epic_Contracts_Edit from "modules/EpicContracts/Epic_Contracts_Edit.jsx";

import Epic_Advantage_Contract_New_For_Member from "modules/EpicContracts/Epic_Advantage_Contract_New.jsx";

// EPIC POINTS LEDGER
import PointsLedger_Main from "modules/PointsLedger/PointsLedger_Main.jsx";

// GIFTS
import Gifts_Main from "modules/Gifts/Gifts_Main.jsx";
import Gifts_New from "modules/Gifts/Gifts_New.jsx";
import Gifts_Detail from "modules/Gifts/Gifts_Detail.jsx";
import Gifts_Edit from "modules/Gifts/Gifts_Edit.jsx";

// INVENTORY
import Inventory_Main from "modules/Inventory/Inventory_Main.jsx";
import Inventory_Detail from "modules/Inventory/Inventory_Detail.jsx";
import Inventory_Tour from "modules/Inventory/Inventory_Tour.jsx";
import Inventory_New from "modules/Inventory/Inventory_New.jsx";
import Inventory_Edit from "modules/Inventory/Inventory_Edit.jsx";
import Inventory_Search_For_Purchase from "modules/Inventory/Inventory_Search_For_Purchase.jsx";
import Inventory_Detail_For_Purchase from "modules/Inventory/Inventory_Detail_For_Purchase.jsx";

// LEADS
import Leads_Main from "modules/Leads/Leads_Main.jsx";
import Leads_New from "modules/Leads/Leads_New.jsx";
import Leads_Edit from "modules/Leads/Leads_Edit.jsx";
import Leads_Detail from "modules/Leads/Leads_Detail.jsx";

// LISTINGS
import Listings_Main from "modules/Listings/Listings_Main.jsx";
import Listings_Detail from "modules/Listings/Listings_Detail.jsx";
import Listings_Edit from "modules/Listings/Listings_Edit.jsx";
import Listings_New from "modules/Listings/Listings_New.jsx";

// EPIC MEMBER
import Epic_Members_Main from "modules/EpicMembers/Epic_Members_Main.jsx";
import Epic_Members_Detail from "modules/EpicMembers/Epic_Members_Detail.jsx";
import Epic_Members_Edit from "modules/EpicMembers/Epic_Members_Edit.jsx";

// RCI Portal
import AgentLoginPage from "modules/RCIPortal/AgentLoginPage.jsx";
import AgentMemberPage from "modules/RCIPortal/AgentMemberPage.jsx";

// REPORTING
import Reports_Main from "modules/Reports/Reports_Main.jsx";
import Reports_Tours_All from "modules/Reports/Reports_Tours_All.jsx";
import Reports_Sales_All from "modules/Reports/Reports_Sales_All.jsx";
import Reports_Sales_Dashboard from "modules/Reports/Reports_Sales_Dashboard.jsx";
import Reports_Sales_Volume_YoY from "modules/Reports/Reports_Sales_Volume_YoY.jsx";
import Reports_Sales_VPG_YoY from "modules/Reports/Reports_Sales_VPG_YoY.jsx";
import Reports_Sales_Rep_Detail from "modules/Reports/Reports_Sales_Rep_Detail.jsx";
import Reports_Marketing_Source_Detail from "modules/Reports/Reports_Marketing_Source_Detail.jsx";
import Reports_Marketing_Dashboard from "modules/Reports/Reports_Marketing_Dashboard.jsx";
import Reports_Epic_Dashboard from "modules/Reports/Reports_Epic_Dashboard.jsx";
import Reports_Epic_Membership_Points from "modules/Reports/Reports_Epic_Membership_Points.jsx";
import Reports_Contracts_Dashboard from "modules/Reports/Reports_Contracts_Dashboard.jsx";

//SEARCH RESULTS
import SearchResults from "modules/Search/SearchResults.jsx";

// TOURS
import Tours_Main from "modules/Tours/Tours_Main.jsx";
import Tours_Detail from "modules/Tours/Tours_Detail.jsx";
import Tours_Edit from "modules/Tours/Tours_Edit.jsx";

// Icons
import AccountingIcon from "@material-ui/icons/AttachMoney";
import SettingsIcon from "@material-ui/icons/Lock";
import EmployeesIcon from '@material-ui/icons/Group';
import CommissionIcon from "@material-ui/icons/LocalAtm";
import ContractsIcon from "@material-ui/icons/InsertDriveFile";
import GiftsIcon from "@material-ui/icons/CardGiftcard";
import InventoryIcon from "@material-ui/icons/VpnKey";
import LeadsIcon from "@material-ui/icons/AssignmentInd";
import ListingsIcon from "@material-ui/icons/ListAlt";
import EpicMemberIcon from "@material-ui/icons/AccountCircle";
import PointsLedgerIcon from "@material-ui/icons/Assignment";
import ReportsIcon from "@material-ui/icons/BarChart";
import ToursIcon from "@material-ui/icons/DateRange";

var dashRoutes = [
  {
    path: "/leads",
    name: "Leads",
    sidebarRbac: "Leads_Main",
    icon: LeadsIcon,
    component: Leads_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/leads-new", name: "New Lead", component: Leads_New, layout: "/admin" },
  { path: "/leads-edit/:id", name: "Edit Lead", component: Leads_Edit, layout: "/admin" },
  { path: "/leads-detail/:id", name: "Lead Detail", component: Leads_Detail, layout: "/admin" },
  {
    path: "/gifts",
    name: "Gifts",
    sidebarRbac: "Gifts_Main",
    icon: GiftsIcon,
    component: Gifts_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/gifts-new", name: "New Gift", component: Gifts_New, layout: "/admin" },
  { path: "/gifts-detail/:id", name: "Gift Detail", component: Gifts_Detail, layout: "/admin" },
  { path: "/gifts-edit/:id", name: "Gift Edit", component: Gifts_Edit, layout: "/admin" },
  {
    path: "/tours",
    name: "Tours",
    sidebarRbac: "Tours_Main",
    icon: ToursIcon,
    component: Tours_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/tours-detail/:id", name: "Tour Detail", component: Tours_Detail, layout: "/admin" },
  { path: "/tours-edit/:id", name: "Edit Tour", component: Tours_Edit, layout: "/admin" },
  {
    path: "/contracts",
    name: "Contracts",
    sidebarRbac: "Contracts_Main",
    icon: ContractsIcon,
    component: Contracts_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/contracts-new", name: "New Contract", component: Contracts_New, layout: "/admin" },
  { path: "/contracts-new-for-tour/:tourId/inventory/:inventoryId", name: "New Contract", component: Contracts_New_For_Tour_Inventory, layout: "/admin" },
  { path: "/contracts-new-hsi-rci-for-tour/:tourId/inventory/:inventoryId", name: "New Contract", component: Contracts_New_Hsi_Rci_For_Tour_Inventory, layout: "/admin" },
  { path: "/contracts-detail/:id", name: "Contract Detail", component: Contracts_Detail, layout: "/admin" },
  { path: "/contracts-edit/:id", name: "Edit Contract", component: Contracts_Edit, layout: "/admin" },
  {
    path: "/epic-contracts",
    name: "Epic Contracts",
    sidebarRbac: "Epic_Contracts_Main",
    icon: ContractsIcon,
    component: Epic_Contracts_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/epic-contracts-new", name: "New Contract", component: Epic_Contracts_New, layout: "/admin" },
  { path: "/epic-contracts-new-for-tour/:tourId", name: "New Contract", component: Epic_Contracts_New_For_Tour, layout: "/admin" },
  { path: "/epic-contracts-detail/:id", name: "Contract Detail", component: Epic_Contracts_Detail, layout: "/admin" },
  { path: "/epic-contracts-edit/:id", name: "Edit Epic Contract", component: Epic_Contracts_Edit, layout: "/admin" },
  { path: "/epic-advantage-new-contract-for-member/:id", name: "Epic Advantage Contract", component: Epic_Advantage_Contract_New_For_Member, layout: "/admin" },
  {
    path: "/epic-members",
    name: "Epic Members",
    sidebarRbac: "Epic_Members_Main",
    icon: EpicMemberIcon,
    component: Epic_Members_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/epic-members-detail/:id", name: "Epic Member Detail", component: Epic_Members_Detail, layout: "/admin" },
  { path: "/epic-members-edit/:id", name: "Edit Epic Member", component: Epic_Members_Edit, layout: "/admin" },
  {
    path: "/points-ledger",
    name: "Epic Points Ledger",
    sidebarRbac: "PointsLedger_Main",
    icon: PointsLedgerIcon,
    component: PointsLedger_Main,
    layout: "/admin",
    flag: true
  },
  {
    path: "/listings",
    name: "Listings",
    sidebarRbac: "Listings_Main",
    icon: ListingsIcon,
    component: Listings_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/listings-detail/:id", name: "Listings Detail", component: Listings_Detail, layout: "/admin" },
  { path: "/listings-edit/:id", name: "Listings Edit", component: Listings_Edit, layout: "/admin" },
  { path: "/listings-new", name: "New Listings", component: Listings_New, layout: "/admin" },
  {
    path: "/inventory",
    name: "Inventory",
    sidebarRbac: "Inventory_Main",
    icon: InventoryIcon,
    component: Inventory_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/inventory-new", name: "New Inventory", component: Inventory_New, layout: "/admin" },
  { path: "/inventory-detail/:id", name: "Inventory Detail", component: Inventory_Detail, layout: "/admin" },
  { path: "/inventory-tour/:inventoryId/tour/:tourId", name: "Inventory Detail", component: Inventory_Tour, layout: "/admin" },
  { path: "/inventory-edit/:id", name: "Edit Inventory", component: Inventory_Edit, layout: "/admin" },
  { path: "/inventory-search-for-tour/:tourId", name: "Inventory Search for Purchase", component: Inventory_Search_For_Purchase, layout: "/admin" },
  { path: "/inventory-detail-for-purchase/:inventoryId/for-tour/:tourId", name: "Inventory Detail for Purchase", component: Inventory_Detail_For_Purchase, layout: "/admin" },
  {
    path: "/commission",
    name: "Commission",
    sidebarRbac: "Commission_Main",
    icon: CommissionIcon,
    component: Commission_Main,
    layout: "/admin",
    flag: true
  },
  {
    path: "/accounting",
    name: "Accounting",
    sidebarRbac: "Accounting_Main",
    icon: AccountingIcon,
    component: Accounting_Main,
    layout: "/admin",
    flag: true
  },
  {
    path: "/reports",
    name: "Reports",
    sidebarRbac: "Reports_Main",
    icon: ReportsIcon,
    component: Reports_Main,
    layout: "/admin",
    flag: true
  },
  {
    path: "/employees",
    name: "Employees",
    sidebarRbac: "Employees_Main",
    icon: EmployeesIcon,
    component: Employees_Main,
    layout: "/admin",
    flag: true
  },
  {
    path: "/settings",
    name: "Settings",
    sidebarRbac: "Settings_Main",
    icon: SettingsIcon,
    component: Settings_Main,
    layout: "/admin",
    flag: true
  },
  { path: "/users-edit/:id", name: "Edit User", component: Users_Edit, layout: "/admin" },
  { path: "/employees-new", name: "New Employee", component: Employees_New, layout: "/admin" },
  { path: "/employees-detail/:id", name: "Employee Detail", component: Employees_Detail, layout: "/admin" },
  { path: "/employees-edit/:id", name: "Employee Edit", component: Employees_Edit, layout: "/admin" },
  { path: "/reports-tours-all", name: "All Tours Report", component: Reports_Tours_All, layout: "/admin" },
  { path: "/reports-sales-all", name: "All Sales Report", component: Reports_Sales_All, layout: "/admin" },
  { path: "/reports-sales-dashboard", name: "Sales Dashboard", component: Reports_Sales_Dashboard, layout: "/admin" },
  { path: "/reports-sales-volume-yoy", name: "Volume Year-Over-Year", component: Reports_Sales_Volume_YoY, layout: "/admin" },
  { path: "/reports-sales-vpg-yoy", name: "VPG Year-Over-Year", component: Reports_Sales_VPG_YoY, layout: "/admin" },
  { path: "/reports-sales-rep-detail", name: "Sales Rep Detail Report", component: Reports_Sales_Rep_Detail, layout: "/admin" },
  { path: "/reports-marketing-source-detail", name: "Marketing Source Detail Report", component: Reports_Marketing_Source_Detail, layout: "/admin" },
  { path: "/reports-marketing-dashboard", name: "Marketing Dashboard", component: Reports_Marketing_Dashboard, layout: "/admin" },
  { path: "/reports-epic-dashboard", name: "Epic Dashboard", component: Reports_Epic_Dashboard, layout: "/admin" },
  { path: "/reports-epic-membership-points", name: "Epic Membership Points", component: Reports_Epic_Membership_Points, layout: "/admin" },
  { path: "/reports-contracts-dashboard", name: "Contracts Dashboard", component: Reports_Contracts_Dashboard, layout: "/admin" },
  { path: "/search/:id", name: "Search Results", component: SearchResults, layout: "/admin" },
  { path: "/login-page", name: "Login Page", component: LoginPage, layout: "/auth" },
  { path: "/forgot-password", name: "Forgot Password", component: ForgotPassword, layout: "/auth" },
  { path: "/change-password/:authToken", name: "Change Password", component: ChangePassword, layout: "/auth" },
  { path: "/rci", name: "RCI Agent Login", component: AgentLoginPage, layout: "/auth" },
  { path: "/rci-member/:id", name: "RCI Agent Portal", component: AgentMemberPage, layout: "/portal" },
];

export default dashRoutes;
