import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import GiftsIcon from "@material-ui/icons/CardGiftcard";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Employees_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      gift: {}
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function (userData) {
          this.setState({ user: userData });
          this.getGiftTypes(this.props.match.params.id);
        }.bind(this),
        function (err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getGiftTypes(giftTypeId) {
    tikiAPI.getGiftTypes(giftTypeId,
      function (response) {
        this.setState({ gift: response });
      }.bind(this),
      function (err) {
        console.log(err);
      })
  }

  editGift() {
    window.location = '/admin/gifts-edit/' + this.state.gift.giftTypeId;
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Gifts_Detail" yes={() => (

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info"><GiftsIcon /></CardIcon>
                <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Gift</h4>
              </CardHeader>
              <CardBody>

                <Can permissions={this.state.user.permissions} perform="Employees_Edit" yes={() => (
                  <GridContainer>
                    <GridItem xs={12}><div align="center"><Button color="success" onClick={(e) => this.editGift()}>Edit</Button></div></GridItem>
                  </GridContainer>
                )} />

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Gift Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Status</div>
                      <div className="displayContent">{this.state.gift.status}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Name</div>
                      <div className="displayContent">{this.state.gift.giftName}</div>
                    </div>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>

      )} /> // End Can RBAC element

    );
  }
}

export default withStyles(styles)(Employees_Detail);