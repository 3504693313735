import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import * as moment from 'moment';
import { Link } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import InventoryIcon from "@material-ui/icons/VpnKey";

import Button from "components/CustomButtons/Button.jsx";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      resortList: [],
      selectResort: "",
      resort: "",
      unitTypeList: [],
      selectUnitType: "",
      unitType: "",
      unitList: [],
      selectUnit: "",
      unit: "",
      inventory: {},
      fullInventory: [],
      searchInventory: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllInventory();
          this.setState( {inventory: {
            listingStartDate: moment().format("MM/DD/YYYY"),
            listingEndDate: moment().add(1, "years").format("MM/DD/YYYY"),
            listingStatus: "Available"
          }});
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }
  
  getAllInventory() {

    tikiAPI.getAllInventory(
      function(data) {
        this.setState({ fullInventory: data });
        const resorts = [...new Set(this.state.fullInventory.map(x => x.resortName))]
        this.setState({ resortList: resorts});
      }.bind(this),
      function(error) { console.log(error) }
    );
    
  }

  searchInventory = event => {
    document.getElementById('searchInventoryButton').innerHTML = "Please wait...";

    var searchInventory = this.state.fullInventory;

    console.log(searchInventory);

    if (document.getElementById('resortName').value != "") {
      searchInventory = searchInventory.filter(v => v.resortName == document.getElementById('resortName').value);
    }

    if (document.getElementById('unitNumber').value != "") {
      searchInventory = searchInventory.filter(v => v.unitNumber == document.getElementById('unitNumber').value);
    }

    if (document.getElementById('useWeek').value != "") {
      searchInventory = searchInventory.filter(v => v.useWeek == document.getElementById('useWeek').value);
    }

    if (document.getElementById('useYearType').value != "") {
      searchInventory = searchInventory.filter(v => v.useYearType == document.getElementById('useYearType').value);
    }

    console.log(searchInventory);

    this.setState({ searchInventory: searchInventory }, function () {
      document.getElementById('searchInventoryButton').innerHTML = "Search for existing inventory";
        if (Object.keys(searchInventory).length > 0) {
        } else {
          document.getElementById("search_message").innerHTML = "No matching inventory found. Please add new inventory."
        }
    });

    document.getElementById("createNewInventoryDiv").style.display = "block";
}

addNewInventory = event => {
  this.setState( { searchInventory: [] }, function() {
    document.getElementById("addNewInventoryDiv").style.display = "block";
  });
}

  getResort(resortId) {

  }

  getUnitTypeList(resortName) {

    tikiAPI.getUnitTypesForResort(resortName,
      function(data) {
        this.setState({ unitTypeList: data });
      }.bind(this),
      function(error) { console.log(error) }
    );

  }

  componentDidMount(){
  }

  handleInput = event => {
    this.setState({ inventory: {
      ...this.state.inventory,
      [event.target.id]: event.target.value
    }});
  }

  handleResortSelect = event => {
    if (event.target.value == "AddNew") {
      var resort = prompt("Please enter the new resort name:");
      this.state.resortList.unshift(resort);
      var newInv = this.state.inventory;
      newInv[event.target.id] = resort;
      this.setState({ inventory: newInv });  
    } else {
      var newInv = this.state.inventory;
      newInv[event.target.id] = event.target.value;
      this.setState({ inventory: newInv });  
    }
  }

  createInventory() {

    tikiAPI.createInventory(this.state.inventory,
      function(data) {
        window.location = '../inventory-detail/' + data.inventoryId;
      }.bind(this),
      function(error) { console.log(error) }
    );
  }

  render() {  

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Inventory_New" yes={() => (

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <InventoryIcon />
              </CardIcon>
              <h4 className={classes.cardTitle}>New Inventory</h4>
            </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Resort / Unit Summary</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">Resort</div>
                      <select id="resortName" value={this.state.inventory.resortName || ''} onChange={(event) => this.handleResortSelect(event)} className="formSelect">
                        <option value="">---Select Resort---</option>
                        <option value="AddNew">* Add New Resort</option>
                        {this.state.resortList.map((resort, index) => (
                          <option key={index} value={resort}>{resort}</option>
                        ))}
                      </select>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Unit #</div>
                      <div><input id="unitNumber" value={this.state.inventory.unitNumber || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Unit #" /></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Week</div>
                      <div><input id="useWeek" value={this.state.inventory.useWeek || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Week" /></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Use Year Type</div>
                      <select id="useYearType" value={this.state.inventory.useYearType || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="">---Use Year---</option>
                        <option value="Annual">Annual</option>
                        <option value="Even">Even</option>
                        <option value="Odd">Odd</option>
                      </select>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{textAlign: "center", marginTop: "20px"}}>
                        <Button id="searchInventoryButton" color="info" size="sm" onClick={(e) => this.searchInventory()}>Search for existing inventory</Button>
                      </div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <table>
                        <thead>
                          <tr>
                          <th>&nbsp;</th>                    
                          <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => ( <th>Listing Status</th> )} />                    
                          <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => ( <th>Listing Exp.</th> )} />                    
                          <th>Resort</th>                    
                          <th>Unit #</th>
                          <th>Week #</th>                    
                          <th>Use Type</th>                    
                          <th>Bed</th>
                          <th>Bath</th>
                          <th align="right">Retail Price</th>
                          <th>Financing</th>
                          <th>Listed w/ Points</th>
                        </tr>
                      </thead>

                        <tbody>
                          <tr><td colSpan="9"><div id="search_message"></div></td></tr>
                          {this.state.searchInventory.map((inv, index) => (
                          <tr key={index}>
                            <td><a href={`/admin/inventory-edit/${inv.inventoryId}`}><Button color="info" size="sm">Edit</Button></a></td>
                            <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => ( <td>{inv.listingStatus}</td> )} />                    
                            <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => ( <td>{moment(inv.listingEndDate).format("MM/DD/YYYY")}</td> )} />                                      
                            <td>{inv.resortName}</td>
                            <td>{inv.unitNumber}</td>
                            <td>{inv.useWeek}</td>
                            <td>{inv.useType}</td>
                            <td>{inv.bedrooms}</td>
                            <td>{inv.bathrooms}</td>
                            <td align="right">{tikiAPI.numberToDisplay(inv.retailPrice)}</td>
                            <td>{inv.financingEligible}</td>
                            <td>{inv.listedWithPoints}</td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div id="createNewInventoryDiv" style={{textAlign: "center", marginTop: "20px", display: "none"}}>
                        <Button id="searchInventoryButton" color="info" size="sm" onClick={(e) => this.addNewInventory()}>Add New Inventory</Button>
                      </div>
                    </GridItem>
                </GridContainer>

              <div id="addNewInventoryDiv" style={{display: "none"}}>
              <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Listing Summary</div></GridItem>
                </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Status</div>
                      <select id="listingStatus" value={this.state.inventory.listingStatus || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="Available" >Available</option>
                        <option value="Canceled" >Canceled</option>
                        <option value="Expired" >Expired</option>
                      </select>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Start Date</div>
                    <div><input id="listingStartDate" value={this.state.inventory.listingStartDate || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Listing Start Date" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing End Date</div>
                    <div><input id="listingEndDate" value={this.state.inventory.listingEndDate || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Listing End Date" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Unit Details</div></GridItem>
              </GridContainer>

              <GridContainer>
              <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit Type</div>
                    <select id="unitType" value={this.state.inventory.unitType || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">---Unit Type---</option>
                      <option value="None">None</option>
                      {this.state.unitTypeList.map((unitType, index) => (
                        <option key={index} value={unitType.unitTypeCode}>{unitType.unitTypeCode}</option>
                      ))}
                    </select>
                  </div>
                </GridItem>

              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Use Year Type</div>                    
                    <div>
                    <select id="useYearType" value={this.state.inventory.useYearType || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="">---Use Year---</option>
                        <option value="Annual">Annual</option>
                        <option value="Even">Even</option>
                        <option value="Odd">Odd</option>
                      </select>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Next Use Year</div>
                    <div><input id="nextUseYear" value={this.state.inventory.nextUseYear || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Next Use Year" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Bedrooms</div>
                    <div><input id="bedrooms" value={this.state.inventory.bedrooms || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Bedrooms" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Bathrooms</div>
                    <div><input id="bathrooms" value={this.state.inventory.bathrooms || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Bathrooms" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit Sleeps</div>
                    <div><input id="unitSleeps" value={this.state.inventory.unitSleeps || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Unit Sleeps" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Golf Package</div>
                    <div><input id="golfPackage" value={this.state.inventory.golfPackage || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Golf Package" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Points</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Point Value</div>
                    <div><input id="pointsAmount" value={this.state.inventory.pointsAmount || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Point Value" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listed w/ Points</div>
                      <select id="listedWithPoints" value={this.state.inventory.listedWithPoints || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="">---Select---</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Points Season</div>
                      <select id="pointsSeason" value={this.state.inventory.pointsSeason || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="">---Select---</option>
                        <option value="Bronze">Bronze</option>
                        <option value="Silver">Silver</option>
                        <option value="Gold">Gold</option>
                        <option value="Platinum">Platinum</option>
                      </select>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Financial</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Retail Price</div>
                    <div><input id="retailPrice" value={this.state.inventory.retailPrice || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Retail Price" /></div>
                  </div>
                </GridItem>

                <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Price</div>
                    <div><input id="listingPrice" value={this.state.inventory.listingPrice || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Listing Price" /></div>
                  </div>
                </GridItem>
                )} />

                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Maintenance Fee</div>
                    <div><input id="maintFee" value={this.state.inventory.maintFee || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Maintenance Fee" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Financing Eligible</div>
                      <select id="financingEligible" value={this.state.inventory.financingEligible || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="">---Select---</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Marketing Fee</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Marketing Fee</div>
                    <div><input id="mktFee" value={this.state.inventory.mktFee || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Marketing Fee" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Mkt Fee Date Paid</div>
                    <div><input id="mktFeeDatePd" value={this.state.inventory.mktFeeDatePd || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Mkt Fee Date Paid" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Amount Paid</div>
                    <div><input id="amountPaid" value={this.state.inventory.amountPaid || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Amount Paid" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Variance</div>
                    <div><input id="variance" value={this.state.inventory.variance || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Variance" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Seller Information</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Entity Type</div>
                      <select id="sellerEntityType" value={this.state.inventory.sellerEntityType || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="">---Select---</option>
                        <option value="Individual">Individual</option>
                        <option value="Company">Company</option>
                      </select>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">HOA Owned</div>
                      <select id="isHoaOwned" value={this.state.inventory.isHoaOwned || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="">---Select---</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Seller Company Name</div>
                    <div><input id="sellerCompanyName" value={this.state.inventory.sellerCompanyName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Seller Company Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Seller Trust Name</div>
                    <div><input id="sellerTrustName" value={this.state.inventory.sellerTrustName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Seller Trust Name" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">First Name</div>
                    <div><input id="sellerFirstName" value={this.state.inventory.sellerFirstName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="First Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Middle Name</div>
                    <div><input id="sellerMiddleName" value={this.state.inventory.sellerMiddleName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Middle Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Last Name</div>
                    <div><input id="sellerLastName" value={this.state.inventory.sellerLastName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Last Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Email</div>
                    <div><input id="sellerEmail" value={this.state.inventory.sellerEmail || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Email" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Address</div>
                    <div><input id="sellerAddress" value={this.state.inventory.sellerAddress || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Address" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">City</div>
                    <div><input id="sellerCity" value={this.state.inventory.sellerCity || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="City" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">State</div>
                    <div><input id="sellerState" value={this.state.inventory.sellerState || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="State" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Zipcode</div>
                    <div><input id="sellerZipcode" value={this.state.inventory.sellerZipcode || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Zipcode" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Country</div>
                    <div><input id="sellerCountry" value={this.state.inventory.sellerCountry || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Country" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Home Phone</div>
                    <div><input id="sellerHomePhone" value={this.state.inventory.sellerHomePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Home Phone" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Work Phone</div>
                    <div><input id="sellerWorkPhone" value={this.state.inventory.sellerWorkPhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Work Phone" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Mobile Phone</div>
                    <div><input id="sellerMobilePhone" value={this.state.inventory.sellerMobilePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Mobile Phone" /></div>
                  </div>
                </GridItem>
              </GridContainer>


              <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Internal Notes</div></GridItem>
              </GridContainer>
              )} />

              <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Entry Date</div>
                    <div><input id="entryDate" value={this.state.inventory.entryDate || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Entry Date" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Verified By</div>
                    <div><input id="verifiedBy" value={this.state.inventory.verifiedBy || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Verified By" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Date Verified</div>
                    <div><input id="dateVerified" value={this.state.inventory.dateVerified || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Date Verified" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={6}>
                  <div>
                    <div className="displayLabel">Notes</div>
                    <div><input id="notes" value={this.state.inventory.notes || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Notes" /></div>
                  </div>
                </GridItem>
              </GridContainer>
              )} />

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
                    <Button color="success" onClick={(e) => this.createInventory()}>Save</Button>
                  </GridItem>
                </GridContainer>

              </div>


              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Panels);