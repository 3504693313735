import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import EmployeesIcon from '@material-ui/icons/AssignmentInd';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Employees_Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      employee: {}
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function (userData) {
          this.setState({ user: userData });
          this.getEmployee(this.props.match.params.id);
        }.bind(this),
        function (err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getEmployee(employeeId) {
    tikiAPI.getEmployee(employeeId,
      function (response) {
        this.setState({ employee: response });
      }.bind(this),
      function (err) {
        console.log(err);
      })
  }

  updateEmployee() {
    this.setState({
      employee: {
        ...this.state.employee,
        fullName: (this.state.employee.firstName + " " + this.state.employee.lastName).trim()
      }
    }, function () {
      tikiAPI.updateEmployee(this.state.employee,
        function (response) {
          window.location = '/admin/employees-detail/' + this.state.employee.employeeId;
        }.bind(this),
        function (err) {
          console.log(err);
        }
      );
    });
  }

  handleInput = event => {
    this.setState({
      employee: {
        ...this.state.employee,
        [event.target.id]: event.target.value
      }
    });
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Employees_Edit" yes={() => (

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info"><EmployeesIcon /></CardIcon>
                <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Edit Employee</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Employee Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Status</div>
                      <div>
                        <select id="employeeStatus" value={this.state.employee.employeeStatus || ''} onChange={(event) => this.handleInput(event)} className="inlineSelect">
                          <option value=""></option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">First Name</div>
                      <div><input id="firstName" value={this.state.employee.firstName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="First Name"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Last Name</div>
                      <div><input id="lastName" value={this.state.employee.lastName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Last Name"></input></div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Senior Sales Manager</div>
                      <div>
                        <select id="isSeniorSalesManager" value={this.state.employee.isSeniorSalesManager || ''} onChange={(event) => this.handleInput(event)} className="inlineSelect">
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Sales Manager</div>
                      <div>
                        <select id="isSalesManager" value={this.state.employee.isSalesManager || ''} onChange={(event) => this.handleInput(event)} className="inlineSelect">
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Sales Rep</div>
                      <div>
                        <select id="isSalesRep" value={this.state.employee.isSalesRep || ''} onChange={(event) => this.handleInput(event)} className="inlineSelect">
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">TO Rep</div>
                      <div>
                        <select id="isToRep" value={this.state.employee.isToRep || ''} onChange={(event) => this.handleInput(event)} className="inlineSelect">
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Marketing Manager</div>
                      <div>
                        <select id="isMarketingManager" value={this.state.employee.isMarketingManager || ''} onChange={(event) => this.handleInput(event)} className="inlineSelect">
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Marketing Rep</div>
                      <div>
                        <select id="isMarketingRep" value={this.state.employee.isMarketingRep || ''} onChange={(event) => this.handleInput(event)} className="inlineSelect">
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Accounting Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeIncomeInv</div>
                      <div><input id="glCodeIncomeInv" value={this.state.employee.glCodeIncomeInv || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeIncomeInv"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeIncomeRci</div>
                      <div><input id="glCodeIncomeRci" value={this.state.employee.glCodeIncomeRci || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeIncomeRci"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeIncomeHsi</div>
                      <div><input id="glCodeIncomeHsi" value={this.state.employee.glCodeIncomeHsi || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeIncomeHsi"></input></div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeCogInv</div>
                      <div><input id="glCodeCogInv" value={this.state.employee.glCodeCogInv || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeCogInv"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeCogRci</div>
                      <div><input id="glCodeCogRci" value={this.state.employee.glCodeCogRci || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeCogRci"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeCogHsi</div>
                      <div><input id="glCodeCogHsi" value={this.state.employee.glCodeCogHsi || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeCogHsi"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeCogInvOr</div>
                      <div><input id="glCodeCogInvOr" value={this.state.employee.glCodeCogInvOr || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeCogInvOr"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeCogRciOr</div>
                      <div><input id="glCodeCogRciOr" value={this.state.employee.glCodeCogRciOr || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeCogRciOr"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeCogHsiOr</div>
                      <div><input id="glCodeCogHsiOr" value={this.state.employee.glCodeCogHsiOr || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeCogHsiOr"></input></div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">GlCodeLiabilityReserve</div>
                      <div><input id="glCodeLiabilityReserve" value={this.state.employee.glCodeLiabilityReserve || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeLiabilityReserve"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={3}>
                    <div>
                      <div className="displayLabel">GlCodeLiabilitySalesComm</div>
                      <div><input id="glCodeLiabilitySalesComm" value={this.state.employee.glCodeLiabilitySalesComm || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="GlCodeLiabilitySalesComm"></input></div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <div align="center"><Button color="success" onClick={(e) => this.updateEmployee()}>Update</Button></div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>

      )} /> // End Can RBAC element

    );
  }
}

export default withStyles(styles)(Employees_Edit);