import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ListingsIcon from "@material-ui/icons/ListAlt";

import Button from "components/CustomButtons/Button.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      inventory: ""
    };

    if (!cookies.get('authToken')) {
      //window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.id);
        }.bind(this),
        function(err) {
          console.log(err);
          //window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount(){
  }

  getData(inventoryId){  

    tikiAPI.getInventory(inventoryId, 
      function(data) {

        console.log(data);

        if (data.listingStartDate) { data.listingStartDate = moment(data.listingStartDate).format("MM/DD/YYYY") }
        if (data.listingEndDate) { data.listingEndDate = moment(data.listingEndDate).format("MM/DD/YYYY") }

        this.setState({inventory: data});
      }.bind(this),
      function(error) { console.log(error) }
    );
  
  }
    
  handleInput = event => {
    console.log(event.target.id);
    var newInv = this.state.inventory;
    newInv[event.target.id] = event.target.value;
    this.setState({ inventory: newInv });
    console.log(this.state.inventory);
  }

  updateListing() {
    tikiAPI.updateInventory(this.state.inventory, 
      function(data) {
        window.location = '../listings-detail/' + this.state.inventory.inventoryId;
      }.bind(this),
      function(error) { console.log(error) }
    );
    
  }

  render() {  

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Inventory_Edit" yes={() => (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <ListingsIcon />
              </CardIcon>
              <h4 className={classes.cardTitle}>Edit Listing</h4>
            </CardHeader>
              <CardBody>
              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Listing Summary</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Inventory ID</div>
                    <div className="displayContent">{this.state.inventory.inventoryId}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Status</div>
                      <select id="listingStatus" value={this.state.inventory.listingStatus || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                        <option value="">Listing Status</option>
                        <option value="Draft" >Draft</option>
                        <option value="Available" >Available</option>
                        <option value="Sale Pending" >Sale Pending</option>
                        <option value="Canceled" >Canceled</option>
                        <option value="Expired" >Expired</option>
                      </select>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Start Date</div>
                    <div><input id="listingStartDate" value={this.state.inventory.listingStartDate || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Listing Start Date" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing End Date</div>
                    <div><input id="listingEndDate" value={this.state.inventory.listingEndDate || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Listing End Date" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Unit Details</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Resort</div>
                    <div><input id="resortName" value={this.state.inventory.resortName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Resort" /></div>
                  </div>
                </GridItem>
              </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit #</div>
                    <div><input id="unitNumber" value={this.state.inventory.unitNumber || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Unit #" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Week</div>
                    <div><input id="useWeek" value={this.state.inventory.useWeek || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Week" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Fixed / Float</div>
                    <select id="fixedFloat" value={this.state.inventory.fixedFloat || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="Fixed">Fixed</option>
                      <option value="Float" >Float</option>
                    </select>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Use Year Type</div>
                    <select id="useYearType" value={this.state.inventory.useYearType || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                      <option value="">Use Year Type</option>
                      <option value="Annual" >Annual</option>
                      <option value="Biennial Even" >Biennial Even</option>
                      <option value="Biennial Odd" >Biennial Odd</option>
                      <option value="Triennial X" >Triennial X</option>
                      <option value="Triennial Y" >Triennial Y</option>
                      <option value="Triennial Z" >Triennial Z</option>
                    </select>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Next Use Year</div>
                    <div><input id="nextUseYear" value={this.state.inventory.nextUseYear || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Next Use Year" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit Type</div>
                    <div><input id="unitType" value={this.state.inventory.unitType || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Unit Type" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Bedrooms</div>
                    <div><input id="bedrooms" value={this.state.inventory.bedrooms || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Bedrooms" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Bathrooms</div>
                    <div><input id="bathrooms" value={this.state.inventory.bathrooms || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Bathrooms" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit Sleeps</div>
                    <div><input id="unitSleeps" value={this.state.inventory.unitSleeps || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Unit Sleeps" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Golf Package</div>
                    <div><input id="golfPackage" value={this.state.inventory.golfPackage || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Golf Package" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Points</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Point Value</div>
                    <div><input id="pointsAmount" value={this.state.inventory.pointsAmount || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Point Value" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listed w/ Points</div>
                    <div><input id="listedWithPoints" value={this.state.inventory.listedWithPoints || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Listed w/ Points" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">RCI Number</div>
                    <div><input id="rciNumber" value={this.state.inventory.rciNumber || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="RCI Number" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Points Season</div>
                    <div><input id="pointsSeason" value={this.state.inventory.pointsSeason || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Points Season" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Financial</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Retail Price</div>
                    <div><input id="retailPrice" value={this.state.inventory.retailPrice || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Retail Price" /></div>
                  </div>
                </GridItem>

                <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Price</div>
                    <div><input id="listingPrice" value={this.state.inventory.listingPrice || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Listing Price" /></div>
                  </div>
                </GridItem>
                )} />

                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Maintenance Fee</div>
                    <div><input id="maintFee" value={this.state.inventory.maintFee || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Maintenance Fee" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Financing Eligible</div>
                    <div><input id="financingEligible" value={this.state.inventory.financingEligible || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Financing Eligible" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Marketing Fee</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Marketing Fee</div>
                    <div><input id="marketingFee" value={this.state.inventory.marketingFee || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Marketing Fee" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={4}>
                  <div>
                    <div className="displayLabel">Marketing Fee Notes</div>
                    <div><input id="variance" value={this.state.inventory.variance || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Marketing Fee Notes" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Seller Information</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Entity Type</div>
                    <div><input id="sellerEntityType" value={this.state.inventory.sellerEntityType || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Entity Type" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">HOA Owned</div>
                    <div><input id="isHoaOwned" value={this.state.inventory.isHoaOwned || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="HOA Owned" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Seller Company Name</div>
                    <div><input id="sellerCompanyName" value={this.state.inventory.sellerCompanyName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Seller Company Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Seller Trust Name</div>
                    <div><input id="sellerTrustName" value={this.state.inventory.sellerTrustName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Seller Trust Name" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">First Name</div>
                    <div><input id="sellerFirstName" value={this.state.inventory.sellerFirstName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="First Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Middle Name</div>
                    <div><input id="sellerMiddleName" value={this.state.inventory.sellerMiddleName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Middle Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Last Name</div>
                    <div><input id="sellerLastName" value={this.state.inventory.sellerLastName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Last Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Email</div>
                    <div><input id="sellerEmail" value={this.state.inventory.sellerEmail || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Email" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Home Phone</div>
                    <div><input id="sellerHomePhone" value={this.state.inventory.sellerHomePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Home Phone" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Work Phone</div>
                    <div><input id="sellerWorkPhone" value={this.state.inventory.sellerWorkPhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Work Phone" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Mobile Phone</div>
                    <div><input id="sellerMobilePhone" value={this.state.inventory.sellerMobilePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Mobile Phone" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Co-Seller First Name</div>
                    <div><input id="coSellerFirstName" value={this.state.inventory.coSellerFirstName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="First Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Co-Seller Middle Name</div>
                    <div><input id="coSellerMiddleName" value={this.state.inventory.coSellerMiddleName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Middle Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Co-Seller Last Name</div>
                    <div><input id="coSellerLastName" value={this.state.inventory.coSellerLastName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Last Name" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Co-Seller Email</div>
                    <div><input id="coSellerEmail" value={this.state.inventory.coSellerEmail || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Email" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Co-Seller Home Phone</div>
                    <div><input id="coSellerHomePhone" value={this.state.inventory.coSellerHomePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Home Phone" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Co-Seller Work Phone</div>
                    <div><input id="coSellerWorkPhone" value={this.state.inventory.coSellerWorkPhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Work Phone" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Co-Seller Mobile Phone</div>
                    <div><input id="coSellerMobilePhone" value={this.state.inventory.coSellerMobilePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Mobile Phone" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Address</div>
                    <div><input id="sellerAddress" value={this.state.inventory.sellerAddress || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Address" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">City</div>
                    <div><input id="sellerCity" value={this.state.inventory.sellerCity || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="City" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">State</div>
                    <div><input id="sellerState" value={this.state.inventory.sellerState || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="State" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Zipcode</div>
                    <div><input id="sellerZipcode" value={this.state.inventory.sellerZipcode || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Zipcode" /></div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Country</div>
                    <div><input id="sellerCountry" value={this.state.inventory.sellerCountry || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Country" /></div>
                  </div>
                </GridItem>
              </GridContainer>

              <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Internal Notes</div></GridItem>
              </GridContainer>
              )} />

              <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
              <GridContainer>
                <GridItem xs={12} sm={8}>
                  <div>
                    <div className="displayLabel">Notes</div>
                    <div><input id="notes" value={this.state.inventory.notes || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Notes" /></div>
                  </div>
                </GridItem>
              </GridContainer>
              )} />

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
                  <Button color="success" onClick={(e) => this.updateListing()}>Update</Button>
                </GridItem>
              </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>


      </div>

      ) } />
      );
  }
}

export default withStyles(styles)(Panels);