import React from "react";
import { TikiAPI } from "../../global";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

import Chart from "react-google-charts";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReportsIcon from "@material-ui/icons/BarChart";

import "assets/main.css";

// react router components
import { Link } from 'react-router-dom';

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      members: [],
      membersByTier: [],
      membersByStatus: [],
      reportTopics: [],
      displayReport: [],
      reportByTitle: "",
      currentBalance: "",
      displayListView: false,
      displayMembers: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData();
          this.setState( { reportByTitle: "Marketing Source" });
        }.bind(this),
          function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getData() {
    tikiAPI.getPointsLedgerCurrentBalance(
        function(data) {
          this.setState({ currentBalance: data });
        }.bind(this),
        function(error) { console.log(error) }
    );

    tikiAPI.getAllEpicMembers(
        function(data) {
            this.setState({ members: data }, function() {

                // Members By Tier
                var membershipTiers = [];
                var membershipTiersCount = [];

                var membershipStatuses = [];
                var membershipStatusesCount = [];

                this.state.members.forEach((member) => {
                    var topicIndex = membershipTiers.indexOf(member.membershipTier);

                    if (topicIndex == -1) {
                        membershipTiers.push(member.membershipTier);
                        topicIndex = membershipTiers.indexOf(member.membershipTier);
                    }
                    
                    if (membershipTiersCount[topicIndex] === undefined) {
                        membershipTiersCount[topicIndex] = 0;
                    }
                    membershipTiersCount[topicIndex]++;

                    var topicIndexII = membershipStatuses.indexOf(member.memberStatus);

                    if (topicIndexII == -1) {
                        membershipStatuses.push(member.memberStatus);
                        topicIndexII = membershipStatuses.indexOf(member.memberStatus);
                    }
                    
                    if (membershipStatusesCount[topicIndexII] === undefined) {
                        membershipStatusesCount[topicIndexII] = 0;
                    }
                    membershipStatusesCount[topicIndexII]++;
                });

                var membersByTier = [];
                membersByTier.push( ['Tier', 'Member Count'] );

                for (var i=0; i<membershipTiers.length; i++) {
                    membersByTier.push( [membershipTiers[i], membershipTiersCount[i]] );
                }

                this.setState( { membersByTier: membersByTier } );

                var membersByStatus = [];
                membersByStatus.push( ['Status', 'Member Count'] );

                for (var i=0; i<membershipStatuses.length; i++) {
                    membersByStatus.push( [membershipStatuses[i], membershipStatusesCount[i]] );
                }

                console.log(membersByStatus);

                this.setState( { membersByStatus: membersByStatus } );

            });
        }.bind(this),
        function(error) { console.log(error) }
    );
  }

  getAllTours() {

    tikiAPI.getAllTours(
      function(data) {
        data.sort(tikiAPI.sortByMarketingSource);
        this.setState({ tours: data }, function() {            
          this.calcReport();
        });
      }.bind(this),
      function(error) { console.log(error) }
    );
  }

  filterMembersByTier(filter) {
    var displayMembers = this.state.members.filter(m => m.membershipTier === filter);
    this.setState({ displayMembers: displayMembers });
  };

  filterMembersByStatus(filter) {
    var displayMembers = this.state.members.filter(m => m.memberStatus === filter);
    this.setState({ displayMembers: displayMembers });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ReportsIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Epic Dashboard</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                    <GridItem xs={11}>
                        <div className="chartTitle">Epic Points Balance: { tikiAPI.numberToDisplay(this.state.currentBalance) }</div>
                        <div style={{ display: 'flex', maxWidth: 1200 }}>
                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            data={ this.state.membersByTier }
                            options={{
                                title: 'Members by Tier',
                            }}
                            chartEvents={[
                                {
                                  eventName: 'select',
                                  callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart()
                                    const selection = chart.getSelection()
                                    if (selection.length === 1) {
                                      const [selectedItem] = selection
                                      const dataTable = chartWrapper.getDataTable()
                                      const { row } = selectedItem
                                      this.filterMembersByTier(dataTable.getValue(row,0));
                                    }
                                  },
                                },
                              ]}                            
                        />

                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            data={ this.state.membersByStatus }
                            options={{
                                title: 'Members by Status',
                            }}
                            chartEvents={[
                                {
                                eventName: 'select',
                                callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart()
                                    const selection = chart.getSelection()
                                    if (selection.length === 1) {
                                    const [selectedItem] = selection
                                    const dataTable = chartWrapper.getDataTable()
                                    const { row } = selectedItem
                                    this.filterMembersByStatus(dataTable.getValue(row,0));
                                    }
                                },
                                },
                            ]}                            
                        />
                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                  <table className="tableNewDesign">
                    <thead>
                      <tr>
                        <th>Member #</th>
                        <th>RCI #</th>
                        <th>Member Name</th>
                        <th>Tier</th>
                        <th>Status</th>
                        <th>Membership Start Date</th>
                      </tr>
                    </thead>

                    <tbody>

                      {this.state.displayMembers.map((epicMember, index) => (
                        <tr key={index}>
                          <td>{epicMember.memberId}</td>
                          <td>{epicMember.rciMemberNumber}</td>
                            <td><Link to={`/admin/epic-members-detail/${epicMember.memberId}`} className="ui basic button green">{epicMember.firstName} {epicMember.lastName}</Link></td>
                          <td>{epicMember.membershipTier}</td>
                          <td>{epicMember.memberStatus}</td>
                          <td>{moment(epicMember.membershipStartDate).format('MM/DD/YYYY')}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>


      </div>
    );
  }
}

export default withStyles(styles)(Panels);