import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import ToursIcon from "@material-ui/icons/DateRange";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

// react router components
import { Link } from 'react-router-dom'

import "assets/main.css";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class TourWaves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      tours: [],
      change: false,
      calendarStartDate: moment().format('YYYY-MM-DD')
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          var weekStartDate = moment(this.state.calendarStartDate, 'YYYY-MM-DD');
          weekStartDate.subtract(weekStartDate.day() - 1, 'days');
          this.setState({calendarStartDate: weekStartDate.format('YYYY-MM-DD')});
          this.getData();      
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount() {
  }

  getData() {

    tikiAPI.getToursFromDateRange(
      moment(moment.now()).subtract(1, "months").format("YYYY-MM-DD"),
      moment(moment.now()).add(1, "months").format("YYYY-MM-DD"),
      function(data) {
        console.log(data);
        this.setState({ tours: data, isLoaded: true });
        
        //data.forEach((tourWave, index) => {
      }.bind(this),
      function(error) {
        console.log(error.ToString())
       }
    );

  }

  render() {

    const { isLoaded } = this.state;

    const { classes } = this.props;

    if (!isLoaded) {
      return <div>Loading...</div>
    } else {
      return (

        <Can permissions={this.state.user.permissions} perform="Tours_Main" yes={() => (

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ToursIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Tour Waves</h4>
              </CardHeader>
              <CardBody>
                <div>
                  <table>
                    <thead>                    
                    <tr>
                      <th>Tour Date</th>
                      <th>Lead Name</th>
                      <th>Tour Status</th>
                      <th>Tour Result</th>
                      <th>Marketing Source</th>
                      <th>Gift(s)</th>
                      <th>Sales Rep</th>
                      <th>Notes</th>
                    </tr>
                    </thead>

                    <tbody>
                    { (this.state.tours.length > 0) ? (
                        this.state.tours.map((tour, index) => (
                          <tr key={index}>
                            <td><Link to={`/admin/tours-detail/${tour.tourId}`} className="ui basic button green">{moment(tour.scheduledStartTime).format('MM/DD/YYYY hh:mm a')}</Link></td>
                            <td>{tour.leadFirstName} {tour.leadLastName}</td>
                            <td>{tour.tourStatus}</td>
                            <td>{tour.tourResult}</td>
                            <td>{tour.marketingSource}</td>
                            <td>{tour.giftNames}</td>
                            <td>{tour.assignedEmployeeFirstName} {tour.assignedEmployeeLastName}</td>
                            <td>{tour.notes}</td>
                          </tr>
                        ))                            
                      ) : (
                        <tr><td colSpan="7">No tours available.</td></tr>
                      )
                    }

                  </tbody>
                </table>
              </div>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    
        ) } />
      )
    }
  }
}

export default withStyles(styles)(TourWaves);
