import $ from 'jquery'; 
import * as moment from 'moment';

let env = "prod"; 
var api_url;

switch (env) {
    case "prod": 
        api_url = "https://tiki-prod-api.azurewebsites.net/";
        break;
    case "test":
        api_url = "https://tiki-test-api.azurewebsites.net/";
        break;
    case "dev":
        api_url = "https://localhost:44380";
        break;
    case "mac":
        api_url = "https://localhost:5001";
        break;
    default:
        break;
}

export const ENV = {
    "BASE_API_URL" : api_url
};

export class TikiAPI {

    static currentUser;
    static fetchOptionsGet;

    /////////////// AUTH //////////////////
    authUser(authToken, successCallback, errorCallback) {        
        fetch(ENV.BASE_API_URL + "/api/Users/AuthToken/" + authToken)
        .then((response) => {
            if (response.status === 204) {
                // Authentication failed
                errorCallback(response);
            }            
            return response.json();
        })
        .then((user) => {
            TikiAPI.currentUser = user;
            TikiAPI.fetchOptionsGet = {
                headers: new Headers({'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken}),
                method: 'GET'
            };

            $.getJSON("https://jsonip.com/?callback=?", function (data) {
                if (!user.permissions.includes("Other_AllowRemoteLogin") && data.ip != "64.203.197.30" && data.ip != "174.99.171.14" && data.ip != "64.203.236.208" && env == "prod") {
                    errorCallback("Unauthorized IP Address")
                }
            });
            successCallback(user);
        })
        .catch((error) => {
            errorCallback(error);
        })
    }

    /////////////// CONTRACT //////////////////
    
    createContract(contract, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Contracts/",
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(contract),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });
    }

    getContract(contractId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Contracts/" + contractId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getContract with response code: ' + response.status);
            return response.json();
        })
        .then((contract) => {
            successCallback(contract);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getAllContracts(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Contracts/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllContracts with response code: ' + response.status);
            return response.json();
        })
        .then((contracts) => {
            successCallback(contracts);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    updateContract(contract, successCallback, errorCallback) {     

        $.ajax({
            url: ENV.BASE_API_URL + "/api/Contracts/" + contract.contractId,
            type: 'PUT',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(contract),
            success: function (contractResponse) {
                successCallback(contractResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });                  
    }

    // SPECIAL METHODS

    getContractsForPayPeriod(payPeriodType, payPeriodDate, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Contracts/ContractsForPayPeriod/" + payPeriodType + "/" + payPeriodDate + "/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getContractsForPayPeriod with response code: ' + response.status);
            return response.json();
        })
        .then((contracts) => {
            successCallback(contracts);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    printContract(contractId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Contracts/PrintContract/" + contractId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on printContract with response code: ' + response.status);
            return response.json();
        })
        .then((contract) => {
            successCallback(contract);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    printSellerContract(contractId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Contracts/PrintSellerContract/" + contractId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on printSellerContract with response code: ' + response.status);
            return response.json();
        })
        .then((contract) => {
            successCallback(contract);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    sendSellerContractToDocusign(contractId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Inventories/SendSellerContractToDocusign/" + contractId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on SendSellerContractToDocusign with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// EPIC ADVANTAGE CONTRACT //////////////////
    createEpicAdvantageContract(epicAdvantageContract, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/EpicAdvantageContracts/",
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(epicAdvantageContract),
            success: function (epicAdvantageContractResponse) {
                successCallback(epicAdvantageContractResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
            });              
        }
    
    getAllEpicAdvantageContractsForMember(epicMemberId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/EpicAdvantageContracts/ForMember/" + epicMemberId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllEpicAdvantageContractsForMember with response code: ' + response.status);
            return response.json();
        })
        .then((epicAdvantageContracts) => {
            successCallback(epicAdvantageContracts);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// EPIC CONTRACT //////////////////

    createEpicContract(epicContract, successCallback, errorCallback) {
        epicContract.membershipTerm = epicContract.membershipTerm.toString();
        epicContract.contractDate = this.displayToDateTime(epicContract.contractDate);
        epicContract.membershipStartDate = this.displayToDateTime(epicContract.membershipStartDate);
        epicContract.membershipExpDate = this.displayToDateTime(epicContract.membershipExpDate);
        
        $.ajax({
            url: ENV.BASE_API_URL + "/api/EpicContracts/",
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(epicContract),
            success: function (epicContractResponse) {
                successCallback(epicContractResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });          
    }

    getEpicContract(epicContractId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/EpicContracts/" + epicContractId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getEpicContract with response code: ' + response.status);
            return response.json();
        })
        .then((epicContract) => {
            successCallback(epicContract);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getAllEpicContracts(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/EpicContracts/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllEpicContract with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    updateEpicContract(epicContract, successCallback, errorCallback) {
        epicContract.membershipTerm = epicContract.membershipTerm.toString();
        epicContract.contractDate = this.displayToDateTime(epicContract.contractDate);
        epicContract.membershipStartDate = this.displayToDateTime(epicContract.membershipStartDate);
        epicContract.membershipExpDate = this.displayToDateTime(epicContract.membershipExpDate);
        epicContract.signedContractDocumentDate = this.displayToDateTime(epicContract.signedContractDocumentDate);
        epicContract.firstLoanPaymentDate = this.displayToDateTime(epicContract.firstLoanPaymentDate);
        epicContract.finalLoanPaymentDate = this.displayToDateTime(epicContract.finalLoanPaymentDate);
        epicContract.contractGoodDate = this.displayToDateTime(epicContract.contractGoodDate);
        epicContract.paidInFullDate = this.displayToDateTime(epicContract.paidInFullDate);

        $.ajax({
            url: ENV.BASE_API_URL + "/api/EpicContracts/" + epicContract.epicContractId,
            type: 'PUT',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(epicContract),
            success: function (epicContractResponse) {
                successCallback(epicContractResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });                  
    }

    // SPECIAL METHODS

    printEpicContract(epicContractId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/EpicContracts/PrintContract/" + epicContractId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on printEpicContract with response code: ' + response.status);
            return response.json();
        })
        .then((epicContract) => {
            successCallback(epicContract);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// EPIC MEMBERS //////////////////

    getEpicMember(epicMemberId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Members/" + epicMemberId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getEpicMember with response code: ' + response.status);
            return response.json();
        })
        .then((epicMember) => {
            successCallback(epicMember);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getAllEpicMembers(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Members/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllEpicMembers with response code: ' + response.status);
            return response.json();
        })
        .then((epicMembers) => {
            successCallback(epicMembers);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    updateEpicMember(epicMember, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Members/" + epicMember.memberId,
            type: 'PUT',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(epicMember),
            success: function (xhr) {
                successCallback();
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });                  
    }

    // SPECIAL METHODS

    rciMemberLookup(rciNumber, memberLastName, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Members/Rci?rciNumber=" + rciNumber + "&memberLastName=" + memberLastName,
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            success: function (epicAdvantageContractResponse) {
                successCallback(epicAdvantageContractResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });          
    }
    
    /////////////// EMPLOYEES //////////////////

    createEmployee(employee, successCallback, errorCallback) {        
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Employees/",
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(employee),
            success: function (response) {
                successCallback(response);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });                  
    }

    getEmployee(employeeId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Employees/" + employeeId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getEmployee with response code: ' + response.status);
            return response.json();
        })
        .then((employee) => {
            successCallback(employee);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getAllEmployees(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Employees/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllEmployees with response code: ' + response.status);
            return response.json();
        })
        .then((contracts) => {
            successCallback(contracts);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    updateEmployee(employee, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Employees/" + employee.employeeId,
            type: 'PUT',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(employee),
            success: function (response) {
                successCallback(response);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });                  
    }

    // SPECIAL METHODS
    getSalesReps(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Employees/SalesReps", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getSalesReps with response code: ' + response.status);
            return response.json();
        })
        .then((salesReps) => {
            successCallback(salesReps);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getMarketingReps(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Employees/MarketingReps", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getSalesReps with response code: ' + response.status);
            return response.json();
        })
        .then((salesReps) => {
            successCallback(salesReps);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// GIFT LEDGER //////////////////

    createGiftLedgerTransaction(giftTransaction, successCallback, errorCallback) {
        console.log(giftTransaction);

        $.ajax({
            url: ENV.BASE_API_URL + "/api/GiftLedgers/" ,
            type: 'POST',
            crossDomain: true,
            dataType: 'jsonp',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(giftTransaction),
            success: function (giftTransactionResponse) {
                successCallback(giftTransactionResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    getLedgerForGiftType(giftTypeId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/GiftLedgers/" + giftTypeId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getLedgerForGiftType with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// GIFT TYPES //////////////////

    createGift(gift, successCallback, errorCallback) {        
        $.ajax({
            url: ENV.BASE_API_URL + "/api/GiftTypes/",
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(gift),
            success: function (response) {
                successCallback(response);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });                  
    }

    updateGift(giftType, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/GiftTypes/" + giftType.giftTypeId,
            type: 'PUT',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(giftType),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    getGiftTypes(giftTypeId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/GiftTypes/" + giftTypeId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getGiftTypes with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getAllGiftTypes(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/GiftTypes", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllGiftTypes with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    // SPECIAL METHODS

    getAvailableGifts(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/GiftTypes/AvailableGifts", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAvailableGifts with response code: ' + response.status);
            return response.json();
        })
        .then((gifts) => {
            successCallback(gifts);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }
    
    /////////////// INTERESTS //////////////////

    createNewInterest(interest, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Interests/",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(interest),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    updateInterest(interest, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Interests/" + interest.interestId,
            type: 'PUT',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(interest),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    // SPECIAL METHODS

    getInterestsForLead(leadId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Interests/ForLead/" + leadId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getInterestsForLead with response code: ' + response.status);
            return response.json();
        })
        .then((interests) => {
            successCallback(interests);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// INVENTORY //////////////////

    createInventory(inventory, successCallback, errorCallback) {
        inventory.listingStartDate = this.displayToDateTime(inventory.listingStartDate);
        inventory.listingEndDate = this.displayToDateTime(inventory.listingEndDate);
        inventory.mktFeeDatePd = this.displayToDateTime(inventory.mktFeeDatePd);
        inventory.entryDate = this.displayToDateTime(inventory.entryDate);
        inventory.verifiedDate = this.displayToDateTime(inventory.verifiedDate);

        $.ajax({
            url: ENV.BASE_API_URL + "/api/Inventories/",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(inventory),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    getInventoryRetailPrice(inventory, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Inventories/RetailPrice/",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(inventory),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    getInventory(inventoryId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Inventories/" + inventoryId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getInventory with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getAllInventory(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Inventories", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllInventory with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    updateInventory(inventory, successCallback, errorCallback) {
        inventory.listingStartDate = this.displayToDateTime(inventory.listingStartDate);
        inventory.listingEndDate = this.displayToDateTime(inventory.listingEndDate);
        inventory.mktFeeDatePd = this.displayToDateTime(inventory.mktFeeDatePd);
        inventory.entryDate = this.displayToDateTime(inventory.entryDate);
        inventory.verifiedDate = this.displayToDateTime(inventory.verifiedDate);

        $.ajax({
            url: ENV.BASE_API_URL + "/api/Inventories/" + inventory.inventoryId,
            type: 'PUT',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(inventory),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    generateListingAgreement(inventoryId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Inventories/GenerateListingAgreement/" + inventoryId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on GenerateListingAgreement with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    sendListingAgreementToDocusign(inventoryId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Inventories/SendListingAgreementToDocusign/" + inventoryId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on SendListingAgreementToDocusign with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    // SPECIAL METHODS

    searchInventory(searchFilters, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Inventories/Search?searchFilters=" + searchFilters, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on searchInventory with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// LEADS //////////////////
    createNewLead(lead, successCallback, errorCallback) {    
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Leads/",
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(lead),
            success: function (leadResponse) {
                successCallback(leadResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });              
    }

    getLead(leadId, successCallback, errorCallback) {    
        fetch(ENV.BASE_API_URL + "/api/Leads/" + leadId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getLead with response code: ' + response.status);
            return response.json();
        })
        .then((lead) => {
            successCallback(lead);
        })
        .catch((error) => {
            errorCallback(error);
        });    
    }
    
    getAllLeads(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Leads", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllLeads with response code: ' + response.status);
            return response.json();
        })
        .then((leads) => {
            successCallback(leads);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    updateLead(lead, successCallback, errorCallback) {    
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Leads/" + lead.leadId,
            type: 'PUT',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(lead),
            success: function (leadResponse) {
                successCallback(leadResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });          
    }    

    deleteLead(lead, successCallback, errorCallback) {    
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Leads/" + lead.leadId,
            type: 'DELETE',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(lead),
            success: function (leadResponse) {
                successCallback(leadResponse);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });          
    }    

    /////////////// POINTS LEDGER //////////////////

    getPointsLedgerCurrentBalance(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/PointLedgers/CurrentBalance/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getPointsLedgerCurrentBalance with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getPointsLedgerTransactions(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/PointLedgers/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getPointsLedgerTransactions with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// PRODUCTS //////////////////

    getAllProducts(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Products/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllProducts with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// RESORTS //////////////////

    getResorts(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Resort/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getResorts with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// SEARCH //////////////////

    getSearchResults(searchId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Searches/" + searchId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getSearchResults with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    postSearch(search, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Searches" ,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: search,
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    /////////////// TOURS //////////////////

    createNewTour(tour, successCallback, errorCallback) {
        tour.tourDate = new Date(tour.tourDate).toISOString();
        tour.scheduledStartTime = new Date(moment.tz(tour.scheduledStartTime, "America/New_York").toString()).toISOString();
        if (tour.assignedEmployeeId == "") { tour.assignedEmployeeId = null}
        if (tour.marketingEmployeeId == "") { tour.marketingEmployeeId = null}

        $.ajax({
            url: ENV.BASE_API_URL + "/api/Tours" ,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(tour),
            success: function (tourData) {
                successCallback(tourData);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    getTour(tourId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Tours/" + tourId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getTour with response code: ' + response.status);
            return response.json();
        })
        .then((tour) => {
            successCallback(tour);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getAllTours(successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Tours/", TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllTours with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    getToursFromDateRange(startDate, endDate, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Tours/From/" + startDate + "/To/" + endDate, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getRecentTours with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    updateTour(tour, successCallback, errorCallback) {
        tour.tourDate = new Date(tour.tourDate).toISOString();
        tour.scheduledStartTime = new Date(moment.tz(tour.scheduledStartTime, "America/New_York").toString()).toISOString();

        $.ajax({
            url: ENV.BASE_API_URL + "/api/Tours/" + tour.tourId,
            type: 'PUT',
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(tour),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });    
    }

    // SPECIAL METHODS

    getToursForLead(leadId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Tours/ForLead/" + leadId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getToursForLead with response code: ' + response.status);
            return response.json();
        })
        .then((tours) => {
            successCallback(tours);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// UNIT TYPES //////////////////

    getUnitTypesForResort(resortName, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/UnitType/ForResort/" + resortName, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getUnitTypesForResort with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// USER //////////////////

    createUser(user, successCallback, errorCallback) {            
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Users/" + user.userId,
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(user),
            success: function () {
                successCallback();
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });                  
    }

    getUser(userId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Users/" + userId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getUser with response code: ' + response.status);
            return response.json();
        })
        .then((users) => {
            successCallback(users);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }
    
    getAllUsers(tenantId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Users/TenantUsers/" + tenantId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getAllUsers with response code: ' + response.status);
            return response.json();
        })
        .then((users) => {
            successCallback(users);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }
    
    updateUser(user, successCallback, errorCallback) {        
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Users/" + user.userId,
            type: 'PUT',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(user),
            success: function () {
                successCallback();
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });                    
    }

    /////////////// ADDITIONAL SPECIAL METHODS //////////////////
    
    forgotPassword(email, successCallback, errorCallback) {    
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Users/ResetPassword?email=" + email,
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });          
    }

    resetMemberPassword(member, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/api/Members/ResetWebsitePassword",
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            data: JSON.stringify(member),
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });          
    }

    uploadSignedContract(contractId, file, filePath,  successCallback, errorCallback) {
        var formdata = new FormData();
        formdata.append("signedContract", file, filePath);
        
        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: new Headers({'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken}),
        };
        
        fetch(ENV.BASE_API_URL + "/api/EpicContracts/UploadSignedContract/" + contractId, requestOptions)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on uploadSignedContract with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    sendMessage(mobileNumber, message, successCallback, errorCallback) {
        $.ajax({
            url: ENV.BASE_API_URL + "/tocapi/sendmessage?mobileNumber=" + mobileNumber + "&messageContent=" + message,
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            contentType: 'application/json-patch+json',
            cache: false,
            headers: { 'Authorization': 'Bearer ' + TikiAPI.currentUser.authToken },
            success: function (data) {
                successCallback(data);
            },
            error: function (xhr, status, err) {
                alert("Please contact customer support with the following error: " + xhr.responseText);
                errorCallback(xhr);
            }
        });          
    }

    getCommunicationForLead(leadId, successCallback, errorCallback) {
        fetch(ENV.BASE_API_URL + "/api/Communications/ForLead/" + leadId, TikiAPI.fetchOptionsGet)
        .then((response) => {
            if (!response.ok) throw new Error('Error occured on getCommunicationForLead with response code: ' + response.status);
            return response.json();
        })
        .then((data) => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
    }

    /////////////// UTILITY FUNCTIONS //////////////////    
    displayToNumber(x) {
        if (typeof x === "undefined") {
            return "";
        } else {
            x = x.toString().replace(",", "");
            x = parseFloat(x);
            x = Number(x);
            x = Math.round(x * 100) / 100;
            return x;        
        }
      }
    
    numberToDisplay(num) {
        var x = num;
        if (typeof(x) == "string") {
            x = x.replace(/,/g, '');
            x = parseFloat(x);
        }
        if (isNaN(x)) {
            // console.log("not a num: " + x);  
            return "";
        }
    
          if (x != null) {
            x = this.displayToNumber(x);
            x = x.toFixed(2);
            x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return x;
        }
      }

    isValueNumber(x) {

        return true;
    }

    displayToDateTime(displayString) {
        if (displayString != "" && displayString != null) {
            var dateTimeValue = new Date(moment.tz(displayString, "America/New_York").toString()).toISOString();
            return dateTimeValue;    
        } else {
            return null;
        }
    }

    dateTimeToDisplay(dateTime) {
        // assumes moment can parse this correctly in all cases?
    }

    sortByTourDate(a, b) {      
        let comparison = 0;
        if (a.tourDate > b.tourDate) {
          comparison = 1;
        } else if (a.tourDate < b.tourDate) {
          comparison = -1;
        }
        return comparison;
    }

    sortByMarketingSource(a, b) {      
        let comparison = 0;
        if (a.marketingSource > b.marketingSource) {
          comparison = 1;
        } else if (a.marketingSource < b.marketingSource) {
          comparison = -1;
        }
        return comparison;
    }

    sortByField(field, a, b) {      
        let comparison = 0;
        if (a[field] > b[field]) {
          comparison = 1;
        } else if (a[field] < b[field]) {
          comparison = -1;
        }
        return comparison;
    }

    filterToursByMonthAndYear(month, year, tours) {
        var filteredTours = tours;
        filteredTours = filteredTours.filter(v => moment(v.tourDate).format('MM') == month && moment(v.tourDate).format('YYYY') == year);
        return filteredTours;
    }

    getSalesVolumeForTours(tours) {
        var salesVolume = 0;

        tours.forEach((tour) => {
            if (tour.salesVolume) { salesVolume += tour.salesVolume };
        });        

        return salesVolume;
    }

    getVPGForTours(tours) {
        var vpg = 0;

        var qualifiedTours = tours;
        qualifiedTours = qualifiedTours.filter(v => v.tourStatus == "Ended");

        vpg = this.getSalesVolumeForTours(qualifiedTours) / qualifiedTours.length;

        return vpg;
    }

    generateTilaForContract(orgContract) {
        let contract = JSON.parse(JSON.stringify(orgContract));

        var monthlyPayment = 0;
        var finalPayment = 0;
        var principalAmount = contract.financedAmount || 0;
        var totalInterest = 0;
        var totalPayments = 0;
        var annualInterestRate = (contract.interestRate || 0) / 100;
        var monthlyInterestRate = annualInterestRate / 12;
        var numberOfPayments = 60;  // Fixed at 60 for now
        var currentPaymentNumber = 0;
        var totalMonthlyPrincipalAmount = 0;

        var numerator = monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments);
        var denomenator = Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1;
        monthlyPayment = principalAmount * (numerator / denomenator);
        monthlyPayment = Math.round(monthlyPayment * 100) / 100;

        while (principalAmount > 0)
        {
            currentPaymentNumber++;
            var monthlyInterest = (principalAmount * monthlyInterestRate);
            var monthlyPrincipal = (monthlyPayment - monthlyInterest);

            if (monthlyPrincipal > principalAmount)
            {
                monthlyPrincipal = principalAmount;
            }

            principalAmount -= monthlyPrincipal;
            totalMonthlyPrincipalAmount += principalAmount;
            totalInterest += parseFloat(monthlyInterest);
            totalPayments += monthlyInterest + monthlyPrincipal;
            finalPayment = monthlyInterest + monthlyPrincipal;
        }

        contract.financeCharge = Math.round((totalInterest + contract.loanProcessingFee) * 100) / 100;
        contract.totalPayments = Math.round((totalInterest + contract.financedAmount) * 100) / 100;
        contract.totalSalesPrice = Math.round((contract.totalPayments + contract.minimumDownPayment + contract.additionalDownPayment) * 100) / 100;
        contract.numberOfRegularPayments = numberOfPayments;
        contract.regularPaymentAmount = monthlyPayment;
        contract.finalPaymentAmount = Math.round((finalPayment * 100)) / 100;
        contract.lateCharge = Math.round(((contract.regularPaymentAmount || 0) * .10) * 100) / 100;
        contract.aprRate = Math.round((((100 * (contract.financeCharge || 0) / ((totalMonthlyPrincipalAmount / ((contract.loanTerm || 0) + 0.5))) / (contract.loanTerm || 0) * 12))) * 100) / 100;

        return contract;
    }
}