import React from "react";
//TODO: implement RBAC
//import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ContractIcon from "@material-ui/icons/InsertDriveFile";
import Button from "components/CustomButtons/Button.jsx";

import { Redirect } from "react-router-dom";

import "assets/main.css";

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: { color: "#3C4858", textDecoration: "none", textAlign: "center" },
  cardCategory: { margin: "0", color: "#999999" },
  selectCSS: { padding: "50px" }
};

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      lead: "",
      inventory: "",
      contract: {},
      tour: "",
      salesReps: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.tourId, this.props.match.params.inventoryId);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  
  getData(tourId, inventoryId){

    tikiAPI.getSalesReps(
      function(data) {
        this.setState({salesReps: data});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

    if (inventoryId == 0) {
      // Set tppResponse to Internal Contract to bypass sending this contract to tpp API
      var newContract = this.state.contract;
      newContract.tppResponse = "Internal Contract";
      this.setState({ contract: newContract });
    }

    if (tourId) {

        tikiAPI.getTour(tourId,
            function(tourData) {
            console.log(tourData);
            this.setState({tour: tourData});

            tikiAPI.getLead(tourData.leadId,
                function(data) {
                this.setState({lead: data});
                console.log(this.state.lead);
                var newContract = this.state.contract;
                newContract.tourId = tourId;
                newContract.leadId = this.state.lead.leadId;
                newContract.buyerName = data.firstName + " " + data.lastName;
                newContract.buyerFirstName = data.firstName;
                newContract.buyerLastName = data.lastName;
                newContract.buyerAddress1 = data.address1;
                newContract.buyerAddress2 = data.address2;
                newContract.buyerCity = data.city;
                newContract.buyerState = data.state;
                newContract.buyerZipcode = data.zipcode;
                newContract.buyerCountry = data.country;
                newContract.buyerPhone = data.mobilePhone;
                newContract.buyerEmail = data.email;
                newContract.buyerEntityType = "INDIVIDUAL";
                this.setState({ contract: newContract });
                this.showHideFields();
                }.bind(this),
                function(error) {
                console.log(error);
                }
            );

            }.bind(this),
            function(error) {
            console.log(error);
            }
        );
    }
}
  
  componentDidMount(){
  }

  displayToNumber(x) {
    x = x.toString().replace(",", "");
    x = parseFloat(x);
    x = Number(x);
    return x;    
  }

  numberToDisplay(x) {
    if (x != null) {
      x = this.displayToNumber(x);
      x = x.toFixed(2);
      x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return x;
    }
  }

  populateDate(daysFromToday, targetId) {
    console.log(targetId);
    var d = moment();
    d = d.add(daysFromToday, 'days');
    var newContract = this.state.contract;
    newContract[targetId] = d.format("MM/DD/YYYY");
    this.setState({ contract: newContract });
    console.log(this.state.contract);
  }

  handleBlurInput = event => {
    console.log("blur event");
    var updateContract = this.state.contract;
    updateContract[event.target.name] = event.target.value;
    this.setState ( { contract : updateContract } );
    event.target.value = this.numberToDisplay(event.target.value);
    this.calcFinancials();
  }

  createContract=(e)=>{
    e.target.innerHTML="Please wait...";

    tikiAPI.createContract(this.state.contract,
      function(data) {
        window.location = '/admin/contracts-detail/' + data.contractId;
      },
      function(error) {
        console.log(error);
      }
    );

  }


  //creaet a UI refresh function
  // remove UI refresh from handleSelect
  showHideFields() {

    if (this.state.contract.isToUsed === "YES") {
      document.getElementById("toName-row").style.display = "table-row";
    } else {
      document.getElementById("toName-row").style.display = "none";
    }

    switch (this.state.contract.buyerEntityType) {
      case "INDIVIDUAL":
          document.getElementById("buyerFirstName-row").style.display = "table-row";
          document.getElementById("buyerMiddleName-row").style.display = "table-row";
          document.getElementById("buyerLastName-row").style.display = "table-row";
          document.getElementById("buyerCompanyName-row").style.display = "none";
          document.getElementById("buyerStateInc-row").style.display = "none";
          document.getElementById("buyerTrustName-row").style.display = "none";
        break;
      case "COMPANY":
          document.getElementById("buyerFirstName-row").style.display = "none";
          document.getElementById("buyerMiddleName-row").style.display = "none";
          document.getElementById("buyerLastName-row").style.display = "none";
          document.getElementById("buyerCompanyName-row").style.display = "table-row";
          document.getElementById("buyerStateInc-row").style.display = "table-row";
          document.getElementById("buyerTrustName-row").style.display = "none";
        break;
      case "TRUST":
          document.getElementById("buyerFirstName-row").style.display = "none";
          document.getElementById("buyerMiddleName-row").style.display = "none";
          document.getElementById("buyerLastName-row").style.display = "none";
          document.getElementById("buyerCompanyName-row").style.display = "none";
          document.getElementById("buyerStateInc-row").style.display = "none";
          document.getElementById("buyerTrustName-row").style.display = "table-row";
        break;
      default:
    }

  }

  handleSelect = event => {
    console.log(event.target.name);
    console.log(event.target.value);

    var updateContract = this.state.contract;
    
    updateContract[event.target.name] = event.target.value;

    this.setState({ contract: updateContract });

    this.showHideFields();

    this.calcFinancials();
  };

calcFinancials () {
    if (this.state.contract.purchasePrice) {
        this.state.contract.contractSalesPrice = tikiAPI.displayToNumber(this.state.contract.purchasePrice);
        this.state.contract.contractSalesPrice = tikiAPI.numberToDisplay(this.state.contract.contractSalesPrice);

        if (this.state.contract.depositAmount) {
            this.state.contract.balanceDue = tikiAPI.displayToNumber(this.state.contract.contractSalesPrice) - tikiAPI.displayToNumber(this.state.contract.depositAmount);
            this.state.contract.balanceDue = tikiAPI.numberToDisplay(this.state.contract.balanceDue);
        }
    }

}

  render() {
    if (!cookies.get('isAuthenticated')) {
      return <Redirect to='../auth/login-page' />
    }
  
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <ContractIcon />
              </CardIcon>
              <h4 className={classes.cardTitle}>New HSI / RCI Contract</h4>
            </CardHeader>
              <CardBody>
                <GridContainer><GridItem xs={12}><div className="softDivider">Sale Type</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">Select contract type:</div>
                    <select name="contractType" id="contractType" value={this.state.contract.contractType || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="HSI" >HSI</option>
                      <option value="RCI" >RCI</option>
                      <option value="HSIANDRCI" >HSI and RCI</option>
                    </select>
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <div className="inputLabelLarge">Is a TO being used in this transaction?</div>
                    <select name="isToUsed" id="isToUsed" value={this.state.contract.isToUsed || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4}>
                    <div id="toName-row" style={{ display: "none" }}>
                      <div className="inputLabelLarge">Who is the TO?</div>
                      <select name="toName" id="toName" value={this.state.contract.toName || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        { (this.state.salesReps.length > 0) ? (
                          this.state.salesReps.map((salesRep, index) => (
                            (salesRep.isToRep === "Yes") && <option key={index} value={salesRep.fullName}>{salesRep.fullName}</option>                               
                          ))                            
                          ) : (
                            <option>No available sales reps.</option>
                          )
                        } 
                      </select>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Resort Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                      <div className="inputLabelLarge">Resort Name</div>
                      <input name="resort" id="resort" value={this.state.contract.resort || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabelLarge">Unit Number</div>
                      <input name="unitNumber" id="unitNumber" value={this.state.contract.unitNumber || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabelLarge">Deeded Week</div>
                      <input name="useWeek" id="useWeek" value={this.state.contract.useWeek || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Buyer Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">Entity Type</div>
                    <select name="buyerEntityType" id="buyerEntityType" value={this.state.contract.buyerEntityType || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="INDIVIDUAL">INDIVIDUAL</option>
                      <option value="COMPANY">COMPANY</option>
                      <option value="TRUST">TRUST</option>
                    </select>
                  </GridItem>

                    <GridItem xs={12} sm={6} md={2}>
                      <div id="buyerFirstName-row" style={{ display: "none" }}>
                        <div className="inputLabelLarge">First Name</div>
                        <input name="buyerFirstName" id="buyerFirstName" value={this.state.contract.buyerFirstName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                      </div>
                      <div id="buyerCompanyName-row" style={{ display: "none" }}>
                        <div className="inputLabelLarge">Company Name</div>
                        <input name="buyerCompanyName" id="buyerCompanyName" value={this.state.contract.buyerCompanyName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                      </div>
                      <div id="buyerTrustName-row" style={{ display: "none" }}>
                        <div className="inputLabelLarge">Trust Name</div>
                        <input name="buyerTrustName" id="buyerTrustName" value={this.state.contract.buyerTrustName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                      </div>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div id="buyerMiddleName-row" style={{ display: "none" }}>
                        <div className="inputLabelLarge">Middle Name</div>
                        <input name="buyerMiddleName" id="buyerMiddleName" value={this.state.contract.buyerMiddleName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                      </div>
                      <div id="buyerStateInc-row" style={{ display: "none" }}>
                        <div className="inputLabelLarge">State of Inc.</div>
                        <select name="buyerStateInc" id="buyerStateInc" value={this.state.contract.buyerStateInc || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                          <option value="">---SELECT---</option>
                          <option value="AL" >Alabama</option>
                          <option value="AK" >Alaska</option>
                          <option value="AR" >Arkansas</option>
                          <option value="AZ" >Azizona</option>
                          <option value="CA" >California</option>
                          <option value="CO" >Colorado</option>
                          <option value="CT" >Connecticut</option>
                          <option value="DE" >Delaware</option>
                          <option value="FL" >Florida</option>
                          <option value="GA" >Georgia</option>
                          <option value="HI" >Hawaii</option>
                          <option value="ID" >Idaho</option>
                          <option value="IL" >Illinois</option>
                          <option value="IN" >Indiana</option>
                          <option value="IA" >Iowa</option>
                          <option value="KS" >Kansas</option>
                          <option value="KY" >Kentucky</option>
                          <option value="LA" >Louisiana</option>
                          <option value="ME" >Maine</option>
                          <option value="MD" >Maryland</option>
                          <option value="MA" >Massachusetts</option>
                          <option value="MI" >Michigan</option>
                          <option value="MN" >Minnesota</option>
                          <option value="MS" >Mississippi</option>
                          <option value="MO" >Missouri</option>
                          <option value="MT" >Montana</option>
                          <option value="NE" >Nebraska</option>
                          <option value="NV" >Nevada</option>
                          <option value="NH" >New Hampshire</option>
                          <option value="NJ" >New Jersey</option>
                          <option value="NM" >New Mexico</option>
                          <option value="NY" >New York</option>
                          <option value="NC" >North Carolina</option>
                          <option value="ND" >North Dakota</option>
                          <option value="OH" >Ohio</option>
                          <option value="OK" >Oklahoma</option>
                          <option value="OR" >Oregon</option>
                          <option value="PA" >Pennsylvania</option>
                          <option value="RI" >Rhode Island</option>
                          <option value="SC" >South Carolina</option>
                          <option value="SD" >South Dakota</option>
                          <option value="TN" >Tennessee</option>
                          <option value="TX" >Texas</option>
                          <option value="UT" >Utah</option>
                          <option value="VT" >Vermont</option>
                          <option value="VA" >Virginia</option>
                          <option value="WA" >Washington</option>
                          <option value="DC" >Washington D.C.</option>
                          <option value="WV" >West Virginia</option>
                          <option value="WI" >Wisconsin</option>
                          <option value="WY" >Wyoming</option>
                        </select>
                      </div>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div id="buyerLastName-row" style={{ display: "none" }}>
                        <div className="inputLabelLarge">Last Name</div>
                        <input name="buyerLastName" id="buyerLastName" value={this.state.contract.buyerLastName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                      </div>
                    </GridItem>                    

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div style={{ display: "none" }}>&nbsp;</div>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div id="coBuyerFirstName-row" style={{ display: "none" }}>
                      <div className="inputLabelLarge">Co-Buyer First Name</div>
                      <input name="coBuyerFirstName" id="coBuyerFirstName" value={this.state.contract.coBuyerFirstName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </div>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div id="coBuyerMiddleName-row" style={{ display: "none" }}>
                      <div className="inputLabelLarge">Co-Buyer Middle Name</div>
                      <input name="coBuyerMiddleName" id="coBuyerMiddleName" value={this.state.contract.coBuyerMiddleName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </div>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div id="coBuyerLastName-row" style={{ display: "none" }}>
                      <div className="inputLabelLarge">Co-Buyer Last Name</div>
                      <input name="coBuyerLastName" id="coBuyerLastName" value={this.state.contract.coBuyerLastName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </div>
                  </GridItem>                    

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">Email</div>
                    <input name="buyerEmail" id="buyerEmail" value={this.state.contract.buyerEmail || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Home Phone</div>
                    <input name="buyerHomePhone" id="buyerHomePhone" value={this.state.contract.buyerHomePhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Work Phone</div>
                    <input name="buyerWorkPhone" id="buyerWorkPhone" value={this.state.contract.buyerWorkPhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Mobile Phone</div>
                    <input name="buyerMobilePhone" id="buyerMobilePhone" value={this.state.contract.buyerMobilePhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <div className="inputLabelLarge">Address</div>
                    <input name="buyerAddress" id="buyerAddress" value={this.state.contract.buyerAddress || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">City</div>
                    <input name="buyerCity" id="buyerCity" value={this.state.contract.buyerCity || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">State/Province</div>
                      <select name="buyerState" id="buyerState" value={this.state.contract.buyerState || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="AL" >Alabama</option>
                        <option value="AK" >Alaska</option>
                        <option value="AR" >Arkansas</option>
                        <option value="AZ" >Azizona</option>
                        <option value="CA" >California</option>
                        <option value="CO" >Colorado</option>
                        <option value="CT" >Connecticut</option>
                        <option value="DE" >Delaware</option>
                        <option value="FL" >Florida</option>
                        <option value="GA" >Georgia</option>
                        <option value="HI" >Hawaii</option>
                        <option value="ID" >Idaho</option>
                        <option value="IL" >Illinois</option>
                        <option value="IN" >Indiana</option>
                        <option value="IA" >Iowa</option>
                        <option value="KS" >Kansas</option>
                        <option value="KY" >Kentucky</option>
                        <option value="LA" >Louisiana</option>
                        <option value="ME" >Maine</option>
                        <option value="MD" >Maryland</option>
                        <option value="MA" >Massachusetts</option>
                        <option value="MI" >Michigan</option>
                        <option value="MN" >Minnesota</option>
                        <option value="MS" >Mississippi</option>
                        <option value="MO" >Missouri</option>
                        <option value="MT" >Montana</option>
                        <option value="NE" >Nebraska</option>
                        <option value="NV" >Nevada</option>
                        <option value="NH" >New Hampshire</option>
                        <option value="NJ" >New Jersey</option>
                        <option value="NM" >New Mexico</option>
                        <option value="NY" >New York</option>
                        <option value="NC" >North Carolina</option>
                        <option value="ND" >North Dakota</option>
                        <option value="OH" >Ohio</option>
                        <option value="OK" >Oklahoma</option>
                        <option value="OR" >Oregon</option>
                        <option value="PA" >Pennsylvania</option>
                        <option value="RI" >Rhode Island</option>
                        <option value="SC" >South Carolina</option>
                        <option value="SD" >South Dakota</option>
                        <option value="TN" >Tennessee</option>
                        <option value="TX" >Texas</option>
                        <option value="UT" >Utah</option>
                        <option value="VT" >Vermont</option>
                        <option value="VA" >Virginia</option>
                        <option value="WA" >Washington</option>
                        <option value="DC" >Washington D.C.</option>
                        <option value="WV" >West Virginia</option>
                        <option value="WI" >Wisconsin</option>
                        <option value="WY" >Wyoming</option>
                        <option value="AB" >Alberta</option>
                        <option value="BC" >British Columbia</option>
                        <option value="MB" >Manitoba</option>
                        <option value="NB" >New Brunswick</option>
                        <option value="NL" >Newfoundland</option>
                        <option value="NT" >Northwest Territories</option>
                        <option value="NS" >Nova Scotia</option>
                        <option value="NU" >Nunavut</option>
                        <option value="ON" >Ontario</option>
                        <option value="PE" >Prince Edward Island</option>
                        <option value="QC" >Quebec</option>
                        <option value="SK" >Saskatchewan</option>
                        <option value="YT" >Yukon</option>
                      </select>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">Postal Code</div>
                    <input name="buyerZipcode" id="buyerZipcode" value={this.state.contract.buyerZipcode || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">Country</div>
                     <select name="buyerCountry" id="buyerCountry" value={this.state.contract.buyerCountry || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="US" >United States</option>
                        <option value="AF" >Afghanistan</option>
                        <option value="AX" >Aland Islands</option>
                        <option value="AL" >Albania</option>
                        <option value="DZ" >Algeria</option>
                        <option value="AS" >American Samoa</option>
                        <option value="AD" >Andorra</option>
                        <option value="AO" >Angola</option>
                        <option value="AI" >Anguilla</option>
                        <option value="AQ" >Antarctica</option>
                        <option value="AG" >Antigua and Barbuda</option>
                        <option value="AR" >Argentina</option>
                        <option value="AM" >Armenia</option>
                        <option value="AW" >Aruba</option>
                        <option value="AU" >Australia</option>
                        <option value="AT" >Austria</option>
                        <option value="AZ" >Azerbaijan</option>
                        <option value="BS" >Bahamas, The</option>
                        <option value="BH" >Bahrain</option>
                        <option value="BD" >Bangladesh</option>
                        <option value="BB" >Barbados</option>
                        <option value="BY" >Belarus</option>
                        <option value="BE" >Belgium</option>
                        <option value="BZ" >Belize</option>
                        <option value="BJ" >Benin</option>
                        <option value="BM" >Bermuda</option>
                        <option value="BT" >Bhutan</option>
                        <option value="BO" >Bolivia</option>
                        <option value="BQ" >Bonaire, Saint Eustatius and Saba</option>
                        <option value="BA" >Bosnia and Herzegovina</option>
                        <option value="BW" >Botswana</option>
                        <option value="BV" >Bouvet Island</option>
                        <option value="BR" >Brazil</option>
                        <option value="IO" >British Indian Ocean Territory</option>
                        <option value="BN" >Brunei Darussalam</option>
                        <option value="BG" >Bulgaria</option>
                        <option value="BF" >Burkina Faso</option>
                        <option value="BI" >Burundi</option>
                        <option value="KH" >Cambodia</option>
                        <option value="CM" >Cameroon</option>
                        <option value="CA" >Canada</option>
                        <option value="CV" >Cape Verde</option>
                        <option value="KY" >Cayman Islands</option>
                        <option value="CF" >Central African Republic</option>
                        <option value="TD" >Chad</option>
                        <option value="CL" >Chile</option>
                        <option value="CN" >China</option>
                        <option value="CX" >Christmas Island</option>
                        <option value="CC" >Cocos (Keeling) Islands</option>
                        <option value="CO" >Colombia</option>
                        <option value="KM" >Comoros</option>
                        <option value="CG" >Congo</option>
                        <option value="CD" >Congo, The Democratic Republic Of The</option>
                        <option value="CK" >Cook Islands</option>
                        <option value="CR" >Costa Rica</option>
                        <option value="CI" >Cote D&#039;ivoire</option>
                        <option value="HR" >Croatia</option>
                        <option value="CW" >Curaçao</option>
                        <option value="CY" >Cyprus</option>
                        <option value="CZ" >Czech Republic</option>
                        <option value="DK" >Denmark</option>
                        <option value="DJ" >Djibouti</option>
                        <option value="DM" >Dominica</option>
                        <option value="DO" >Dominican Republic</option>
                        <option value="EC" >Ecuador</option>
                        <option value="EG" >Egypt</option>
                        <option value="SV" >El Salvador</option>
                        <option value="GQ" >Equatorial Guinea</option>
                        <option value="ER" >Eritrea</option>
                        <option value="EE" >Estonia</option>
                        <option value="ET" >Ethiopia</option>
                        <option value="FK" >Falkland Islands (Malvinas)</option>
                        <option value="FO" >Faroe Islands</option>
                        <option value="FJ" >Fiji</option>
                        <option value="FI" >Finland</option>
                        <option value="FR" >France</option>
                        <option value="GF" >French Guiana</option>
                        <option value="PF" >French Polynesia</option>
                        <option value="TF" >French Southern Territories</option>
                        <option value="GA" >Gabon</option>
                        <option value="GM" >Gambia, The</option>
                        <option value="GE" >Georgia</option>
                        <option value="DE" >Germany</option>
                        <option value="GH" >Ghana</option>
                        <option value="GI" >Gibraltar</option>
                        <option value="GR" >Greece</option>
                        <option value="GL" >Greenland</option>
                        <option value="GD" >Grenada</option>
                        <option value="GP" >Guadeloupe</option>
                        <option value="GU" >Guam</option>
                        <option value="GT" >Guatemala</option>
                        <option value="GG" >Guernsey</option>
                        <option value="GN" >Guinea</option>
                        <option value="GW" >Guinea-Bissau</option>
                        <option value="GY" >Guyana</option>
                        <option value="HT" >Haiti</option>
                        <option value="HM" >Heard Island and the McDonald Islands</option>
                        <option value="VA" >Holy See</option>
                        <option value="HN" >Honduras</option>
                        <option value="HK" >Hong Kong</option>
                        <option value="HU" >Hungary</option>
                        <option value="IS" >Iceland</option>
                        <option value="IN" >India</option>
                        <option value="ID" >Indonesia</option>
                        <option value="IQ" >Iraq</option>
                        <option value="IE" >Ireland</option>
                        <option value="IM" >Isle Of Man</option>
                        <option value="IL" >Israel</option>
                        <option value="IT" >Italy</option>
                        <option value="JM" >Jamaica</option>
                        <option value="JP" >Japan</option>
                        <option value="JE" >Jersey</option>
                        <option value="JO" >Jordan</option>
                        <option value="KZ" >Kazakhstan</option>
                        <option value="KE" >Kenya</option>
                        <option value="KI" >Kiribati</option>
                        <option value="KR" >Korea, Republic Of</option>
                        <option value="KW" >Kuwait</option>
                        <option value="KG" >Kyrgyzstan</option>
                        <option value="LA" >Lao People&#039;s Democratic Republic</option>
                        <option value="LV" >Latvia</option>
                        <option value="LB" >Lebanon</option>
                        <option value="LS" >Lesotho</option>
                        <option value="LR" >Liberia</option>
                        <option value="LY" >Libya</option>
                        <option value="LI" >Liechtenstein</option>
                        <option value="LT" >Lithuania</option>
                        <option value="LU" >Luxembourg</option>
                        <option value="MO" >Macao</option>
                        <option value="MK" >Macedonia, The Former Yugoslav Republic Of</option>
                        <option value="MG" >Madagascar</option>
                        <option value="MW" >Malawi</option>
                        <option value="MY" >Malaysia</option>
                        <option value="MV" >Maldives</option>
                        <option value="ML" >Mali</option>
                        <option value="MT" >Malta</option>
                        <option value="MH" >Marshall Islands</option>
                        <option value="MQ" >Martinique</option>
                        <option value="MR" >Mauritania</option>
                        <option value="MU" >Mauritius</option>
                        <option value="YT" >Mayotte</option>
                        <option value="MX" >Mexico</option>
                        <option value="FM" >Micronesia, Federated States Of</option>
                        <option value="MD" >Moldova, Republic Of</option>
                        <option value="MC" >Monaco</option>
                        <option value="MN" >Mongolia</option>
                        <option value="ME" >Montenegro</option>
                        <option value="MS" >Montserrat</option>
                        <option value="MA" >Morocco</option>
                        <option value="MZ" >Mozambique</option>
                        <option value="MM" >Myanmar</option>
                        <option value="NA" >Namibia</option>
                        <option value="NR" >Nauru</option>
                        <option value="NP" >Nepal</option>
                        <option value="NL" >Netherlands</option>
                        <option value="AN" >Netherlands Antilles</option>
                        <option value="NC" >New Caledonia</option>
                        <option value="NZ" >New Zealand</option>
                        <option value="NI" >Nicaragua</option>
                        <option value="NE" >Niger</option>
                        <option value="NG" >Nigeria</option>
                        <option value="NU" >Niue</option>
                        <option value="NF" >Norfolk Island</option>
                        <option value="MP" >Northern Mariana Islands</option>
                        <option value="NO" >Norway</option>
                        <option value="OM" >Oman</option>
                        <option value="PK" >Pakistan</option>
                        <option value="PW" >Palau</option>
                        <option value="PS" >Palestinian Territories</option>
                        <option value="PA" >Panama</option>
                        <option value="PG" >Papua New Guinea</option>
                        <option value="PY" >Paraguay</option>
                        <option value="PE" >Peru</option>
                        <option value="PH" >Philippines</option>
                        <option value="PN" >Pitcairn</option>
                        <option value="PL" >Poland</option>
                        <option value="PT" >Portugal</option>
                        <option value="PR" >Puerto Rico</option>
                        <option value="QA" >Qatar</option>
                        <option value="RE" >Reunion</option>
                        <option value="RO" >Romania</option>
                        <option value="RU" >Russian Federation</option>
                        <option value="RW" >Rwanda</option>
                        <option value="BL" >Saint Barthelemy</option>
                        <option value="SH" >Saint Helena</option>
                        <option value="KN" >Saint Kitts and Nevis</option>
                        <option value="LC" >Saint Lucia</option>
                        <option value="MF" >Saint Martin</option>
                        <option value="PM" >Saint Pierre and Miquelon</option>
                        <option value="VC" >Saint Vincent and The Grenadines</option>
                        <option value="WS" >Samoa</option>
                        <option value="SM" >San Marino</option>
                        <option value="ST" >Sao Tome and Principe</option>
                        <option value="SA" >Saudi Arabia</option>
                        <option value="SN" >Senegal</option>
                        <option value="RS" >Serbia</option>
                        <option value="SC" >Seychelles</option>
                        <option value="SL" >Sierra Leone</option>
                        <option value="SG" >Singapore</option>
                        <option value="SX" >Sint Maarten</option>
                        <option value="SK" >Slovakia</option>
                        <option value="SI" >Slovenia</option>
                        <option value="SB" >Solomon Islands</option>
                        <option value="SO" >Somalia</option>
                        <option value="ZA" >South Africa</option>
                        <option value="GS" >South Georgia and the South Sandwich Islands</option>
                        <option value="ES" >Spain</option>
                        <option value="LK" >Sri Lanka</option>
                        <option value="SR" >Suriname</option>
                        <option value="SJ" >Svalbard and Jan Mayen</option>
                        <option value="SZ" >Swaziland</option>
                        <option value="SE" >Sweden</option>
                        <option value="CH" >Switzerland</option>
                        <option value="TW" >Taiwan</option>
                        <option value="TJ" >Tajikistan</option>
                        <option value="TZ" >Tanzania, United Republic Of</option>
                        <option value="TH" >Thailand</option>
                        <option value="TL" >Timor-leste</option>
                        <option value="TG" >Togo</option>
                        <option value="TK" >Tokelau</option>
                        <option value="TO" >Tonga</option>
                        <option value="TT" >Trinidad and Tobago</option>
                        <option value="TN" >Tunisia</option>
                        <option value="TR" >Turkey</option>
                        <option value="TM" >Turkmenistan</option>
                        <option value="TC" >Turks and Caicos Islands</option>
                        <option value="TV" >Tuvalu</option>
                        <option value="UG" >Uganda</option>
                        <option value="UA" >Ukraine</option>
                        <option value="AE" >United Arab Emirates</option>
                        <option value="GB" >United Kingdom</option>
                        <option value="US" >United States</option>
                        <option value="UM" >United States Minor Outlying Islands</option>
                        <option value="UY" >Uruguay</option>
                        <option value="UZ" >Uzbekistan</option>
                        <option value="VU" >Vanuatu</option>
                        <option value="VE" >Venezuela</option>
                        <option value="VN" >Vietnam</option>
                        <option value="VG" >Virgin Islands, British</option>
                        <option value="VI" >Virgin Islands, U.S.</option>
                        <option value="WF" >Wallis and Futuna</option>
                        <option value="EH" >Western Sahara</option>
                        <option value="YE" >Yemen</option>
                        <option value="ZM" >Zambia</option>
                        <option value="ZW" >Zimbabwe</option>
                      </select>
                  </GridItem>                    
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Sales Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">Sales Agent</div>
                    <select name="salesAgent" id="salesAgent" value={this.state.contract.salesAgent || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      { (this.state.salesReps.length > 0) ? (
                        this.state.salesReps.map((salesRep, index) => (
                          <option key={index} value={salesRep.fullName}>{salesRep.fullName}</option>
                        ))                            
                        ) : (
                          <option>No available sales reps.</option>
                        )
                      } 
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">Contracts Processor</div>
                    <select name="contractsProcessor" id="contractsProcessor" value={this.state.contract.contractsProcessor || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="J.E. FRENCH COMPANY INC" >J.E. French Company Inc</option>
                      <option value="JOE NAHMAN" >Joe Nahman</option>
                      <option value="STEVE HAMBERG" >Steve Hamberg</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabelLarge">Contract Date</div>
                    <input name="contractDate" id="contractDate" value={this.state.contract.contractDate || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    <div style={{ padding: "10px" }}><button onClick={() => this.populateDate(0, 'contractDate')}>TODAY</button> <button onClick={() => this.populateDate(1, 'contractDate')}>TOMORROW</button></div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Purchase Price</div>
                    <input name="purchasePrice" id="purchasePrice" type="text" className="formInput" onBlur={(event) => this.handleBlurInput(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Deposit made by</div>
                    <select name="depositMadeBy" id="depositMadeBy" value={this.state.contract.depositMadeBy || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="CASH" >Cash</option>
                      <option value="CHECK" >Check</option>
                      <option value="CREDIT CARD" >Credit Card</option>
                      <option value="MONEY ORDER" >Money Order</option>
                      <option value="WIRE TRANSFER" >Wire Transfer</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Amount of Deposit</div>
                    <input name="depositAmount" id="depositAmount" type="text" className="formInput" onBlur={(event) => this.handleBlurInput(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Balance Due Type</div>
                    <select name="balanceDueType" id="balanceDueType" value={this.state.contract.balanceDueType || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value=" CHECK" > Check</option>
                      <option value=" CREDIT CARD" > Credit Card</option>
                      <option value="FINANCE" >Finance</option>
                      <option value=" NONE" > None</option>
                      <option value="WIRE TRANSFER" >Wire Transfer</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Balance Due</div>
                    <input readOnly name="balanceDue" id="balanceDue" value={this.state.contract.balanceDue || ''} type="text" className="formInput" onBlur={(event) => this.handleBlurInput(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabelLarge">Total Sales Price</div>
                    <input readOnly name="contractSalesPrice" id="contractSalesPrice" value={this.state.contract.contractSalesPrice || ''} type="text" className="formInput" onBlur={(event) => this.handleBlurInput(event)}></input>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
                    <Button color="success" onClick={(e) => this.createContract(e)}>Save</Button>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Panels);