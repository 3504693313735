import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import EpicMemberIcon from "@material-ui/icons/AccountCircle";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Epic_Contracts_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      epicMember: {},
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getEpicMember(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getEpicMember(epicMemberId) {
    tikiAPI.getEpicMember( epicMemberId,
      function(epicMemberResponse) {
        console.log(epicMemberResponse);
        this.setState( { epicMember: epicMemberResponse, memberCommunications: JSON.parse(epicMemberResponse.communications) });
      }.bind(this),
      function(err) {
        console.log(err);
      })
    }
  
    handleInput = event => {
        this.setState({ epicMember: {
            ...this.state.epicMember,
            [event.target.id]: event.target.value
        } });
    }

    removePlaceholder = event => {
      var cssClass = event.target.className;
      cssClass = cssClass.replace(' placeholderColor', '');
      event.target.className = cssClass;
    }

    updateEpicMember() {

        tikiAPI.updateEpicMember(this.state.epicMember,
          function(response) {
            window.location = '/admin/epic-members-detail/' + this.state.epicMember.memberId;
        }.bind(this),
        function(err) {
            console.log(err);
          }
        );
    
      }
  
  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Epic_Members_Detail" yes={() => (

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"><EpicMemberIcon /></CardIcon>
              <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Edit Epic Member</h4>
            </CardHeader>
              <CardBody>

                <GridContainer>
                    <GridItem xs={12}><div className="softDivider">Member Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                        <div className="displayLabel">First Name</div>
                        <div><input id="firstName" value={this.state.epicMember.firstName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="First Name"></input></div>
                    </GridItem>

                    <GridItem xs={12} sm={8} md={2}>
                        <div className="displayLabel">Middle Name</div>
                        <div><input id="middleName" value={this.state.epicMember.middleName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Middle Name"></input></div>
                    </GridItem>

                    <GridItem xs={12} sm={8} md={2}>
                        <div className="displayLabel">Last Name</div>
                        <div><input id="lastName" value={this.state.epicMember.lastName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Last Name"></input></div>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                        <div>
                          <div className="displayLabel">Member #</div>
                          <div className="displayContent">{this.state.epicMember.memberId}</div>
                        </div>
                    </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">RCI #</div>
                      <div><input id="rciMemberNumber" value={this.state.epicMember.rciMemberNumber || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="RCI #"></input></div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">SoR #</div>
                      <div><input id="sorLink" value={this.state.epicMember.sorLink || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="SoR #"></input></div>
                    </div>
                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Address</div>
                      <div><input id="address" value={this.state.epicMember.address || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Address"></input></div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">City</div>
                      <div><input id="city" value={this.state.epicMember.city || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="City"></input></div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">State</div>
                      <div><input id="state" value={this.state.epicMember.state || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="State"></input></div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Zip Code</div>
                      <div><input id="zipcode" value={this.state.epicMember.zipcode || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Zip Code"></input></div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Country</div>
                      <div><input id="country" value={this.state.epicMember.country || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Country"></input></div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>

                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Mobile Phone</div>
                      <div><input id="mobilePhone" value={this.state.epicMember.mobilePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Mobile Phone"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Home Phone</div>
                      <div><input id="homePhone" value={this.state.epicMember.homePhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Home Phone"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Work Phone</div>
                      <div><input id="workPhone" value={this.state.epicMember.workPhone || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Work Phone"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Email</div>
                      <div><input id="email" value={this.state.epicMember.email || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Email"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Website Username</div>
                      <div><input id="username" value={this.state.epicMember.username || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Username"></input></div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Epic Vacations Membership</div></GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Status</div>
                      <div><input id="memberStatus" value={this.state.epicMember.memberStatus || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Membership Status"></input></div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Tier</div>
                      <div className="displayContent">{this.state.epicMember.membershipTier}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Term</div>
                      <div className="displayContent">{this.state.epicMember.membershipTerm}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                   <div>
                      <div className="displayLabel">Membership Start</div>
                      <div className="displayContent">{moment(this.state.epicMember.membershipStartDate).format('MM/DD/YYYY')}</div>
                    </div>
                   </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Membership Expires</div>
                      <div className="displayContent">{moment(this.state.epicMember.membershipExpDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={2}>
                    <div>
                      <div className="displayLabel">Contract #</div>
                      <div className="displayContent">{this.state.epicMember.epicContractId}</div>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">&nbsp;</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <div align="center"><Button color="success" onClick={(e) => this.updateEpicMember()}>Update</Button></div>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

      </GridContainer>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Epic_Contracts_Detail);