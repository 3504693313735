import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import { TikiAPI } from "../../global";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

// react router components
import { Link } from 'react-router-dom'

import "assets/main.css";

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      employees: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllEmployees();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  getAllEmployees() {

    tikiAPI.getAllEmployees(
      function(data) { 
        this.setState({ employees: data })
    }.bind(this),
      function(error) {
        console.log(error);
      }
    );

  }

  newEmployee() {
    window.location = '/admin/employees-new/';
}

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Employees_Main" yes={() => (

      <div>

        <GridContainer>

        <GridItem xs={1}>&nbsp;</GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <AssignmentIndIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Employees</h4>
              </CardHeader>

              <CardBody>

                <Can permissions={this.state.user.permissions} perform="Employees_New" yes={() => (
                    <GridContainer>
                      <GridItem xs={12}><div align="center"><Button color="success" onClick={(e) => this.newEmployee()}>New Employee</Button></div></GridItem>
                    </GridContainer>
                  )} />

                <table cellPadding="5" cellSpacing="0" width="100%">
                  <thead>
                    <tr>
                    <th>Employee Name</th>
                    <th>Status</th>
                    <th>Sr. Sales Mgr</th>
                    <th>Sales Manager</th>
                    <th>Sales Rep</th>
                    <th>TO Rep</th>
                    <th>Marketing Mgr</th>
                    <th>Marketing Rep</th>
                    </tr>
                  </thead>

                  <tbody>
                  {this.state.employees.map((employee, index) => (
                    <tr key={index}>
                      <td><a href={`/admin/employees-detail/${employee.employeeId}`}>{ employee.fullName }</a></td>
                      <td>{ employee.employeeStatus }</td>
                      <td>{ employee.isSeniorSalesManager }</td>
                      <td>{ employee.isSalesManager }</td>
                      <td>{ employee.isSalesRep }</td>
                      <td>{ employee.isToRep }</td>
                      <td>{ employee.isMarketingManager }</td>
                      <td>{ employee.isMarketingRep }</td>
                    </tr>
                  ))}
                  </tbody>
                </table>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      ) } />
);
  }
}

export default withStyles(styles)(Panels);