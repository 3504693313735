import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";

import LeadsIcon from "@material-ui/icons/AssignmentInd";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Leads_New extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      lead: {},
      interest: {},
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  createNewLeadWithInterest(lead, interest) {
    lead.LeadStatus = "New";

    tikiAPI.createNewLead(lead,
      function(leadResponse) {
        // Assign the newly created leadId to the interest data before creating
        interest.leadId = leadResponse.leadId;

        tikiAPI.createNewInterest(interest,
          function(interestData) {
            window.location = '/admin/leads-detail/' + leadResponse.leadId;
          },
          function(err) {
            console.log(err);
          }
        );

      },
      function(err) {
        console.log(err);
      }
    );

  }

  handleInputForLead = event => {
    this.setState({ lead: {
      ...this.state.lead,
      [event.target.id]: event.target.value
    } });
  }

  smsOptIn = event => {
    this.setState({ lead: {
      ...this.state.lead,
      smsOptIn: event.target.checked
    } });
  }

  handleInputForInterest = event => {
    this.setState({ interest: {
      ...this.state.interest,
      [event.target.id]: event.target.value
    } });
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Leads_New" yes={() => (

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"><LeadsIcon /></CardIcon>
              <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>New Lead</h4>
            </CardHeader>
              <CardBody>

              <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Main Contact</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={4}><div className="inputLabel">First Name</div><input id="firstName" value={this.state.lead.firstName || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="First Name"></input></GridItem>
                  <GridItem xs={12} sm={8} md={4}><div className="inputLabel">Last Name</div><input id="lastName" value={this.state.lead.lastName || ''} onChange={(event) => this.handleInputForLead(event)}className="formInput" type="text" placeholder="Last Name"></input></GridItem>              
                  <GridItem xs={12} sm={8} md={4}><div className="inputLabel">Email</div><input id="email" value={this.state.lead.email || ''} onChange={(event) => this.handleInputForLead(event)}className="formInput" type="text" placeholder="Email"></input></GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <div className="inputLabel">Mobile Phone</div>
                    <input id="mobilePhone" value={this.state.lead.mobilePhone || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Mobile Phone"></input>
                    <Can permissions={this.state.user.permissions} perform="Other_Beta" yes={() => (
                      <div><br />&nbsp;<input id="smsOptIn" onChange={(event) => this.smsOptIn(event)} type="checkbox" /> Opt-in to receive text messages</div>
                    )} />
                  </GridItem>
                  <GridItem xs={12} sm={4}><div className="inputLabel">Home Phone</div><input id="homePhone" value={this.state.lead.homePhone || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Home Phone"></input></GridItem>              
                  <GridItem xs={12} sm={4}><div className="inputLabel">Work Phone</div><input id="workPhone" value={this.state.lead.workPhone || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Work Phone"></input></GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Spouse</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={8} md={4}><div className="inputLabel">Spouse First Name</div><input id="spouseFirstName" value={this.state.lead.spouseFirstName || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="First Name (Spouse)"></input></GridItem>
                  <GridItem xs={12} sm={8} md={4}><div className="inputLabel">Spouse Last Name</div><input id="spouseLastName" value={this.state.lead.spouseLastName || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Last Name (Spouse)"></input></GridItem>              
                  <GridItem xs={12} sm={8} md={4}><div className="inputLabel">Spouse Email</div><input id="spouseEmail" value={this.state.lead.spouseEmail || ''} onChange={(event) => this.handleInputForLead(event)}className="formInput" type="text" placeholder="Email (Spouse)"></input></GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={4}><div className="inputLabel">Spouse Mobile Phone</div><input id="spouseMobilePhone" value={this.state.lead.spouseMobilePhone || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Mobile Phone (Spouse)"></input></GridItem>
                  <GridItem xs={12} sm={4}><div className="inputLabel">Spouse Home Phone</div><input id="spouseHomePhone" value={this.state.lead.spouseHomePhone || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Home Phone (Spouse)"></input></GridItem>              
                  <GridItem xs={12} sm={4}><div className="inputLabel">Spouse Work Phone</div><input id="spouseWorkPhone" value={this.state.lead.spouseWorkPhone || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Work Phone (Spouse)"></input></GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Address</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={6}><div className="inputLabel">Address 1</div><input id="address1" value={this.state.lead.address1 || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Address"></input></GridItem>
                  <GridItem xs={6}><div className="inputLabel">Address 2</div><input id="address2" value={this.state.lead.address2 || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Address (Line 2)"></input></GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={5}><div className="inputLabel">City</div><input id="city" value={this.state.lead.city || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="City"></input></GridItem>
                  <GridItem xs={2}><div className="inputLabel">State / Province</div>
                    <select id="state" value={this.state.lead.state || ''} onChange={(event) => this.handleInputForLead(event)} className="formSelect">
                      <option value="">State / Province</option>
                      <option value="AL" >Alabama</option>
                      <option value="AK" >Alaska</option>
                      <option value="AR" >Arkansas</option>
                      <option value="AZ" >Azizona</option>
                      <option value="CA" >California</option>
                      <option value="CO" >Colorado</option>
                      <option value="CT" >Connecticut</option>
                      <option value="DE" >Delaware</option>
                      <option value="FL" >Florida</option>
                      <option value="GA" >Georgia</option>
                      <option value="HI" >Hawaii</option>
                      <option value="ID" >Idaho</option>
                      <option value="IL" >Illinois</option>
                      <option value="IN" >Indiana</option>
                      <option value="IA" >Iowa</option>
                      <option value="KS" >Kansas</option>
                      <option value="KY" >Kentucky</option>
                      <option value="LA" >Louisiana</option>
                      <option value="ME" >Maine</option>
                      <option value="MD" >Maryland</option>
                      <option value="MA" >Massachusetts</option>
                      <option value="MI" >Michigan</option>
                      <option value="MN" >Minnesota</option>
                      <option value="MS" >Mississippi</option>
                      <option value="MO" >Missouri</option>
                      <option value="MT" >Montana</option>
                      <option value="NE" >Nebraska</option>
                      <option value="NV" >Nevada</option>
                      <option value="NH" >New Hampshire</option>
                      <option value="NJ" >New Jersey</option>
                      <option value="NM" >New Mexico</option>
                      <option value="NY" >New York</option>
                      <option value="NC" >North Carolina</option>
                      <option value="ND" >North Dakota</option>
                      <option value="OH" >Ohio</option>
                      <option value="OK" >Oklahoma</option>
                      <option value="OR" >Oregon</option>
                      <option value="PA" >Pennsylvania</option>
                      <option value="RI" >Rhode Island</option>
                      <option value="SC" >South Carolina</option>
                      <option value="SD" >South Dakota</option>
                      <option value="TN" >Tennessee</option>
                      <option value="TX" >Texas</option>
                      <option value="UT" >Utah</option>
                      <option value="VT" >Vermont</option>
                      <option value="VA" >Virginia</option>
                      <option value="WA" >Washington</option>
                      <option value="DC" >Washington D.C.</option>
                      <option value="WV" >West Virginia</option>
                      <option value="WI" >Wisconsin</option>
                      <option value="WY" >Wyoming</option>
                      <option value="AB" >Alberta</option>
                      <option value="BC" >British Columbia</option>
                      <option value="MB" >Manitoba</option>
                      <option value="NB" >New Brunswick</option>
                      <option value="NL" >Newfoundland</option>
                      <option value="NT" >Northwest Territories</option>
                      <option value="NS" >Nova Scotia</option>
                      <option value="NU" >Nunavut</option>
                      <option value="ON" >Ontario</option>
                      <option value="PE" >Prince Edward Island</option>
                      <option value="QC" >Quebec</option>
                      <option value="SK" >Saskatchewan</option>
                      <option value="YT" >Yukon</option>
                    </select>
                  </GridItem>              
                  <GridItem xs={2}><div className="inputLabel">Zip Code</div><input id="zipcode" value={this.state.lead.zipcode || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Zip Code"></input></GridItem>              
                  <GridItem xs={3}><div className="inputLabel">Country</div>
                    <select id="country" value={this.state.lead.country || ''} onChange={(event) => this.handleInputForLead(event)} className="formSelect">
                      <option value="US">United States</option>
                      <option value="CA" >Canada</option>
                      <option value="AF" >Afghanistan</option>
                      <option value="AX" >Aland Islands</option>
                      <option value="AL" >Albania</option>
                      <option value="DZ" >Algeria</option>
                      <option value="AS" >American Samoa</option>
                      <option value="AD" >Andorra</option>
                      <option value="AO" >Angola</option>
                      <option value="AI" >Anguilla</option>
                      <option value="AQ" >Antarctica</option>
                      <option value="AG" >Antigua and Barbuda</option>
                      <option value="AR" >Argentina</option>
                      <option value="AM" >Armenia</option>
                      <option value="AW" >Aruba</option>
                      <option value="AU" >Australia</option>
                      <option value="AT" >Austria</option>
                      <option value="AZ" >Azerbaijan</option>
                      <option value="BS" >Bahamas, The</option>
                      <option value="BH" >Bahrain</option>
                      <option value="BD" >Bangladesh</option>
                      <option value="BB" >Barbados</option>
                      <option value="BY" >Belarus</option>
                      <option value="BE" >Belgium</option>
                      <option value="BZ" >Belize</option>
                      <option value="BJ" >Benin</option>
                      <option value="BM" >Bermuda</option>
                      <option value="BT" >Bhutan</option>
                      <option value="BO" >Bolivia</option>
                      <option value="BQ" >Bonaire, Saint Eustatius and Saba</option>
                      <option value="BA" >Bosnia and Herzegovina</option>
                      <option value="BW" >Botswana</option>
                      <option value="BV" >Bouvet Island</option>
                      <option value="BR" >Brazil</option>
                      <option value="IO" >British Indian Ocean Territory</option>
                      <option value="BN" >Brunei Darussalam</option>
                      <option value="BG" >Bulgaria</option>
                      <option value="BF" >Burkina Faso</option>
                      <option value="BI" >Burundi</option>
                      <option value="KH" >Cambodia</option>
                      <option value="CM" >Cameroon</option>
                      <option value="CV" >Cape Verde</option>
                      <option value="KY" >Cayman Islands</option>
                      <option value="CF" >Central African Republic</option>
                      <option value="TD" >Chad</option>
                      <option value="CL" >Chile</option>
                      <option value="CN" >China</option>
                      <option value="CX" >Christmas Island</option>
                      <option value="CC" >Cocos (Keeling) Islands</option>
                      <option value="CO" >Colombia</option>
                      <option value="KM" >Comoros</option>
                      <option value="CG" >Congo</option>
                      <option value="CD" >Congo, The Democratic Republic Of The</option>
                      <option value="CK" >Cook Islands</option>
                      <option value="CR" >Costa Rica</option>
                      <option value="CI" >Cote D&#039;ivoire</option>
                      <option value="HR" >Croatia</option>
                      <option value="CW" >Curaçao</option>
                      <option value="CY" >Cyprus</option>
                      <option value="CZ" >Czech Republic</option>
                      <option value="DK" >Denmark</option>
                      <option value="DJ" >Djibouti</option>
                      <option value="DM" >Dominica</option>
                      <option value="DO" >Dominican Republic</option>
                      <option value="EC" >Ecuador</option>
                      <option value="EG" >Egypt</option>
                      <option value="SV" >El Salvador</option>
                      <option value="GQ" >Equatorial Guinea</option>
                      <option value="ER" >Eritrea</option>
                      <option value="EE" >Estonia</option>
                      <option value="ET" >Ethiopia</option>
                      <option value="FK" >Falkland Islands (Malvinas)</option>
                      <option value="FO" >Faroe Islands</option>
                      <option value="FJ" >Fiji</option>
                      <option value="FI" >Finland</option>
                      <option value="FR" >France</option>
                      <option value="GF" >French Guiana</option>
                      <option value="PF" >French Polynesia</option>
                      <option value="TF" >French Southern Territories</option>
                      <option value="GA" >Gabon</option>
                      <option value="GM" >Gambia, The</option>
                      <option value="GE" >Georgia</option>
                      <option value="DE" >Germany</option>
                      <option value="GH" >Ghana</option>
                      <option value="GI" >Gibraltar</option>
                      <option value="GR" >Greece</option>
                      <option value="GL" >Greenland</option>
                      <option value="GD" >Grenada</option>
                      <option value="GP" >Guadeloupe</option>
                      <option value="GU" >Guam</option>
                      <option value="GT" >Guatemala</option>
                      <option value="GG" >Guernsey</option>
                      <option value="GN" >Guinea</option>
                      <option value="GW" >Guinea-Bissau</option>
                      <option value="GY" >Guyana</option>
                      <option value="HT" >Haiti</option>
                      <option value="HM" >Heard Island and the McDonald Islands</option>
                      <option value="VA" >Holy See</option>
                      <option value="HN" >Honduras</option>
                      <option value="HK" >Hong Kong</option>
                      <option value="HU" >Hungary</option>
                      <option value="IS" >Iceland</option>
                      <option value="IN" >India</option>
                      <option value="ID" >Indonesia</option>
                      <option value="IQ" >Iraq</option>
                      <option value="IE" >Ireland</option>
                      <option value="IM" >Isle Of Man</option>
                      <option value="IL" >Israel</option>
                      <option value="IT" >Italy</option>
                      <option value="JM" >Jamaica</option>
                      <option value="JP" >Japan</option>
                      <option value="JE" >Jersey</option>
                      <option value="JO" >Jordan</option>
                      <option value="KZ" >Kazakhstan</option>
                      <option value="KE" >Kenya</option>
                      <option value="KI" >Kiribati</option>
                      <option value="KR" >Korea, Republic Of</option>
                      <option value="KW" >Kuwait</option>
                      <option value="KG" >Kyrgyzstan</option>
                      <option value="LA" >Lao People&#039;s Democratic Republic</option>
                      <option value="LV" >Latvia</option>
                      <option value="LB" >Lebanon</option>
                      <option value="LS" >Lesotho</option>
                      <option value="LR" >Liberia</option>
                      <option value="LY" >Libya</option>
                      <option value="LI" >Liechtenstein</option>
                      <option value="LT" >Lithuania</option>
                      <option value="LU" >Luxembourg</option>
                      <option value="MO" >Macao</option>
                      <option value="MK" >Macedonia, The Former Yugoslav Republic Of</option>
                      <option value="MG" >Madagascar</option>
                      <option value="MW" >Malawi</option>
                      <option value="MY" >Malaysia</option>
                      <option value="MV" >Maldives</option>
                      <option value="ML" >Mali</option>
                      <option value="MT" >Malta</option>
                      <option value="MH" >Marshall Islands</option>
                      <option value="MQ" >Martinique</option>
                      <option value="MR" >Mauritania</option>
                      <option value="MU" >Mauritius</option>
                      <option value="YT" >Mayotte</option>
                      <option value="MX" >Mexico</option>
                      <option value="FM" >Micronesia, Federated States Of</option>
                      <option value="MD" >Moldova, Republic Of</option>
                      <option value="MC" >Monaco</option>
                      <option value="MN" >Mongolia</option>
                      <option value="ME" >Montenegro</option>
                      <option value="MS" >Montserrat</option>
                      <option value="MA" >Morocco</option>
                      <option value="MZ" >Mozambique</option>
                      <option value="MM" >Myanmar</option>
                      <option value="NA" >Namibia</option>
                      <option value="NR" >Nauru</option>
                      <option value="NP" >Nepal</option>
                      <option value="NL" >Netherlands</option>
                      <option value="AN" >Netherlands Antilles</option>
                      <option value="NC" >New Caledonia</option>
                      <option value="NZ" >New Zealand</option>
                      <option value="NI" >Nicaragua</option>
                      <option value="NE" >Niger</option>
                      <option value="NG" >Nigeria</option>
                      <option value="NU" >Niue</option>
                      <option value="NF" >Norfolk Island</option>
                      <option value="MP" >Northern Mariana Islands</option>
                      <option value="NO" >Norway</option>
                      <option value="OM" >Oman</option>
                      <option value="PK" >Pakistan</option>
                      <option value="PW" >Palau</option>
                      <option value="PS" >Palestinian Territories</option>
                      <option value="PA" >Panama</option>
                      <option value="PG" >Papua New Guinea</option>
                      <option value="PY" >Paraguay</option>
                      <option value="PE" >Peru</option>
                      <option value="PH" >Philippines</option>
                      <option value="PN" >Pitcairn</option>
                      <option value="PL" >Poland</option>
                      <option value="PT" >Portugal</option>
                      <option value="PR" >Puerto Rico</option>
                      <option value="QA" >Qatar</option>
                      <option value="RE" >Reunion</option>
                      <option value="RO" >Romania</option>
                      <option value="RU" >Russian Federation</option>
                      <option value="RW" >Rwanda</option>
                      <option value="BL" >Saint Barthelemy</option>
                      <option value="SH" >Saint Helena</option>
                      <option value="KN" >Saint Kitts and Nevis</option>
                      <option value="LC" >Saint Lucia</option>
                      <option value="MF" >Saint Martin</option>
                      <option value="PM" >Saint Pierre and Miquelon</option>
                      <option value="VC" >Saint Vincent and The Grenadines</option>
                      <option value="WS" >Samoa</option>
                      <option value="SM" >San Marino</option>
                      <option value="ST" >Sao Tome and Principe</option>
                      <option value="SA" >Saudi Arabia</option>
                      <option value="SN" >Senegal</option>
                      <option value="RS" >Serbia</option>
                      <option value="SC" >Seychelles</option>
                      <option value="SL" >Sierra Leone</option>
                      <option value="SG" >Singapore</option>
                      <option value="SX" >Sint Maarten</option>
                      <option value="SK" >Slovakia</option>
                      <option value="SI" >Slovenia</option>
                      <option value="SB" >Solomon Islands</option>
                      <option value="SO" >Somalia</option>
                      <option value="ZA" >South Africa</option>
                      <option value="GS" >South Georgia and the South Sandwich Islands</option>
                      <option value="ES" >Spain</option>
                      <option value="LK" >Sri Lanka</option>
                      <option value="SR" >Suriname</option>
                      <option value="SJ" >Svalbard and Jan Mayen</option>
                      <option value="SZ" >Swaziland</option>
                      <option value="SE" >Sweden</option>
                      <option value="CH" >Switzerland</option>
                      <option value="TW" >Taiwan</option>
                      <option value="TJ" >Tajikistan</option>
                      <option value="TZ" >Tanzania, United Republic Of</option>
                      <option value="TH" >Thailand</option>
                      <option value="TL" >Timor-leste</option>
                      <option value="TG" >Togo</option>
                      <option value="TK" >Tokelau</option>
                      <option value="TO" >Tonga</option>
                      <option value="TT" >Trinidad and Tobago</option>
                      <option value="TN" >Tunisia</option>
                      <option value="TR" >Turkey</option>
                      <option value="TM" >Turkmenistan</option>
                      <option value="TC" >Turks and Caicos Islands</option>
                      <option value="TV" >Tuvalu</option>
                      <option value="UG" >Uganda</option>
                      <option value="UA" >Ukraine</option>
                      <option value="AE" >United Arab Emirates</option>
                      <option value="GB" >United Kingdom</option>
                      <option value="US" >United States</option>
                      <option value="UM" >United States Minor Outlying Islands</option>
                      <option value="UY" >Uruguay</option>
                      <option value="UZ" >Uzbekistan</option>
                      <option value="VU" >Vanuatu</option>
                      <option value="VE" >Venezuela</option>
                      <option value="VN" >Vietnam</option>
                      <option value="VG" >Virgin Islands, British</option>
                      <option value="VI" >Virgin Islands, U.S.</option>
                      <option value="WF" >Wallis and Futuna</option>
                      <option value="EH" >Western Sahara</option>
                      <option value="YE" >Yemen</option>
                      <option value="ZM" >Zambia</option>
                      <option value="ZW" >Zimbabwe</option>
                    </select>
                  </GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Demographic Information</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}><div className="inputLabel">Age</div><input id="age" value={this.state.lead.age || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Age"></input></GridItem>
                  <GridItem xs={2}><div className="inputLabel">Credit Score</div><input id="creditScore" value={this.state.lead.creditScore || ''} onChange={(event) => this.handleInputForLead(event)} className="formInput" type="text" placeholder="Credit Score"></input></GridItem>              
                  <GridItem xs={2}><div className="inputLabel">Income Tier</div>
                    <select id="incomeTier" value={this.state.lead.incomeTier || ''} onChange={(event) => this.handleInputForLead(event)} className="formSelect">
                    <option value="">Income</option>
                    <option value="50000">$50,000</option>
                    <option value="60000">$60,000</option>
                    <option value="70000">$70,000</option>
                    <option value="80000">$80,000</option>
                    <option value="90000">$90,000</option>
                    <option value="100000">$100,000</option>
                    <option value="110000">$110,000</option>
                    <option value="120000">$120,000</option>
                    <option value="130000">$130,000</option>
                    <option value="140000">$140,000</option>
                    <option value="150000">$150,000</option>
                    </select> 
                  </GridItem>              
                  <GridItem xs={3}><div className="inputLabel">Marital Status</div>
                    <select id="maritalStatus" value={this.state.lead.maritalStatus || ''} onChange={(event) => this.handleInputForLead(event)} className="formSelect">
                    <option value="">Marital Status</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                    </select> 
                  </GridItem>              
                  <GridItem xs={3}><div className="inputLabel">Occupation</div>
                    <select id="occupation" value={this.state.lead.occupation || ''} onChange={(event) => this.handleInputForLead(event)} className="formSelect">
                      <option value="">Occupation</option>
                      <option value="Accountant">Accountant</option>
                      <option value="Administrative">Administrative</option>
                      <option value="Construction">Construction</option>
                      <option value="Customer Service">Customer Service</option>
                      <option value="Financial Services">Financial Services</option>
                      <option value="Food Services">Food Services</option>
                      <option value="General Labor">General Labor</option>
                      <option value="Hospitality">Hospitality</option>
                      <option value="Homemaker">Homemaker</option>
                      <option value="Human Resources">Human Resources</option>
                      <option value="Management">Management</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Medical Professional">Medical Professional</option>
                      <option value="Military">Military</option>
                      <option value="Professional Services">Professional Services</option>
                      <option value="Public Service">Public Service</option>
                      <option value="Retail">Retail</option>
                      <option value="Retired">Retired</option>
                      <option value="Salesperson">Salesperson</option>
                      <option value="Self-employed">Self-employed</option>
                      <option value="Student">Student</option>
                      <option value="Technology Professional">Technology Professional</option>
                      <option value="Unemployed">Unemployed</option>
                      <option value="Warehouse">Warehouse</option>
                      <option value="Other">Other</option>
                    </select>  
                  </GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Marketing Information</div></GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={3}><div className="inputLabel">Guest Vacation Location</div>
                    <select id="guestVacationLocation" value={this.state.lead.guestVacationLocation || ''} onChange={(event) => this.handleInputForLead(event)} className="formSelect">
                      <option value="">Vacation Location</option>
                      <option value="Ocean Cove">Ocean Cove</option>
                      <option value="Palmetto Dunes">Palmetto Dunes</option>
                      <option value="Sands Village">Sands Village</option>
                      <option value="Shipyard">Shipyard</option>
                      <option value="Spinnaker">Spinnaker</option>
                      <option value="Southwind II">Southwind II</option>
                      <option value="Village at Palmetto Dunes">Village at Palmetto Dunes</option>
                      <option value="Marriott">Marriott</option>
                      <option value="Players Club">The Players Club</option>
                      <option value="-">- - - - - - - - -</option>
                      <option value="Bluewater Resort and Marina">Bluewater Resort and Marina</option>
                      <option value="Brigantine Quarters">Brigantine Quarters</option>
                      <option value="Coral Reef Resort">Coral Reef Resort</option>
                      <option value="Coral Resorts Group">Coral Resorts Group</option>
                      <option value="Coral Sands Resort">Coral Sands Resort</option>
                      <option value="Cottages at Shipyard">Cottages at Shipyard</option>
                      <option value="Disney's Hilton Head Island Resort">Disney's Hilton Head Island Resort</option>
                      <option value="Egret Point by Spinnaker">Egret Point by Spinnaker</option>
                      <option value="Fiddles Cove">Fiddles Cove</option>
                      <option value="Harbour Club at Harbour Town">Harbour Club at Harbour Town</option>
                      <option value="Harbour Town Yacht Club">Harbour Town Yacht Club</option>
                      <option value="Harbourside III">Harbourside III</option>
                      <option value="Heritage Club at Harbour Town">Heritage Club at Harbour Town</option>
                      <option value="Hilton Head Island Beach and Tennis Resort">Hilton Head Island Beach and Tennis Resort</option>
                      <option value="Island Club IV">Island Club IV</option>
                      <option value="Island Links Resort">Island Links Resort</option>
                      <option value="Island Links by Coral Resorts">Island Links by Coral Resorts</option>
                      <option value="Ketch Court Resort">Ketch Court Resort</option>
                      <option value="Kingston Cove">Kingston Cove</option>
                      <option value="Main Street Inn and Spa">Main Street Inn and Spa</option>
                      <option value="Marriott's Barony Beach Club">Marriott's Barony Beach Club</option>
                      <option value="Marriott's Grande Ocean">Marriott's Grande Ocean</option>
                      <option value="Marriott's Harbour Club">Marriott's Harbour Club</option>
                      <option value="Marriott's Harbour Point at Shelter Cove">Marriott's Harbour Point at Shelter Cove</option>
                      <option value="Marriott's Heritage Club">Marriott's Heritage Club</option>
                      <option value="Marriott's Monarch">Marriott's Monarch</option>
                      <option value="Marriott's Sunset Pointe">Marriott's Sunset Pointe</option>
                      <option value="Marriott's SurfWatch">Marriott's SurfWatch</option>
                      <option value="Night Heron Loft">Night Heron Loft</option>
                      <option value="Ocean Dunes">Ocean Dunes</option>
                      <option value="Ocean Oak Resort by Hilton Grand Vacations Club">Ocean Oak Resort by Hilton Grand Vacations Club</option>
                      <option value="Ocean Palms Resort at Port Royal">Ocean Palms Resort at Port Royal</option>
                      <option value="Ocean View at Island Club">Ocean View at Island Club</option>
                      <option value="Owners Club at Hilton Head">Owners Club at Hilton Head</option>
                      <option value="Plantation Club Villas">Plantation Club Villas</option>
                      <option value="Port O' Call">Port O' Call</option>
                      <option value="Port Villas">Port Villas</option>
                      <option value="Racquet Club Villas">Racquet Club Villas</option>
                      <option value="Royal Dunes">Royal Dunes</option>
                      <option value="Royal Dunes Beach Villas at Port Royal">Royal Dunes Beach Villas at Port Royal</option>
                      <option value="Sand Shares Resort">Sand Shares Resort</option>
                      <option value="Sea Crest Surf and Racquet Club">Sea Crest Surf and Racquet Club</option>
                      <option value="Sea Pines Plantation">Sea Pines Plantation</option>
                      <option value="Sea Pines Resort">Sea Pines Resort</option>
                      <option value="Seawatch at Island Club">Seawatch at Island Club</option>
                      <option value="Southwind II">Southwind II</option>
                      <option value="Southwind Villas">Southwind Villas</option>
                      <option value="Southwind's Carolina Club - Holiday Network">Southwind's Carolina Club - Holiday Network</option>
                      <option value="Spicebush at Sea Pines">Spicebush at Sea Pines</option>
                      <option value="Springwood">Springwood</option>
                      <option value="Spinnaker at the Carolina Club">Spinnaker at the Carolina Club</option>
                      <option value="Spinnaker Blue Water">Spinnaker Blue Water</option>
                      <option value="Swallowtail at Sea Pines Resort">Swallowtail at Sea Pines Resort</option>
                      <option value="Twin Oaks Villas">Twin Oaks Villas</option>
                      <option value="Vacation Time of Hilton Head Island">Vacation Time of Hilton Head Island</option>
                      <option value="Waterside by Spinnaker">Waterside by Spinnaker</option>
                      <option value="-">- - - - - - - - -</option>
                      <option value="Other">Other</option>
                    </select>                  
                  </GridItem>
                  <GridItem xs={3}><div className="inputLabel">Marketing Source</div>
                    <select id="marketingSource" value={this.state.lead.marketingSource ||''} onChange={(event) => this.handleInputForLead(event)} className="formSelect">
                      <option value="">Marketing Source</option>
                      <option value="RMC Check-In">RMC Check-In</option>
                      <option value="Spinnaker Wine and Cheese">Spinnaker Wine and Cheese</option>
                      <option value="Spinnaker Breakfast">Spinnaker Breakfast</option>
                      <option value="Spinnaker Check-In">Spinnaker Check-In</option>
                      <option value="Village Check-In">Village Check-In</option>
                      <option value="Village Wine and Cheese">Village Wine and Cheese</option>
                      <option value="Village Breakfast">Village Breakfast</option>
                      <option value="Southwind II Wine and Cheese">Southwind II Wine and Cheese</option>
                      <option value="Southwind II Check-In">Southwind II Check-In</option>
                      <option value="Sea Crest Check-In">Sea Crest Check-In</option>
                      <option value="Sea Crest Wine and Cheese">Sea Crest Wine and Cheese</option>
                      <option value="Sea Crest Breakfast">Sea Crest Breakfast</option>
                      <option value="Seawatch Check-In">Seawatch Check-In</option>
                      <option value="Seawatch Wine and Cheese">Seawatch Wine and Cheese</option>
                      <option value="Seawatch Breakfast">Seawatch Breakfast</option>
                      <option value="The Players Club Check-In">The Players Club Check-In</option>
                      <option value="The Players Club Wine and Cheese">The Players Club Wine and Cheese</option>
                      <option value="The Players Club Breakfast">The Players Club Breakfast</option>
                      <option value="Sands Village Check-In">Sands Village Check-In</option>
                      <option value="Ocean Cove Check-In">Ocean Cove Check-In</option>
                      <option value="Ocean Cove Wine and Cheese">Ocean Cove Wine and Cheese</option>
                      <option value="Springwood Check-In">Springwood Check-In</option>
                      <option value="Marriott's Grande Ocean Wine and Cheese">Marriott's Grande Ocean Wine and Cheese</option>
                      <option value="Ocean View Wine and Cheese">Ocean View Wine and Cheese</option>
                      <option value="Sands Village Wine and Cheese">Sands Village Wine and Cheese</option>
                      <option value="Flyer">Flyer</option>
                      <option value="Flynn">Flynn</option>
                      <option value="Invitation">Invitation</option>
                      <option value="Mimosas">Mimosas</option>
                      <option value="Mailout">Mailout</option>
                      <option value="Mini">Mini</option>
                      <option value="Telemarketing">Telemarketing</option>
                      <option value="OPC">OPC</option>
                      <option value="Walk-In">Walk-In</option>
                      <option value="Call In">Call In</option>
                      <option value="Brochure">Brochure</option>
                      <option value="Be Back">Be Back</option>
                      <option value="Referral">Referral</option>
                      <option value="Pool">Pool</option>
                      <option value="RMC Staff">RMC Staff</option>
                      <option value="GMG">GMG</option>
                      <option value="Digital Science">Digital Science</option>
                      <option value="Black Jet">Black Jet</option>
                      <option value="Allen Marketing Group (AMG)">Allen Marketing Group (AMG)</option>
                      <option value="Other"></option>
                    </select>
                  </GridItem>
                  <GridItem xs={3}><div className="inputLabel">Marketing Campaign</div><input className="formInput" type="text" placeholder="Marketing Campaign"></input></GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="softDivider">Vacation Interests</div></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4}><div className="inputLabel">Resort</div>
                    <select id="resort" value={this.state.interest.resort || ''} onChange={(event) => this.handleInputForInterest(event)} className="formSelect">
                      <option value="">Resort</option>
                      <option value="Ocean Cove">Ocean Cove</option>
                      <option value="Palmetto Dunes">Palmetto Dunes</option>
                      <option value="Sands Village">Sands Village</option>
                      <option value="Shipyard">Shipyard</option>
                      <option value="Spinnaker">Spinnaker</option>
                      <option value="Southwind II">Southwind II</option>
                      <option value="Village at Palmetto Dunes">Village at Palmetto Dunes</option>
                      <option value="Marriott">Marriott</option>
                      <option value="Players Club">The Players Club</option>
                      <option value="-">- - - - - - - - -</option>
                      <option value="Bluewater Resort and Marina">Bluewater Resort and Marina</option>
                      <option value="Brigantine Quarters">Brigantine Quarters</option>
                      <option value="Coral Reef Resort">Coral Reef Resort</option>
                      <option value="Coral Resorts Group">Coral Resorts Group</option>
                      <option value="Coral Sands Resort">Coral Sands Resort</option>
                      <option value="Cottages at Shipyard">Cottages at Shipyard</option>
                      <option value="Disney's Hilton Head Island Resort">Disney's Hilton Head Island Resort</option>
                      <option value="Egret Point by Spinnaker">Egret Point by Spinnaker</option>
                      <option value="Fiddles Cove">Fiddles Cove</option>
                      <option value="Harbour Club at Harbour Town">Harbour Club at Harbour Town</option>
                      <option value="Harbour Town Yacht Club">Harbour Town Yacht Club</option>
                      <option value="Harbourside III">Harbourside III</option>
                      <option value="Heritage Club at Harbour Town">Heritage Club at Harbour Town</option>
                      <option value="Hilton Head Island Beach and Tennis Resort">Hilton Head Island Beach and Tennis Resort</option>
                      <option value="Island Club IV">Island Club IV</option>
                      <option value="Island Links Resort">Island Links Resort</option>
                      <option value="Island Links by Coral Resorts">Island Links by Coral Resorts</option>
                      <option value="Ketch Court Resort">Ketch Court Resort</option>
                      <option value="Kingston Cove">Kingston Cove</option>
                      <option value="Main Street Inn and Spa">Main Street Inn and Spa</option>
                      <option value="Marriott's Barony Beach Club">Marriott's Barony Beach Club</option>
                      <option value="Marriott's Grande Ocean">Marriott's Grande Ocean</option>
                      <option value="Marriott's Harbour Club">Marriott's Harbour Club</option>
                      <option value="Marriott's Harbour Point at Shelter Cove">Marriott's Harbour Point at Shelter Cove</option>
                      <option value="Marriott's Heritage Club">Marriott's Heritage Club</option>
                      <option value="Marriott's Monarch">Marriott's Monarch</option>
                      <option value="Marriott's Sunset Pointe">Marriott's Sunset Pointe</option>
                      <option value="Marriott's SurfWatch">Marriott's SurfWatch</option>
                      <option value="Night Heron Loft">Night Heron Loft</option>
                      <option value="Ocean Dunes">Ocean Dunes</option>
                      <option value="Ocean Oak Resort by Hilton Grand Vacations Club">Ocean Oak Resort by Hilton Grand Vacations Club</option>
                      <option value="Ocean Palms Resort at Port Royal">Ocean Palms Resort at Port Royal</option>
                      <option value="Ocean View at Island Club">Ocean View at Island Club</option>
                      <option value="Owners Club at Hilton Head">Owners Club at Hilton Head</option>
                      <option value="Plantation Club Villas">Plantation Club Villas</option>
                      <option value="Port O' Call">Port O' Call</option>
                      <option value="Port Villas">Port Villas</option>
                      <option value="Racquet Club Villas">Racquet Club Villas</option>
                      <option value="Royal Dunes">Royal Dunes</option>
                      <option value="Royal Dunes Beach Villas at Port Royal">Royal Dunes Beach Villas at Port Royal</option>
                      <option value="Sand Shares Resort">Sand Shares Resort</option>
                      <option value="Sea Crest Surf and Racquet Club">Sea Crest Surf and Racquet Club</option>
                      <option value="Sea Pines Plantation">Sea Pines Plantation</option>
                      <option value="Sea Pines Resort">Sea Pines Resort</option>
                      <option value="Seawatch at Island Club">Seawatch at Island Club</option>
                      <option value="Southwind II">Southwind II</option>
                      <option value="Southwind Villas">Southwind Villas</option>
                      <option value="Southwind's Carolina Club - Holiday Network">Southwind's Carolina Club - Holiday Network</option>
                      <option value="Spicebush at Sea Pines">Spicebush at Sea Pines</option>
                      <option value="Springwood">Springwood</option>
                      <option value="Spinnaker at the Carolina Club">Spinnaker at the Carolina Club</option>
                      <option value="Spinnaker Blue Water">Spinnaker Blue Water</option>
                      <option value="Swallowtail at Sea Pines Resort">Swallowtail at Sea Pines Resort</option>
                      <option value="Twin Oaks Villas">Twin Oaks Villas</option>
                      <option value="Vacation Time of Hilton Head Island">Vacation Time of Hilton Head Island</option>
                      <option value="Waterside by Spinnaker">Waterside by Spinnaker</option>
                      <option value="-">- - - - - - - - -</option>
                      <option value="Other">Other</option>
                    </select>                    
                  </GridItem>
                  <GridItem xs={2}><div className="inputLabel">Unit Number</div><input id="unitNumber" value={this.state.interest.unitNumber || ''} onChange={(event) => this.handleInputForInterest(event)} className="formInput" type="text" placeholder="Unit Number"></input></GridItem>
                  <GridItem xs={2}><div className="inputLabel">Use Week</div><input id="useWeek" value={this.state.interest.useWeek || ''} onChange={(event) => this.handleInputForInterest(event)} className="formInput" type="text" placeholder="Use Week"></input></GridItem>
                  <GridItem xs={2}><div className="inputLabel">Bedrooms</div>
                      <select id="bedrooms" value={this.state.interest.bedrooms} onChange={(event) => this.handleInputForInterest(event)} className="formSelect">
                        <option value="">Bedrooms</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                  </GridItem>
                  <GridItem xs={2}><div className="inputLabel">Price</div>
                      <select id="price" value={this.state.interest.price} onChange={(event) => this.handleInputForInterest(event)} className="formSelect">
                        <option value="">Price</option>
                        <option value="2000">$2,000</option>
                        <option value="3000">$3,000</option>
                        <option value="4000">$4,000</option>
                        <option value="5000">$5,000</option>
                        <option value="6000">$6,000</option>
                        <option value="7000">$7,000</option>
                        <option value="8000">$8,000</option>
                        <option value="9000">$9,000</option>
                        <option value="10000">$10,000</option>
                      </select>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={3}><div className="inputLabel">Fixed / Float</div>
                      <select id="fixedFloat" value={this.state.interest.fixedFloat} onChange={(event) => this.handleInputForInterest(event)} className="formSelect">
                        <option value="">Fixed or Float</option>
                        <option value="Fixed">Fixed</option>
                        <option value="Float">Float</option>
                      </select>
                  </GridItem>
                  <GridItem xs={3}><div className="inputLabel">Use Year Type</div>
                      <select id="useYearType" value={this.state.interest.useYearType} onChange={(event) => this.handleInputForInterest(event)} className="formSelect">
                        <option value="">Use Year Type</option>
                        <option value="Annual">Annual</option>
                        <option value="Bi-EnnialEven">Bi-Ennial Even</option>
                        <option value="Bi-EnnialOdd">Bi-Ennial Odd</option>
                        <option value="Tri-Annual">Tri-Annual</option>
                      </select>
                  </GridItem>
                  <GridItem xs={3}><div className="inputLabel">Season</div>
                      <select id="season" value={this.state.interest.season} onChange={(event) => this.handleInputForInterest(event)} className="formSelect">
                        <option value="">Season</option>
                        <option value="Summer">Summer</option>
                        <option value="Fall">Fall</option>
                        <option value="Winter">Winter</option>
                        <option value="Spring">Spring</option>
                      </select>
                  </GridItem>
                  <GridItem xs={3}><div className="inputLabel">RCI Points</div>
                      <select id="rciPoints" value={this.state.interest.rciPoints} onChange={(event) => this.handleInputForInterest(event)} className="formSelect">
                      <option value="">RCI Points</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      </select>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}><div className="inputLabel">Notes</div><textarea id="notes" value={this.state.interest.notes || ''} onChange={(event) => this.handleInputForInterest(event)} className="formInput" placeholder="Notes" /></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <div align="center"><Button color="success" onClick={(e) => this.createNewLeadWithInterest(this.state.lead, this.state.interest)}>Save</Button></div>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

      </GridContainer>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Leads_New);