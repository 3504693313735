import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ContractIcon from "@material-ui/icons/InsertDriveFile";
import Button from "components/CustomButtons/Button.jsx";

import { Redirect } from "react-router-dom";

import "assets/main.css";

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: { color: "#3C4858", textDecoration: "none", textAlign: "center" },
  cardCategory: { margin: "0", color: "#999999" },
  selectCSS: { padding: "50px" }
};

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      lead: "",
      inventory: "",
      contract: {},
      tour: "",
      salesReps: [],
      resortList: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }
  }
  
  getData(contractId){

    tikiAPI.getSalesReps(
      function(data) {
        this.setState({salesReps: data});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );

    this.state.contract.contractType = "Inventory";

      tikiAPI.getContract(contractId,
        function(data) {
          this.setState({ contract: data}, function() {
            this.calcFinancials();
          });
        }.bind(this),
        function(error) {
          console.log(error);
        }
      );
    }

  handleBlurInput = event => {
    console.log("blur event");
    var updateContract = this.state.contract;
    updateContract[event.target.name] = event.target.value;
    this.setState ( { contract : updateContract } );
    event.target.value = tikiAPI.numberToDisplay(event.target.value);
    this.calcFinancials();
  }

  updateContract() {
    console.log(this.state.contract.contractDate);
    if (this.state.contract.contractDate == undefined) {
      alert("You must enter a valid contract date.");
    } else if (this.state.contract.fundsEscrowByDate == undefined) {
        alert("You must enter a valid funds in escrow by date.");
    } else if (this.state.contract.sellerSignBy == undefined) {
      alert("You must enter a valid seller sign by date.");
    } else if (this.state.contract.closingDate == undefined) {
      alert("You must enter a valid closing date.");
    } else {
      tikiAPI.updateContract(this.state.contract,
        function(data) {
          window.location = '/admin/contracts-detail/' + this.state.contract.contractId;
        }.bind(this),
        function(error) {
          console.log(error);
        }
      );  
    }
  }

  handleSelect = event => {
    console.log(event.target.name);
    console.log(event.target.value);

    var updateContract = this.state.contract;

    if (event.target.name.toString() === "closingAgent") {
      if (event.target.value === "Steffens Law Firm PLLC") {
        updateContract["closingAgent"] = "Steffens Law Firm PLLC";
        updateContract["closingAgentAddress"] = "1540 International Pkwy, Suite 2000";
        updateContract["closingAgentCity"] = "Lake Mary";
        updateContract["closingAgentState"] = "FL";
        updateContract["closingAgentZipcode"] = "32746";
        updateContract["closingAgentPhone"] = "407-388-6140";
        updateContract["closingAgentEscrowBank"] = "First Horizon";
      }
      if (event.target.value === "Fairwater Title of Brevard") {
        updateContract["closingAgent"] = "Fairwater Title of Brevard";
        updateContract["closingAgentAddress"] = "2955 Pineda Plaza Way, Suite 107";
        updateContract["closingAgentCity"] = "Melbourne";
        updateContract["closingAgentState"] = "FL";
        updateContract["closingAgentZipcode"] = "32940";
        updateContract["closingAgentPhone"] = "321-733-3337";
        updateContract["closingAgentEscrowBank"] = "South State Bank";
      }
      if (event.target.value === "Robertson Law Escrow, PA") {
        updateContract["closingAgent"] = "Robertson Law Escrow, PA";
        updateContract["closingAgentAddress"] = "283 Cranes Roost Blvd STE 111";
        updateContract["closingAgentCity"] = "Altamonte Springs";
        updateContract["closingAgentState"] = "FL";
        updateContract["closingAgentZipcode"] = "32701";
        updateContract["closingAgentPhone"] = "800-242-0532";
        updateContract["closingAgentEscrowBank"] = "Fairwinds Credit Union";
      }
    }
    
    if (event.target.name.toString() === "closingFee" || event.target.name.toString() === "creditReportFee") {
      var targetValue = Math.round((event.target.value * 100) / 100);
      updateContract[event.target.name] = targetValue;
    } else {
      updateContract[event.target.name] = event.target.value;
    }

    this.setState({ contract: updateContract }, function() {
      console.log(this.state.contract);
    });

    this.calcFinancials();
  };

  calcFinancials () {
    console.log("calcFinancials");
    console.log(this.state.contract.purchasePrice);

    // Calculate the Contract Sales Price
    var updateContractSalesPrice = 0;
    if (this.state.contract.purchasePrice) { updateContractSalesPrice = tikiAPI.displayToNumber(this.state.contract.purchasePrice) };
    if (this.state.contract.closingFee) { updateContractSalesPrice += tikiAPI.displayToNumber(this.state.contract.closingFee) };
    if (this.state.contract.creditReportFee) { updateContractSalesPrice += tikiAPI.displayToNumber(this.state.contract.creditReportFee) };
    if (this.state.contract.prePaidMaintFee) { updateContractSalesPrice += tikiAPI.displayToNumber(this.state.contract.prePaidMaintFee) };

    // setState not working?
    /*
    this.setState({ contract: {...this.state.contract, contractSalesPrice: "999.00"} }, function() {
      console.log(this.state.contract.contractSalesPrice);
      console.log(this.state.contract.contractSalesPrice);
    });
    */
    this.state.contract.contractSalesPrice = Math.round((updateContractSalesPrice * 100) / 100);

    /* REMOVING % DOWN
    if (document.getElementById("downPaymentPercentage-row").style.display === "table-row") {
      if (document.getElementById("downPaymentPercentage").value == "") {
        var updateDepositAmount = null;
      } else {
        var updateDepositAmount = Math.round(((this.state.contract.contractSalesPrice * (this.state.contract.downPaymentPercentage * 0.01)) * 100) / 100);
      }
     this.state.contract.depositAmount = updateDepositAmount;
    }
    */

    if (this.state.contract.fullAmountCollected == "YES") {
      // this.setState({ contract: {...this.state.contract, subtotalCharges: this.state.contract.contractSalesPrice} })
      this.state.contract.subtotalCharges = this.state.contract.contractSalesPrice;
      this.state.contract.depositAmount = this.state.contract.subtotalCharges;
    } else {
      if (this.state.contract.depositAmount) {
        //this.setState({ contract: {...this.state.contract, subtotalCharges: this.state.contract.depositAmount} })
        this.state.contract.subtotalCharges = this.state.contract.depositAmount;
      } else {
        //this.setState({ contract: {...this.state.contract, subtotalCharges: 0} })
        this.state.contract.subtotalCharges = 0;
      }
    }

    if (this.state.contract.subtotalCharges && this.state.contract.depositMadeBy == "CREDIT CARD") {      
      var creditCardFeePercent = 0.03;
      var newFee = (this.state.contract.subtotalCharges * creditCardFeePercent)
      //this.setState({ contract: {...this.state.contract, creditCardProcessingFee: newFee, cashDueAtSigning: tikiAPI.displayToNumber(this.state.contract.subtotalCharges) + tikiAPI.displayToNumber(this.state.contract.creditCardProcessingFee)} })
      this.state.contract.creditCardProcessingFee = Math.round(newFee * 100) / 100;
      this.state.contract.cashDueAtSigning = Math.round((tikiAPI.displayToNumber(this.state.contract.subtotalCharges) + tikiAPI.displayToNumber(this.state.contract.creditCardProcessingFee)) * 100) / 100;
    } else {
      //this.setState({ contract: {...this.state.contract, creditCardProcessingFee: 0} })
      this.state.contract.creditCardProcessingFee = 0;
      this.state.contract.cashDueAtSigning = Math.round((tikiAPI.displayToNumber(this.state.contract.subtotalCharges) + tikiAPI.displayToNumber(this.state.contract.creditCardProcessingFee)) * 100) / 100;
    }

  if (this.state.contract.cashDueAtSigning) {
    //this.setState({ contract: {...this.state.contract, balanceDue: tikiAPI.displayToNumber(this.state.contract.contractSalesPrice) - tikiAPI.displayToNumber(this.state.contract.subtotalCharges)} })
    this.state.contract.balanceDue = Math.round((tikiAPI.displayToNumber(this.state.contract.contractSalesPrice) - tikiAPI.displayToNumber(this.state.contract.subtotalCharges)) * 100) / 100;
  }

  // Possible issue with setState above -> WHEN CALC IS BASED ON PREVIOUS CALC, NEED TO USE CALLBACK   https://stackoverflow.com/questions/37401635/react-js-wait-for-setstate-to-finish-before-triggering-a-function/37401726

}

  render() {
  
    const { classes } = this.props;
    return (

      <Can permissions={this.state.user.permissions} perform="Contracts_Edit" yes={() => (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <ContractIcon />
              </CardIcon>
              <h4 className={classes.cardTitle}>Edit Contract</h4>
            </CardHeader>
              <CardBody>

                <GridContainer><GridItem xs={12}><div className="softDivider">Contract</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                      <div className="displayLabel">Contract ID</div>
                      <div className="displayContent">{this.state.contract.contractId}</div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="displayLabel">TPP Deal #</div>
                      <input name="tppDealNumber" id="tppDealNumber" value={this.state.contract.tppDealNumber || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Contract Date</div>
                    <input name="contractDate" id="contractDate" value={this.state.contract.contractDate || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Contract Good Date</div>
                    <input name="contractGoodDate" id="contractGoodDate" value={this.state.contract.contractGoodDate || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Paid In Full Date</div>
                    <input name="paidInFullDate" id="paidInFullDate" value={this.state.contract.paidInFullDate || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Contract Status</div>
                    <div>
                      <select name="contractStatus" id="contractStatus" value={this.state.contract.contractStatus || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value=""></option>
                       <option value="Draft">Draft</option>
                        <option value="Documents Generated">Documents Generated</option>
                        <option value="Signed">Signed</option>
                        <option value="Preparing To Close">Preparing To Close</option>
                        <option value="Closed">Closed</option>
                        <option value="Accounting Complete">Accounting Complete</option>
                        <option value="Rescinded">Rescinded</option>
                        <option value="Canceled">Canceled</option>
                        <option value="Expired">Expired</option>
                      </select>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Payment Status</div>
                    <div>
                      <select name="paymentStatus" id="paymentStatus" value={this.state.contract.paymentStatus || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value=""></option>
                         <option value="Draft">Draft</option>
                        <option value="Pender">Pender</option>
                        <option value="Down Payment Paid">Down Payment Paid</option>
                        <option value="Paid in Full at Signing">Paid in Full at Signing</option>
                        <option value="Paid in Full at Closing">Paid in Full at Closing</option>
                      </select>
                    </div>
                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <div className="softDivider">Resort Info</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Resort Name</div>
                      <input name="resort" id="resort" value={this.state.contract.resort || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Building Number</div>
                      <input name="buildingNumber" id="buildingNumber" value={this.state.contract.buildingNumber || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Unit Number</div>
                      <input name="unitNumber" id="unitNumber" value={this.state.contract.unitNumber || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>


                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Unit Sleeps</div>
                    <select name="unitSleeps" id="unitSleeps" value={this.state.contract.unitSleeps || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="2" >2</option>
                      <option value="3" >3</option>
                      <option value="4" >4</option>
                      <option value="5" >5</option>
                      <option value="6" >6</option>
                      <option value="7" >7</option>
                      <option value="8" >8</option>
                      <option value="9" >9</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Bedrooms</div>
                    <select name="bedrooms" id="bedrooms" value={this.state.contract.bedrooms || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="STUDIO" >Studio</option>
                      <option value="1" >1</option>
                      <option value="2" >2</option>
                      <option value="3" >3</option>
                      <option value="4" >4</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Bathrooms</div>
                    <select name="bathrooms" id="bathrooms" value={this.state.contract.bathrooms || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="1" >1</option>
                      <option value="1.5" >1.5</option>
                      <option value="2" >2</option>
                      <option value="2.5" >2.5</option>
                      <option value="3" >3</option>
                      <option value="3.5" >3.5</option>
                      <option value="OTHER" >Other</option>
                    </select>
                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Maintenance Fee</div>
                      <input name="maintFee" id="maintFee" defaultValue={tikiAPI.numberToDisplay(this.state.contract.maintFee) || ''} type="text" className="formSelect" onBlur={(event) => this.handleBlurInput(event)} />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Deeded Week</div>
                      <input name="useWeek" id="useWeek" value={this.state.contract.useWeek || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Fixed or Float?</div>
                      <select name="fixedFloat" id="fixedFloat" value={this.state.contract.fixedFloat || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="FIXED">FIXED</option>
                        <option value="FLOAT">FLOAT</option>
                      </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Use Type</div>
                        <select name="useYearType" id="useYearType" value={this.state.contract.useYearType || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                          <option value="">---SELECT---</option>
                          <option value="Annual" >Annual</option>
                        <option value="Biennial Even" >Biennial Even</option>
                        <option value="Biennial Odd" >Biennial Odd</option>
                        <option value="Triennial X" >Triennial X</option>
                        <option value="Triennial Y" >Triennial Y</option>
                        <option value="Triennial Z" >Triennial Z</option>
                        </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Ownership Type</div>
                    <select name="ownershipType" id="ownershipType" value={this.state.contract.ownershipType || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="DEED" >Deed</option>
                      <option value="MEMBERSHIP / RIGHT TO USE" >Membership / Right to Use</option>
                    </select>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <div className="inputLabel">Managing Entity</div>
                    <input name="managingEntity" id="managingEntity" value={this.state.contract.managingEntity || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Buyer Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Entity Type</div>
                    <select name="buyerEntityType" id="buyerEntityType" value={this.state.contract.buyerEntityType || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="INDIVIDUAL">INDIVIDUAL</option>
                      <option value="COMPANY">COMPANY</option>
                      <option value="TRUST">TRUST</option>
                    </select>
                  </GridItem>
                </GridContainer>

                { this.state.contract.buyerEntityType == "INDIVIDUAL" && 
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">First Name</div>
                      <input name="buyerFirstName" id="buyerFirstName" value={this.state.contract.buyerFirstName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Middle Name</div>
                      <input name="buyerMiddleName" id="buyerMiddleName" value={this.state.contract.buyerMiddleName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Last Name</div>
                      <input name="buyerLastName" id="buyerLastName" value={this.state.contract.buyerLastName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Home Phone</div>
                      <input name="buyerHomePhone" id="buyerHomePhone" value={this.state.contract.buyerHomePhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Mobile Phone</div>
                      <input name="buyerMobilePhone" id="buyerMobilePhone" value={this.state.contract.buyerMobilePhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Email</div>
                      <input name="buyerEmail" id="buyerEmail" value={this.state.contract.buyerEmail || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Co-Buyer First Name</div>
                      <input name="coBuyerFirstName" id="coBuyerFirstName" value={this.state.contract.coBuyerFirstName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Co-Buyer Middle Name</div>
                      <input name="coBuyerMiddleName" id="coBuyerMiddleName" value={this.state.contract.coBuyerMiddleName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Co-Buyer Last Name</div>
                      <input name="coBuyerLastName" id="coBuyerLastName" value={this.state.contract.coBuyerLastName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Co-Buyer Home Phone</div>
                      <input name="coBuyerHomePhone" id="coBuyerHomePhone" value={this.state.contract.coBuyerHomePhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Co-Buyer Mobile Phone</div>
                      <input name="coBuyerMobilePhone" id="coBuyerMobilePhone" value={this.state.contract.coBuyerMobilePhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Co-Buyer Email</div>
                      <input name="coBuyerEmail" id="coBuyerEmail" value={this.state.contract.coBuyerEmail || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    
                  </GridContainer>
                </div>
                }

                { this.state.contract.buyerEntityType == "COMPANY" && 
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <div className="inputLabel">Company Name</div>
                      <input name="buyerCompanyName" id="buyerCompanyName" value={this.state.contract.buyerCompanyName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">State of Inc.</div>
                      <select name="buyerStateInc" id="buyerStateInc" value={this.state.contract.buyerStateInc || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="AL" >Alabama</option>
                        <option value="AK" >Alaska</option>
                        <option value="AR" >Arkansas</option>
                        <option value="AZ" >Azizona</option>
                        <option value="CA" >California</option>
                        <option value="CO" >Colorado</option>
                        <option value="CT" >Connecticut</option>
                        <option value="DE" >Delaware</option>
                        <option value="FL" >Florida</option>
                        <option value="GA" >Georgia</option>
                        <option value="HI" >Hawaii</option>
                        <option value="ID" >Idaho</option>
                        <option value="IL" >Illinois</option>
                        <option value="IN" >Indiana</option>
                        <option value="IA" >Iowa</option>
                        <option value="KS" >Kansas</option>
                        <option value="KY" >Kentucky</option>
                        <option value="LA" >Louisiana</option>
                        <option value="ME" >Maine</option>
                        <option value="MD" >Maryland</option>
                        <option value="MA" >Massachusetts</option>
                        <option value="MI" >Michigan</option>
                        <option value="MN" >Minnesota</option>
                        <option value="MS" >Mississippi</option>
                        <option value="MO" >Missouri</option>
                        <option value="MT" >Montana</option>
                        <option value="NE" >Nebraska</option>
                        <option value="NV" >Nevada</option>
                        <option value="NH" >New Hampshire</option>
                        <option value="NJ" >New Jersey</option>
                        <option value="NM" >New Mexico</option>
                        <option value="NY" >New York</option>
                        <option value="NC" >North Carolina</option>
                        <option value="ND" >North Dakota</option>
                        <option value="OH" >Ohio</option>
                        <option value="OK" >Oklahoma</option>
                        <option value="OR" >Oregon</option>
                        <option value="PA" >Pennsylvania</option>
                        <option value="RI" >Rhode Island</option>
                        <option value="SC" >South Carolina</option>
                        <option value="SD" >South Dakota</option>
                        <option value="TN" >Tennessee</option>
                        <option value="TX" >Texas</option>
                        <option value="UT" >Utah</option>
                        <option value="VT" >Vermont</option>
                        <option value="VA" >Virginia</option>
                        <option value="WA" >Washington</option>
                        <option value="DC" >Washington D.C.</option>
                        <option value="WV" >West Virginia</option>
                        <option value="WI" >Wisconsin</option>
                        <option value="WY" >Wyoming</option>
                      </select>
                    </GridItem>                    
                  </GridContainer>
                </div>
                }
  
                { this.state.contract.buyerEntityType == "TRUST" && 
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <div className="inputLabel">Trust Name</div>
                      <input name="buyerTrustName" id="buyerTrustName" value={this.state.contract.buyerTrustName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    
                  </GridContainer>
                </div>
                }

                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabel">Address</div>
                    <input name="buyerAddress" id="buyerAddress" value={this.state.contract.buyerAddress || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabel">City</div>
                    <input name="buyerCity" id="buyerCity" value={this.state.contract.buyerCity || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">State/Province</div>
                      <select name="buyerState" id="buyerState" value={this.state.contract.buyerState || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="AL" >Alabama</option>
                        <option value="AK" >Alaska</option>
                        <option value="AR" >Arkansas</option>
                        <option value="AZ" >Azizona</option>
                        <option value="CA" >California</option>
                        <option value="CO" >Colorado</option>
                        <option value="CT" >Connecticut</option>
                        <option value="DE" >Delaware</option>
                        <option value="FL" >Florida</option>
                        <option value="GA" >Georgia</option>
                        <option value="HI" >Hawaii</option>
                        <option value="ID" >Idaho</option>
                        <option value="IL" >Illinois</option>
                        <option value="IN" >Indiana</option>
                        <option value="IA" >Iowa</option>
                        <option value="KS" >Kansas</option>
                        <option value="KY" >Kentucky</option>
                        <option value="LA" >Louisiana</option>
                        <option value="ME" >Maine</option>
                        <option value="MD" >Maryland</option>
                        <option value="MA" >Massachusetts</option>
                        <option value="MI" >Michigan</option>
                        <option value="MN" >Minnesota</option>
                        <option value="MS" >Mississippi</option>
                        <option value="MO" >Missouri</option>
                        <option value="MT" >Montana</option>
                        <option value="NE" >Nebraska</option>
                        <option value="NV" >Nevada</option>
                        <option value="NH" >New Hampshire</option>
                        <option value="NJ" >New Jersey</option>
                        <option value="NM" >New Mexico</option>
                        <option value="NY" >New York</option>
                        <option value="NC" >North Carolina</option>
                        <option value="ND" >North Dakota</option>
                        <option value="OH" >Ohio</option>
                        <option value="OK" >Oklahoma</option>
                        <option value="OR" >Oregon</option>
                        <option value="PA" >Pennsylvania</option>
                        <option value="RI" >Rhode Island</option>
                        <option value="SC" >South Carolina</option>
                        <option value="SD" >South Dakota</option>
                        <option value="TN" >Tennessee</option>
                        <option value="TX" >Texas</option>
                        <option value="UT" >Utah</option>
                        <option value="VT" >Vermont</option>
                        <option value="VA" >Virginia</option>
                        <option value="WA" >Washington</option>
                        <option value="DC" >Washington D.C.</option>
                        <option value="WV" >West Virginia</option>
                        <option value="WI" >Wisconsin</option>
                        <option value="WY" >Wyoming</option>
                        <option value="AB" >Alberta</option>
                        <option value="BC" >British Columbia</option>
                        <option value="MB" >Manitoba</option>
                        <option value="NB" >New Brunswick</option>
                        <option value="NL" >Newfoundland</option>
                        <option value="NT" >Northwest Territories</option>
                        <option value="NS" >Nova Scotia</option>
                        <option value="NU" >Nunavut</option>
                        <option value="ON" >Ontario</option>
                        <option value="PE" >Prince Edward Island</option>
                        <option value="QC" >Quebec</option>
                        <option value="SK" >Saskatchewan</option>
                        <option value="YT" >Yukon</option>
                      </select>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Postal Code</div>
                    <input name="buyerZipcode" id="buyerZipcode" value={this.state.contract.buyerZipcode || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Country</div>
                     <select name="buyerCountry" id="buyerCountry" value={this.state.contract.buyerCountry || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="US" >United States</option>
                        <option value="CA" >Canada</option>
                      </select>
                  </GridItem>                    
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Seller Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Entity Type</div>
                    <select name="sellerEntityType" id="sellerEntityType" value={this.state.contract.sellerEntityType || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="INDIVIDUAL">INDIVIDUAL</option>
                      <option value="COMPANY">COMPANY</option>
                      <option value="TRUST">TRUST</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Is this an HOA sale?</div>
                    <select name="isHoaInventory" id="isHoaInventory" value={this.state.contract.isHoaInventory || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </GridItem>
                </GridContainer>

                { this.state.contract.sellerEntityType == "INDIVIDUAL" && 
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">First Name</div>
                      <input name="sellerFirstName" id="sellerFirstName" value={this.state.contract.sellerFirstName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Middle Name</div>
                      <input name="sellerMiddleName" id="sellerMiddleName" value={this.state.contract.sellerMiddleName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Last Name</div>
                      <input name="sellerLastName" id="sellerLastName" value={this.state.contract.sellerLastName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Home Phone</div>
                      <input name="sellerHomePhone" id="sellerHomePhone" value={this.state.contract.sellerHomePhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Mobile Phone</div>
                      <input name="sellerMobilePhone" id="sellerMobilePhone" value={this.state.contract.sellerMobilePhone || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Email</div>
                      <input name="sellerEmail" id="sellerEmail" value={this.state.contract.sellerEmail || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    
                  </GridContainer>
                </div>
                }

                { this.state.contract.sellerEntityType == "COMPANY" && 
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <div className="inputLabel">Company Name</div>
                      <input name="sellerCompanyName" id="sellerCompanyName" value={this.state.contract.sellerCompanyName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    

                    <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">State of Inc.</div>
                      <select name="sellerStateInc" id="sellerStateInc" value={this.state.contract.sellerStateInc || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="AL" >Alabama</option>
                        <option value="AK" >Alaska</option>
                        <option value="AR" >Arkansas</option>
                        <option value="AZ" >Azizona</option>
                        <option value="CA" >California</option>
                        <option value="CO" >Colorado</option>
                        <option value="CT" >Connecticut</option>
                        <option value="DE" >Delaware</option>
                        <option value="FL" >Florida</option>
                        <option value="GA" >Georgia</option>
                        <option value="HI" >Hawaii</option>
                        <option value="ID" >Idaho</option>
                        <option value="IL" >Illinois</option>
                        <option value="IN" >Indiana</option>
                        <option value="IA" >Iowa</option>
                        <option value="KS" >Kansas</option>
                        <option value="KY" >Kentucky</option>
                        <option value="LA" >Louisiana</option>
                        <option value="ME" >Maine</option>
                        <option value="MD" >Maryland</option>
                        <option value="MA" >Massachusetts</option>
                        <option value="MI" >Michigan</option>
                        <option value="MN" >Minnesota</option>
                        <option value="MS" >Mississippi</option>
                        <option value="MO" >Missouri</option>
                        <option value="MT" >Montana</option>
                        <option value="NE" >Nebraska</option>
                        <option value="NV" >Nevada</option>
                        <option value="NH" >New Hampshire</option>
                        <option value="NJ" >New Jersey</option>
                        <option value="NM" >New Mexico</option>
                        <option value="NY" >New York</option>
                        <option value="NC" >North Carolina</option>
                        <option value="ND" >North Dakota</option>
                        <option value="OH" >Ohio</option>
                        <option value="OK" >Oklahoma</option>
                        <option value="OR" >Oregon</option>
                        <option value="PA" >Pennsylvania</option>
                        <option value="RI" >Rhode Island</option>
                        <option value="SC" >South Carolina</option>
                        <option value="SD" >South Dakota</option>
                        <option value="TN" >Tennessee</option>
                        <option value="TX" >Texas</option>
                        <option value="UT" >Utah</option>
                        <option value="VT" >Vermont</option>
                        <option value="VA" >Virginia</option>
                        <option value="WA" >Washington</option>
                        <option value="DC" >Washington D.C.</option>
                        <option value="WV" >West Virginia</option>
                        <option value="WI" >Wisconsin</option>
                        <option value="WY" >Wyoming</option>
                      </select>
                    </GridItem>                    
                  </GridContainer>
                </div>
                }

                { this.state.contract.sellerEntityType == "TRUST" && 
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <div className="inputLabel">Trust Name</div>
                      <input name="sellerTrustName" id="sellerTrustName" value={this.state.contract.sellerTrustName || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                    </GridItem>                    
                  </GridContainer>

                </div>
                }


                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabel">Address</div>
                    <input name="sellerAddress" id="sellerAddress" value={this.state.contract.sellerAddress || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabel">City</div>
                    <input name="sellerCity" id="sellerCity" value={this.state.contract.sellerCity || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">State/Province</div>
                      <select name="sellerState" id="sellerState" value={this.state.contract.sellerState || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="AL" >Alabama</option>
                        <option value="AK" >Alaska</option>
                        <option value="AR" >Arkansas</option>
                        <option value="AZ" >Azizona</option>
                        <option value="CA" >California</option>
                        <option value="CO" >Colorado</option>
                        <option value="CT" >Connecticut</option>
                        <option value="DE" >Delaware</option>
                        <option value="FL" >Florida</option>
                        <option value="GA" >Georgia</option>
                        <option value="HI" >Hawaii</option>
                        <option value="ID" >Idaho</option>
                        <option value="IL" >Illinois</option>
                        <option value="IN" >Indiana</option>
                        <option value="IA" >Iowa</option>
                        <option value="KS" >Kansas</option>
                        <option value="KY" >Kentucky</option>
                        <option value="LA" >Louisiana</option>
                        <option value="ME" >Maine</option>
                        <option value="MD" >Maryland</option>
                        <option value="MA" >Massachusetts</option>
                        <option value="MI" >Michigan</option>
                        <option value="MN" >Minnesota</option>
                        <option value="MS" >Mississippi</option>
                        <option value="MO" >Missouri</option>
                        <option value="MT" >Montana</option>
                        <option value="NE" >Nebraska</option>
                        <option value="NV" >Nevada</option>
                        <option value="NH" >New Hampshire</option>
                        <option value="NJ" >New Jersey</option>
                        <option value="NM" >New Mexico</option>
                        <option value="NY" >New York</option>
                        <option value="NC" >North Carolina</option>
                        <option value="ND" >North Dakota</option>
                        <option value="OH" >Ohio</option>
                        <option value="OK" >Oklahoma</option>
                        <option value="OR" >Oregon</option>
                        <option value="PA" >Pennsylvania</option>
                        <option value="RI" >Rhode Island</option>
                        <option value="SC" >South Carolina</option>
                        <option value="SD" >South Dakota</option>
                        <option value="TN" >Tennessee</option>
                        <option value="TX" >Texas</option>
                        <option value="UT" >Utah</option>
                        <option value="VT" >Vermont</option>
                        <option value="VA" >Virginia</option>
                        <option value="WA" >Washington</option>
                        <option value="DC" >Washington D.C.</option>
                        <option value="WV" >West Virginia</option>
                        <option value="WI" >Wisconsin</option>
                        <option value="WY" >Wyoming</option>
                        <option value="AB" >Alberta</option>
                        <option value="BC" >British Columbia</option>
                        <option value="MB" >Manitoba</option>
                        <option value="NB" >New Brunswick</option>
                        <option value="NL" >Newfoundland</option>
                        <option value="NT" >Northwest Territories</option>
                        <option value="NS" >Nova Scotia</option>
                        <option value="NU" >Nunavut</option>
                        <option value="ON" >Ontario</option>
                        <option value="PE" >Prince Edward Island</option>
                        <option value="QC" >Quebec</option>
                        <option value="SK" >Saskatchewan</option>
                        <option value="YT" >Yukon</option>
                      </select>
                  </GridItem>                    

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Postal Code</div>
                    <input name="sellerZipcode" id="sellerZipcode" value={this.state.contract.sellerZipcode || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>                    
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Country</div>
                     <select name="sellerCountry" id="sellerCountry" value={this.state.contract.sellerCountry || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="US" >United States</option>
                        <option value="CA" >Canada</option>
                      </select>
                  </GridItem>                    
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Sales Info</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Sales Agent</div>
                    <select name="salesAgent" id="salesAgent" value={this.state.contract.salesAgent || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        
                        { (this.state.salesReps.length > 0) ? (
                          this.state.salesReps.map((salesRep, index) => (
                            <option key={index} value={salesRep.fullName}>{salesRep.fullName}</option>
                          ))                            
                        ) : (
                          <option>No available sales reps.</option>
                        )
                      } 

                      </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Is a TO being used?</div>
                      <select name="isToUsed" id="isToUsed" value={this.state.contract.isToUsed || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                      <div className="inputLabel">Who is the TO?</div>
                      <select name="toName" id="toName" value={this.state.contract.toName || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>                        
                        { (this.state.salesReps.length > 0) ? (
                          this.state.salesReps.map((salesRep, index) => (
                            (salesRep.isToRep === "Yes") && <option key={index} value={salesRep.fullName}>{salesRep.fullName}</option>                               
                          ))                            
                          ) : (
                            <option>No available sales reps.</option>
                          )
                        } 
                      </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Closing Agent</div>
                    <select name="closingAgent" id="closingAgent" value={this.state.contract.closingAgent || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="" >---SELECT---</option>
                      <option value="Steffens Law Firm PLLC" >Steffens Law Firm PLLC</option>
                      <option value="Fairwater Title of Brevard" >Fairwater Title of Brevard</option>
                      <option value="Robertson Law Escrow, PA" >Robertson Law Escrow, PA</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Contracts Processor</div>
                    <select name="contractsProcessor" id="contractsProcessor" value={this.state.contract.contractsProcessor || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="J.E. FRENCH COMPANY INC" >J.E. French Company Inc</option>
                      <option value="JOE NAHMAN" >Joe Nahman</option>
                      <option value="STEVE HAMBERG" >Steve Hamberg</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={2}>
                    <div className="inputLabel">Escrow Agent</div>
                    <select name="escrowAgent" id="escrowAgent" value={this.state.contract.escrowAgent || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="TIMESHARE ESCROW AND TITLE LLC" >Timeshare Escrow and Title LLC</option>
                      <option value="RESORT MARKETING CORPORATION" >Resort Marketing Corporation</option>
                      <option value="DOUG MAC NEILLE" >Doug Mac Neille</option>
                    </select>
                  </GridItem>


                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Contract Dates</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabel">Contract Date</div>
                    <input name="contractDate" id="contractDate" value={this.state.contract.contractDate || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={2}>
                    <div className="inputLabel">Funds in escrow by:</div>
                    <input name="fundsEscrowByDate" id="fundsEscrowByDate" value={this.state.contract.fundsEscrowByDate || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={2}>
                    <div className="inputLabel">Seller sign by:</div>
                    <input name="sellerSignBy" id="sellerSignBy" value={this.state.contract.sellerSignBy || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>

                  <GridItem xs={2}>
                    <div className="inputLabel">Closing Date</div>
                    <input name="closingDate" id="closingDate" value={this.state.contract.closingDate || ''} type="text" className="formInput" onChange={(event) => this.handleSelect(event)}></input>
                  </GridItem>
                </GridContainer>

                <GridContainer><GridItem xs={12}><div className="softDivider">Financial Terms</div></GridItem></GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Purchase Price</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <input name="purchasePrice" id="purchasePrice" defaultValue={tikiAPI.numberToDisplay(this.state.contract.purchasePrice) || ''} type="text" className="formSelect" onBlur={(event) => this.handleBlurInput(event)}></input>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Closing Fee</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <select name="closingFee" id="closingFee" value={this.state.contract.closingFee || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="499" >499.00</option>
                      <option value="525" >525.00</option>
                    </select>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Credit Report Fee</div>
                  </GridItem>

                  <GridItem xs={2}>
                  <select name="creditReportFee" id="creditReportFee" value={this.state.contract.creditReportFee || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="0" >0.00</option>
                      <option value="25" >25.00</option>
                    </select>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Pre-Paid Maint, Fee</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <input name="prePaidMaintFee" id="prePaidMaintFee" defaultValue={this.state.contract.prePaidMaintFee || ''} type="text" className="formSelect" onBlur={(event) => this.handleBlurInput(event)} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Contract Sales Price</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <input readOnly name="contractSalesPrice" id="contractSalesPrice" value={tikiAPI.numberToDisplay(this.state.contract.contractSalesPrice) || ''} type="text" style={{backgroundColor: "#FAFAFA" }} className="formInput" />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Paid in full at signing?</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <select name="fullAmountCollected" id="fullAmountCollected" value={this.state.contract.fullAmountCollected || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="YES" >Yes</option>
                      <option value="NO" >No</option>
                    </select>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Deposit / Signing Type</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <select name="depositMadeBy" id="depositMadeBy" value={this.state.contract.depositMadeBy || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="CASH" >Cash</option>
                        <option value="CHECK" >Check</option>
                        <option value="CREDIT CARD" >Credit Card</option>
                        <option value="MONEY ORDER" >Money Order</option>
                        <option value="WIRE TRANSFER" >Wire Transfer</option>
                      </select>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Deposit / Signing Amount</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <input name="depositAmount" id="depositAmount" defaultValue={tikiAPI.numberToDisplay(this.state.contract.depositAmount) || ''} type="text" className="formSelect" onBlur={(event) => this.handleBlurInput(event)} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Subtotal of charges</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <input readOnly name="subtotalCharges" id="subtotalCharges" value={tikiAPI.numberToDisplay(this.state.contract.subtotalCharges) || ''} type="text" onChange={(event) => this.handleSelect(event)} style={{backgroundColor: "#FAFAFA" }} className="formInput" />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Credit Card Fee</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <input name="creditCardProcessingFee" id="creditCardProcessingFee" defaultValue={tikiAPI.numberToDisplay(this.state.contract.creditCardProcessingFee) || ''} type="text" onBlur={(event) => this.handleBlurInput(event)} style={{backgroundColor: "#FAFAFA" }} className="formInput" />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Due on contract signing</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <input readOnly name="cashDueAtSigning" id="cashDueAtSigning" value={tikiAPI.numberToDisplay(this.state.contract.cashDueAtSigning) || ''} type="text" onChange={(event) => this.handleSelect(event)} style={{backgroundColor: "#FAFAFA" }} className="formInput" />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <div className="inputLabelTable">Balance Due at Closing</div>
                  </GridItem>

                  <GridItem xs={2}>
                    <input readOnly name="balanceDue" id="balanceDue" value={tikiAPI.numberToDisplay(this.state.contract.balanceDue) || ''} type="text" style={{backgroundColor: "#FAFAFA" }} className="formInput" />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12}>
                    <div className="softDivider">Additional Options</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>

                  <GridItem xs={12} sm={6} md={3}>
                    <div className="inputLabel">Is there a resort enhancement?</div>
                    <select name="includesResortEnhancement" id="includesResortEnhancement" value={this.state.contract.includesResortEnhancement || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                      <option value="">---SELECT---</option>
                      <option value="NONE" >None</option>
                      <option value="RCI" >RCI</option>
                      <option value="II - CLUB INTERVAL GOLD" >II - Club Interval Gold</option>
                    </select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                      <div className="inputLabel">Internal timeshare trade-in?</div>
                      <select name="includesInternalTradein" id="includesInternalTradein" value={this.state.contract.includesInternalTradein || ''} onChange={(event) => this.handleSelect(event)} className="formSelect">
                        <option value="">---SELECT---</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                  </GridItem>
                </GridContainer>

                <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
                  <Button color="success" onClick={(e) => this.updateContract()}>Save</Button>
                </GridItem>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

) } />

    );
  }
}

export default withStyles(styles)(Panels);