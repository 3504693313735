import React from "react";
import $ from 'jquery'; 
import Cookies from 'universal-cookie';
import { Redirect } from "react-router-dom";
import { TikiAPI } from "../../global";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CardMedia from '@material-ui/core/CardMedia';

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";


import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      rciNumber: "",
      epicMemberNumber: "",
      memberLastName: ""
    };
  }

  handleInput = event => {
    this.setState({[event.target.id]: event.target.value});
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      400
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  lookupMember() {
    tikiAPI.rciMemberLookup(this.state.rciNumber, this.state.memberLastName,
      function(data) {
        if (data == undefined) {
          alert("No member matches the RCI number and last name you entered.");
          // no member
        } else {
          cookies.set('isAuthenticated', true, { path: '/' });
          cookies.set('authToken', "rciagent" + data.memberId, { path: '/' });
            window.location = '/portal/rci-member/' + data.memberId;
        }
      },
      function(error) {
        console.log(error);
      });

  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="warning"
                >
                  <h4 className={classes.cardTitle}>RCI Agent - Member Lookup</h4>
                </CardHeader>
                <CardBody>
                  <CardMedia image="/assets/img/ResortSourceLogo.png" title="Resort Source Logo" />
                  <CustomInput
                    labelText="RCI Member Number"
                    id="rciNumber"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: (event) => this.handleInput(event),
                          type: "text"
                    }}
                  />

                  <CustomInput
                    labelText="Member Last Name"
                    id="memberLastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: (event) => this.handleInput(event),
                          type: "text"
                    }}
                  />

                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="rose" simple size="lg" block onClick={ e => {
                      this.lookupMember();
                    }}>
                    Lookup Member
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
