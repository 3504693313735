// This is used for searching inventory for leads to purchase.  Refactor to point to new Contracts_New_For_Lead_Invnentory route

import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Inventory from "@material-ui/icons/VpnKey";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

// react router components
import { Link } from 'react-router-dom';

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      checked: [],
      inventory: [],
      leadId: "",
      tourId: "",
      open: false,
      deleteData: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getSearchResults(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount() {
  }

  getSearchResults(searchId) {

    tikiAPI.getSearchResults(searchId,
      function(data) {
        var fullResults = JSON.parse(data.searchResponse);
        var limitedResults = fullResults.splice(0,10); 
        this.setState({"leadId": data.leadId});
        this.setState({"tourId": data.tourId});
        this.setState({"inventory": limitedResults});
      }.bind(this),
      function(error) { console.log(error) })

  }

  render() {
     
    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="SearchResults" yes={() => (

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Inventory />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Available Inventory</h4>
            </CardHeader>
            <CardBody>
              <div>
                <table cellPadding="5" cellSpacing="0" width="100%">
                <thead>
                  <tr bgcolor="#f0f0f0">
                    <td>Resort</td>
                    <td>Week Number</td>
                    <td>Unit Number</td>
                    <td>Use Type</td>
                    <td>Bedrooms</td>
                    <td>Bathrooms</td>
                    <td>Retail Price</td>
                    <td>Financing Eligible</td>
                  </tr>
                  </thead>
                  <tbody>  
                  {this.state.inventory.map((unitInventory, index) => (                    
                    <tr key={index}>
                      <td><Link
                      to={`/admin/inventory-tour/${unitInventory.InventoryId}/tour/${this.state.tourId}`}
                      className="ui basic button green">{ unitInventory.ResortName }</Link></td>
                      <td>{ unitInventory.UseWeek }</td>
                      <td>{ unitInventory.UnitNumber }</td>
                      <td>{ unitInventory.UseType }</td>
                      <td>{ unitInventory.Bedrooms }</td>
                      <td>{ unitInventory.Bathrooms }</td>
                      <td>{ tikiAPI.numberToDisplay(unitInventory.RetailPrice) }</td>
                      <td>{ unitInventory.FinancingEligible }</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      ) } />
      
      );
  }
}

export default withStyles(extendedTablesStyle)(Search);
