import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import * as moment from 'moment';

import Datetime from "react-datetime";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import LeadsIcon from "@material-ui/icons/Assignment";

import Button from "components/CustomButtons/Button.jsx";

// react router components

import "assets/main.css";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Leads_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      tour: "",
      salesReps: [],
      gifts: [],
      selectedGifts: [],
      selectTourTime: "",
      selectTourDate: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.id);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  componentDidMount(){
  }

  getData(tourId){
    tikiAPI.getTour(tourId,
      function(response) {
        this.setState({ tour: response, selectedGifts: response.giftNames.split(",") });
        var tourTime = moment(response.scheduledStartTime).format("hh:mm A");
        console.log(tourTime);
        this.setState( {selectTourTime: tourTime})
        this.setState( {selectTourDate: moment(this.state.tour.tourDate).format("MM/DD/YYYY")})
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );  

    tikiAPI.getSalesReps(
        function(data) {
          this.setState({salesReps: data});
        }.bind(this),
        function(error) { console.log(error) }
      );

    tikiAPI.getAvailableGifts(
      function(giftsResponse) {
        this.setState({gifts: giftsResponse});
      }.bind(this),
      function(error) {
        console.log(error);
      }
    );
  
  }

  updateTour() {


    this.setState({ tour: {
      ...this.state.tour,
      tourDate: this.state.selectTourDate,
      scheduledStartTime: this.state.selectTourDate + " " + this.state.selectTourTime
    } }, function() {
      tikiAPI.updateTour(this.state.tour,
        function(leadResponse) {
          window.location = '/admin/tours-detail/' + this.state.tour.tourId;
        }.bind(this),
        function(err) {
          console.log(err);
        }
      );  
    });  
  }

  handleInput = event => {
    this.setState({ tour: {
      ...this.state.tour,
      [event.target.id]: event.target.value
    } });
  }

  handleInputTourTime = event => {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleRemoveGift(event) {
    var currentGifts = this.state.selectedGifts;
    var index = currentGifts.indexOf(event.target.value);
    if (index !== -1) currentGifts.splice(index, 1);
    this.setState( {selectedGifts: currentGifts}, function() {
      this.setState({ tour: {
        ...this.state.tour,
        giftNames: this.state.selectedGifts.join()
      } });  
    });
  }

  handleSelectedGift = event => {
    var currentGifts = this.state.selectedGifts;
    currentGifts.push(event.target.value);
    this.setState( {selectedGifts: currentGifts}, function() {
      this.setState({ tour: {
        ...this.state.tour,
        giftNames: this.state.selectedGifts.join()
      } });  
    });
    event.target.value = "Select a Gift";
  }

  selectTourDate = date => {
    var selectedDate = date.format("MM/DD/YYYY");
    this.setState({ selectTourDate: selectedDate });
  }


  render() {  

    const { classes } = this.props;

    return (
      <Can permissions={this.state.user.permissions} perform="Tours_Edit" yes={() => (

        <div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <LeadsIcon />
                        </CardIcon>
                        
                        <h4 className={classes.cardTitle}>Edit Tour</h4>
                    </CardHeader>
                    
                    <CardBody>

                        <GridContainer>
                            <GridItem xs={12}><div align="center"><Button color="success" onClick={(e) => this.updateTour()}>Save</Button></div></GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12}><div className="softDivider">Tour Information</div></GridItem>
                        </GridContainer>
                        
                        <GridContainer>
                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Lead First Name</div>
                                <div><input id="leadFirstName" value={this.state.tour.leadFirstName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="First Name"></input></div>
                            </div>
                            </GridItem>

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Lead Last Name</div>
                                <div><input id="leadLastName" value={this.state.tour.leadLastName || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Last Name"></input></div>
                            </div>
                            </GridItem>

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Assigned Sales Rep</div>
                                <select id="assignedEmployeeId" value={this.state.tour.assignedEmployeeId || ''} onChange={(event) => this.handleInput(event)} className="formSelect">
                                    <option value="">Select Sales Rep</option>
                                    { (this.state.salesReps.length > 0) ? (
                                        this.state.salesReps.map((salesRep, index) => (
                                            <option key={index} value={salesRep.employeeId}>{salesRep.firstName} {salesRep.lastName}</option>
                                        )
                                      )                            
                                    ) : (
                                      <option>No available sales reps.</option>
                                    )
                                    }
                                </select>
                            </div>
                            </GridItem> 

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Marketing Source</div>
                                <select id="marketingSource" value={this.state.tour.marketingSource ||''} onChange={(event) => this.handleInput(event)} className="formSelect">
                                    <option value="">Select Marketing Source</option>
                                    <option value="RMC Check-In">RMC Check-In</option>
                                    <option value="Spinnaker Wine and Cheese">Spinnaker Wine and Cheese</option>
                                    <option value="Spinnaker Breakfast">Spinnaker Breakfast</option>
                                    <option value="Spinnaker Check-In">Spinnaker Check-In</option>
                                    <option value="Village Check-In">Village Check-In</option>
                                    <option value="Village Wine and Cheese">Village Wine and Cheese</option>
                                    <option value="Village Breakfast">Village Breakfast</option>
                                    <option value="Southwind II Wine and Cheese">Southwind II Wine and Cheese</option>
                                    <option value="Southwind II Check-In">Southwind II Check-In</option>
                                    <option value="Sea Crest Check-In">Sea Crest Check-In</option>
                                    <option value="Sea Crest Wine and Cheese">Sea Crest Wine and Cheese</option>
                                    <option value="Sea Crest Breakfast">Sea Crest Breakfast</option>
                                    <option value="Seawatch Check-In">Seawatch Check-In</option>
                                    <option value="Seawatch Wine and Cheese">Seawatch Wine and Cheese</option>
                                    <option value="Seawatch Breakfast">Seawatch Breakfast</option>
                                    <option value="The Players Club Check-In">The Players Club Check-In</option>
                                    <option value="The Players Club Wine and Cheese">The Players Club Wine and Cheese</option>
                                    <option value="The Players Club Breakfast">The Players Club Breakfast</option>
                                    <option value="Sands Village Check-In">Sands Village Check-In</option>
                                    <option value="Ocean Cove Check-In">Ocean Cove Check-In</option>
                                    <option value="Ocean Cove Wine and Cheese">Ocean Cove Wine and Cheese</option>
                                    <option value="Springwood Check-In">Springwood Check-In</option>
                                    <option value="Marriott's Grande Ocean Wine and Cheese">Marriott's Grande Ocean Wine and Cheese</option>
                                    <option value="Ocean View Wine and Cheese">Ocean View Wine and Cheese</option>
                                    <option value="Sands Village Wine and Cheese">Sands Village Wine and Cheese</option>
                                    <option value="Flyer">Flyer</option>
                                    <option value="Flynn">Flynn</option>
                                    <option value="Invitation">Invitation</option>
                                    <option value="Mimosas">Mimosas</option>
                                    <option value="Mailout">Mailout</option>
                                    <option value="Mini">Mini</option>
                                    <option value="Telemarketing">Telemarketing</option>
                                    <option value="OPC">OPC</option>
                                    <option value="Walk-In">Walk-In</option>
                                    <option value="Call In">Call In</option>
                                    <option value="Brochure">Brochure</option>
                                    <option value="Be Back">Be Back</option>
                                    <option value="Referral">Referral</option>
                                    <option value="Pool">Pool</option>
                                    <option value="RMC Staff">RMC Staff</option>
                                    <option value="Other"></option>
                                </select>
                            </div>
                            </GridItem> 
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Date</div>
                                <div className="DateTime-adjust">
                                  <Datetime dateFormat="MM/DD/YYYY" value={this.state.selectTourDate || ''} inputProps={{ placeholder: 'Choose Tour Date'}} timeFormat={false} closeOnSelect={true} onChange={this.selectTourDate} />
                                </div>
                            </div>
                            </GridItem>

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Time</div>
                                <select id="selectTourTime" value={this.state.selectTourTime || ''} onChange={(event) => this.handleInputTourTime(event)} className="formSelect">
                                  <option value="">Select Tour Time</option>
                                  <option value="09:00 AM">09:00 AM</option>
                                  <option value="09:15 AM">09:15 AM</option>
                                  <option value="09:30 AM">09:30 AM</option>
                                  <option value="09:45 AM">09:45 AM</option>
                                  <option value="10:00 AM">10:00 AM</option>
                                  <option value="10:15 AM">10:15 AM</option>
                                  <option value="10:30 AM">10:30 AM</option>
                                  <option value="10:45 AM">10:45 AM</option>
                                  <option value="11:00 AM">11:00 AM</option>
                                  <option value="11:15 AM">11:15 AM</option>
                                  <option value="11:30 AM">11:30 AM</option>
                                  <option value="11:45 AM">11:45 AM</option>
                                  <option value="12:00 PM">12:00 PM</option>
                                  <option value="12:15 PM">12:15 PM</option>
                                  <option value="12:30 PM">12:30 PM</option>
                                  <option value="12:45 PM">12:45 PM</option>
                                  <option value="01:00 PM">01:00 PM</option>
                                  <option value="01:15 PM">01:15 PM</option>
                                  <option value="01:30 PM">01:30 PM</option>
                                  <option value="01:45 PM">01:45 PM</option>
                                  <option value="02:00 PM">02:00 PM</option>
                                  <option value="02:15 PM">02:15 PM</option>
                                  <option value="02:30 PM">02:30 PM</option>
                                  <option value="02:45 PM">02:45 PM</option>
                                  <option value="03:00 PM">03:00 PM</option>
                                </select>
                            </div>
                            </GridItem>

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Status</div>
                                <select id="tourStatus" value={this.state.tour.tourStatus ||''} onChange={(event) => this.handleInput(event)} className="formSelect">
                                    <option value="">Select Tour Status</option>
                                    <option value="Scheduled">Scheduled</option>
                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Canceled">Canceled</option>
                                    <option value="No Show">No Show</option>
                                    <option value="Not Qualified">Not Qualified</option>
                                    <option value="Started">Started</option>
                                    <option value="Ended">Ended</option>
                                </select>
                            </div>
                            </GridItem> 

                            <GridItem xs={12} sm={8} md={2}>
                            <div>
                                <div className="displayLabel">Result</div>
                                <select id="tourResult" value={this.state.tour.tourResult ||''} onChange={(event) => this.handleInput(event)} className="formSelect">
                                    <option value="">Select Tour Result</option>
                                    <option value="Purchased">Purchased</option>
                                    <option value="No Buy">No Buy</option>
                                    <option value="Walk Out">Walk Out</option>
                                    <option value="Rescheduled">Rescheduled</option>
                                    <option value="Be Back">Be Back</option>
                                </select>
                            </div>
                            </GridItem> 
                        </GridContainer>


                          <GridContainer>
                            <GridItem xs={8}>
                            <div>
                                <div className="displayLabel">Notes</div>
                                <div><input id="notes" value={this.state.tour.notes || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="text" placeholder="Notes"></input></div>
                            </div>
                            </GridItem> 
                          </GridContainer>

                          <GridContainer>
                            <GridItem xs={12} md={3}>
                              <div className="displayLabel">Available Gifts</div>
                              <select className="formSelect" onChange={(event) => this.handleSelectedGift(event)} >
                                <option>Select a Gift</option>
                                { this.state.gifts.map((gift, index) =>
                                  (
                                    <option key={index}>{gift.giftName}</option>
                                  )
                                )}
                              </select>
                            </GridItem> 
  
                            <GridItem xs={12} sm={8} md={4}>
                            <div>
                                <div className="displayLabel">Tour Gifts</div>
                                
                                { this.state.selectedGifts.map((gift, index) =>
                                  (
                                    <div key={index} className="displayContent"><button value={gift} onClick={ (event) => this.handleRemoveGift(event)}>Delete</button>&nbsp;{gift}</div>
                                  )
                                )}
                            </div>
                            </GridItem> 
                          </GridContainer>

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        </div>

      )} />
      
      );
    }
}

export default withStyles(styles)(Leads_Detail);