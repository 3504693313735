import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import EpicMemberIcon from "@material-ui/icons/AccountCircle";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

import "assets/main.css";

// react router components
import { Link } from 'react-router-dom';

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle
};

class Epic_Contracts_Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      epicMembers: [],
      contracts: []
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getAllEpicMembers();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getAllEpicMembers() {
    tikiAPI.getAllEpicContracts(
      function(contracts) {
        this.setState( { contracts: contracts }, function() {
          tikiAPI.getAllEpicMembers(
            function(epicMembersResponse) {
              this.setState( { epicMembers: epicMembersResponse });
            }.bind(this),
            function(err) {
              console.log(err);
            })
          });
      }.bind(this),
      function(err) {
        console.log(err);
      })

  }
  
  getContract(id) {
    var searchContracts = this.state.contracts;
    searchContracts = searchContracts.filter(v => v.epicContractId == id);
    return searchContracts[0];
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Epic_Members_Main" yes={() => (

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"><EpicMemberIcon /></CardIcon>
              <h4 className={classes.cardTitle} style={{ color: "#00acc1", fontWeight: "400" }}>Epic Vacations Members</h4>
            </CardHeader>
              <CardBody>

                <GridContainer>
                  <table className="tableNewDesign">
                    <thead>
                      <tr>
                        <th>Member Name</th>
                        <th>Member # - Status</th>
                        <th>Contract # - Status</th>
                        <th>Signed</th>
                        <th>Tier</th>
                        <th>Start Date</th>
                        <th>RCI #</th>
                        <th>SoR #</th>
                        <th>Logged In</th>
                      </tr>
                    </thead>

                    <tbody>

                      {this.state.epicMembers.map((epicMember, index) => (
                        <tr key={index}>
                          <td><Link to={`/admin/epic-members-detail/${epicMember.memberId}`} className="ui basic button green">{epicMember.firstName} {epicMember.lastName}</Link></td>
                          <td>{epicMember.memberId} - {epicMember.memberStatus}</td>
                          <td>{epicMember.epicContractId} - {this.getContract(epicMember.epicContractId) && this.getContract(epicMember.epicContractId).contractStatus}</td>
                          <td>{this.getContract(epicMember.epicContractId) && this.getContract(epicMember.epicContractId).signedContractDocumentLink && 'Yes'}{this.getContract(epicMember.epicContractId) && !this.getContract(epicMember.epicContractId).signedContractDocumentLink && 'No'}</td>
                          <td>{epicMember.membershipTier}</td>
                          <td>{moment(epicMember.membershipStartDate).format('MM/DD/YYYY')}</td>
                          <td>{epicMember.rciMemberNumber}</td>
                          <td>{epicMember.sorLink}</td>
                          <td>{epicMember.passwordIsTemp == 'Yes' && 'No'}{epicMember.passwordIsTemp == 'No' && 'Yes'}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

      </GridContainer>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Epic_Contracts_Detail);