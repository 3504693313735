import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { cardTitle } from "assets/jss/tiki-main.jsx";
import { cardSubtitle } from "assets/jss/tiki-main.jsx";

// react router components
import { Link } from 'react-router-dom';

import "assets/main.css";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle
};

class Leads_Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      checked: [],
      leads: [],
      open: false,
      deleteData: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      searchResultMessage: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount() {
  }

  getData() {
    tikiAPI.getAllLeads(
      function(leadsData) {
        var leadsToDisplay = 25;
        leadsData.reverse();
        this.setState({ leads: leadsData.splice(0,leadsToDisplay) });
      }.bind(this),
      function(error) {
        console.log(error);
      });
  }

  deleteLead(leadId) {
    tikiAPI.deleteLead(leadId,
      function(response) {
        var listData = this.state.leads.filter(v => v.leadId !== response.leadId)
        this.setState({ leads: listData });
      },
      function(error) {
        console.log(error);
      });
  }

  postSearch(searchData) {
    this.setState({ searchResultMessage: ""});  

    tikiAPI.postSearch(searchData,
      function(searchResults) {
        if (searchResults.searchResponse.length === 2) {
          this.setState({ searchResultMessage: "No leads found."});  
        }
        console.log(searchResults);
        var fullResults = JSON.parse(searchResults.searchResponse);
        const camelcaseKeys = require('camelcase-keys');
        var camelResults = camelcaseKeys(fullResults);
        this.setState({leads:camelResults});  
      }.bind(this),
      function(error) {
        console.log(error);
      });

  }

  handleSearchLeads = () => {
    var searchData = `{ "userId" : ${this.state.user.userId}, "leadId" : 0, "searchType" : "leads", "searchCriteria" : "{'FirstName' : '${this.state.firstName}', 'LastName' : '${this.state.lastName}', 'MobilePhone' : '${this.state.mobilePhone}', 'HomePhone' : '${this.state.homePhone}', 'WorkPhone' : '${this.state.workPhone}', 'Email' : '${this.state.email}'}" }`;
    this.postSearch(searchData);
  }

  handleClickOpen = (idx, detID) => {
      console.log("handleClickOpen called");
    this.setState({ open: true });
    this.setState({ deleteData: detID })
  }
  handleClose = () => {
    this.setState({ open: false });
  };

  handleInput = event => {
    this.setState({ [event.target.id]: event.target.value });
  }

  render() {

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Leads_Main" yes={() => (
      
      <GridContainer>

        <Can permissions={this.state.user.permissions} perform="Leads_New" yes={() => (

        <GridItem xs={12}>
          <div align="center"><Link to='leads-new/'><Button color="success">New Lead</Button></Link></div>
        </GridItem>

        )} />

        <GridItem xs={1}>&nbsp;</GridItem>

        <GridItem xs={10}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={3}>
                  <CustomInput labelText="First name" id="firstName" value={this.state.firstName}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ onChange: (event) => this.handleInput(event), type: "text", value: this.state.firstName }}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput labelText="Last name" id="lastName" value={this.state.lastName}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ onChange: (event) => this.handleInput(event), type: "text", value: this.state.lastName }}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput labelText="Phone" id="phone" value={this.state.phone}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ onChange: (event) => this.handleInput(event), type: "text", value: this.state.phone }}
                  />
                </GridItem>

                <GridItem xs={3}>
                   <div className="verticalAlignButton"><Button onClick={this.handleSearchLeads}>Search</Button></div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        
        <GridItem xs={1}>&nbsp;</GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info"><Assignment /></CardIcon>
              <h4 className={classes.cardIconTitle}>Leads</h4>
            </CardHeader>
            
            <CardBody>
              <div>
                <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile Phone</th>
                    <th>Guest Vacation Location</th>
                    <th>Marketing Source</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.leads.map((lead, index) => (
                    <tr key={index}>
                      <td>
                        <Can permissions={this.state.user.permissions} perform="Leads_Detail"
                          yes={() => ( <Link to={`leads-detail/${lead.leadId}`} className="ui basic button green">{lead.firstName} {lead.lastName}</Link> ) }
                          no={() => ( <div>{lead.firstName} {lead.lastName}</div> ) }
                        />
                      </td>
                      <td>{lead.mobilePhone}</td>
                      <td>{lead.guestVacationLocation}</td>
                      <td>{lead.marketingSource}</td>
                      <td>{lead.leadStatus}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                {this.state.searchResultMessage && <div style={{ width: "100%", textAlign: "center" }}>{this.state.searchResultMessage}</div>}  
              </div>
          </CardBody>
        </Card>
      </GridItem> 
    </GridContainer>

    )} />
        
    );
  }
}

export default withStyles(styles)(Leads_Main);