const rules = {
    QA: {
        static: [
            "Accounting_Main",
            "Accounting_Detail",
            "Accounting_New",
            "Accounting_Edit",
            "Accounting_Admin",
            "Accounting_Sensitive",
            "Commission_Main",
            "Commission_Detail",
            "Commission_New",
            "Commission_Edit",
            "Commission_Admin",
            "Commission_Sensitive",
            "Contracts_Main",
            "Contracts_Detail",
            "Contracts_New",
            "Contracts_Edit",
            "Contracts_Admin",
            "Contracts_Sensitive",
            "Employees_Main",
            "Epic_Contracts_Main",
            "Epic_Contracts_Detail",
            "Epic_Contracts_New",
            "Epic_Contracts_Edit",
            "Epic_Contracts_Admin",
            "Epic_Contracts_Sensitive",
            "Epic_Members_Main",
            "Epic_Members_Detail",
            "Gifts_Main",
            "Gifts_Detail",
            "Gifts_New",
            "Gifts_Edit",
            "Gifts_Admin",
            "Gifts_Sensitive",
            "Inventory_Main",
            "Inventory_List",
            "Inventory_Detail",
            "Inventory_New",
            "Inventory_Edit",
            "Inventory_Admin",
            "Inventory_Sensitive",
            "Leads_Main",
            "Leads_Detail",
            "Leads_New",
            "Leads_Edit",
            "Leads_Admin",
            "Leads_Sensitive",
            "Owners_Main",
            "Owners_Detail",
            "Owners_New",
            "Owners_Edit",
            "Owners_Admin",
            "Owners_Sensitive",
            "PointsLedger_Main",
            "Premiums_Main",
            "Premiums_Detail",
            "Premiums_New",
            "Premiums_Edit",
            "Premiums_Admin",
            "Premiums_Sensitive",
            "Reports_Main",
            "Reports_Detail",
            "Reports_New",
            "Reports_Edit",
            "Reports_Admin",
            "Reports_Sensitive",
            "SearchResults",
            "Tours_Main",
            "Tours_Detail",
            "Tours_New",
            "Tours_Edit",
            "Tours_Admin",
            "Tours_Sensitive",
            "Tours_Manifest",
        ]
    },
    Executive: {
        static: [
            "Accounting_Main",
            "Accounting_Detail",
            "Accounting_New",
            "Accounting_Edit",
            "Accounting_Admin",
            "Accounting_Sensitive",
            "Commission_Main",
            "Commission_Detail",
            "Commission_New",
            "Commission_Edit",
            "Commission_Admin",
            "Commission_Sensitive",
            "Contracts_Main",
            "Contracts_Detail",
            "Contracts_New",
            "Contracts_Edit",
            "Contracts_Admin",
            "Contracts_Sensitive",
            "Employees_Main",
            "Employees_Detail",
            "Employees_New",
            "Employees_Edit",
            "Epic_Contracts_Main",
            "Epic_Contracts_Detail",
            "Epic_Contracts_New",
            "Epic_Contracts_Edit",
            "Epic_Contracts_Admin",
            "Epic_Contracts_Sensitive",
            "Epic_Members_Main",
            "Epic_Members_Detail",
            "Epic_Members_Edit",
            "Gifts_Main",
            "Gifts_Detail",
            "Gifts_New",
            "Gifts_Edit",
            "Gifts_Admin",
            "Gifts_Sensitive",
            "Inventory_Main",
            "Inventory_List",
            "Inventory_Detail",
            "Inventory_New",
            "Inventory_Edit",
            "Inventory_Admin",
            "Inventory_Sensitive",
            "Leads_Main",
            "Leads_Detail",
            "Leads_New",
            "Leads_Edit",
            "Leads_Admin",
            "Leads_Sensitive",
            "Owners_Main",
            "Owners_Detail",
            "Owners_New",
            "Owners_Edit",
            "Owners_Admin",
            "Owners_Sensitive",
            "PointsLedger_Main",
            "Premiums_Main",
            "Premiums_Detail",
            "Premiums_New",
            "Premiums_Edit",
            "Premiums_Admin",
            "Premiums_Sensitive",
            "Reports_Main",
            "Reports_Detail",
            "Reports_New",
            "Reports_Edit",
            "Reports_Admin",
            "Reports_Sensitive",
            "SearchResults",
            "Settings_Main",
            "Tours_Main",
            "Tours_Detail",
            "Tours_New",
            "Tours_Edit",
            "Tours_Admin",
            "Tours_Sensitive",
            "Tours_Manifest",
        ]
    },
    Inventory_Manager: {
        static: [
            "Contracts_Main",
            "Contracts_Detail",
            "Epic_Contracts_Main",
            "Epic_Contracts_Detail",
            "Epic_Members_Main",
            "Epic_Members_Detail",
            "Epic_Members_Edit",
            "Gifts_Main",
            "Gifts_Detail",
            "Inventory_Main",
            "Inventory_Detail",
            "Inventory_New",
            "Inventory_Sensitive",
            "Inventory_List",
            "Leads_Main",
            "Leads_Detail",
            "Owners_Main",
            "Owners_Detail",
            "Owners_New",
            "Owners_Edit",
            "Owners_Admin",
            "Owners_Sensitive",
            "PointsLedger_Main",
            "PointsLedger_Detail",
            "PointsLedger_New",
            "PointsLedger_Edit",
            "PointsLedger_Admin",
            "PointsLedger_Sensitive",
            "Reports_Main",
            "Reports_Detail",
            "Reports_New",
            "Reports_Sensitive",
            "SearchResults",
        ]
    },
    Sales_Manager: {
        static: [
            "Commission_Main",
            "Commission_Detail",
            "Commission_Sensitive",
            "Contracts_Main",
            "Contracts_Detail",
            "Contracts_New",
            "Contracts_Edit",
            "Contracts_Admin",
            "Contracts_Sensitive",
            "Epic_Contracts_Main",
            "Epic_Contracts_Detail",
            "Epic_Contracts_New",
            "Epic_Contracts_Edit",
            "Epic_Contracts_Admin",
            "Employees_Main",
            "Employees_Detail",
            "Employees_New",
            "Employees_Edit",
            "Gifts_Main",
            "Gifts_Detail",
            "Gifts_Sensitive",
            "Inventory_Main",
            "Inventory_Detail",
            "Inventory_List",
            "Inventory_New",
            "Inventory_Edit",
            "Leads_Main",
            "Leads_Detail",
            "Leads_New",
            "Leads_Edit",
            "Leads_Sensitive",
            "SearchResults",
            "Tours_Main",
            "Tours_Detail",
            "Tours_Edit",
            "Tours_Manifest",
            "Tours_New",
            "Tours_Sensitive",
        ]
    },
    Sales_Administrator: {
        static: [
            "Commission_Main",
            "Commission_Detail",
            "Commission_Sensitive",
            "Contracts_Main",
            "Contracts_Detail",
            "Contracts_New",
            "Contracts_Edit",
            "Contracts_Admin",
            "Contracts_Sensitive",
            "Epic_Contracts_Main",
            "Epic_Contracts_Detail",
            "Epic_Contracts_New",
            "Epic_Contracts_Edit",
            "Epic_Contracts_Admin",
            "Epic_Members_Main",
            "Epic_Members_Detail",
            "Epic_Members_Edit",
            "Gifts_Main",
            "Gifts_Detail",
            "Gifts_Sensitive",
            "Inventory_Main",
            "Inventory_Detail",
            "Inventory_List",
            "Inventory_New",
            "Inventory_Edit",
            "Leads_Main",
            "Leads_Detail",
            "Leads_New",
            "Leads_Edit",
            "Leads_Sensitive",
            "SearchResults",
            "Tours_Main",
            "Tours_Detail",
            "Tours_Edit",
            "Tours_Manifest",
            "Tours_New",
            "Tours_Sensitive",
        ]
    },
    Receptionist: {
        static: [
            "Gifts_Main",
            "Gifts_Detail",
            "Gifts_New",
            "Leads_Main",
            "Leads_Detail",
            "Leads_Edit",
            "Leads_New",
            "SearchResults",
            "Tours_Main",
            "Tours_Detail",
            "Tours_Edit",
            "Tours_New",
            "Tours_Manifest",
        ]
    },
    Accounting_Manager: {
        static: [
            "Accounting_Main",
            "Accounting_Detail",
            "Accounting_New",
            "Accounting_Edit",
            "Accounting_Admin",
            "Accounting_Sensitive",
            "Commission_Main",
            "Commission_Detail",
            "Commission_New",
            "Commission_Edit",
            "Commission_Admin",
            "Commission_Sensitive",
            "Contracts_Main",
            "Contracts_Detail",
            "Contracts_Sensitive",
            "Epic_Contracts_Main",
            "Epic_Contracts_Detail",
            "Epic_Contracts_Sensitive",
            "Epic_Members_Main",
            "Epic_Members_Detail",
            "Employees_Main",
            "Employees_Detail",
            "Employees_New",
            "Employees_Edit",
            "Gifts_Main",
            "Gifts_Detail",
            "Gifts_New",
            "Gifts_Edit",
            "Gifts_Admin",
            "Gifts_Sensitive",
            "Inventory_Main",
            "Inventory_Detail",
            "Inventory_Sensitive",
            "Leads_Main",
            "Leads_Detail",
            "PointsLedger_Main",
            "Premiums_Main",
            "Premiums_Detail",
            "Premiums_New",
            "Premiums_Edit",
            "Premiums_Admin",
            "Premiums_Sensitive",
            "Reports_Main",
            "Reports_Detail",
            "Reports_New",
            "Reports_Edit",
            "Reports_Admin",
            "Reports_Sensitive",
            "SearchResults",
            "Tours_Main",
            "Tours_Detail",
            "Tours_Manifest",
        ]
    },
    Inventory_Tablet: {
        static: [
            "Inventory_Main",
            "Inventory_List",
            "Inventory_Detail",
        ]
    }, 
    Closing_Manager: {
        static: [
            "Contracts_Main",
            "Contracts_Detail",
            "Contracts_Edit",
            "Contracts_Admin",
            "Contracts_Sensitive",
            "Epic_Contracts_Main",
            "Epic_Contracts_Detail",
            "Epic_Contracts_Edit",
            "Epic_Contracts_Admin",
            "Epic_Contracts_Sensitive",
            "Epic_Members_Main",
            "Epic_Members_Detail"
        ]
    },
    Epic_Manager: {
        static: [
            "Leads_Main",
            "Leads_Detail",
            "Tours_Main",
            "Tours_Detail",
            "Gifts_Main",
            "Gifts_Detail",
            "Reports_Main",
            "Reports_Detail",
            "Epic_Contracts_Main",
            "Epic_Contracts_Detail",
            "Epic_Contracts_Edit",
            "Epic_Contracts_Admin",
            "Epic_Contracts_Sensitive",
            "Epic_Members_Main",
            "Epic_Members_Detail",
            "Epic_Members_Edit",
            "Epic_Members_Admin",
            "Epic_Members_Sensitive",
            "PointsLedger_Main"
        ]
    }  
};

export default rules;