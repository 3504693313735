import React from "react";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import "assets/main.css";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      user: "",
      newPassword: "",
      repeatNewPassword: ""
    };

    tikiAPI.authUser(this.props.match.params.authToken,
        function(userData) {
            console.log(userData);
            this.setState({ user: userData });
        }.bind(this),
        function(err) {
            console.log(err);
            window.location = '/auth/login-page';
        });

  }

  handleInput = event => {
    this.setState({[event.target.id]: event.target.value});
  }

  savePassword() {

    if (this.state.newPassword != this.state.repeatNewPassword) {
      document.getElementById('errorMessage').innerHTML = "Your passwords don't match, please try again.";
    } else {
      var updateUser = this.state.user;
      updateUser.password = this.state.newPassword;
  
      tikiAPI.updateUser(updateUser,
        function() {
          cookies.set('isAuthenticated', true, { path: '/' });
          cookies.set('authToken', this.state.user.authToken, { path: '/' });
          document.location.href = this.state.user.homeRoute;
        }.bind(this),
        function(err) {
            console.log(err);
            //window.location = '/auth/login-page';
        });  
    }

  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="warning"
                >
                  <h4 className={classes.cardTitle}>Change Password</h4>
                </CardHeader>
                <CardBody>
                  <div className="inputLabel">New Password</div>
                  <input id="newPassword" value={this.state.newPassword || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="password" placeholder="New Password"></input>

                  <div className="inputLabel">Repeat Password</div>
                  <input id="repeatNewPassword" value={this.state.repeatNewPassword || ''} onChange={(event) => this.handleInput(event)} className="formInput" type="password" placeholder="Repeat Password"></input>

                  <div id="errorMessage"></div>

                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="info" simple size="lg" block onClick={ e => {
                      this.savePassword();
                    }}>
                    Save Password
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(ChangePassword);