import React from "react";
import { TikiAPI } from "../../global";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';
import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import InventoryIcon from "@material-ui/icons/VpnKey";
import Button from "components/CustomButtons/Button.jsx";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      inventory: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.getData(this.props.match.params.inventoryId);
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount(){
  }

  getData(inventoryId){

    tikiAPI.getInventory(inventoryId,
      function(data) {
        this.setState({inventory: data});
      }.bind(this),
      function(error) { console.log(error) }
      );
  
    }
      
  purchaseInventory() {
    window.location = `/admin/contracts-new-for-tour/${this.props.match.params.tourId}/inventory/${this.props.match.params.inventoryId}`;
  }

  render() {  

    const { classes } = this.props;

    return (

      <Can permissions={this.state.user.permissions} perform="Inventory_Detail" yes={() => (

      <div>
        <GridContainer>

        <Can permissions={this.state.user.permissions} perform="Contracts_New" yes={() => (

        <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
          <Button color="info" onClick={(e) => this.purchaseInventory()}>Purchase</Button>
        </GridItem>

        ) } />

          <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <InventoryIcon />
              </CardIcon>
              <h4 className={classes.cardTitle}>Inventory Detail</h4>
            </CardHeader>
            <CardBody>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Listing Summary</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Inventory ID</div>
                    <div className="displayContent">{this.state.inventory.inventoryId}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Seller Name</div>
                    <div className="displayContent">
                      {this.state.inventory.sellerFirstName} {this.state.inventory.sellerMiddleName} {this.state.inventory.sellerLastName}
                      {this.state.inventory.sellerCompanyName}
                      {this.state.inventory.sellerTrustName}
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Status</div>
                    <div className="displayContent">{this.state.inventory.listingStatus}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Start Date</div>
                    <div className="displayContent">{moment(this.state.inventory.listingStartDate).format("MM/DD/YYYY")}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing End Date</div>
                    <div className="displayContent">{moment(this.state.inventory.listingEndDate).format("MM/DD/YYYY")}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Unit Details</div></GridItem>
              </GridContainer>

              <GridContainer>
              <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Resort</div>
                    <div className="displayContent">{this.state.inventory.resortName}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit #</div>
                    <div className="displayContent">{this.state.inventory.unitNumber}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Week</div>
                    <div className="displayContent">{this.state.inventory.useWeek}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Use Year Type</div>
                    <div className="displayContent">{this.state.inventory.useYearType}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Next Use Year</div>
                    <div className="displayContent">{this.state.inventory.nextUseYear}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={3}>
                  <div>
                    <div className="displayLabel">Unit Type</div>
                    <div className="displayContent">{this.state.inventory.unitType}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Bedrooms</div>
                    <div className="displayContent">{this.state.inventory.bedrooms}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Bathrooms</div>
                    <div className="displayContent">{this.state.inventory.bathrooms}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Unit Sleeps</div>
                    <div className="displayContent">{this.state.inventory.unitSleeps}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Golf Package</div>
                    <div className="displayContent">{this.state.inventory.golfPackage}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Points</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Point Value</div>
                    <div className="displayContent">{this.state.inventory.pointsAmount}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listed w/ Points</div>
                    <div className="displayContent">{this.state.inventory.listedWithPoints}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Points Season</div>
                    <div className="displayContent">{this.state.inventory.pointsSeason}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Financial</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Retail Price</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.retailPrice)}</div>
                  </div>
                </GridItem>

                <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Listing Price</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.listingPrice)}</div>
                  </div>
                </GridItem>
                )} />

                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Maintenance Fee</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.maintFee)}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Financing Eligible</div>
                    <div className="displayContent">{this.state.inventory.financingEligible}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Marketing Fee</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Marketing Fee</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.mktFee)}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Mkt Fee Date Paid</div>
                    <div className="displayContent">{moment(this.state.inventory.mktFeeDatePd).format("MM/DD/YYYY")}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Amount Paid</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.amountPaid)}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Variance</div>
                    <div className="displayContent">{tikiAPI.numberToDisplay(this.state.inventory.variance)}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Seller Information</div></GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Entity Type</div>
                    <div className="displayContent">{this.state.inventory.sellerEntityType}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">HOA Owned</div>
                    <div className="displayContent">{this.state.inventory.isHoaOwned}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Seller Name</div>
                    <div className="displayContent">
                      {this.state.inventory.sellerFirstName} {this.state.inventory.sellerMiddleName} {this.state.inventory.sellerLastName}
                      {this.state.inventory.sellerCompanyName}
                      {this.state.inventory.sellerTrustName}
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Email</div>
                    <div className="displayContent">{this.state.inventory.sellerEmail}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Address</div>
                    <div className="displayContent">
                      {this.state.inventory.sellerAddress}<br />
                      {this.state.inventory.sellerCity}, {this.state.inventory.sellerState} {this.state.inventory.sellerZipcode} {this.state.inventory.sellerCountry}<br />
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Home Phone</div>
                    <div className="displayContent">{this.state.inventory.sellerHomePhone}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Work Phone</div>
                    <div className="displayContent">{this.state.inventory.sellerWorkPhone}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Mobile Phone</div>
                    <div className="displayContent">{this.state.inventory.sellerMobilePhone}</div>
                  </div>
                </GridItem>
              </GridContainer>

              <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
              <GridContainer>
                <GridItem xs={12}><div className="softDivider">Internal Notes</div></GridItem>
              </GridContainer>
              )} />

              <Can permissions={this.state.user.permissions} perform="Inventory_Sensitive" yes={() => (
              <GridContainer>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Entry Date</div>
                    <div className="displayContent">{moment(this.state.inventory.entryDate).format("MM/DD/YYYY")}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Verified By</div>
                    <div className="displayContent">{this.state.inventory.verifiedBy}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={2}>
                  <div>
                    <div className="displayLabel">Date Verified</div>
                    <div className="displayContent">{moment(this.state.inventory.dateVerified).format("MM/DD/YYYY")}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={6}>
                  <div>
                    <div className="displayLabel">Notes</div>
                    <div className="displayContent">{this.state.inventory.notes}</div>
                  </div>
                </GridItem>
              </GridContainer>
              )} />

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      ) } />
      
      );
  }
}

export default withStyles(styles)(Panels);