import React from "react";
import Can from "../../components/Can";
import Cookies from 'universal-cookie';

import { TikiAPI } from "../../global";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import InventoryIcon from "@material-ui/icons/VpnKey";

// react router components
import { Link } from 'react-router-dom'

import "assets/main.css";

const cookies = new Cookies();
const tikiAPI = new TikiAPI();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      inventory: [],
      displayInventory: [],
      resortInventory: [],
      selectResort: "",
      inputUnitNumber: "",
      resortSelect: [],
      locationSelect: "",
      bedroomSelect: "",
      unitSelect: "",
      priceSelect: "",
      seasonSelect: "",
      typeSelect: "",
      timeshareTypeSelect: "",
      checked: [],
      tourId: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {

      tikiAPI.authUser(cookies.get('authToken'),
        function(userData) {
          this.setState({ user: userData });
          this.setState({ tourId: this.props.match.params.tourId });
          this.getAllInventory();
        }.bind(this),
        function(err) {
          window.location = '/auth/login-page';
        }
      );

    }

  }

  componentDidMount() {
  }

  
 
  handleKeyPress = event => {
    console.log(this.state.resortInventory);

    if (event.key === "Enter") {
      this.searchInventory();
    }

  }

  searchInventory = event => {
          // TODO: filter based on each searchField everytime; ignore if blank; create display inventory; create searchInventory() menthod

          var searchInventory = this.state.inventory;

          searchInventory = searchInventory.filter(v => v.listingStatus != 'Expired');

          if (document.getElementById('search_resortName').value != "") {
            searchInventory = searchInventory.filter(v => v.resortName == document.getElementById('search_resortName').value);
          }
    
          if (document.getElementById('search_unitNumber').value != "") {
            searchInventory = searchInventory.filter(v => v.unitNumber == document.getElementById('search_unitNumber').value);
          }
    
          if (document.getElementById('search_useWeek').value != "") {
            console.log(document.getElementById('search_useWeek').value);
            var weeks = document.getElementById('search_useWeek').value.split(",");
            console.log(weeks);

            var subsearchInventory = [];
            var isFirstLoad = true;

            for (var week in weeks) {
              var matchingWeekInventory = searchInventory.filter(v => v.useWeek == weeks[week].trim());

              if (isFirstLoad) {
                subsearchInventory = matchingWeekInventory;
                isFirstLoad = false;
              } else {
                subsearchInventory = subsearchInventory.concat(matchingWeekInventory);
              }
            }
 
              searchInventory = subsearchInventory;
              
          }
    
          if (document.getElementById('search_useType').value != "") {
            searchInventory = searchInventory.filter(v => v.useType == document.getElementById('search_useType').value);
          }
    
          if (document.getElementById('search_bedrooms').value != "") {
            searchInventory = searchInventory.filter(v => v.bedrooms == document.getElementById('search_bedrooms').value);
          }
    
          if (document.getElementById('search_bathrooms').value != "") {
            searchInventory = searchInventory.filter(v => v.bathrooms == document.getElementById('search_bathrooms').value);
          }
    
          if (document.getElementById('search_retailPrice').value != "") {
            searchInventory = searchInventory.filter(v => v.retailPrice < document.getElementById('search_retailPrice').value);
          }
    
          if (document.getElementById('search_isHoaOwned').value != "") {
            searchInventory = searchInventory.filter(v => v.isHoaOwned == document.getElementById('search_isHoaOwned').value);
          }
    
          if (document.getElementById('search_pointsAmount').value != "") {
            searchInventory = searchInventory.filter(v => v.pointsAmount == document.getElementById('search_pointsAmount').value);
          }  
          this.setState({ searchInventory: searchInventory });
          this.setState({ displayInventory: searchInventory.slice(0,15) });
    
          if (!searchInventory) {
            alert("No inventory matches your search.  Please try again.");
          }    
  }

  handleResortSelect = (event) => {
    this.searchInventory();
  }

  getAllInventory() {

    tikiAPI.getAllInventory(
      function(data) {
        var filteredData = data.filter(v => v.listingStatus == 'Available');
        this.setState({ inventory: filteredData });
        const resorts = [...new Set(this.state.inventory.map(x => x.resortName))]
        this.setState({ resortSelect: resorts});
      }.bind(this),
      function(error) { console.log(error) }
    );
    
  }

  render() {

    const { classes } = this.props;

    return (

    <Can permissions={this.state.user.permissions} perform="Inventory_Main" yes={() => (

    <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

            <Can permissions={this.state.user.permissions} perform="Inventory_List" yes={() => (

            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <InventoryIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Inventory</h4>
              </CardHeader>
              <CardBody>

                <table cellPadding="5" cellSpacing="0" width="100%">
                  <thead>
                    <tr>
                      <td width="25%">
                        <select id="search_resortName" className="searchInput" onChange={(event) => this.handleResortSelect(event)}>
                          <option value="">Select Resort</option>
                          {this.state.resortSelect.map((resort, index) => (
                            <option key={index} value={resort}>{resort}</option>
                          ))}
                        </select>
                      </td>                    
                      <td><input id="search_unitNumber" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Unit #" /></td>                    
                      <td><input id="search_useWeek" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Week #" /></td>                    
                      <td><input id="search_useType" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Use Type" /></td>                    
                      <td><input id="search_bedrooms" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Bed" /></td>                    
                      <td><input id="search_bathrooms" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Bath" /></td>                    
                      <td><input id="search_retailPrice" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Retail Price" /></td>                    
                      <td><input id="search_isHoaOwned" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="HOA Owned" /></td>                    
                      <td><input id="search_pointsAmount" onBlur={(event) => this.searchInventory(event)} onKeyPress={(event) => this.handleKeyPress(event)} className="searchInput" type="text" placeholder="Points" /></td>                    
                    </tr>

                    <tr>
                      <th>Resort</th>                    
                      <th>Unit #</th>
                      <th>Week #</th>                    
                      <th>Use Type</th>                    
                      <th>Bed</th>
                      <th>Bath</th>
                      <th align="right">Retail Price</th>
                      <th>HOA Owned</th>
                      <th>Points Value</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.displayInventory.map((inv, index) => (
                    <tr key={index}>
                        <td><Link
                        to={`/admin/inventory-detail-for-purchase/${inv.inventoryId}/for-tour/${this.state.tourId}`}
                        className="ui basic button green">{inv.resortName}</Link></td>
                        <td>{inv.unitNumber}</td>
                        <td>{inv.useWeek}</td>
                        <td>{inv.useType}</td>
                        <td>{inv.bedrooms}</td>
                        <td>{inv.bathrooms}</td>
                        <td align="right">{tikiAPI.numberToDisplay(inv.retailPrice)}</td>
                        <td>{inv.isHoaOwned}</td>
                        <td>{inv.pointsAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </CardBody>
            </Card>
            
            )} />

          </GridItem>
        </GridContainer>
      </div>

    ) } />
    );
  }
}

export default withStyles(styles)(Panels);