import React from "react";
import { TikiAPI } from "../../global";
import Cookies from 'universal-cookie';

import Chart from "react-google-charts";

import * as moment from 'moment';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { cardSubtitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReportsIcon from "@material-ui/icons/BarChart";

import "assets/main.css";

const tikiAPI = new TikiAPI();
const cookies = new Cookies();

const styles = {
  cardTitle,
  cardSubtitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      tours: [],
      reportTopics: [],
      displayReport: [],
      reportByTitle: ""
    };

    if (!cookies.get('authToken')) {
      window.location = '/auth/login-page';
    } else {
      tikiAPI.authUser(cookies.get('authToken'),
        function (userData) {
          this.setState({ user: userData });
          this.getAllTours();
          this.setState({ reportByTitle: "Sales Rep" });
        }.bind(this),
        function (err) {
          window.location = '/auth/login-page';
        }
      );
    }

  }

  getAllTours() {

    tikiAPI.getToursFromDateRange(
      moment(moment.now()).subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
      moment(moment.now()).endOf("year").format("YYYY-MM-DD"),
      function (data) {
        this.setState({ tours: data }, function () {
          this.calcReport();
        });
      }.bind(this),
      function (error) { console.log(error) }
    );
  }

  calcReport() {

    var reportRows = [];

    var reportType = "assignedEmployeeId";

    console.log(this.state.tours);

    this.state.tours.forEach((tour) => {
      if (tour[reportType] === null) { return };

      var topicIndex = this.state.reportTopics.indexOf(tour[reportType].toString());

      if (topicIndex == -1) {
        this.state.reportTopics.push(tour[reportType].toString());
      }

      topicIndex = this.state.reportTopics.indexOf(tour[reportType].toString());

      var salesRepName = "";

      if (tour.assignedEmployeeFirstName) { salesRepName += tour.assignedEmployeeFirstName };

      salesRepName += " ";

      if (tour.assignedEmployeeLastName) { salesRepName += tour.assignedEmployeeLastName };

      if (reportRows[topicIndex] === undefined) {
        reportRows[topicIndex] = {
          reportType: salesRepName,
          tourCount: 0,
          salesSum: 0,
          salesCount: 0,
          noShow: 0,
          goodTours: 0,
          noQual: 0,
          qual: 0,
          rescindCount: 0,
          rescindSalesSum: 0
        };
      }

      reportRows[topicIndex].tourCount++;

      if (tour.contractStatus == "Rescinded") {
        reportRows[topicIndex].salesCount++;
        reportRows[topicIndex].salesSum += tour.salesVolume;
        reportRows[topicIndex].rescindCount++;
        reportRows[topicIndex].rescindSalesSum += tour.salesVolume;
      }

      if (tour.tourStatus === "No Show" || tour.tourStatus === "Canceled") {
        reportRows[topicIndex].noShow++;
        return;
      } else {
        reportRows[topicIndex].goodTours++;
      }

      if (tour.tourStatus === "Not Qualified") {
        reportRows[topicIndex].noQual++;
        return;
      } else {
        reportRows[topicIndex].qual++;
      }

      if (tour.tourResult == "Purchased") {
        reportRows[topicIndex].salesCount++;
        reportRows[topicIndex].salesSum += tour.salesVolume;
      }
    });

    reportRows.sort(function (a, b) { return (a.reportType > b.reportType) ? 1 : ((b.reportType > a.reportType) ? -1 : 0); });

    this.setState({ displayReport: reportRows });

    console.log(reportRows);

  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ReportsIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Sales Dashboard</h4>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  <GridItem xs={11}>
                    <div className="chartTitle">Monthly Sales Volume</div>
                    <div style={{ display: 'flex', maxWidth: 1200 }}>
                      <Chart
                        width={1000}
                        height={300}
                        chartType="Bar"
                        data={[
                          ['', '2022 Sales', '2021 Sales'],
                          ['Jan', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("01", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("01", "2021", this.state.tours))],
                          ['Feb', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("02", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("02", "2021", this.state.tours))],
                          ['Mar', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("03", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("03", "2021", this.state.tours))],
                          ['Apr', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("04", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("04", "2021", this.state.tours))],
                          ['May', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("05", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("05", "2021", this.state.tours))],
                          ['Jun', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("06", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("06", "2021", this.state.tours))],
                          ['Jul', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("07", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("07", "2021", this.state.tours))],
                          ['Aug', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("08", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("08", "2021", this.state.tours))],
                          ['Sep', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("09", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("09", "2021", this.state.tours))],
                          ['Oct', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("10", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("10", "2021", this.state.tours))],
                          ['Nov', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("11", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("11", "2021", this.state.tours))],
                          ['Dec', tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("12", "2022", this.state.tours)), tikiAPI.getSalesVolumeForTours(tikiAPI.filterToursByMonthAndYear("12", "2021", this.state.tours))],
                        ]}
                      />
                    </div>
                  </GridItem>
                </GridContainer>


              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Panels);